import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  expandableList: {
    position: "relative",
  },
  listExpanderBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    bottom: 0,
  },
  listExpanderBoxExpanded: {},
  listExpanderBoxCollapsed: {
    position: "absolute",
    background: `linear-gradient(0deg, ${theme.palette.light.main}, transparent)`,
  },
}));

export default function TruncatedContainer({
  children,
  collapsedHeight,
  initialExpanded,
  ...rest
}) {
  collapsedHeight = collapsedHeight || 250;

  const classes = useStyles();
  const [contentHeight, setContentHeight] = React.useState(0);
  const [expanded, setExpanded] = React.useState(initialExpanded || false);

  const refCallback = (element) => {
    if (element) {
      setContentHeight(element.getBoundingClientRect().height);
    }
  };

  const showShowMore = contentHeight > collapsedHeight;
  const actualCollapsedHeight = Math.min(contentHeight, collapsedHeight);

  return (
    <Box className={classes.expandableList} {...rest}>
      <Collapse collapsedHeight={actualCollapsedHeight} in={expanded}>
        <div ref={refCallback}>{children}</div>
      </Collapse>
      {showShowMore && (
        <Box
          className={clsx(
            classes.listExpanderBox,
            expanded ? classes.listExpanderBoxExpanded : classes.listExpanderBoxCollapsed
          )}
          mt={1}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? "Show Fewer" : "Show More"}
          </Button>
        </Box>
      )}
    </Box>
  );
}
