import { Actions, bureaus } from "./constants";
import { useEffect, useReducer, useState } from "react";

import api from "../../../api";

export const useBureauController = (enrollmentId) => {
  const [tab, setTab] = useState(0);

  const [feedTimeLine, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case Actions.GET_FEED_TIMELINE:
          return {
            ...state,
            status: "loading",
            data: action.payload,
          };
        case Actions.GET_FEED_TIMELINE_SUCCESS:
          return {
            ...state,
            status: "success",
            data: action.payload.items,
          };
        case Actions.GET_FEED_TIMELINE_ERROR:
          return {
            ...state,
            status: "error",
            data: action.payload,
          };
        default:
          return state;
      }
    },
    {
      status: "loading", // loading | success | error
      data: undefined,
    }
  );

  const getFeedTimeLine = async (bureau) => {
    dispatch({ type: Actions.GET_FEED_TIMELINE });
    try {
      const response = await api.getFeedTimeLine(enrollmentId, {
        bureau: bureaus[bureau],
      });
      dispatch({ type: Actions.GET_FEED_TIMELINE_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({
        type: Actions.GET_FEED_TIMELINE_ERROR,
        payload: error.message ?? "Unrecognized error occurred",
      });
    }
  };

  useEffect(() => {
    getFeedTimeLine(tab);
  }, [tab]);

  return {
    tab,
    setTab,
    feedTimeLine,
    getFeedTimeLine,
  };
};
