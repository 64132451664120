import React from "react";
import DetailSection from "./DetailSection";
import SimpleTable from "./SimpleTable";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    scrollbarWidth: "none", // Firefox
    "&::-webkit-scrollbar": {
      display: "none", // Chrome
    },
    border: "1px solid #ccc",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  bureauHeader: {
    textAlign: "center",
    margin: theme.spacing(2, 0),
  },
}));

export default function FurnishmentErrors({ furnishment }) {
  const classes = useStyles();

  const bureauMap = (furnishment.notReportedTradelines || []).reduce(
    (acc, [id, message, bureau]) => {
      bureau = bureau || "noBureau";
      if (!acc[bureau]) {
        acc[bureau] = {};
      }
      if (!acc[bureau][message]) {
        acc[bureau][message] = [];
      }
      acc[bureau][message].push(id);
      return acc;
    },
    {}
  );

  const bureauSections = Object.entries(bureauMap).map(([bureau, messages]) => {
    const messageRows = Object.entries(messages).map(([message, ids]) => ({
      message,
      ids: ids.join(", "),
    }));
    return { bureau, messageRows };
  });

  if (!bureauSections.length) return null;

  return (
    <DetailSection title="Errors">
      {bureauSections.map((section) => (
        <div
          key={section.bureau}
          className={section.bureau !== "noBureau" ? classes.tableContainer : ""}
        >
          {section.bureau !== "noBureau" && (
            <h3 className={classes.bureauHeader}>{section.bureau}</h3>
          )}
          <SimpleTable
            headers={["Message", "IDs"]}
            rows={section.messageRows}
            keyRowAttr="message"
            toCells={(row) => [row.message, row.ids]}
          />
        </div>
      ))}
    </DetailSection>
  );
}
