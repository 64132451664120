import React from "react";
import SimpleTable from "./SimpleTable";
import Time from "../lithic/Moment";
import _ from "lodash";
import Money from "../lithic/Money";

export default function LineItemBreakDownTable({ items, className, shown }) {
  if (!shown) return null;

  return (
    <SimpleTable
      className={className}
      tableProps={{ size: "small" }}
      headers={["Name", "Fee"]}
      rows={items}
      getKey={(row) => row.name}
      toCells={(row) => [row.name, <Money key={1} value={row.fee} />]}
    />
  );
}
