import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormError from "./FormError";
import React from "react";

export default function ConfirmationDialog({
  open,
  cancelProps,
  confirmProps,
  onConfirm,
  onCancel,
  text,
  title,
  error,
  resetError,
  children,
}) {
  function handleCancel(e) {
    e.preventDefault();
    resetError && resetError();
    onCancel && onCancel();
  }
  function handleConfirm(e) {
    e.preventDefault();
    resetError && resetError();
    onConfirm && onConfirm();
  }
  return (
    <div>
      <Dialog open={open} onClose={onCancel}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {children ? children : <DialogContentText>{text}</DialogContentText>}
          <FormError error={error} resetError={resetError} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} {...cancelProps} />
          <Button
            onClick={handleConfirm}
            color="primary"
            variant="contained"
            autoFocus
            {...confirmProps}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
