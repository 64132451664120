import InlineEditable from "./InlineEditable";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";

export default function KeyValueListItems({
  fields,
  object,
  inset,
  transformers,
  editableFieldProps,
  onCommit,
}) {
  editableFieldProps = editableFieldProps || {};
  const classes = useStyles();
  const items = fields.map((f) => {
    const editableProps = editableFieldProps[f];
    const rawValue = object[f];
    if (!rawValue && !editableProps) {
      return null;
    }
    const label = _.startCase(f);
    const value = (transformers[f] || _.identity)(rawValue);
    if (!editableProps) {
      return (
        <ListItem key={f}>
          <ListItemText inset={inset}>
            {label}: {value}
          </ListItemText>
        </ListItem>
      );
    }
    return (
      <InlineEditable
        key={f}
        label={label}
        displayFunc={() => (
          <ListItemText inset={inset} className={classes.listItemText}>
            {label}: {value}
          </ListItemText>
        )}
        value={value}
        ListItemComponent={ListItem}
        onCommit={(v) => onCommit({ [f]: v })}
        {...editableProps}
      />
    );
  });
  return _.compact(items);
}

const useStyles = makeStyles(() => ({
  listItemText: {
    flex: "inherit",
  },
}));
