import { AdminContainer, AlignedListContainer } from "../components/layouts";
import {
  CustomerLinkListItem,
  GutterlessListItem as ListItem,
} from "../components/lists";
import DocumentsList, { useDocumentsState } from "../components/DocumentsList";
import Time, { isoString } from "../lithic/Moment";
import { useHistory, useParams } from "react-router";

import AdminActionLinks from "../components/AdminActionLinks";
import ApproveRejectEnrollment from "../components/ApproveRejectEnrollment";
import AuditLogsTable from "../components/AuditLogsTable";
import Button from "@material-ui/core/Button";
import ConfirmationDialog from "../components/ConfirmationDialog";
import DateTimePicker from "../components/DateTimePicker";
import DetailSection from "../components/DetailSection";
import ExternalLinksList from "../components/ExternalLinksList";
import FormControl from "@material-ui/core/FormControl";
import HiddenCopyInput from "../components/HiddenCopyInput";
import InlineEditable from "../components/InlineEditable";
import InputLabel from "@material-ui/core/InputLabel";
import Link from "../components/Link";
import ListItemText from "@material-ui/core/ListItemText";
import Money from "../lithic/Money";
import PlaidInfo from "../components/PlaidInfo";
import PortalLogin from "../components/PortalLogin";
import React from "react";
import SimpleTable from "../components/SimpleTable";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";
import VerificationStatus from "../components/VerificationStatus";
import _ from "lodash";
import api from "../api";
import clsx from "clsx";
import dayjs from "dayjs";
import { makeStyles } from "@material-ui/core/styles";
import { useError } from "../components/FormError";
import useToggle from "../state/useToggle";

const useStyles = makeStyles((theme) => ({
  inactive: {
    color: theme.palette.muted.main,
  },
  needsAttention: {
    color: theme.palette.error.main,
  },
  bold: { fontWeight: "bold" },
  rejectButton: { marginTop: theme.spacing(1) },
  subitemList: { flexDirection: "column", alignItems: "start" },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  inlineIconButton: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  inlineButton: {
    marginLeft: theme.spacing(2),
  },
  warning: {
    color: theme.palette.error.main,
    fontWeight: "bold",
  },
  extCtrlElement: {
    display: "block",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  oneOffPayments: {
    display: "flex",
    flexDirection: "row",
    marginTop: theme.spacing(2),
  },
  oneOffButton: {
    marginRight: theme.spacing(1),
  },
  modalInputs: {
    display: "flex",
    flexDirection: "column",
  },
  modalInput: {
    marginBottom: theme.spacing(2),
  },
}));

export default function CreditAccountEnrollmentDetailPage() {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();

  const [enrollment, setEnrollment] = React.useState({ case: { customer: {} } });
  const ecase = enrollment.case;
  const customer = ecase.customer;

  const boomSplitApprovalToggle = useToggle(false);
  const [boomSplitApprovalError, setBoomSplitApprovalError] = useError();

  React.useEffect(() => {
    api.getCreditAccountEnrollment({ id }).then(api.pickData).then(setEnrollment);
  }, [id]);

  const [bankAccounts, setBankAccounts] = React.useState([]);
  React.useEffect(() => {
    if (customer.id) {
      api
        .getCustomerBankAccounts({ id: customer.id })
        .then(api.pick("data.bankAccounts"))
        .then(setBankAccounts);
    }
  }, [customer]);

  const { createDocumentsFromFiles } = useDocumentsState(
    ecase,
    "credit_account_enrollment"
  );

  function callEnrollmentApi(apiMethod, params) {
    return apiMethod({ ...params, id: enrollment.id })
      .then(api.pickData)
      .then(setEnrollment);
  }
  function callCaseApi(apiMethod, params) {
    return apiMethod({ ...params, id: ecase.id, entity: "credit_account_enrollment" })
      .then(api.pickData)
      .then(setEnrollment);
  }

  return (
    <AdminContainer variant="detail" customer={customer}>
      <AlignedListContainer maxWidth="sm">
        <ListItem>
          <ListItemText>Enrollment Id: {enrollment.id}</ListItemText>
        </ListItem>
        <CustomerLinkListItem disableGutters customer={customer} />
        <ListItem>
          <ListItemText>
            Created:{" "}
            <Time d="(unset)" format={"MMM DD, YYYY HH:mm:ss"}>
              {enrollment.createdAt}
            </Time>
          </ListItemText>
        </ListItem>
        <InlineEditable
          label="Fee"
          value={enrollment.feeAmount?.cents}
          displayValue={<Money value={enrollment.feeAmount} />}
          ListItemComponent={ListItem}
          onCommit={(cents) =>
            callEnrollmentApi(api.updateCreditAccountEnrollment, {
              feeAmount: _.merge({}, enrollment.feeAmount, { cents }),
            })
          }
          inputProps={{ type: "number" }}
        />
        <ListItem>
          <ListItemText>
            Monthly Rent: <Money>{ecase.monthlyRentAmount}</Money>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>Rent Day of Month: {ecase.monthlyRentDayOfMonth}</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            {ecase.leaseMonthToMonth ? (
              "Lease is month-to-month"
            ) : (
              <span>
                Lease End:{" "}
                <Time formatExact={"MMMM YYYY"} local>
                  {ecase.leaseEndDate}
                </Time>
              </span>
            )}
          </ListItemText>
        </ListItem>
        <InlineEditable
          label="Enrollment Bank Account"
          value={ecase.bankAccount?.id}
          displayValue={ecase.bankAccount?.label}
          editable={ecase.canSwitchEnrollmentBankAccount}
          ListItemComponent={ListItem}
          onCommit={(bankAccountId) =>
            callEnrollmentApi(api.switchCreditAccountEnrollmentBankAccount, {
              bankAccountId,
              target: "enrollment",
            })
          }
          inputType="select"
          inputProps={{
            options: bankAccounts.map((ba) => ({
              value: ba.id,
              label: ba.label,
            })),
          }}
        />
        {enrollment.fundingBankAccount && (
          <InlineEditable
            label="Funding Bank Account"
            value={enrollment.fundingBankAccount.id}
            displayValue={enrollment.fundingBankAccount.label}
            ListItemComponent={ListItem}
            onCommit={(bankAccountId) =>
              callEnrollmentApi(api.switchCreditAccountEnrollmentBankAccount, {
                bankAccountId,
                target: "account",
              })
            }
            inputType="select"
            inputProps={{
              options: bankAccounts.map((ba) => ({
                value: ba.id,
                label: ba.label,
              })),
            }}
          />
        )}
        <InlineEditable
          label="Note"
          value={enrollment.note}
          ListItemComponent={ListItem}
          onCommit={(note) =>
            callEnrollmentApi(api.updateCreditAccountEnrollment, { note })
          }
        />
      </AlignedListContainer>
      <VerificationStatus status={enrollment.status} label="Enrollment Status" />
      {ecase.status === "submitted" && (
        <DetailSection title="Approve or Reject Boom Split Application">
          <ListItem>
            <ListItemText>
              <Link
                to={`/reporting-account-enrollments/${enrollment.seeAlsoReportingAccountEnrollmentId}`}
                className={classes.bold}
              >
                To work with this Enrollment Case, such as verifying the lease or
                landlord, please use the Reporting Account page for now. ➡
              </Link>
            </ListItemText>
          </ListItem>
          <ApproveRejectEnrollment
            enrollment={enrollment}
            toggle={boomSplitApprovalToggle}
            title="Boom Split"
            error={boomSplitApprovalError}
            resetError={setBoomSplitApprovalError}
            onConfirmApprove={() =>
              callEnrollmentApi(api.approveCreditAccountEnrollment)
                .then(boomSplitApprovalToggle.turnOff)
                .catch(setBoomSplitApprovalError)
            }
            onConfirmReject={() =>
              callEnrollmentApi(api.rejectCreditAccountEnrollment)
                .then(boomSplitApprovalToggle.turnOff)
                .catch(setBoomSplitApprovalError)
            }
            onConfirmReconsider={() =>
              callEnrollmentApi(api.reconsiderCreditAccountEnrollment)
                .then(boomSplitApprovalToggle.turnOff)
                .catch(setBoomSplitApprovalError)
            }
            onConfirmCaseClose={() =>
              callCaseApi(api.closeEnrollmentCase)
                .then(boomSplitApprovalToggle.turnOff)
                .catch(setBoomSplitApprovalError)
            }
          />
        </DetailSection>
      )}
      <DetailSection title="Documents">
        <DocumentsList
          documents={enrollment.documents}
          onDocumentsDropped={createDocumentsFromFiles}
          disableDrop={!ecase}
        />
      </DetailSection>
      <DetailSection>
        <PortalLogin
          portalAuthCredential={ecase.portalAuthCredential}
          onPortalChange={(params) => callCaseApi(api.updateEnrollmentCasePortal, params)}
        />
      </DetailSection>
      <CreditAccountDetails enrollment={enrollment} />
      <PlaidInfo
        bankAccount={ecase.bankAccount}
        fundingBankAccount={enrollment.fundingBankAccount}
        customer={customer}
        showBalanceCheck
      />
      <ExternalLinksList links={enrollment.externalLinks} />
      <AdminActionLinks
        links={enrollment.adminActionLinks}
        onActionSuccess={(resp) => setEnrollment(resp.data)}
      />
      <DetailSection>
        <AuditLogsTable auditLogs={enrollment.auditLogs} />
      </DetailSection>
      <DetailSection>
        <LedgerTable ledgerLines={enrollment.ledgerLines} />
        {enrollment.creditAccountId && (
          <div className={classes.oneOffPayments}>
            <OneOffPaymentButton
              type="Debit Payment"
              defaultAmount={ecase.monthlyRentAmount.cents}
              onConfirm={(v) =>
                api
                  .scheduleCreditAccountPaymentDebit({ id: enrollment.id, ...v })
                  .then(api.followRedirect(history))
              }
            />
            <OneOffPaymentButton
              type="Repayment Credit"
              defaultAmount={Math.floor(ecase.monthlyRentAmount.cents / 2)}
              onConfirm={(v) =>
                api
                  .scheduleCreditAccountRepaymentCredit({ id: enrollment.id, ...v })
                  .then(api.followRedirect(history))
              }
            />
          </div>
        )}
      </DetailSection>
    </AdminContainer>
  );
}

function LedgerTable({ ledgerLines }) {
  const classes = useStyles();
  if (_.isEmpty(ledgerLines)) {
    return null;
  }
  return (
    <SimpleTable
      tableProps={{ size: "small" }}
      headers={["Ledger", "Amount", "Status", "Balance"]}
      rows={ledgerLines}
      getKey={(row) => row.adminLink}
      toCells={(row) => [
        <span
          key="at"
          className={clsx(dayjs(row.at).isAfter(dayjs()) && classes.inactive)}
        >
          <Time format={"MMM DD, YYYY HH:mm:ss"}>{row.at}</Time>
        </span>,
        <Money
          key="amt"
          accounting
          value={row.amount}
          className={clsx(row.needsAttention && classes.needsAttention)}
        />,
        <Link key="payhref" to={row.adminLink}>
          {row.paymentStatus}
        </Link>,
        <Money key="balance" accounting value={row.balance} />,
      ]}
    />
  );
}

function OneOffPaymentButton({ type, defaultAmount, onConfirm }) {
  const classes = useStyles();
  const toggle = useToggle();
  const [amountCents, setAmountCents] = React.useState(defaultAmount);
  const [applyAt, setApplyAt] = React.useState(dayjs().add(1, "day"));

  function confirm() {
    onConfirm({
      amount: { cents: amountCents, currency: "USD" },
      applyAt: isoString(applyAt),
    }).then(toggle.turnOff);
  }
  return (
    <>
      <Button
        className={classes.oneOffButton}
        color="secondary"
        variant="outlined"
        onClick={toggle.turnOn}
      >
        Create {type}
      </Button>
      <ConfirmationDialog
        title={"Edit " + type}
        open={toggle.on}
        onCancel={toggle.turnOff}
        onConfirm={confirm}
        cancelProps={{ children: "Go back" }}
        confirmProps={{ children: "Schedule " + type }}
      >
        <div className={classes.modalInputs}>
          <TextField
            className={classes.modalInput}
            label="Amount Cents"
            type="number"
            value={amountCents}
            onChange={(e) => setAmountCents(e.target.value)}
          />
          <DateTimePicker
            className={classes.modalInput}
            ampm={false}
            disablePast
            value={applyAt}
            onChange={setApplyAt}
          />
        </div>
      </ConfirmationDialog>
    </>
  );
}

function CreditAccountDetails({ enrollment }) {
  const classes = useStyles();
  if (!enrollment || !enrollment.accountNumber) {
    return null;
  }
  return (
    <ListItem className={classes.subitemList}>
      <Typography variant="h6" gutterBottom color="primary">
        Increase Account
      </Typography>
      <form>
        <FormControl className={classes.extCtrlElement}>
          <InputLabel>Account number</InputLabel>
          <HiddenCopyInput value={enrollment.accountNumber} />
        </FormControl>
        <FormControl className={classes.extCtrlElement}>
          <InputLabel>Routing number</InputLabel>
          <HiddenCopyInput value={enrollment.routingNumber} />
        </FormControl>
      </form>
    </ListItem>
  );
}
