import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
} from "@material-ui/core";
import VerifyReject, { useVerifyRejectState } from "../../components/VerifyReject";
import { AdminContainer } from "../../components/layouts";
import Time from "../../lithic/Moment";

import { makeStyles } from "@material-ui/core/styles";
import { lighten } from "@material-ui/core/styles/colorManipulator";

import { DatePicker } from "@material-ui/pickers";
import dayjs from "dayjs";
import _ from "lodash";
import React from "react";
import { useHistory, useParams } from "react-router";
import api from "../../api";
import AdminActionLinks from "../../components/AdminActionLinks";
import AuditLogsTable from "../../components/AuditLogsTable";
import BankAccounts from "../../components/BankAccounts";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import CreateRefurnishmentModal from "../../components/CreateRefurnishmentModal";
import DetailSection from "../../components/DetailSection";
import ExternalLinksList from "../../components/ExternalLinksList";
import { useError, useToastError } from "../../components/FormError";
import Link from "../../components/Link";
import PortalLogin from "../../components/PortalLogin";
import PvsTable from "../../components/PvsTable";
import SimpleTable from "../../components/SimpleTable";
import WarningModal from "../../components/WarningModal";
import { useLoading } from "../../hooks/loading";
import Money from "../../lithic/Money";
import usStateCodes from "../../modules/usStateCodes";
import useToggle from "../../state/useToggle";
import { CustomerDetailsSection } from "./CustomerDetailsSection";
import { EnrollmentDetails } from "./EnrollmentDetails";
import { FurnishmentSection } from "./FurnishmentSection/FurnishmentSection";
import { LandlordAndLeaseDetails } from "./LandlordAndLeaseDetails";
import { ProductsSection } from "./ProductsSections";
import { SubscriptionsSection } from "./SubscriptionsSection";
import { AuditLogs } from "../../components/AuditLogs";
import { Bureaus } from "./BureausSection";

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(1),
  },
  errorColor: {
    color: theme.palette.error.main,
  },
  boxBorder: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.warning.light, 0.7),
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  boxBorderGreen: {
    border: "1px solid",
    borderColor: theme.palette.success.main,
    backgroundColor: lighten(theme.palette.success.main, 0.7),
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  boxBorderOutlinedHalf: {
    border: "1px solid",
    padding: "10px",
    borderColor: theme.palette.success.main,
    width: "50vw",
    marginTop: 0,
    marginBottom: 8,
  },
  inactive: {
    color: theme.palette.muted.main,
  },
  link: {
    color: theme.palette.dark.main,
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      color: theme.palette.primary.main,
      textDecoration: "underline",
    },
  },
  needsAttention: {
    color: theme.palette.error.main,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  inlineIconButton: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  inlineButton: {
    marginLeft: theme.spacing(2),
  },
  rowClassApproved: {
    backgroundColor: lighten(theme.palette.success.light, 0.7),
  },
  rowClassPending: {
    backgroundColor: theme.palette.grey["100"],
  },
  rowClassRejected: {
    backgroundColor: lighten(theme.palette.warning.light, 0.7),
  },
  selectedStyle: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: lighten(theme.palette.secondary.light, 0.7),
    padding: theme.spacing(1),
  },
  success: {
    color: theme.palette.success.main,
    marginLeft: theme.spacing(1),
  },
  successColor: {
    color: theme.palette.success.main,
  },
  tableContainer: {
    scrollbarWidth: "none" /* Firefox */,
    "&::-webkit-scrollbar": {
      display: "none" /* Chrome */,
    },
  },
  tableText: {
    color: theme.palette.primary.contrastText,
  },
  extCtrlElement: {
    display: "block",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  canGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "5px 5px",
    width: "35vw",
  },
  formGroup: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridGap: "10px",
    width: "450px",
    marginTop: "20px",
  },
  formControl: {
    width: "150px",
  },
}));

export default function ReportingAccountEnrollmentDetailPage() {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();

  const landlordDetailsToggle = useToggle(false);
  const [landlordDetailsError, setLandlordDetailsError] = useError();
  const leaseDetailsToggle = useToggle(false);
  const [leaseDetailsError, setLeaseDetailsError] = useError();
  const uploadedLeaseVerifyRejectState = useVerifyRejectState();
  const portalVerifyRejectState = useVerifyRejectState();
  const [pvs, setPvs] = React.useState({});
  const toastError = useToastError();

  const [enrollment, setEnrollment] = React.useState({ case: { customer: {} } });
  const ecase = enrollment.case;
  ecase["movedOutDate"] = enrollment.movedOutDate;
  const customer = ecase.customer;

  const [furnishments, setFurnishments] = React.useState([]);

  const { startLoading, stopLoading } = useLoading();

  const [selectedInvoice, setSelectedInvoice] = React.useState();

  const refundFormToggle = useToggle(false);

  const createRefurnishmentToggle = useToggle(false);

  const [subscriptions, setSubscriptions] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      startLoading();

      await Promise.all([
        api.getReportingAccountEnrollment({ id }).then(api.pickData).then(setEnrollment),
        refreshPvs(),
        api
          .getReportingAccountEnrollmentFurnishments({ id })
          .then(api.pickData)
          .then((data) => setFurnishments(data)),
      ]);

      setTimeout(stopLoading, 200);

      // Checking if there is an anchor here
      const anchor = window.location.hash.slice(1);
      if (anchor) {
        const anchorEl = document.getElementById(anchor);
        if (anchorEl) anchorEl.scrollIntoView();
      }
    })();
  }, [id]);

  React.useEffect(() => {
    if (customer.id) {
      startLoading();
      api.getCustomerSubscriptions({ customerId: customer.id })
        .then(api.pickData)
        .then((data) => setSubscriptions(data))
        .finally(() => stopLoading());
    }
  }, [customer.id]);

  function refreshPvs() {
    api
      .getRentalVerifications({ id, verifiedOnly: true })
      .then(api.pickData)
      .then(({ items }) => setPvs(items))
      .catch((error) => {
        toastError(error);
        setPvs({});
      });
  }

  function callCaseApi(apiMethod, params) {
    return apiMethod({ ...params, id: ecase.id, entity: "reporting_account_enrollment" })
      .then(api.pickData)
      .then(setEnrollment);
  }

  function handleShowRefundInvoice(invoice) {
    setSelectedInvoice(invoice);
    refundFormToggle.turnOn();
  }

  function handleRefund(selectedLineItemIds) {
    return api
      .refundLineItems({ invoiceId: selectedInvoice.id, lineItems: selectedLineItemIds })
      .then(() => api.getReportingAccountEnrollment({ id }))
      .then(api.pickData)
      .then((data) => {
        setEnrollment(data);
        setSelectedInvoice();
      });
  }

  const portalStatus = _.split(ecase.portalVerificationStatus, "_")[2];
  function handleCreateRefurnishment(data) {
    return api
      .createReportingAccountEnrollmentRefurnishment({ id, ...data })
      .then(() => api.getReportingAccountEnrollment({ id }))
      .then(api.pickData)
      .then((data) => {
        setEnrollment(data);
        createRefurnishmentToggle.turnOff();
      });
  }

  const { issues, resolvedIssues } = enrollment;

  const leaseEndedWithNoResponse =
    enrollment.auditLogs?.find((al) => al.event === "lease_ended_no_response") &&
    ecase.expiredLeaseEndDate;

  const switchedToLeaseEndDate =
    enrollment.auditLogs?.find((al) => al.event === "switch_to_lease_end_date") &&
    ecase.leaseEndDate;

  const eolHasResolution =
    enrollment.confirmedLeaseEnding ||
    enrollment.movedOutDate ||
    ecase.pendingLeaseEndDate ||
    ecase.pendingLeaseMonthToMonth ||
    switchedToLeaseEndDate;

  const updateSubscription = (updatedSubscription) => {
    setSubscriptions(subs => subs.map((sub) =>
      sub.id === updatedSubscription.id ? updatedSubscription : sub
    ));
  };

  return (
    <AdminContainer variant="detail-wide">
      {enrollment.eolSpecificHandling ? (
        <DetailSection title="Specific Handling" className={classes.boxBorder}>
          If this user resolved their EOL issue card and ready to be worked upon - Please
          assign this account to Joven/Mark/Mike for resolution
        </DetailSection>
      ) : null}
      {enrollment.disableOngoingUpsell ? (
        <DetailSection title="No further processing" className={classes.boxBorder}>
          This should be past only account which has been reported once. So need not
          reported again. If this account is in workload of ops, reach out to eng team.
        </DetailSection>
      ) : null}
      {enrollment.sb1157 ? (
        <DetailSection
          title="sb1157 customer - Do not process"
          className={classes.boxBorder}
        >
          This is known sb1157 customer. If this comes in ops workload, do not process
        </DetailSection>
      ) : null}
      {enrollment.status === "errored" ? (
        <DetailSection title="In Error State" className={classes.boxBorder}>
          Ops team: please do not work this account. This account should already be moved
          out of the workload queue and is for engineering to resolve before further
          processing.
        </DetailSection>
      ) : null}
      {enrollment.fromPartner ? (
        <DetailSection
          title={`From Partner ${enrollment.partner ? enrollment.partner.name : ""}`}
          className={classes.boxBorderGreen}
        >
          {enrollment.partnerServiceType === "full_service"
            ? "Process as normal (Full service)"
            : "Please process Identity. RVs should be processed by partner."}
        </DetailSection>
      ) : null}
      {customer.communicationAllowed === false ? (
        <DetailSection title="Communication Muted" className={classes.boxBorderGreen}>
          There will be no events, notification to users.
        </DetailSection>
      ) : null}

      <DetailSection title="Customer Details" id="customer-details">
        <CustomerDetailsSection enrollment={enrollment} setEnrollment={setEnrollment} />
      </DetailSection>

      <DetailSection title="Boom Subscription">
        <SubscriptionsSection
          subscriptions={subscriptions}
          customerId={customer?.id}
          updateSubscription={updateSubscription}
        />
      </DetailSection>

      <DetailSection title="Product + Billing">
        <ProductsSection enrollment={enrollment} />
      </DetailSection>

      {issues?.length ? (
        <DetailSection title="Issues" className={classes.boxBorder}>
          {issues.map((issue) => {
            return (
              <ListItem key={issue.id} divider>
                <ListItemText>
                  {dayjs(issue.createdAt).format("YYYY-MM-DD")} {issue.title}
                </ListItemText>
              </ListItem>
            );
          })}
        </DetailSection>
      ) : null}

      {ecase.status === "submitted" ? (
        <DetailSection title="Enrollment Details">
          <EnrollmentDetails enrollment={enrollment} />
        </DetailSection>
      ) : null}

      <DetailSection id="landlord-verification-required" title="">
        <LandlordAndLeaseDetails
          enrollment={enrollment}
          leaseDetailsToggle={leaseDetailsToggle}
          landlordDetailsToggle={landlordDetailsToggle}
          setEnrollment={setEnrollment}
          uploadedLeaseVerifyRejectState={uploadedLeaseVerifyRejectState}
        />
      </DetailSection>

      {leaseEndedWithNoResponse ? (
        <DetailSection title="Lease ended but no response" className={classes.boxBorder}>
          Lease has ended but user hasn&apos;t response. Temporarily changed to lease
          month to month.
        </DetailSection>
      ) : null}

      {eolHasResolution ? (
        <DetailSection title="End of lease resolution" className={classes.boxBorderGreen}>
          {enrollment.confirmedLeaseEnding
            ? `Customer confirmed lease is ending on ${ecase.leaseEndDate}`
            : null}
          {enrollment.movedOutDate
            ? `Customer confirmed moved out on ${enrollment.movedOutDate}`
            : null}
          {ecase.pendingLeaseEndDate
            ? `Customer is extending their lease to ${ecase.pendingLeaseEndDate}`
            : null}
          {ecase.pendingLeaseMonthToMonth
            ? `Customer is switching their lease to month to month`
            : null}
          {switchedToLeaseEndDate
            ? `Customer switched their lease from M2M to Lease end date`
            : null}
        </DetailSection>
      ) : null}

      {resolvedIssues?.length ? (
        <DetailSection title="Resolved Issues" className={classes.boxBorderGreen}>
          {resolvedIssues.map((issue) => {
            return (
              <ListItem key={issue.id} divider>
                <ListItemText>
                  {dayjs(issue.createdAt).format("YYYY-MM-DD")} {issue.title}
                </ListItemText>
              </ListItem>
            );
          })}
        </DetailSection>
      ) : null}

      <DetailSection id="portal-verification-required">
        <PortalLogin
          portalAuthCredential={ecase.portalAuthCredential}
          portalStatus={ecase.portalVerificationStatus}
          onPortalChange={(params) => callCaseApi(api.updateEnrollmentCasePortal, params)}
        />
        <VerifyReject
          title="portal"
          status={portalStatus}
          caseStatus={ecase.status}
          approveRejectState={portalVerifyRejectState}
          onConfirmReject={() =>
            callCaseApi(api.rejectEnrollmentCasePortal)
              .then(portalVerifyRejectState.rejectToggle.turnOff)
              .catch(portalVerifyRejectState.setRejectError)
          }
        />
      </DetailSection>

      <BankAccounts id="bankAccounts" customerId={customer?.id} />

      <DetailSection title="Furnishment">
        <FurnishmentSection
          furnishments={furnishments}
          enrollment={enrollment}
          createRefurnishmentToggle={createRefurnishmentToggle}
        />
      </DetailSection>

      <AdminActionLinks
        links={enrollment.adminActionLinks}
        onActionSuccess={(resp) => setEnrollment(resp.data)}
      />
      <DetailSection
        id="rv"
        title="Rental Verifications"
        button={
          <Button
            onClick={() =>
              history.push(`/reporting-account-enrollments/${id}/payment-verifications`)
            }
            color="primary"
            variant="contained"
          >
            Go to payment verifications
          </Button>
        }
      >
        <PvsTable
          enrollmentId={id}
          headers={["", "Month", "Status", "Delinquency", "Processed by"]}
          tableProps={{ size: "small" }}
          rentalVerifications={pvs}
          refreshPvs={refreshPvs}
          readOnly={true}
        />
      </DetailSection>
      {ecase.pendingLeaseEndDate ||
      ecase.pendingLeaseMonthToMonth ||
      enrollment.movedOutDate ? (
        <DetailSection id="lease-pending-update" title="Pending Lease Update Details">
          <SimpleTable
            center
            className={classes.tableContainer}
            truncated
            dense
            padLast
            getKey={(r) => r.id || ""}
            headers={[
              "Pending End Date",
              "Pending Transition to Month to Month",
              "Moved Out Date",
            ]}
            rows={[ecase]}
            toCells={(row) => [
              row.pendingLeaseEndDate || "N/A",
              row.pendingLeaseMonthToMonth ? "✔️" : "✖️",
              row.movedOutDate || "N/A",
            ]}
          />
        </DetailSection>
      ) : null}

      <ExternalLinksList links={enrollment.externalLinks} />
      <DetailSection title="Invoices">
        <SimpleTable
          truncated
          tableProps={{ size: "small" }}
          headers={["Invoices", "Status", "Amount", "Updated at", "Actions"]}
          rows={enrollment.invoices}
          keyRowAttr="id"
          toCells={(row) => [
            <Link key={1} to={`/invoices/${row.id}`}>
              {row.id}
            </Link>,
            <Link key={2} to={`/invoices/${row.id}`}>
              {row.status}{" "}
              {row.status === "refunded" && (
                <>
                  {"("}
                  {row.refundedTotal?.cents !== row.total?.cents ? (
                    <>Partial </>
                  ) : (
                    <>Full </>
                  )}
                  <Money>{row.refundedTotal}</Money>
                  {")"}
                </>
              )}
            </Link>,
            <Money key={3} value={row.total} />,
            <Time key={4} format={"MMM DD, YYYY HH:mm:ss"}>
              {row.updatedAt}
            </Time>,
            row.status !== "refunded" ? (
              <Button
                color="primary"
                onClick={() => handleShowRefundInvoice(row)}
                key={1}
              >
                Refund
              </Button>
            ) : (
              <>Refunded</>
            ),
          ]}
        />
      </DetailSection>
      <DetailSection title="Audit Logs">
        <AuditLogs auditableId={ecase.id} auditableType="Boom::Enrollment::Case" />
        <AuditLogsTable auditLogs={enrollment.auditLogs} />
      </DetailSection>

      <DetailSection title="Furnishment Activity Feed">
        <Bureaus type={"1"} customerId={id} />
      </DetailSection>

      <LandlordForm
        toggle={landlordDetailsToggle}
        landlord={ecase.legalEntity}
        error={landlordDetailsError}
        resetError={setLandlordDetailsError}
        onCommit={(params) =>
          callCaseApi(api.updateEnrollmentCaseLandlord, params)
            .then(landlordDetailsToggle.turnOff)
            .catch(setLandlordDetailsError)
        }
      />
      <LeaseForm
        toggle={leaseDetailsToggle}
        enrollmentCase={ecase}
        warning={true}
        error={leaseDetailsError}
        resetError={setLeaseDetailsError}
        onCommit={(params) =>
          callCaseApi(api.updateEnrollmentCaseLease, params)
            .then(leaseDetailsToggle.turnOff)
            .catch(setLeaseDetailsError)
        }
      />

      {selectedInvoice ? (
        <RefundForm
          toggle={refundFormToggle}
          lineItems={selectedInvoice.lineItems}
          onRefund={handleRefund}
        />
      ) : null}

      <CreateRefurnishmentModal
        toggle={createRefurnishmentToggle}
        onCommit={handleCreateRefurnishment}
      />
    </AdminContainer>
  );
}

function RefundForm({ toggle, onRefund, lineItems }) {
  const [needToRefundLineItems, setNeedToRefundLineItemsineItems] = React.useState([]);

  function handleConfirm() {
    onRefund(needToRefundLineItems);
  }

  function handleChecked(id) {
    if (needToRefundLineItems.includes(id)) {
      setNeedToRefundLineItemsineItems(needToRefundLineItems.filter((cid) => cid !== id));
    } else {
      setNeedToRefundLineItemsineItems([...needToRefundLineItems, id]);
    }
  }

  function totalRefund() {
    let total = 0;
    needToRefundLineItems.forEach((id) => {
      total += lineItems.find((li) => li.id === id).amount.cents;
    });
    return total;
  }

  function refundProductDescription(description) {
    if (description.includes("ongoing rent report")) {
      return "(Unenroll ongoing subscription)";
    } else if (description.includes("past rent report")) {
      return "(Unenroll past reporting)";
    }
    return "";
  }

  return (
    <ConfirmationDialog
      title={`Refund Total: $${(totalRefund() / 100).toFixed(2)}`}
      open={toggle.on}
      onCancel={toggle.turnOff}
      onConfirm={handleConfirm}
      cancelProps={{ children: "Go back" }}
      confirmProps={{
        children: `Refund selected ${needToRefundLineItems.length} Line Items`,
        disabled: !needToRefundLineItems.length,
      }}
      // error={error}
      // resetError={resetError}
    >
      {lineItems?.length
        ? lineItems.map((li) => (
            <FormControl key={`${li.id}`} margin="normal" fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={needToRefundLineItems.includes(li.id)}
                    onChange={() => handleChecked(li.id)}
                    color="primary"
                  />
                }
                label={li.description + ` ${refundProductDescription(li.description)}`}
              />
            </FormControl>
          ))
        : null}
    </ConfirmationDialog>
  );
}

function LeaseForm({ toggle, enrollmentCase, error, resetError, onCommit, warning }) {
  const [fields, setFields] = React.useState({ ...enrollmentCase });
  const [address, setAddress] = React.useState(fields.address || {});
  const [showWarning, setShowWarning] = React.useState(warning || false);

  React.useEffect(() => {
    setFields({ ...enrollmentCase });
    setAddress({ ...enrollmentCase?.address });
    setShowWarning(warning || false);
    // eslint-disable-next-line
  }, [enrollmentCase, toggle.on]);

  function mergeFields(p) {
    setFields({ ...fields, ...p });
  }
  function mergeAddress(p) {
    setAddress({ ...address, ...p });
  }

  const leaseEndDate = fields.leaseMonthToMonth ? null : fields.leaseEndDate;

  const { address1, city, stateOrProvince, postalCode } = address;
  const valid = city && address1 && stateOrProvince && postalCode;

  function handleConfirm() {
    const data = { ...fields, address };
    if (data.leaseMonthToMonth) {
      data.leaseEndDate = null;
    }
    onCommit(data);
  }

  function warningConfirm() {
    setShowWarning(false);
  }

  function warningCancel() {
    toggle.turnOff();
  }

  const warningText = `
    ***This change will create a refurnishment which is very risky.
    Please consult with Joven before making these changes.
  `;

  return (
    <>
      <ConfirmationDialog
        title="Edit lease details"
        open={toggle.on && showWarning === false}
        onCancel={toggle.turnOff}
        onConfirm={handleConfirm}
        cancelProps={{ children: "Go back" }}
        confirmProps={{ children: "Save", disabled: !valid }}
        error={error}
        resetError={resetError}
      >
        <FormControl margin="normal" fullWidth>
          <TextField
            required
            label="City"
            type="text"
            value={city}
            variant="outlined"
            onChange={(e) => mergeAddress({ city: e.target.value })}
          />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <TextField
            required
            label="Adress Line 1"
            type="text"
            value={address1}
            variant="outlined"
            onChange={(e) => mergeAddress({ address1: e.target.value })}
          />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <TextField
            label="Adress Line 2"
            type="text"
            value={address.address2}
            variant="outlined"
            onChange={(e) => mergeAddress({ address2: e.target.value })}
          />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <TextField
            required
            label="State"
            type="text"
            value={stateOrProvince}
            variant="outlined"
            onChange={(e) => mergeAddress({ stateOrProvince: e.target.value })}
            select
          >
            {usStateCodes.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <TextField
            required
            label="Zip Code"
            type="text"
            value={postalCode}
            variant="outlined"
            onChange={(e) => mergeAddress({ postalCode: e.target.value })}
          />
        </FormControl>

        <FormControl margin="normal" fullWidth>
          <DatePicker
            label="Move In Date"
            value={fields.moveInDate}
            onChange={(newValue) =>
              mergeFields({ moveInDate: dayjs(newValue).format("YYYY-MM-DD") })
            }
            format="MM/DD/YYYY"
          />
        </FormControl>

        {!fields.leaseMonthToMonth ? (
          <FormControl margin="normal" fullWidth>
            <DatePicker
              label="Lease End Date"
              value={leaseEndDate}
              onChange={(newValue) =>
                mergeFields({ leaseEndDate: dayjs(newValue).format("YYYY-MM-DD") })
              }
              format="MM/DD/YYYY"
            />
          </FormControl>
        ) : null}

        <FormControl margin="normal" fullWidth>
          <DatePicker
            label="Moved out date"
            value={fields.movedOutDate}
            onChange={(newValue) =>
              mergeFields({ movedOutDate: dayjs(newValue).format("YYYY-MM-DD") })
            }
            format="MM/DD/YYYY"
          />
        </FormControl>

        <FormControl margin="normal" fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                checked={fields.leaseMonthToMonth}
                onChange={(e) => mergeFields({ leaseMonthToMonth: e.target.checked })}
                color="primary"
              />
            }
            label="Lease month-to-month"
          />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <TextField
            label="Rent day of month"
            type="text"
            value={fields.monthlyRentDayOfMonth}
            variant="outlined"
            onChange={(e) => mergeFields({ monthlyRentDayOfMonth: e.target.value })}
          />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <TextField
            label={
              <>
                Rent Amount: <Money>{fields.monthlyRentAmount}</Money>
              </>
            }
            type="number"
            value={fields.monthlyRentAmount?.cents}
            variant="outlined"
            onChange={(e) =>
              mergeFields({
                monthlyRentAmount: _.merge({}, fields.monthlyRentAmount, {
                  cents: e.target.value,
                }),
              })
            }
          />
        </FormControl>
      </ConfirmationDialog>
      <WarningModal
        open={toggle.on && showWarning}
        onConfirm={warningConfirm}
        onCancel={warningCancel}
      >
        {warningText}
      </WarningModal>
    </>
  );
}

function LandlordForm({ toggle, landlord, onCommit, error, resetError }) {
  const [fields, setFields] = React.useState({ ...landlord });

  React.useEffect(() => {
    setFields({ ...landlord });
  }, [landlord]);

  const types = _.uniq(["company", "landlord", landlord?.type]);
  return (
    <ConfirmationDialog
      title="Edit landlord details"
      open={toggle.on}
      onCancel={toggle.turnOff}
      onConfirm={() => onCommit(fields)}
      cancelProps={{ children: "Go back" }}
      confirmProps={{ children: "Save" }}
      error={error}
      resetError={resetError}
    >
      <FormControl margin="normal" fullWidth>
        <TextField
          label="Type"
          type="text"
          value={fields.type}
          variant="outlined"
          onChange={(e) => setFields({ ...fields, type: e.target.value })}
          select
        >
          {types.map((type) => (
            <MenuItem key={"" + type} value={type}>
              {_.startCase(type)}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
      {fields.type === "landlord" ? (
        <>
          <FormControl margin="normal" fullWidth>
            <TextField
              label="First Name"
              type="text"
              value={fields.contactFirstName}
              variant="outlined"
              onChange={(e) => setFields({ ...fields, contactFirstName: e.target.value })}
            />
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <TextField
              label="Last Name"
              type="text"
              value={fields.contactLastName}
              variant="outlined"
              onChange={(e) => setFields({ ...fields, contactLastName: e.target.value })}
            />
          </FormControl>
        </>
      ) : (
        <FormControl margin="normal" fullWidth>
          <TextField
            label="Company Name"
            type="text"
            value={fields.companyName}
            variant="outlined"
            onChange={(e) => setFields({ ...fields, companyName: e.target.value })}
          />
        </FormControl>
      )}

      <FormControl margin="normal" fullWidth>
        <TextField
          label="Email"
          type="text"
          value={fields.email}
          variant="outlined"
          onChange={(e) => setFields({ ...fields, email: e.target.value })}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <TextField
          label="Phone"
          type="text"
          value={fields.phone}
          variant="outlined"
          onChange={(e) => setFields({ ...fields, phone: e.target.value })}
        />
      </FormControl>
    </ConfirmationDialog>
  );
}

export const renderItem = (label, value) => {
  return (
    <ListItem>
      <ListItemText>
        {label}: <span style={{ fontWeight: 700 }}>{value}</span>
      </ListItemText>
    </ListItem>
  );
};
