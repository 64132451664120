import React, { useEffect, useState } from "react";

import { Button, ListItem, ListItemText, Menu, MenuItem } from "@material-ui/core";

import DetailSection from "../../../components/DetailSection";
import Refurnishments from "../../../components/Refurnishments";
import FurnishmentsTable from "../../../components/FurnishmentsTable";
import SimpleTable from "../../../components/SimpleTable";
import { toast } from "react-toastify";
import Time from "../../../lithic/Moment";
import { MoreHorizOutlined } from "@material-ui/icons";


import Link from "../../../components/Link";
import { TradelineFurnishmentStatusLabel } from "../../../components/TradelineFurnishmentStatusLabel";
import api from "../../../api";

const ActionsButton = ({ tradeline, updateTradeline }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActionClick = async (action) => {
    if (!tradeline.id) {
      return;
    }
    try {
      let updatedTradeline;

      switch (action) {
        case "enableFurnishment":
          updatedTradeline = await api.updateTradeline({
            tradelineId: tradeline.id,
            furnishmentStatus: "enabled",
          });
          break;
        case "disableFurnishment":
          updatedTradeline = await api.updateTradeline({
            tradelineId: tradeline.id,
            furnishmentStatus: "disabled",
          });
          break;
        default:
          break;
      }

      if (updatedTradeline) {
        updateTradeline(updatedTradeline.data);
        toast.success("Tradeline has been successfully updated");
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizOutlined />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {tradeline.furnishmentStatus === "enabled" ? (
          <MenuItem onClick={() => handleActionClick("disableFurnishment")}>
            Disable furnishment
          </MenuItem>
        ) : (
          <MenuItem onClick={() => handleActionClick("enableFurnishment")}>
            Enable furnishment
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

export const FurnishmentSection = ({
  enrollment,
  furnishments,
  createRefurnishmentToggle,
}) => {
  const [tradelines, setTradelines] = useState(null);

  useEffect(() => {
    if (enrollment?.id)
      (async () => {
        try {
          setTradelines((await api.getTradlinesForEnrollment(enrollment.id)).data);
        } catch (err) {
          toast.error(err.message);
        }
      })();
  }, [enrollment]);

  const updateTradeline = (tradeline) => {
    const updatedTradelines = tradelines.map((trd) =>
      trd.id === tradeline.id ? tradeline : trd
    );
    setTradelines(updatedTradelines);
  }

  return (
    <>
      <ListItem>
        {tradelines ? (
          <DetailSection level={2} mt={0} title="Tradelines">
            <SimpleTable
              headers={[
                "ID",
                "Bureau",
                "CAN",
                "Furnishment Status",
                "Bureau Status",
                "Status",
                "Needs furnishment",
                "Tradeline opened at",
              ]}
              rows={tradelines}
              keyRowAttr="id"
              toCells={(tradeline) => [
                <Link key={tradeline.id} to={`/tradelines/${tradeline.id}`}>
                  {tradeline.id}
                </Link>,
                <ListItemText key={tradeline.id}>{tradeline.bureau}</ListItemText>,
                <ListItemText key={tradeline.id}>{tradeline.consumerAccountNumber}</ListItemText>,
                <TradelineFurnishmentStatusLabel
                  key={tradeline.id}
                  status={tradeline.furnishmentStatus}
                />,
                <ListItemText key={tradeline.id}>{tradeline.bureauStatus || "-"}</ListItemText>,
                <ListItemText key={tradeline.id}>{tradeline.status}</ListItemText>,
                <ListItemText key={tradeline.id}>{tradeline.needsFurnishment && "yes"}</ListItemText>,
                <Time key={tradeline.id} d="-" format={"MMM DD, YYYY HH:mm:ss"}>
                  {tradeline.tradelineOpenedAt}
                </Time>,
                <ActionsButton key={tradeline.id} tradeline={tradeline} updateTradeline={updateTradeline} />
              ]}
            />
          </DetailSection>
        ) : null}
      </ListItem>
      <ListItem>
        <DetailSection level={2} mt={0} title="Furnishments">
          {furnishments?.length ? (
            <FurnishmentsTable furnishments={furnishments}></FurnishmentsTable>
          ) : (
            <h5> No furnishments at the moment </h5>
          )}
        </DetailSection>
      </ListItem>
      <ListItem>
        <DetailSection level={2} mt={0} title="Refurnishments">
          <Button
            margin="normal"
            variant="contained"
            color="primary"
            onClick={() => createRefurnishmentToggle.turnOn()}
          >
            Create Refurnishment
          </Button>
          <Refurnishments refurnishments={enrollment.refurnishments} />
        </DetailSection>
      </ListItem>
    </>
  );
};
