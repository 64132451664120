import { CircularProgress } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../api";
import SimpleTable from "../../components/SimpleTable";
import { StatusLabel } from "../../components/StatusLabel";
import { ListItemText } from "@material-ui/core";
import { LinkIconToCustomer } from "../../components/customers/LinkIconToCustomer";
import Time from "../../lithic/Moment";
import { useTheme } from "@material-ui/core/styles";
import { toast } from "react-toastify";

export const ReportingAccountEnrollmentVerifications = ({ enrollmentId }) => {
  const [verifications, setVerifications] = useState(null);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    (async () => {
      if (enrollmentId) {
        try {
          setLoading(true);
          const result = await api.listEnrollmentVerificationsForEnrollment(enrollmentId);
          setVerifications(result.data.enrollmentVerifications);
        } catch (err) {
          toast.error(err.message)
        }
        setLoading(false);
      }
    })();
  }, [enrollmentId]);

  if (loading) return <CircularProgress />;

  if (!verifications) return <></>;

  function styleKindOfVerification(verification) {
    let color;
    let backgroundColor;
    switch (verification.kind) {
      case "identity":
        color = theme.palette.light.main;
        backgroundColor = theme.palette.warning.lighter;
        break;
      case "lease":
        color = theme.palette.light.main;
        backgroundColor = theme.palette.primary.light;
        break;
      case "portal":
        color = theme.palette.light.main;
        backgroundColor = theme.palette.warning.main;
        break;
      case "landlord":
        color = theme.palette.light.main;
        backgroundColor = theme.palette.secondary.lighter;
        break;
      default:
        color = theme.palette.dark.main;
        backgroundColor = theme.palette.info.main;
        break;
    }
    return {
      color,
      backgroundColor,
      padding: theme.spacing(0.5),
      borderRadius: 100,
      textDecoration: "none",
    };
  }

  return (
    <div>
      <SimpleTable
        headers={[
          "id",
          "Verification Type",
          "Progress",
          "Status",
          "Processed by",
          "Updated at",
          "Created at",
        ]}
        rows={verifications}
        keyRowAttr="id"
        toCells={(verification) => [
          <Link key={verification.id} to={`/enrollment-verifications/${verification.id}`}>
            {verification.id}
          </Link>,
          <Link
            key={verification.id}
            to={`/enrollment-verifications/${verification.id}`}
            style={styleKindOfVerification(verification)}
          >
            {verification.kind}
          </Link>,
          <ListItemText key={verification.id}>
            {verification.progress?.processed} / {verification.progress?.total}
          </ListItemText>,
          <StatusLabel key={verification.id} status={verification.status} label=" " />,
          <LinkIconToCustomer key={verification.id} person={verification.processedBy} />,
          <Time key={verification.id} d="(unset)" formatExact={"MMM DD, YYYY HH:mm:ss"}>
            {verification.updatedAt}
          </Time>,
          <Time key={verification.id} d="(unset)" formatExact={"MMM DD, YYYY HH:mm:ss"}>
            {verification.createdAt}
          </Time>,
        ]}
      />
    </div>
  );
};
