import React from "react";

export const useDebouncedValue = (valueToDebounce) => {
  const [v, setV] = React.useState(valueToDebounce);
  const timeoutFilter = React.useRef();

  React.useEffect(() => {
    if (timeoutFilter.current) {
      clearTimeout(timeoutFilter.current);
    }
    timeoutFilter.current = setTimeout(() => {
      setV(valueToDebounce);
    }, 500);
  }, [valueToDebounce]);

  return [v];
};
