import ConfirmationDialog from "./ConfirmationDialog";
import React from "react";

export default function WarningModal({ toggle, children, onConfirm, onCancel, open }) {
  return (
    <ConfirmationDialog
      title="Warning !!!"
      open={open || (toggle && toggle.on)}
      onCancel={onCancel}
      onConfirm={onConfirm}
      cancelProps={{ children: "Go back" }}
      confirmProps={{ children: "Acknowledge" }}
    >
      {children}
    </ConfirmationDialog>
  );
}
