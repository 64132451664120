import { AdminContainer, AlignedListContainer } from "../components/layouts";
import {
  CustomerLinkListItem,
  GutterlessListItem as ListItem,
  TextList,
} from "../components/lists";
import Time, { isoString } from "../lithic/Moment";

import AccountBalancesTable from "../components/AccountBalancesTable";
import AdminActionLinks from "../components/AdminActionLinks";
import AuditLogsTable from "../components/AuditLogsTable";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ExternalLinksList from "../components/ExternalLinksList";
import FormControl from "@material-ui/core/FormControl";
import HiddenCopyInput from "../components/HiddenCopyInput";
import InlineEditable from "../components/InlineEditable";
import InputLabel from "@material-ui/core/InputLabel";
import Link from "../components/Link";
import ListItemText from "@material-ui/core/ListItemText";
import Money from "../lithic/Money";
import PaymentInstrumentListItems from "../components/PaymentInstrumentListItems";
import React from "react";
import SafeExternalLink from "../lithic/SafeExternalLink";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import api from "../api";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import oneLineAddress from "../modules/oneLineAddress";
import useDirty from "../state/useDirty";
import { useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  inactive: {
    color: theme.palette.muted.main,
  },
  extCtrlElement: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function PaymentDetailPage() {
  const { id } = useParams();
  const classes = useStyles();

  function saveAttribute(params) {
    return api
      .updatePayment({ id, ...params })
      .then(api.pickData)
      .then(setPayment);
  }

  function invokeApi(func) {
    return func({ id }).then(api.pickData).then(setPayment);
  }

  function handleTakeAction(promise) {
    return promise.then(api.pickData).then(setPayment);
  }

  const [payment, setPayment] = React.useState({});
  React.useEffect(() => {
    api.getPayment({ id }).then(api.pickData).then(setPayment);
  }, [id]);

  const [accountBalances, setAccountBalances] = React.useState([]);
  const [balancesDirty, setBalancesDirty] = useDirty();

  const customerId = _.get(payment, "customer.id");
  React.useEffect(() => {
    if (!customerId) {
      return;
    }
    api
      .getAccountBalances({ id: customerId })
      .then(api.pickData)
      .then((d) => setAccountBalances(d.items));
  }, [customerId, balancesDirty]);

  let reviewedAtDisplay = payment.reviewedAt ? "✔️" : "✖️";
  if (payment.reviewedBy) {
    reviewedAtDisplay += ` (by ${payment.reviewedBy.email})`;
  }

  return (
    <AdminContainer variant="detail" customer={payment.customer}>
      <AlignedListContainer maxWidth="sm">
        <ListItem>
          <ListItemText>Projected Payment Id: {payment.id}</ListItemText>
        </ListItem>
        <ListItem className={clsx(!payment.paymentId && classes.inactive)}>
          <ListItemText>Payment Id: {payment.paymentId || "(none)"}</ListItemText>
        </ListItem>
        <CustomerLinkListItem disableGutters customer={payment.customer} />
        <ListItem>
          <ListItemText className={clsx(!payment.paymentSeries && classes.inactive)}>
            Autopay: {!payment.paymentSeries && " (none)"}
          </ListItemText>
        </ListItem>
        {payment.paymentSeries && (
          <React.Fragment>
            <ListItem>
              <ListItemText inset>Id: {_.get(payment, "paymentSeries.id")}</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText inset>
                Start:{" "}
                <Time d="(unset)" format={"MMM DD, YYYY HH:mm:ss"}>
                  {_.get(payment, "paymentSeries.period.start")}
                </Time>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText inset>
                End:{" "}
                <Time d="(unset)" format={"MMM DD, YYYY HH:mm:ss"}>
                  {_.get(payment, "paymentSeries.period.end")}
                </Time>
              </ListItemText>
            </ListItem>
          </React.Fragment>
        )}
        <ListItem>
          <ListItemText>
            Created:{" "}
            <Time d="(unset)" format={"MMM DD, YYYY HH:mm:ss"}>
              {payment.createdAt}
            </Time>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText className={clsx(!payment.updatedAt && classes.inactive)}>
            Updated:{" "}
            <Time d="(unset)" format={"MMM DD, YYYY HH:mm:ss"}>
              {payment.updatedAt}
            </Time>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText className={clsx(!payment.softDeletedAt && classes.inactive)}>
            Deleted:{" "}
            <Time d="(unset)" format={"MMM DD, YYYY HH:mm:ss"}>
              {payment.softDeletedAt}
            </Time>
          </ListItemText>
        </ListItem>
        <InlineEditable
          label="Apply At"
          value={payment.applyAt}
          displayValue={
            <Time d="(unset)" format={"MMM DD, YYYY HH:mm:ss"}>
              {payment.applyAt}
            </Time>
          }
          inputType="datetime"
          inputProps={{ ampm: false, disablePast: true }}
          ListItemComponent={ListItem}
          onCommit={(applyAt) => saveAttribute({ applyAtMoment: isoString(applyAt) })}
          editable={payment.editable}
        />
        <ListItem>
          <ListItemText>
            Estimated Arrival:{" "}
            <Time d="(unset)" formatExact={"MMM DD, YYYY"}>
              {payment.arrivalDate}
            </Time>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Status: {payment.status} ({payment.coarseStatus}/{payment.trackingStatus})
          </ListItemText>
        </ListItem>
        <InlineEditable
          label="Amount"
          value={payment.amount?.cents}
          displayValue={<Money value={payment.amount} />}
          ListItemComponent={ListItem}
          onCommit={(cents) =>
            saveAttribute({ amount: _.merge({}, payment.amount, { cents }) })
          }
          inputProps={{ type: "number" }}
          editable={payment.editable}
        />
        <InlineEditable
          label="Memo"
          value={payment.memo}
          displayValue={payment.memo || "(none)"}
          ListItemComponent={ListItem}
          onCommit={(memo) => saveAttribute({ memo })}
          editable={payment.editable}
        />
        <ListItem>
          <ListItemText>
            Rental Address: {oneLineAddress(payment.legalEntity?.address || {})}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText className={clsx(!payment.sendingInstrument && classes.inactive)}>
            Sending Instrument: {!payment.sendingInstrument && " (none)"}
          </ListItemText>
        </ListItem>
        {payment.sendingInstrument && (
          <PaymentInstrumentListItems instrument={payment.sendingInstrument} />
        )}
        <ListItem>
          <ListItemText
            className={clsx(!payment.receivingInstrument && classes.inactive)}
          >
            Receiving Instrument: {!payment.receivingInstrument && " (none)"}
          </ListItemText>
        </ListItem>
        {payment.receivingInstrument && (
          <PaymentInstrumentListItems instrument={payment.receivingInstrument} />
        )}
        <InlineEditable
          label="Reviewed"
          inputType="switcher"
          value={Boolean(payment.reviewedAt)}
          displayValue={reviewedAtDisplay}
          ListItemComponent={ListItem}
          onCommit={(val) => invokeApi(val ? api.reviewPayment : api.unreviewPayment)}
        />
        {!payment.editable && (
          <>
            <ListItem className={clsx(!payment.lineItems?.length && classes.inactive)}>
              <ListItemText>
                Line Items: {!payment.lineItems?.length && "(none)"}
              </ListItemText>
            </ListItem>
            {payment.lineItems?.map(({ id, description, amount, invoiceId }) => (
              <ListItem key={id}>
                <ListItemText inset>
                  {description} - <Money value={amount} /> (Id: {id})
                  <span>
                    {" "}
                    <Link to={`/invoices/${invoiceId}`}>Invoice {invoiceId}</Link>
                  </span>
                </ListItemText>
              </ListItem>
            ))}
          </>
        )}
        {payment.linkedPayment && (
          <ListItem>
            <ListItemText>
              Linked to:{" "}
              <Link to={`/payments/${payment.linkedPayment.id}`}>
                Payment {payment.linkedPayment.id}
              </Link>
            </ListItemText>
          </ListItem>
        )}
        {!_.isEmpty(payment.linkedPaymentsBackref) && (
          <ListItem>
            <ListItemText>Linked to:</ListItemText>
            {payment.linkedPaymentsBackref.map((p) => (
              <ListItemText key={p.id}>
                <Link to={`/payments/${p.id}`}>Payment {p.id}</Link>
              </ListItemText>
            ))}
          </ListItem>
        )}
        {!payment.editable && (
          <>
            <ListItem>
              <ListItemText
                className={clsx(
                  payment.trackingEvents &&
                    payment.trackingEvents.length === 0 &&
                    classes.inactive
                )}
              >
                Tracking Events:{payment.trackingEvents?.length === 0 && " (none)"}
              </ListItemText>
            </ListItem>
            {payment.trackingEvents?.map(({ at, event, message }) => (
              <ListItem key={event}>
                <ListItemText inset>{message}</ListItemText>
              </ListItem>
            ))}
          </>
        )}
        <PortalPaymentControls payment={payment} onTakeAction={handleTakeAction} />
      </AlignedListContainer>
      <AccountBalancesTable
        balances={accountBalances}
        onRefreshNeeded={setBalancesDirty}
        highlightIf={(row) =>
          _.isMatch(row.modelInstance, {
            paymentMethodType: "bank_account",
            id: payment.sendingInstrument?.id,
          })
        }
        showBalanceCheck
      />
      <ExternalLinksList links={payment.externalLinks} />
      <AdminActionLinks
        links={payment.adminActionLinks}
        onActionSuccess={(resp) => setPayment(resp.data)}
      />
      <Box mt={5}>
        <AuditLogsTable auditLogs={payment.auditLogs} />
      </Box>
    </AdminContainer>
  );
}

function PortalPaymentControls({ payment, onTakeAction }) {
  const classes = useStyles();
  if (!payment || !payment.portalPaymentControls) {
    return null;
  }
  const ctrl = payment.portalPaymentControls;
  return (
    <Box mt={3} display="flex" flexDirection="column">
      <Typography variant="h6" gutterBottom>
        Portal: {ctrl.portalName}
      </Typography>
      <Typography gutterBottom>
        This payment must be sent through the customer&rsquo;s property management portal.
      </Typography>
      <TextList
        items={[
          "1. Log into the portal using the given username and password.",
          "2. Add the bank account details, shown below.",
          "3. Schedule a payment for the day of the month indicated on this payment.",
          "4. Modify this payment to match what was scheduled in the portal.",
          "5. Click the &ldquo;Mark Funds Sent&rdquo; button to indicate the payment has been processed in the portal.",
          "6. The payment will automatically process.",
          "7. You may wish to review any additional information listed below.",
        ]}
      />
      <SafeExternalLink href={ctrl.loginUrl} className={classes.extCtrlElement}>
        Open Portal
      </SafeExternalLink>
      <form>
        <FormControl className={classes.extCtrlElement}>
          <InputLabel>Portal Username</InputLabel>
          <HiddenCopyInput value={ctrl.unencryptedUsername} />
        </FormControl>
        <FormControl className={classes.extCtrlElement}>
          <InputLabel>Portal Password</InputLabel>
          <HiddenCopyInput value={ctrl.unencryptedPassword} />
        </FormControl>
        <FormControl className={classes.extCtrlElement}>
          <InputLabel>Routing Number</InputLabel>
          <HiddenCopyInput value={ctrl.unencryptedRoutingNumber} />
        </FormControl>
        <FormControl className={classes.extCtrlElement}>
          <InputLabel>Account Number</InputLabel>
          <HiddenCopyInput value={ctrl.unencryptedAccountNumber} />
        </FormControl>
      </form>
      {ctrl.fundsSentAtMutable && (
        <Button
          variant="outlined"
          color="primary"
          className={classes.extCtrlElement}
          onClick={() =>
            onTakeAction(api.markPaymentStrategyFundsSent({ id: payment.id }))
          }
        >
          Mark Funds Sent
        </Button>
      )}
      {ctrl.fundsSentAt && (
        <div className={classes.extCtrlElement}>
          Funds sent at: <Time format={"MMM DD, YYYY HH:mm:ss"}>{ctrl.fundsSentAt}</Time>
        </div>
      )}
      <pre>{_.map(ctrl.diagnostics, (v, k) => `${_.startCase(k)}: ${v}`).join("\n")}</pre>
    </Box>
  );
}
