import IconButton from "@material-ui/core/IconButton";
import Link from "./Link";
import Money from "../lithic/Money";
import React from "react";
import RefreshIcon from "@material-ui/icons/Refresh";
import SaveAlt from "@material-ui/icons/SaveAlt";
import SimpleTable from "./SimpleTable";
import Time from "../lithic/Moment";
import Warning from "@material-ui/icons/Warning";
import _ from "lodash";
import api from "../api";
import config from "../config";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";

export default function AccountBalancesTable({
  balances,
  highlightIf,
  renderSubname,
  onRefreshNeeded,
  showBalanceCheck,
}) {
  highlightIf = highlightIf || _.constant(false);
  renderSubname = renderSubname || _.constant("");
  const classes = useStyles();

  function downloadTransactions(url) {
    window.location.href = `${config.apiHost}${url}`;
  }
  function checkBalance(url) {
    return api.post(url).then(onRefreshNeeded);
  }

  return (
    <SimpleTable
      tableProps={{ size: "small" }}
      headers={["Balances", "Last4", "Available", "Current", "Limit", "Checked", "CSV"]}
      rows={balances}
      getKey={(row) => `${row.instrumentName}-${row.last4}`}
      rowClass={(row) => (highlightIf(row) ? classes.highlight : null)}
      toCells={(row) => [
        <AccountName key={0} row={row} renderSubname={renderSubname} />,
        row.last4,
        <Money key={1}>{row.available}</Money>,
        <Money key={2}>{row.current}</Money>,
        <Money key={3}>{row.limit}</Money>,
        <BalanceCheckedAt
          key={4}
          row={row}
          onCheckBalanceClick={() => checkBalance(row.balanceRefreshUrl)}
          showBalanceCheck={showBalanceCheck}
        />,
        <DownloadTransactions
          key={5}
          onDownloadClick={() => downloadTransactions(row.transactionsDownloadUrl)}
        />,
      ]}
    />
  );
}

function AccountName({ row, renderSubname }) {
  const classes = useStyles();
  let name = row.instrumentName;
  if (row.needsAttention) {
    name = (
      <span>
        {name}
        {row.needsAttention && (
          <Warning color="error" className={classes.warning} fontSize="inherit" />
        )}
      </span>
    );
  }
  if (row.adminLink) {
    name = <Link to={row.adminLink}>{name}</Link>;
  }
  const subname = renderSubname(row);
  if (!subname) {
    return name;
  }
  return (
    <>
      {name}
      <br />
      <span className={classes.subname}>{subname}</span>
    </>
  );
}

function BalanceCheckedAt({ row, onCheckBalanceClick, showBalanceCheck }) {
  const classes = useStyles();
  return (
    <>
      {row.checkedAt ? (
        <Time key={4} format="MMM DD, YYYY">
          {row.checkedAt}
        </Time>
      ) : (
        "Never"
      )}
      {showBalanceCheck && (
        <IconButton onClick={onCheckBalanceClick} className={classes.inlineIconButton}>
          <RefreshIcon color="primary" />
        </IconButton>
      )}
    </>
  );
}

function DownloadTransactions({ onDownloadClick }) {
  const classes = useStyles();
  return (
    <IconButton onClick={onDownloadClick} className={classes.inlineIconButton}>
      <SaveAlt color="primary" />
    </IconButton>
  );
}

const useStyles = makeStyles((theme) => ({
  inlineIconButton: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  highlight: {
    backgroundColor: lighten(theme.palette.secondary.light, 0.7),
  },
  usage: {
    color: theme.palette.text.secondary,
    fontSize: "90%",
  },
  warning: {
    verticalAlign: "middle",
  },
}));
