import { Button, makeStyles } from "@material-ui/core";

import Dropzone from "react-dropzone";
import React from "react";
import { Upload } from "@mui/icons-material";

export default function UploadButton({ disabled, onFilesDropped }) {
  const [uploading, setUploading] = React.useState(false);
  const classes = useStyles();

  const onFileDropped = async (files) => {
    setUploading(true);
    try {
      await onFilesDropped(files);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div>
      <Dropzone disabled={disabled} onDrop={onFileDropped}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Button
              className={classes.uploadButton}
              color="secondary"
              variant="outlined"
              disabled={uploading}
            >
              <Upload />
            </Button>
          </div>
        )}
      </Dropzone>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  uploadButton: {
    marginLeft: 16,
    borderRadius: 2,
  },
}));
