import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) => ({
  active: {
    color: theme.palette.success.main,
  },
  base: {
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  inactive: {
    color: theme.palette.error.main,
  },
}));

export const PAID = "Paid";
export const REFUNDED = "Refunded";

export default function PaymentStatus({ status }) {
  const classes = useStyles();
  return (
    <span>
      <span
        className={clsx(classes.base, {
          [classes.active]: status === PAID,
          [classes.inactive]: status === REFUNDED,
        })}
      >
        {status}
      </span>
    </span>
  );
}
