import { AdminContainer } from "../../components/layouts";

import { Button, ListItem, Paper, Typography, TextField, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Check as CheckIcon, Close } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import api from "../../api";
import { useLoading } from "../../hooks/loading";
import { Check } from "./Check";
import { DetailsView } from "./DetailsView";
import { toast } from "react-toastify";

export default function KybVerificationDetailPage() {
  const classes = useStyles();
  const { id } = useParams();

  const { startLoading, stopLoading } = useLoading();

  const [verification, setVerification] = useState(null);
  const [memo, setMemo] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [files, setFiles] = useState(null);
  const [selectedFileIndex, setSelectedFileIndex] = React.useState(0);

  useEffect(() => {
    (async () => {
      startLoading();
      try {
        const result = await api.getKybVerification(id);
        setVerification(result.data);
        setFiles(result.data.documents)
      } catch (err) {
        toast.error(err.message)
      }
      stopLoading();
    })();
  }, [id]);

  useEffect(() => {
    if (verification && !memo) setMemo(verification.memo);
  }, [verification]);

  const updateVerificationStatus = async (status) => {
    startLoading();
    try {
      const apiMethod =
        status === "approve"
          ? api.postApproveKybVerification
          : api.postRejectKybVerification;

      const result = await apiMethod(id, memo);
      setVerification(result.data.enrollmentVerification);
    } catch (err) {
      toast.error(err.message)
    }
    stopLoading();
  };

  const areAllChecksCompleted = !!verification?.checks.find((check) => !check.status);

  if (!verification) return "";

  return (
    <AdminContainer variant="detail-wide" className={classes.container}>
      <Paper elevation={3} className={classes.paperContainer}>
        <div className={classes.checksContainer}>
          <div className={classes.verificationTitleContainer}>
            <h2>KYB verification</h2>
            {verification.status !== "ready_to_review" && (
              <div>
                <div className={`${classes.verificationStatus}`}>
                  {verification.status === "approved" ? (
                    <CheckIcon className={classes.verificationStatusApproved} />
                  ) : (
                    <Close className={classes.verificationStatusRejected} />
                  )}
                  <Typography
                    variant="h6"
                    style={{
                      color: verification.status === "approved" ? "green" : "red",
                    }}
                  >
                    {verification.status}
                  </Typography>
                </div>
              </div>
            )}
          </div>

          {verification?.checks?.map((check) => (
            <Check
              key={check.id}
              check={check}
              kybVerificationId={id}
              setKybVerification={setVerification}
              kybVerification={verification}
            />
          ))}

          <ListItem className={classes.actionContainer}>
            <TextField
              value={memo}
              onChange={(e) => setMemo(e.target.value)}
              disabled={verification.status !== "ready_to_review"}
              fullWidth
              label="Memo"
              multiline
              rows={4}
              variant="outlined"
            />
            {verification.status === "ready_to_review" && (
              <div className={classes.actionButtonContainer}>
                <Button
                  onClick={() => updateVerificationStatus("approve")}
                  variant="contained"
                  color="primary"
                  disabled={areAllChecksCompleted}
                >
                  Approve
                </Button>
                <Button
                  className={classes.rejectButton}
                  variant="outlined"
                  color="primary"
                  disabled={areAllChecksCompleted || !(memo && memo.length > 16)}
                  onClick={() => updateVerificationStatus("reject")}
                >
                  Reject
                </Button>
              </div>
            )}
          </ListItem>
        </div>

        <div className={classes.detailsViewContainer}>
          <DetailsView
            verification={verification}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            files={files}
            setFiles={setFiles}
            selectedFileIndex={selectedFileIndex}
            setSelectedFileIndex={setSelectedFileIndex}
          />
        </div>
      </Paper>
    </AdminContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginBottom: 0,
  },
  verificationTitleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  paperContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "stretch",
    height: "89vh",
  },
  checksContainer: {
    flex: 2,
    height: "100%",
    padding: 16,
    overflowY: "scroll",
  },
  detailsViewContainer: {
    flex: 3,
    height: "100%",
    overflowY: "scroll",
  },
  checkInput: {
    marginBottom: 16,
  },
  checkActionButtonContainer: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 16,
  },
  actionContainer: {
    marginTop: 32,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  actionButtonContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 16,
  },
  rejectButton: {
    marginLeft: 16,
  },
  verificationStatus: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 16,
  },
  verificationStatusApproved: {
    color: theme.palette.success.light,
  },
  verificationStatusRejected: {
    color: theme.palette.error.light,
  },
}));
