import Dropzone from "react-dropzone";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  dropzone: {
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
    padding: "10px",
    borderRadius: "5px",
    height: "100px",
    width: "500px",
    cursor: "pointer",
  },
  dropzoneActive: {
    boxShadow: "green 0px 7px 29px 0px;",
  },
}));

export default function DropZone({ disabled, onFilesDropped, dropzoneLabel }) {
  const [uploading, setUploading] = React.useState(false);
  const [fileEnter, setFileEnter] = React.useState(false);

  const classes = useStyles();

  const onFileDrop = async (files) => {
    setUploading(true);
    try {
      await onFilesDropped(files);
    } finally {
      setUploading(false);
      setFileEnter(false);
    }
  };

  return (
    <div>
      <Dropzone
        onDragEnter={() => setFileEnter(true)}
        onDragLeave={() => setFileEnter(false)}
        disabled={disabled}
        onDrop={onFileDrop}
        multiple={false}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              className={[classes.dropzone, fileEnter ? classes.dropzoneActive : ""].join(
                " "
              )}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {uploading ? (
                <p>Uploading files</p>
              ) : (
                <p>
                  {dropzoneLabel ||
                    "Drag & drop some files here, or click to select files"}
                </p>
              )}
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  );
}
