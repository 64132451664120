import { AdminContainer, AlignedListContainer } from "../../components/layouts";
import Time, { isoString } from "../../lithic/Moment";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { AuditLogs } from "../../components/AuditLogs";
import AuditLogsTable from "../../components/AuditLogsTable";
import Box from "@material-ui/core/Box";
import CopyContentIconButton from "../../components/CopyContentIconButton";
import DetailSection from "../../components/DetailSection";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControl from "@material-ui/core/FormControl";
import HiddenCopyInput from "../../components/HiddenCopyInput";
import IdentityVerificationTable from "../../components/IdentityVerificationTable";
import InlineEditable from "../../components/InlineEditable";
import { InputLabel } from "@material-ui/core";
import Link from "../../components/Link";
import { GutterlessListItem as ListItem } from "../../components/lists";
import ListItemText from "@material-ui/core/ListItemText";
import Money from "../../lithic/Money";
import React from "react";
import SafeExternalLink from "../../lithic/SafeExternalLink";
import SimpleTable from "../../components/SimpleTable";
import TypeToConfirm from "../../components/TypeToConfirm";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import api from "../../api";
import clsx from "clsx";
import displayPhone from "../../modules/displayPhone";
import fullName from "../../modules/fullName";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";
import oneLineAddress from "../../modules/oneLineAddress";
import { useParams } from "react-router";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  inactive: {
    color: theme.palette.muted.main,
  },
  dangerBody: {
    display: "flex",
    flexDirection: "column",
  },
  dangerHeading: {
    color: theme.palette.error.main,
  },
  dangerTypeToConfirm: {
    width: "50%",
  },
  inlineInput: {
    marginLeft: theme.spacing(2),
    width: 170,
  },
  primary: {
    color: theme.palette.primary.main,
  },
  containerDanger: {
    background: "rgb(255, 220, 245)",
  },
  boxBorderGreen: {
    border: "1px solid",
    borderColor: theme.palette.success.main,
    backgroundColor: lighten(theme.palette.success.main, 0.7),
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
}));

export default function CustomerDetailPage() {
  const { id } = useParams();
  const classes = useStyles();

  function saveAttribute(params) {
    return api
      .updateCustomer({ id, ...params })
      .then(api.pickData)
      .then(setCustomer);
  }

  function closeAccount() {
    return api.closeCustomerAccount({ id }).then(api.pickData).then(setCustomer);
  }

  function markFraudulent() {
    return api
      .markFraudulent({ id })
      .then(api.pickData)
      .tap(setCustomer)
      .tap((c) => {
        if (c.markedFraudulentAt) {
          toast.success(`The account for ${c.email} has been marked as Fraud`);
        }
      });
  }

  function unmarkFraudulent() {
    return api
      .unmarkFraudulent({ id })
      .then(api.pickData)
      .tap(setCustomer)
      .tap((c) => {
        if (!c.markedFraudulentAt) {
          toast.success(`The account for ${c.email} has been unmarked as Fraud`);
        }
      });
  }

  function addReferral(referralCode) {
    return api
      .addCustomerReferral({ id, referralCode, checkIfAffiliateExist: true })
      .then(api.pickData)
      .tap(setCustomer);
  }

  const [customer, setCustomer] = React.useState({ roles: [] });
  React.useEffect(() => {
    api.getCustomer({ id }).then(api.pickData).then(setCustomer);
  }, [id]);
  const [payments, setPayments] = React.useState([]);
  React.useEffect(() => {
    api.getCustomerPayments({ id }).then(api.pick("data.items")).then(setPayments);
  }, [id]);

  const [invoices, setInvoices] = React.useState([]);
  React.useEffect(() => {
    api.getCustomerInvoices({ id }).then(api.pick("data.items")).then(setInvoices);
  }, [id]);

  const [paymentInstruments, setPaymentInstruments] = React.useState([]);
  React.useEffect(() => {
    api
      .getCustomerPaymentInstruments({ id })
      .then(api.pick("data.items"))
      .then(setPaymentInstruments);
  }, [id]);

  const customerFullName = fullName(customer, {
    firstNameField: "firstName",
    lastNameField: "lastName",
  });

  const warningText = `
    ***This change might create a refurnishment which is very risky.
    Please consult with Joven before making these changes.
  `;

  return (
    <AdminContainer
      variant="detail"
      customer={customer}
      className={customer.markedFraudulentAt ? classes.containerDanger : ""}
    >
      {customer.fromPartner && (
        <DetailSection title="From Partner" className={classes.boxBorderGreen}>
          {customer.partnerServiceType === "full_service"
            ? "Process as normal (Full service)"
            : "Please process Identity. RVs should be processed by partner."}
        </DetailSection>
      )}
      {customer.communicationAllowed === false && (
        <DetailSection title="Communication Muted" className={classes.boxBorderGreen}>
          There will be no events, notification to users.
        </DetailSection>
      )}
      <AlignedListContainer maxWidth="xl">
        <ListItem>
          <ListItemText>Id: {customer.id}</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Created: <Time format={"MMM DD, YYYY HH:mm:ss"}>{customer.createdAt}</Time>
          </ListItemText>
        </ListItem>
        <Typography variant="h6" color="primary">
          Customer Details
        </Typography>
        {customer.markedFraudulentAt && (
          <Typography variant="h6" color="primary">
            Marked As Fraudulent on {customer.markedFraudulentAt} by{" "}
            {customer.markedFraudulentBy.name}
          </Typography>
        )}
        <InlineEditable
          label="First Name"
          value={customer.firstName}
          ListItemComponent={ListItem}
          onCommit={(firstName) => saveAttribute({ firstName })}
          copyContent
          warning={true}
          warningComponent={<h2>{warningText}</h2>}
        />
        <InlineEditable
          label="Last Name"
          value={customer.lastName}
          ListItemComponent={ListItem}
          onCommit={(lastName) => saveAttribute({ lastName })}
          copyContent
          warning={true}
          warningComponent={<h2>{warningText}</h2>}
        />
        <ListItemText>
          Full name: {customerFullName}
          <CopyContentIconButton value={customerFullName} />
        </ListItemText>
        <InlineEditable
          label="Email"
          value={customer.email}
          ListItemComponent={ListItem}
          onCommit={(email) => saveAttribute({ email })}
          warning={true}
          warningComponent={<h2>{warningText}</h2>}
        />
        <InlineEditable
          label="Phone"
          value={displayPhone(customer.phone)}
          ListItemComponent={ListItem}
          onCommit={(phone) =>
            saveAttribute({ phone: "1" + phone.replace(/[^0-9]/g, "") })
          }
          warning={true}
          warningComponent={<h2>{warningText}</h2>}
        />
        <InlineEditable
          label="Note"
          value={customer.note}
          ListItemComponent={ListItem}
          onCommit={(note) => saveAttribute({ note })}
        />
        <InlineEditable
          label="Social security"
          value={customer.ssn}
          ListItemComponent={ListItem}
          onCommit={(ssn) => saveAttribute({ ssn })}
          displayFunc={() => (
            <FormControl className={classes.extCtrlElement}>
              <InputLabel>Social security</InputLabel>
              <HiddenCopyInput value={customer.ssn} />
            </FormControl>
          )}
          warning={true}
          warningComponent={<h2>{warningText}</h2>}
        />
        <InlineEditable
          label="Date of Birth"
          value={customer.dob}
          inputType="date"
          ListItemComponent={ListItem}
          onCommit={(dob) => saveAttribute({ dob: isoString(dob) })}
          warning={true}
          warningComponent={<h2>{warningText}</h2>}
        />
        <Typography variant="h6" color="primary">
          Verification
        </Typography>
        <ListItem>
          <SafeExternalLink href="https://dashboard.stripe.com/identity/verification-sessions/create">
            Stripe identity verification
          </SafeExternalLink>
        </ListItem>
        <InlineEditable
          label="Phone Verified"
          inputType="switcher"
          value={Boolean(customer.phoneVerifiedAt)}
          ListItemComponent={ListItem}
          onCommit={(phoneVerified) => saveAttribute({ phoneVerified })}
        />
        <InlineEditable
          label="Email Verified"
          inputType="switcher"
          value={Boolean(customer.emailVerifiedAt)}
          ListItemComponent={ListItem}
          onCommit={(emailVerified) => saveAttribute({ emailVerified })}
        />
        <ListItem>
          <ListItemText>
            Rental Address: {oneLineAddress(customer.legalEntity?.address || {})}
          </ListItemText>
        </ListItem>
        <InlineEditable
          label="Timezone"
          value={customer.timezone}
          ListItemComponent={ListItem}
          onCommit={(timezone) => saveAttribute({ timezone })}
        />
        {!customer.referral && (
          <InlineEditable
            label="Add referral code"
            value=""
            ListItemComponent={ListItem}
            onCommit={(code) => addReferral(code)}
          />
        )}
        <ListItem className={clsx(!_.get(customer, "referral") && classes.inactive)}>
          <ListItemText>
            <span className={classes.primary}>Referrer: </span>{" "}
            <Referral referral={customer.referral} />
          </ListItemText>
        </ListItem>

        <ListItem
          className={clsx(
            !_.get(customer, "expoPushNotificationTokens.length") && classes.inactive
          )}
        >
          <ListItemText>
            Expo Notification Tokens:{" "}
            {customer.expoPushNotificationTokens?.join(",") || "(none)"}
          </ListItemText>
        </ListItem>
        <InlineEditable
          label="Roles"
          inputType="multiselect"
          value={customer.roles}
          ListItemComponent={ListItem}
          displayValue={customer.roles.join(", ")}
          onCommit={(roles) => saveAttribute({ roles })}
          inputProps={{ options: customer.availableRoles, chip: true }}
        />
        {customer.creditAccountEnrollments?.map((en) => (
          <ListItem key={en.id}>
            <ListItemText>
              Credit Account Enrollment:{" "}
              <Link to={`/credit-account-enrollments/${en.id}`}>
                {en.status},{" "}
                <Time format={"MMM DD, YYYY HH:mm:ss"}>
                  {en.submittedAt || en.createdAt}
                </Time>
              </Link>
            </ListItemText>
          </ListItem>
        ))}
        {customer.reportingAccountEnrollments?.map((en) => (
          <ListItem key={en.id}>
            <ListItemText>
              Report Account Enrollment:{" "}
              <Link to={`/reporting-account-enrollments/${en.id}`}>
                {en.status},{" "}
                <Time formatExact={"MMM DD, YYYY HH:mm:ss"}>
                  {en.submittedAt || en.createdAt}
                </Time>{" "}
                (ID: {en.id}) (Address: {en.address?.address1} {en.address?.address2},{" "}
                {en.address?.city} {en.address?.stateOrProvince})
              </Link>
            </ListItemText>
          </ListItem>
        ))}
      </AlignedListContainer>
      <DetailSection title="Identity Verification" id="identity-verification-required">
        <IdentityVerificationTable
          key={customer.id}
          subjectId={customer.id}
          subjectType="Boom::Customer"
        />
      </DetailSection>
      <Box mt={5}>
        <SimpleTable
          truncated
          tableProps={{ size: "small" }}
          headers={[
            "Customer Journeys",
            "Service",
            "Name",
            "Created",
            "Processed",
            "Message",
          ]}
          rows={customer?.journeys}
          toCells={(row) => [
            row.id,
            row.service,
            row.name,
            <Time key={1} format={"MMM DD, YYYY HH:mm:ss"}>
              {row.createdAt}
            </Time>,
            <Time key={2} format={"MMM DD, YYYY HH:mm:ss"}>
              {row.processedAt}
            </Time>,
            row.message,
          ]}
        />
      </Box>
      <Box mt={5}>
        <SimpleTable
          truncated
          tableProps={{ size: "small" }}
          headers={["Payments", "Amount", "Recipient", "Status", "Starting", "Created"]}
          rows={payments}
          keyRowAttr="id"
          toCells={(row) => [
            <Link key={1} to={`/payments/${row.id}`}>
              {row.id}
            </Link>,
            <Money key={2} value={row.amount} />,
            row.receivingLegalEntity.name,
            <Link key={4} to={`/payments/${row.id}`}>
              {row.status}
            </Link>,
            <Time key={5} format={"MMM DD, YYYY HH:mm:ss"}>
              {row.applyAt}
            </Time>,
            <Time key={6} format={"MMM DD, YYYY HH:mm:ss"}>
              {row.createdAt}
            </Time>,
          ]}
        />
      </Box>
      <Box mt={5}>
        <SimpleTable
          truncated
          tableProps={{ size: "small" }}
          headers={["Instrument", "Name", "Institution", "Last4"]}
          rows={paymentInstruments}
          keyRowAttr="id"
          toCells={(row) => [
            <Link key={1} to={row.adminLink}>
              {_.startCase(row.paymentMethodType)} ({row.id})
            </Link>,
            <Link key={3} to={row.adminLink}>
              {row.name}
            </Link>,
            row.display.institutionName,
            row.display.last4,
          ]}
        />
      </Box>
      <Box mt={5}>
        <SimpleTable
          truncated
          tableProps={{ size: "small" }}
          headers={["Invoices", "Status", "Amount", "Updated at"]}
          rows={invoices}
          keyRowAttr="id"
          toCells={(row) => [
            <Link key={1} to={`/invoices/${row.id}`}>
              {row.id}
            </Link>,
            <Link key={4} to={`/invoices/${row.id}`}>
              {row.status}
            </Link>,
            <Money key={2} value={row.total} />,
            <Time key={5} format={"MMM DD, YYYY HH:mm:ss"}>
              {row.updatedAt}
            </Time>,
          ]}
        />
      </Box>
      <Box mt={5}>
        <SimpleTable
          truncated
          tableProps={{ size: "small" }}
          headers={["Sessions", "IP", "Created", "User Agent"]}
          rows={customer?.sessions}
          toCells={(row) => [
            row.id,
            <SafeExternalLink key={1} href={row.ipLookupLink}>
              {row.peerIp}
            </SafeExternalLink>,
            <Time key={2} format={"MMM DD, YYYY HH:mm:ss"}>
              {row.createdAt}
            </Time>,
            row.userAgent,
          ]}
        />
      </Box>
      <Box mt={5}>
        <SimpleTable
          truncated
          tableProps={{ size: "small" }}
          headers={["Referrals", "Created", "Type", "Channel", "Referred"]}
          rows={customer?.referrals}
          toCells={(row) => [
            row.id,
            <Time key={2} format={"MMM DD, YYYY HH:mm:ss"}>
              {row.createdAt}
            </Time>,
            row.referralType,
            row.channelName,
            <Link key={3} to={`/customers/${row.referred.id}`}>
              {row.referred.name}
            </Link>,
          ]}
        />
      </Box>
      <Box mt={5}>
        <SimpleTable
          truncated
          tableProps={{ size: "small" }}
          headers={["Reset Codes", "Created", "Transport", "Token", "Used", "Expires"]}
          rows={customer?.resetCodes}
          toCells={(row) => [
            row.id,
            <Time key={1} format={"MMM DD, YYYY HH:mm:ss"}>
              {row.createdAt}
            </Time>,
            row.transport,
            row.token,
            row.used ? "Yes" : "No",
            <Time key={2} format={"MMM DD, YYYY HH:mm:ss"}>
              {row.expireAt}
            </Time>,
          ]}
        />
      </Box>
      <DetailSection title="Audit Logs">
        <AuditLogsTable auditLogs={customer.auditLogs} />
        <br />
        <AuditLogs auditableId={customer.id} auditableType="Boom::Customer" />
      </DetailSection>

      <AlignedListContainer maxWidth="xl">
        <Box mt={5}>
          {!customer.softDeletedAt && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle2" className={classes.dangerHeading}>
                  Close Account
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.dangerBody}>
                <Typography gutterBottom>
                  Closing the account for {customer.email} will remove the ability for
                  them to login, scramble their email, and cancel any upcoming payments.
                  With some effort, it is undoable.
                </Typography>
                <TypeToConfirm
                  confirmString={customer.email}
                  buttonLabel="Close Account"
                  inputLabel="Type customer email to confirm"
                  rootClassName={classes.dangerTypeToConfirm}
                  onConfirm={closeAccount}
                />
              </AccordionDetails>
            </Accordion>
          )}

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle2" className={classes.dangerHeading}>
                {customer.markedFraudulentAt ? "Unmark" : "Mark"} Fraudulent
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.dangerBody}>
              <Typography gutterBottom>
                {customer.markedFraudulentAt ? "Unmarking" : "Marking"} customer{" "}
                {customer.email} Fraudulent
              </Typography>
              <TypeToConfirm
                confirmString={customer.email}
                buttonLabel={`${
                  customer.markedFraudulentAt ? "Unmark" : "Mark"
                } Fraudulent`}
                inputLabel="Type customer email to confirm"
                rootClassName={classes.dangerTypeToConfirm}
                onConfirm={
                  customer.markedFraudulentAt ? unmarkFraudulent : markFraudulent
                }
              />
            </AccordionDetails>
          </Accordion>
        </Box>
      </AlignedListContainer>
    </AdminContainer>
  );
}

function Referral({ referral }) {
  if (!referral) {
    return "(none)";
  }
  if (referral.referralType === "channel") {
    return "From " + referral.channelName;
  }
  return (
    <Link key={3} to={`/customers/${referral.referrer.id}`}>
      {referral.referrer.name}
    </Link>
  );
}
