import { toTz, useTimezone } from "../lithic/Moment";

import { DatePicker as MDatePicker } from "@material-ui/pickers";
import React from "react";
import dayjs from "dayjs";

const defaultTz = dayjs.tz.guess();

export default function DatePicker({ timezone, value, ...rest }) {
  const { timezone: tzFromContext } = useTimezone() || {};
  const tz = timezone || tzFromContext || defaultTz;
  const val = toTz(value, tz);
  return <MDatePicker value={val} {...rest} />;
}
