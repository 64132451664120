import Link from "./Link";
import React from "react";
import _ from "lodash";
import theme from "../theme";

export function renderTableLink({ url, id = "id", body, style, anchor = "" }) {
  const bodyCallback = _.isFunction(body) ? body : _.property(body);
  const idCallback = _.isFunction(id) ? id : _.property(id);

  return (rowData) => {
    return (
      <Link
        to={`${url}${idCallback(rowData)}${anchor}`}
        style={{
          color: theme.palette.dark.main,
          ...(style ? style(rowData) : {}),
        }}
      >
        {bodyCallback(rowData)}
      </Link>
    );
  };
}

export function renderText({ body, style }) {
  const bodyCallback = _.isFunction(body) ? body : _.property(body);

  return (rowData) => {
    return (
      <span
        style={{
          color: theme.palette.dark.main,
          ...(style ? style(rowData) : {}),
        }}
      >
        {bodyCallback(rowData)}
      </span>
    );
  };
}
