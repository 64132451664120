import CancelIcon from "@material-ui/icons/CloseOutlined";
import CheckIcon from "@material-ui/icons/CheckOutlined";
import CopyContentIconButton from "./CopyContentIconButton";
import DatePicker from "./DatePicker";
import DateTimePicker from "./DateTimePicker";
import DateTimeRangePicker from "./DateTimeRangePicker";
import EditIcon from "@material-ui/icons/Edit";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MultiSelect from "./MultiSelect";
import React from "react";
import ReactInputMask from "react-input-mask";
import SelectInput from "./SelectInput";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import WarningModal from "./WarningModal";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { useToastError } from "./FormError";
import useToggle from "../state/useToggle";

const useStyles = makeStyles(() => ({
  flexGrow: {
    flexGrow: 1,
  },
  text: {
    overflow: "hidden",
  },
}));

export default function InlineEditable({
  label,
  value,
  displayValue,
  displayFunc,
  onCommit,
  inputType,
  inputProps,
  editable,
  ListItemComponent,
  InputComponent,
  copyContent,
  warning,
  warningComponent,
}) {
  const classes = useStyles();
  const toastError = useToastError();
  const [editing, setEditing] = React.useState(false);
  const [tempValue, setTempValue] = React.useState(value);
  editable = _.isUndefined(editable) ? true : editable;
  ListItemComponent = ListItemComponent || ListItem;
  const warningToggle = useToggle(false);

  function edit() {
    if (warning) {
      warningToggle.turnOn();
    }
    setEditing(true);
    setTempValue(value);
  }

  function warningConfirm() {
    setEditing(true);
    setTempValue(value);
    warningToggle.turnOff();
  }

  function warningCancel() {
    setEditing(false);
    warningToggle.turnOff();
  }

  async function commit() {
    try {
      await onCommit(tempValue);
    } catch (e) {
      toastError(e);
      throw e;
    }
    setEditing(false);
  }
  function cancel() {
    setEditing(false);
  }

  if (!editing) {
    if (_.isUndefined(displayValue)) {
      if (inputType === "switcher") {
        displayValue = value ? "✔️" : "✖️";
      } else {
        displayValue = value;
      }
    }

    return (
      <ListItemComponent>
        {displayFunc ? (
          displayFunc()
        ) : (
          <ListItemText className={classes.text}>
            {label ? (
              <>
                {label}: {displayValue}
              </>
            ) : (
              displayValue
            )}
            {copyContent ? <CopyContentIconButton value={value} /> : null}
          </ListItemText>
        )}
        {editable && (
          <IconButton onClick={edit}>
            <EditIcon color={"primary"} />
          </IconButton>
        )}
      </ListItemComponent>
    );
  }
  let inputEl;
  if (InputComponent) {
    inputEl = (
      <InputComponent
        className={classes.flexGrow}
        value={tempValue}
        onChange={(ev) => setTempValue(ev.target.value)}
        onChangeValue={setTempValue}
        label={label}
        {...inputProps}
      />
    );
  } else if (inputType === "switcher") {
    inputEl = (
      <>
        <FormControlLabel
          control={
            <Switch
              checked={Boolean(tempValue)}
              color={"primary"}
              onChange={(ev) => setTempValue(ev.target.checked)}
              {...inputProps}
            />
          }
          label={label}
        />
        <div className={classes.flexGrow} />
      </>
    );
  } else if (inputType === "multiselect") {
    inputEl = (
      <MultiSelect
        className={classes.flexGrow}
        value={tempValue}
        onChange={(ev) => setTempValue(ev.target.value)}
        label={label}
        {...inputProps}
      />
    );
  } else if (inputType === "date") {
    inputEl = (
      <DatePicker
        className={classes.flexGrow}
        value={tempValue}
        onChange={setTempValue}
        {...inputProps}
      />
    );
  } else if (inputType === "datetime") {
    inputEl = (
      <DateTimePicker
        className={classes.flexGrow}
        value={tempValue}
        onChange={setTempValue}
        {...inputProps}
      />
    );
  } else if (inputType === "datetimerange") {
    inputEl = (
      <DateTimeRangePicker
        className={classes.flexGrow}
        value={tempValue}
        onChange={setTempValue}
        pickerProps={inputProps}
      />
    );
  } else if (inputType === "select") {
    inputEl = (
      <SelectInput
        className={classes.flexGrow}
        label={label}
        value={tempValue}
        onChangeValue={setTempValue}
        {...inputProps}
      />
    );
  } else if (inputType === "ssn") {
    inputEl = (
      <ReactInputMask
        mask="999-99-9999"
        className={classes.flexGrow}
        label={label}
        value={tempValue}
        onChange={(ev) => setTempValue(ev.target.value)}
        {...inputProps}
      >
        {(props) => <TextField {...props} />}
      </ReactInputMask>
    );
  } else if (inputType === "phone") {
    inputEl = (
      <ReactInputMask
        mask="(999) 999-9999"
        className={classes.flexGrow}
        label={label}
        value={tempValue}
        onChange={(ev) => setTempValue(ev.target.value)}
        {...inputProps}
      >
        {(props) => <TextField {...props} />}
      </ReactInputMask>
    );
  } else {
    inputEl = (
      <TextField
        className={classes.flexGrow}
        label={label}
        value={tempValue}
        onChange={(ev) => setTempValue(ev.target.value)}
        {...inputProps}
      />
    );
  }
  return (
    <ListItem>
      {inputEl}
      <IconButton onClick={commit}>
        <CheckIcon color={"primary"} />
      </IconButton>
      <IconButton onClick={cancel}>
        <CancelIcon color={"primary"} />
      </IconButton>
      <WarningModal
        toggle={warningToggle}
        onConfirm={warningConfirm}
        onCancel={warningCancel}
      >
        {warningComponent}
      </WarningModal>
    </ListItem>
  );
}
