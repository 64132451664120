import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  active: {
    color: theme.palette.success.main,
  },
  base: {
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  rejected: {
    color: theme.palette.error.main,
  },
  inactive: {
    color: theme.palette.grey[600],
  },
}));

export default function AutoRenewStatus({ status }) {
  const classes = useStyles();
  return (
    <span>
      <span
        className={clsx(classes.base, {
          [classes.inactive]: status === "auto_renew_inactive",
          [classes.active]: status === "auto_renew_active",
          [classes.rejected]: status === "auto_renew_failed",
        })}
      >
        {status?.replace("auto_renew_", "").replace("_", " ").toUpperCase()}
      </span>
    </span>
  );
}
