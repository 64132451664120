import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import api from "../../api";
import CircularProgress from "@mui/material/CircularProgress";

export const LookupPartner = ({ paramKey, columnDef, onFilterChanged }) => {
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [search, setSearch] = React.useState("");

  React.useEffect(() => {
    setLoading(true);
    api
      .getPartners({
        page: 1,
        search: search,
        perPage: 10,
      })
      .then((resp) => {
        setOptions(
          resp.data.items.map((item) => ({
            label: item.name,
            value: item.id,
          }))
        );
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [search]);

  return (
    <Autocomplete
      id="combo-box-demo"
      onChange={(e, value) => {
        onFilterChanged(columnDef.tableData.id, value ? [value.value] : undefined);
      }}
      options={loading ? [{ label: "Loading...", value: null }] : options}
      sx={{ width: 150 }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            onChange: (e) => {
              setSearch(e.target.value);
            },
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
