import { ListItem, ListItemText, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { AlignedListContainer } from "../../components/layouts";
import PartnerDetailPage from "../PartnerDetailPage/PartnerDetailPage";

export const PartnerDetailView = ({ verification }) => {

  if (!verification) return <></>;

  const { partner } = verification;

  return (
    <AlignedListContainer maxWidth="xl">
      <PartnerDetailPage partnerId={partner.id}/>
    </AlignedListContainer>
  );
};
