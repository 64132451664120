import KeyValueListItems from "../components/KeyValueListItems";
import { GutterlessListItem as ListItem } from "../components/lists";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import oneLineAddress from "../modules/oneLineAddress";

export default function LegalEntityListItems({ legalEntity, editable, onCommit }) {
  if (!legalEntity) {
    return null;
  }
  return (
    <>
      <ListItem>
        <ListItemText>Legal Entity ({legalEntity.id}):</ListItemText>
      </ListItem>
      <KeyValueListItems
        fields={fields}
        object={legalEntity}
        transformers={transformers}
        editableFieldProps={editable ? editableFieldProps : null}
        onCommit={onCommit}
        inset
      />
    </>
  );
}

const transformers = {
  address: oneLineAddress,
};

const fields = [
  "address",
  "companyName",
  "contactFirstName",
  "contactLastName",
  "email",
  "name",
  "phone",
  "type",
];

const editableFieldProps = {
  companyName: {},
  contactFirstName: {},
  contactLastName: {},
  email: {},
  name: {},
  phone: {},
};
