import React from "react";
import { Grid, Box, List, ListItem, Table } from "@mui/material";
import { Check, Close } from "@material-ui/icons";
import cx from "clsx";
import { PaymentHistory } from "./PaymentHistory";

export const SmartRenderer = ({ data }) => {
  const renderValue = (value, parentKey) => {
    if (value === null) {
      return <p className="text-sm text-red-500 font-medium">null</p>;
    } else if (value === " ") {
      return <p className="text-sm">Empty String</p>;
    } else if (
      ["true", "false", "yes", "no", "y", "n"].includes(String(value).toLowerCase())
    ) {
      return (
        <p className="text-sm">
          {String(value).toLowerCase() === "true" ||
          String(value).toLowerCase() === "yes" ||
          String(value).toLowerCase() === "y" ? (
            <Check style={{ color: "green" }} />
          ) : (
            <Close style={{ color: "red" }} />
          )}
        </p>
      );
    } else if (typeof value === "undefined") {
      return <p className="text-sm text-red-500 font-medium">undefined</p>;
    } else if (Array.isArray(value)) {
      return (
        <ul>
          {parentKey && (
            <p className="text-lg border-b-2 border-gray-300 pb-4 mb-4 font-medium">
              {parentKey}:
            </p>
          )}
          {value.map((item, index) => (
            <li key={index}>
              {parentKey && (
                <p className="text-lg capitalize font-medium mb-2">
                  {parentKey} {index + 1}:
                </p>
              )}
              {renderValue(item)}
            </li>
          ))}
        </ul>
      );
    } else if (typeof value === "object") {
      return renderData(value);
    } else if (String(value).includes("http")) {
      return (
        <a
          className="text-blue-500 underline truncate block max-w-[300px]"
          href={value}
          target="_blank"
          rel="noreferrer"
        >
          {value}
        </a>
      );
    } else {
      return <p className="text-sm">{String(value)}</p>;
    }
  };

  const renderData = (data) => {
    if (data?.paymentHistory1to24) {
      console.log(data?.paymentHistory1to24, data);
    }

    if (data?.paymentHistory1to24) {
      const { paymentHistory1to24, ...rest } = data;
      const mph24 = rest["24MonthPaymentHistory"];

      delete rest["24MonthPaymentHistory"];
      return (
        <>
          <p>
            <span className="font-bold">Payment History 1-24:</span>
          </p>
          <PaymentHistory
            payment_history={paymentHistory1to24}
            date_reported={data.dateReported}
          />

          {mph24?.length ? (
            <>
              <p>
                <span className="font-bold">24 Month Payment History:</span>
              </p>
              <PaymentHistory payment_history={mph24} date_reported={data.dateReported} />{" "}
            </>
          ) : null}
          {Object.entries(rest).map(([key, value]) => {
            return (
              <div
                key={key}
                className={typeof value === "object" ? "my-6" : "flex items-center gap-2"}
              >
                <p
                  className={cx(
                    typeof value === "object"
                      ? "border border-gray-200 bg-gray-50 p-2.5 rounded-lg"
                      : "",
                    "font-bold capitalize text-base"
                  )}
                >
                  {key}:
                </p>

                <div className={typeof value === "object" ? "p-2.5" : ""}>
                  {renderValue(value, key)}
                </div>
              </div>
            );
          })}
        </>
      );
    }

    if (Object.keys(data).length === 0) return <p className="text-sm">Empty Object</p>;
    if (Object.keys(data).length === 1) return renderValue(Object.values(data)[0]);

    return Object.entries(data).map(([key, value]) => {
      return (
        <div
          key={key}
          className={typeof value === "object" ? "my-6" : "flex items-start gap-2"}
        >
          <p
            className={cx(
              typeof value === "object"
                ? "border border-gray-200 bg-gray-50 p-2.5 rounded-lg"
                : "",
              "font-bold capitalize text-base"
            )}
          >
            {key}:
          </p>

          <div className={typeof value === "object" ? "p-2.5" : ""}>
            {renderValue(value, key)}
          </div>
        </div>
      );
    });
  };

  return (
    <Grid item xs={12}>
      {renderValue(data)}
    </Grid>
  );
};
