import {
  Button,
  CircularProgress,
  lighten,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Check as CheckIcon, Close, Edit } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import dayjs from "dayjs";
import { omit } from "lodash";
import React, { useEffect, useState } from "react";
import api from "../../api";
import InlineEditable from "../../components/InlineEditable";
import { stripPhoneSpecialCharacters } from "../../modules/displayUtils";

export const Check = ({
  enrollmentVerificationId,
  check: originalCheck,
  setEnrollmentVerification,
  enrollmentVerification,
}) => {
  const classes = useStyles();

  const [check, setCheck] = useState(originalCheck);
  const [otherValue, setOtherValue] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setCheck(originalCheck);
  }, [originalCheck]);

  const respondCheck = async (status, selected) => {
    setLoading(true);
    setError(null);
    try {
      const result = await api.postUpdateCheck(enrollmentVerificationId, {
        ...check,
        status,
        selected: status === "approved" ? selected : null,
      });
      setEnrollmentVerification(result.data.enrollmentVerification);
    } catch (err) {
      const errorMessage = err?.response?.data?.error?.message || err.message;
      setError(errorMessage);
    }
    setLoading(false);
    setEditMode(false);
  };

  const getInlineEditableProps = (checkValue, value) => {
    if (check.type === "date")
      return {
        value: value === "" ? undefined : value,
        inputType: "date",
        onCommit: (value) =>
          setOtherValue(value ? dayjs(value).format("YYYY-MM-DD") : value),
      };

    if (check.type === "ssn") {
      return {
        inputType: "ssn",
      };
    }

    if (check.type === "phone") {
      return {
        inputType: "phone",
        displayValue: stripPhoneSpecialCharacters(value),
      };
    }

    if (check.type === "number") {
      return {
        type: "number",
        onCommit: (value) => {
          const numberValue = parseFloat(value);
          setOtherValue((prev) => (isNaN(numberValue) ? prev.value : numberValue));
        },
      };
    }

    return {};
  };

  const renderOtherInput = (checkValue) => {
    const value =
      otherValue ||
      (originalCheck.selected?.source === "other" && originalCheck.selected?.value) ||
      checkValue.value;
    return (
      <InlineEditable
        editable={enrollmentVerification.status === "pending" && (!check.status || editMode)}
        value={value}
        label={checkValue.title}
        onCommit={(value) => setOtherValue(value)}
        {...getInlineEditableProps(checkValue, value)}
      />
    );
  };

  return (
    <ListItem className={classes.checkInput}>
      <div>
        <div className={classes.checkTitleContainer}>
          {check.status && (
            <>
              {check.status === "approved" ? (
                <CheckIcon className={classes.approvedCheck} />
              ) : (
                <Close color="error" />
              )}
            </>
          )}
          <Typography className={classes.checkTitle}>{check.title}</Typography>
          {(enrollmentVerification.status === "pending" && (!check.status || editMode)) && (
            <Button
              className={classes.redButton}
              variant="outlined"
              onClick={() => respondCheck("rejected")}
            >
              <Close />
            </Button>
          )}
          {check.status && !editMode && enrollmentVerification.status === "pending" && (
            <Button onClick={() => setEditMode(true)}>
              <Edit />
            </Button>
          )}
        </div>
        <Typography className={classes.checkHint}>{check.hint}</Typography>

        {check.values.map((checkValue) => (
          <div
            className={`${classes.checkValueContainer} ${
              checkValue.source === originalCheck.selected?.source && !editMode
                ? classes.selectedCheck
                : ""
            }`}
            key={checkValue.source}
          >
            {checkValue.source === "other" ? (
              renderOtherInput(checkValue)
            ) : (
              <ListItem>
                <ListItemText>
                  {checkValue.title && <span dangerouslySetInnerHTML={{__html: checkValue.title + ":&nbsp;" }}></span>}
                  <span className={classes.bold}>{checkValue.value}</span>
                </ListItemText>
              </ListItem>
            )}

            {(enrollmentVerification.status === "pending" && (!check.status || editMode)) && (
              <div className={classes.checkActionButtonContainer}>
                <Button
                  disabled={checkValue.source === "other" && !otherValue}
                  variant="outlined"
                  size="small"
                  onClick={() =>
                    respondCheck(
                      "approved",
                      omit(
                        checkValue.source === "other"
                          ? { ...checkValue, value: otherValue }
                          : checkValue,
                        ["title"]
                      )
                    )
                  }
                  className={classes.greenButton}
                >
                  <CheckIcon fontSize="small" />
                </Button>
              </div>
            )}
          </div>
        ))}
        {loading && <CircularProgress />}
        {error && <Alert severity="error">{error}</Alert>}
      </div>
    </ListItem>
  );
};

const useStyles = makeStyles((theme) => ({
  checkInput: {
    marginBottom: 4,
  },
  checkActionButtonContainer: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 16,
  },
  checkValueContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  bold: {
    fontWeight: "bold",
  },
  checkTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 4,
  },
  checkTitle: {
    fontWeight: "bold",
    marginRight: 16,
    borderBottom: "1px solid black",
  },
  checkHint: {
    fontStyle: "italic",
  },
  selectedCheck: {
    backgroundColor: lighten(theme.palette.success.light, 0.5),
  },
  approvedCheck: {
    color: theme.palette.success.light,
  },
  greenButton: {
    borderColor: theme.palette.success.light,
    color: theme.palette.success.light,
  },
  redButton: {
    borderColor: theme.palette.error.light,
    color: theme.palette.error.light,
  },
}));
