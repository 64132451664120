import { Checkbox, ListItemText, makeStyles } from "@material-ui/core";

import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import React from "react";
import Select from "@material-ui/core/Select";
import _ from "lodash";
import clsx from "clsx";
import useQueryParam from "../state/useQueryParam";

export default function TableSelectFilter({
  paramKey,
  columnDef,
  onFilterChanged,
  lookup,
  onlyOneSelection,
}) {
  const classes = useStyles();
  const [paramValue, setParamValue] = useQueryParam(
    paramKey,
    columnDef.defaultFilter || _.keys(lookup),
    {
      parse,
      serialize,
    }
  );
  const [localState, setLocalState] = React.useState(paramValue);

  React.useEffect(() => {
    // Run this on load, since this is a controlled component because we can't control table filtering.
    if (!_.isEmpty(paramValue)) {
      onFilterChanged(columnDef.tableData.id, paramValue);
    }
    // eslint-disable-next-line
  }, []);

  function handleOpen() {
    setLocalState(paramValue);
  }

  function handleChange(e) {
    let val = e.target.value;
    if (onlyOneSelection) {
      setLocalState([val]);
      setParamValue([val]);
      return;
    }
    // On autofill we get a the stringified value.
    val = typeof val === "string" ? val.split(",") : val;
    setLocalState(val);
  }

  function handleClose() {
    if (!_.isEqual(localState, paramValue)) {
      setParamValue(localState);
      const filterVal = _.isEmpty(paramValue) ? null : paramValue;
      onFilterChanged(columnDef.tableData.id, filterVal);
    }
  }

  function handleOnly(ev, value) {
    ev.preventDefault();
    ev.stopPropagation();
    setLocalState([value]);
  }

  return (
    <Select
      value={localState}
      onOpen={handleOpen}
      onChange={handleChange}
      onClose={handleClose}
      multiple={!onlyOneSelection}
      renderValue={(selected) => {
        if (onlyOneSelection) {
          return lookup[selected];
        }
        return selected.map((v) => lookup[v]).join(", ");
      }}
      className={classes.root}
    >
      {Object.entries(lookup).map(([value, label]) => (
        <MenuItem key={value} value={value} className={classes.item}>
          <Checkbox checked={_.includes(localState, value)} />
          <ListItemText primary={label} />
          <span
            className={clsx("tsf-only", classes.only)}
            onClick={(e) => handleOnly(e, value)}
          >
            {!onlyOneSelection && "Only"}
          </span>
        </MenuItem>
      ))}
    </Select>
  );
}

TableSelectFilter.propTypes = {
  paramKey: PropTypes.string.isRequired,
};

function parse(s) {
  if (_.isString(s)) {
    return s.split(",");
  }
  if (_.isArray(s)) {
    return s;
  }
  return undefined;
}

function serialize(o) {
  return parse(o);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  item: {
    "& .tsf-only": {
      opacity: 0,
    },
    "&:hover .tsf-only": {
      opacity: 1,
    },
  },
  only: {
    textAlign: "right",
    minWidth: 50,
    fontSize: "70%",
    color: theme.palette.muted.dark,
  },
}));
