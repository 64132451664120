import React from "react";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";

import { Feed } from "./components/Feed";
import { TabPanel } from "./components/TabPanel";
import { useBureauController } from "./controllers";

export const Bureaus = ({ customerId }) => {
  const { tab, setTab, feedTimeLine } = useBureauController(customerId);
  return (
    <Paper square>
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(_, v) => setTab(v)}
        aria-label="disabled tabs example"
      >
        <Tab label="Equifax" aria-label="Equifax" />
        <Tab label="Experian" aria-label="Experian" />
        <Tab label="Transunion" aria-label="Transunion" />
      </Tabs>

      {feedTimeLine.status === "success" ? (
        <div>
          <TabPanel value={tab} index={0}>
            <Feed data={feedTimeLine.data} />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Feed data={feedTimeLine.data} />
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <Feed data={feedTimeLine.data} />
          </TabPanel>
        </div>
      ) : feedTimeLine.status === "loading" ? (
        <Typography>Loading...</Typography>
      ) : (
        <Typography>
          {typeof feedTimeLine.data === "string"
            ? feedTimeLine.data
            : "Unrecognized error occurred"}
        </Typography>
      )}
    </Paper>
  );
};
