import { apiCollectionToRowResponse, rowQueryToApiParams } from "../modules/tableQuery";
import { renderTableLink } from "../components/renderTableCell";

import { AdminContainer } from "../components/layouts";
import { ListItemText } from "@material-ui/core";
import MaterialTable from "material-table";
import React from "react";
import TableSelectFilter from "../components/TableSelectFilter";
import TableInputFilter from "../components/TableInputFilter";
import Time from "../lithic/Moment";
import api from "../api";
import tableIcons from "../components/tableIcons";
import { useTheme } from "@material-ui/core/styles";

export const VerifiedEnrollmentsListPage = () => {
  const tableRef = React.createRef();
  const theme = useTheme();
  const columns = makeColumns(theme);

  return (
    <AdminContainer variant="list">
      <MaterialTable
        title="Payment Verification"
        tableRef={tableRef}
        columns={columns}
        options={{
          debounceInterval: 500,
          emptyRowsWhenPaging: false,
          filtering: true,
          search: false,
          pageSize: 50,
          pageSizeOptions: [50, 100, 200],
          sorting: true,
          thirdSortClick: false,
          filterCellStyle: {
            transform: "scale(0.80)",
            transformOrigin: "left",
            maxWidth: 0,
          },
        }}
        data={(q) => {
          return api
            .listEnrollmentsReadForPaymentVerification(rowQueryToApiParams(q))
            .then((resp) => apiCollectionToRowResponse(resp));
        }}
        icons={tableIcons}
      />
    </AdminContainer>
  );
};

function makeColumns(theme) {
  function styleKindOfVerification(rowData) {
    let color;
    let backgroundColor;
    switch (rowData.verification) {
      case "initial":
        color = theme.palette.light.main;
        backgroundColor = theme.palette.warning.lighter;
        break;
      case "ongoing":
        color = theme.palette.light.main;
        backgroundColor = theme.palette.secondary.lighter;
        break;
      case "final":
        color = theme.palette.light.main;
        backgroundColor = theme.palette.warning.main;
        break;
      default:
        color = theme.palette.dark.main;
        backgroundColor = theme.palette.info.main;
        break;
    }
    return { color, backgroundColor, padding: theme.spacing(0.5), borderRadius: 100 };
  }

  return [
    {
      title: "Id",
      field: "id",
      defaultSort: "desc",
      filtering: false,
      width: 20,
      render: renderTableLink({
        url: "/reporting-account-enrollments/",
        id: "id",
        body: "id",
        anchor: "/payment-verifications",
      }),
    },
    {
      title: "Priority",
      field: "priority",
      sorting: false,
      filtering: true,
      filterComponent: (props) => <TableInputFilter {...props} paramKey="priority" />,
      defaultFilter: [""],
      render: (rowData) => rowData.priority,
    },
    {
      title: "Customer",
      field: "customer_name",
      sorting: false,
      filtering: false,
      render: renderTableLink({
        url: "/reporting-account-enrollments/",
        id: "id",
        body: "customer.name",
        anchor: "/payment-verifications",
      }),
    },
    {
      title: "Address",
      field: "one_line_address",
      filtering: false,
      sorting: false,
      render: (rowData) => rowData.oneLineAddress,
    },
    {
      title: "Verification type",
      field: "verification",
      filterComponent: (props) => (
        <TableSelectFilter
          {...props}
          paramKey="verification"
          onlyOneSelection={true}
          lookup={{
            "": "-",
            initial: "initial",
            ongoing: "ongoing",
            final: "final",
            has_pending_pms_transactions: "has pending PMS transactions",
            has_pending_plaid_transactions: "has pending Plaid transactions",
          }}
        />
      ),
      defaultFilter: ["initial"],
      render: renderTableLink({
        url: "/reporting-account-enrollments/",
        id: "id",
        body: "verification",
        style: styleKindOfVerification,
        anchor: "/payment-verifications",
      }),
    },
    {
      title: "Progress",
      field: "progress",
      filtering: false,
      sorting: false,
      render: (rowData) => (
        <ListItemText>
          {rowData.progress?.processed} / {rowData.progress?.total}
        </ListItemText>
      ),
    },
    {
      title: "Partner",
      field: "from_partner",
      sorting: false,
      filtering: true,
      filterComponent: (props) => (
        <TableInputFilter
          {...props}
          placeholder="Enter partner ID"
          paramKey="from_partner"
        />
      ),
      defaultFilter: [""],
      render: renderTableLink({
        url: "/partners/",
        id: "partner.id",
        body: "partner.name",
      }),
    },
    {
      title: "Submitted At",
      field: "submitted_at",
      filtering: false,
      sorting: false,
      render: (rowData) => <Time formatExact="MMM DD, YYYY">{rowData.submittedAt}</Time>,
    },
  ];
}
