import Alert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import React from "react";

export default function BannerMessage(props) {
  const message = props.text;
  const hidden = !message;
  if (hidden) {
    return null;
  }
  const color = props.color || "error";
  return (
    <Alert icon={false} color={color} onClose={props.onDismiss}>
      {message}
    </Alert>
  );
}

BannerMessage.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  color: PropTypes.string,
  onDismiss: PropTypes.func,
};
