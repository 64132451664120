import { apiCollectionToRowResponse, rowQueryToApiParams } from "../modules/tableQuery";
import { renderTableLink, renderText } from "../components/renderTableCell";

import { AdminContainer } from "../components/layouts";
import MaterialTable from "material-table";
import React from "react";
import SaveIcon from "@material-ui/icons/Save";
import TableSelectFilter from "../components/TableSelectFilter";
import TableInputFilter from "../components/TableInputFilter";
import Time from "../lithic/Moment";
import api from "../api";
import { saveAs } from "file-saver";
import tableIcons from "../components/tableIcons";
import { useTheme } from "@material-ui/core/styles";

export default function ReportingAccountEnrollmentsListPage({
  filters,
  className,
  dataApi,
}) {
  const tableRef = React.createRef();
  const lastDataRequestQueryRef = React.useRef({});
  const theme = useTheme();

  const DEFAULT_FILTERS = {
    status: {
      enabled: true,
    },
    issues: {
      enabled: true,
    },
    has_verified_rv: {
      enabled: true,
    },
    has_pending_verification_rv: {
      enabled: true,
    },
    from_partner: {
      enabled: true,
    },
    product: {
      enabled: false,
    },
    verifications: {
      enabled: true,
    },
    identity_verification: {
      enabled: true,
    },
    has_rejected_tv: {
      enabled: false,
    },
    from_source: {
      enabled: true,
    },
  };

  const DEFAULT_GET_DATA_API = api.listReportingAccountEnrollments;

  const getDataApi = dataApi || DEFAULT_GET_DATA_API;

  const columns = makeColumns(theme, { ...DEFAULT_FILTERS, ...filters });

  const handleExport = () => {
    const file_requested_at = new Date();
    return api
      .exportReportingAccountEnrollments(
        rowQueryToApiParams(lastDataRequestQueryRef.current)
      )
      .then((resp) => {
        saveAs(
          resp.request.response,
          `filtered_enrollments_${file_requested_at.toISOString()}.csv`
        );
      });
  };

  return (
    <AdminContainer variant="list" className={className}>
      <MaterialTable
        title="Boom Report Enrollments"
        tableRef={tableRef}
        columns={columns}
        options={{
          debounceInterval: 500,
          emptyRowsWhenPaging: false,
          filtering: true,
          pageSize: 50,
          pageSizeOptions: [50, 100, 200],
          sorting: true,
          thirdSortClick: false,
          filterCellStyle: {
            transform: "scale(0.80)",
            transformOrigin: "left",
            maxWidth: 0,
          },
        }}
        data={(q) => {
          lastDataRequestQueryRef.current = q;
          return getDataApi(rowQueryToApiParams(q)).then((resp) =>
            apiCollectionToRowResponse(resp)
          );
        }}
        icons={tableIcons}
        actions={[
          {
            isFreeAction: true,
            icon: () => <SaveIcon />,
            tooltip: "Export to CSV",
            onClick: handleExport,
          },
        ]}
      />
    </AdminContainer>
  );
}

function makeColumns(theme, filters = {}) {
  function enrollmentStatusStyleFn(rowData) {
    let color;

    switch (rowData.status) {
      case "approved":
        color = theme.palette.success.main;
        break;
      case "rejected":
        color = theme.palette.error.main;
        break;
      case "finished":
        color = theme.palette.warning.dark;
        break;
      default:
        color = theme.palette.dark.main;
        break;
    }

    return { color };
  }

  function pendingVerificationRvsStyleFn(rowData) {
    const color =
      rowData.pendingVerificationRvsCount > 0
        ? theme.palette.error.main
        : theme.palette.success.main;

    return { color };
  }

  function verifiedRvsStyleFn(rowData) {
    const color =
      rowData.verifiedRvsCount > 0
        ? theme.palette.success.main
        : theme.palette.error.main;

    return { color };
  }

  const columns = [
    {
      title: "Id",
      field: "id",
      defaultSort: "desc",
      filtering: false,
      width: 20,
      render: renderTableLink({
        url: "/reporting-account-enrollments/",
        body: "id",
      }),
    },
    {
      title: "Submitted",
      field: "submitted_at",
      sorting: false,
      filtering: false,
      render: renderTableLink({
        url: "/reporting-account-enrollments/",
        body: (rowData) => (
          <Time formatExact="MMM DD, YYYY HH:mm:ss">{rowData.submittedAt}</Time>
        ),
      }),
    },
    {
      title: "Customer",
      field: "customer_name",
      sorting: false,
      filtering: false,
      render: renderTableLink({
        url: "/customers/",
        id: "customer.id",
        body: "customer.name",
      }),
    },
    {
      title: "Email",
      field: "customer_email",
      sorting: false,
      filtering: false,
      render: renderTableLink({
        url: "/customers/",
        id: "customer.id",
        body: "customer.email",
      }),
    },
    {
      title: "Address",
      field: "oneLineAddress",
      sorting: false,
      filtering: false,
      render: renderText({
        body: "oneLineAddress",
      }),
    },
  ];

  if (filters.status?.enabled) {
    columns.push({
      title: "Status",
      field: "status",
      sorting: false,
      filterComponent: (props) => (
        <TableSelectFilter
          {...props}
          paramKey="status"
          onlyOneSelection={false}
          lookup={{
            pending: "Submitted",
            approved: "Approved",
            rejected: "Rejected",
            finished: "Finished",
          }}
        />
      ),
      defaultFilter: [],
      render: renderText({
        body: "status",
        style: enrollmentStatusStyleFn,
      }),
    });
  }

  if (filters.from_partner?.enabled) {
    columns.push({
      title: "Partner",
      field: "from_partner",
      sorting: false,
      filtering: true,
      filterComponent: (props) => <TableInputFilter {...props} paramKey="from_partner" />,
      defaultFilter: [""],
      render: renderTableLink({
        url: "/partners/",
        id: "partner.id",
        body: "partner.name",
      }),
    });
  }

  if (filters.verifications?.enabled) {
    columns.push({
      title: "Verifications",
      field: "verifications",
      sorting: false,
      filtering: true,
      filterComponent: (props) => (
        <TableSelectFilter
          {...props}
          paramKey="verifications"
          onlyOneSelection={true}
          lookup={{
            "": "-",
            pending: "pending",
            failed: "failed",
            finished: "finished",
          }}
        />
      ),
      defaultFilter: [""],
      render: renderText({
        body: (enrollment) => {
          return enrollment.verifications?.map((verification) => {
            return (
              <div key={verification.id}>
                {verification.status === "approved" && "✅ "}
                {verification.status === "pending" && "⚪ "}
                {verification.status === "rejected" && "⛔ "}
                {verification.status === "canceled" && "🗙 "}
                {verification.kind}
              </div>
            );
          });
        },
      }),
    });
  }

  if (filters.identity_verification?.enabled) {
    columns.push({
      title: "Identity",
      field: "identity_verification",
      sorting: false,
      filtering: true,
      filterComponent: (props) => (
        <TableSelectFilter
          {...props}
          paramKey="identity_verification"
          onlyOneSelection={true}
          lookup={{
            "": "-",
            pending: "Pending",
            finished: "Verified",
            failed: "Rejected",
            review_required: "Review Required",
          }}
        />
      ),
      defaultFilter: [""],
      render: renderText({
        body: (enrollment) => {
          return enrollment.identityVerifications?.map((verification) => {
            return (
              <div key={verification.id} style={{ whiteSpace: "nowrap" }}>
                {verification.status === "finished" && "✅ "}
                {verification.status === "pending" && "⚪ "}
                {verification.status === "failed" && "⛔ "}
                {verification.status === "review_required" && "❗ "}
                {verification.kind}
              </div>
            );
          });
        },
      }),
    });
  }

  if (filters.product?.enabled) {
    columns.push({
      title: "Product",
      field: "product",
      sorting: false,
      filtering: true,
      filterComponent: (props) => (
        <TableSelectFilter
          {...props}
          paramKey="product"
          onlyOneSelection={true}
          lookup={{
            "": "-",
            past: "BR Past",
            ongoing: "BR Ongoing",
            past_ongoing: "BR Past & Ongoing",
          }}
        />
      ),
      defaultFilter: [""],
      render: renderText({
        body: "product",
      }),
    });
  }

  if (filters.issues?.enabled) {
    columns.push({
      title: "Issues",
      field: "issues",
      sorting: false,
      filtering: true,
      filterComponent: (props) => (
        <TableSelectFilter
          {...props}
          paramKey="issues"
          onlyOneSelection={false}
          lookup={{
            any: "Any issue",
            no_issues: "No issues",
            "bank-account-required": "Bank link error",
            "bank-link-bypassed": "Bank link bypassed",
            "identity-verification-required": "Identity verification",
            "landlord-verification-required": "Landlord verification",
            "lease-upload-requires": "Lease upload required",
            "lease-verification-required": "Lease verification",
            "payment-verification": "Payment verification",
            "plaid-auth-update-required": "Plaid auth update required",
            "portal-verification-required": "Portal auth",
            lease_end_close_verification: "Lease ending",
            lease_m2m_verification: "M2M payment verification",
            resubscription_failed_payment: "Resubscription failed",
            resubscription_need_payment_update: "Card expiring",
          }}
        />
      ),
      defaultFilter: [],
      render: renderText({
        body: (rowData) => {
          return rowData.issues?.map((issue) => {
            return <li key={issue.id}>{issue.title}</li>;
          });
        },
      }),
    });
  }

  if (filters.has_pending_verification_rv?.enabled) {
    columns.push({
      title: "Has pending verification RV",
      field: "has_pending_verification_rv",
      sorting: false,
      filtering: true,
      filterComponent: (props) => (
        <TableSelectFilter
          {...props}
          paramKey="pending_verification_rvs_count"
          onlyOneSelection={true}
          lookup={{
            "": "-",
            true: "Yes",
            false: "No",
          }}
        />
      ),
      defaultFilter: [""],
      render: renderText({
        body: "pendingVerificationRvsCount",
        style: pendingVerificationRvsStyleFn,
      }),
    });
  }

  if (filters.has_verified_rv?.enabled) {
    columns.push({
      title: "Has verified RV",
      field: "has_verified_rv",
      sorting: false,
      filtering: true,
      filterComponent: (props) => (
        <TableSelectFilter
          {...props}
          paramKey="has_verified_rv"
          onlyOneSelection={true}
          lookup={{
            "": "-",
            true: "Yes",
            false: "No",
          }}
        />
      ),
      defaultFilter: [""],
      render: renderText({
        body: "verifiedRvsCount",
        style: verifiedRvsStyleFn,
      }),
    });
  }

  if (filters.has_rejected_tv?.enabled) {
    columns.push({
      title: "Has Rejected TVs",
      field: "has_rejected_tv",
      sorting: false,
      filtering: true,
      filterComponent: (props) => (
        <TableSelectFilter
          {...props}
          paramKey="has_rejected_tv"
          onlyOneSelection={true}
          lookup={{
            "": "-",
            true: "Yes",
            false: "No",
          }}
        />
      ),
      defaultFilter: [""],
      render: renderText({
        body: "rejectedTvsCount",
      }),
    });
  }

  if (filters.has_verified_tv?.enabled) {
    columns.push({
      title: "Has Verified TVs",
      field: "has_verified_tv",
      sorting: false,
      filtering: true,
      filterComponent: (props) => (
        <TableSelectFilter
          {...props}
          paramKey="has_verified_tv"
          onlyOneSelection={true}
          lookup={{
            "": "-",
            true: "Yes",
            false: "No",
          }}
        />
      ),
      defaultFilter: [""],
      render: renderText({
        body: "verifiedTvsCount",
      }),
    });
  }

  if (filters.from_source?.enabled) {
    columns.push({
      title: "From Source",
      field: "from_source",
      sorting: false,
      filtering: true,
      filterComponent: (props) => (
        <TableSelectFilter
          {...props}
          paramKey="from_source"
          onlyOneSelection={true}
          lookup={{
            "": "-",
            app: "App",
            web: "Web",
            portal: "Portal",
            sdk: "SDK",
            legacy: "Legacy",
          }}
        />
      ),
      defaultFilter: [""],
      render: renderText({
        body: "fromSource",
      }),
    });
  }

  return columns;
}
