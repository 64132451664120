import { Divider } from "@material-ui/core";
import React from "react";
import SafeExternalLink from "../lithic/SafeExternalLink";
import Time from "../lithic/Moment";
import UploadReportButton from "./UploadReportButton";
import clsx from "clsx";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";

export default function FurnishmentReportsList({
  reports,
  withLink,
  upload,
  onReportUpload,
}) {
  const classes = useStyles();
  let gridTemplateColumns = "0.2fr 100px 1fr 1fr";

  if (withLink) {
    gridTemplateColumns = "0.2fr 100px 1fr 250px 1fr";
  }

  if (upload) {
    gridTemplateColumns += " 50px";
  }

  const STATUSES_CLASS = {
    pending: classes.rowClassPending,
    approved: classes.rowClassApproved,
  };

  const sortedReport = reports.sort((a, b) => {
    if (b.url > a.url) return 1;
    if (b.url < a.url) return -1;
    return 0;
  });

  return (
    <div>
      {sortedReport
        ? sortedReport.map((report, i) => {
            return (
              <div
                key={`li-row-${report.id}-${i}`}
                className={clsx(classes.row_wrapper, STATUSES_CLASS[report.status])}
                style={{ gridTemplateColumns }}
              >
                <div>{report.id}</div>

                <div>{report.bureau}</div>

                <div>
                  Uploaded at:
                  {report.uploadedAt ? (
                    <Time formatExact={"MMM Do YYYY, h:mm:ss"}>{report.verifiedAt}</Time>
                  ) : (
                    "-"
                  )}
                </div>

                {withLink && (
                  <div className={classes.link_grid}>
                    <SafeExternalLink href={report.url}>Link</SafeExternalLink>
                  </div>
                )}

                <div>{report.status}</div>

                {upload && ["pending", "failed"].indexOf(report.status) > -1 && (
                  <UploadReportButton
                    bureau={report.bureau}
                    id={report.id}
                    onClick={onReportUpload}
                  />
                )}
              </div>
            );
          })
        : "Not available"}
      <Divider />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  row_wrapper: {
    display: "grid",
    gridTemplateColumns: "0.2fr 1fr 1fr 1fr",
    columnGap: "20px",
    padding: "5px",
  },
  column: {
    flexBasis: "20%",
  },
  tradeline_expand_detail: {
    width: "100%",
    height: "100px",
  },
  link_grid: {
    overflowWrap: "break-word",
  },
  details: {},
  rowClassApproved: {
    backgroundColor: lighten(theme.palette.success.light, 0.7),
  },
  rowClassRejected: {
    backgroundColor: lighten(theme.palette.error.light, 0.7),
  },
  rowClassPending: {
    backgroundColor: lighten(theme.palette.warning.light, 0.9),
  },
}));
