import { AdminContainer, AlignedListContainer } from "../components/layouts";
import {
  CustomerLinkListItem,
  GutterlessListItem as ListItem,
} from "../components/lists";

import AdminActionLinks from "../components/AdminActionLinks";
import AuditLogsTable from "../components/AuditLogsTable";
import Box from "@material-ui/core/Box";
import ExternalLinksList from "../components/ExternalLinksList";
import InlineEditable from "../components/InlineEditable";
import Link from "../components/Link";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import RentalVerificationProgress from "../components/RentalVerificationProgress";
import Time from "../lithic/Moment";
import api from "../api";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router";

export default function RentalVerificationDetailPage() {
  const { id } = useParams();
  const classes = useStyles();

  const [rentalVerification, setRentalVerification] = React.useState({
    reportingAccountEnrollment: {},
  });
  React.useEffect(() => {
    api.getRentalVerification({ id }).then(api.pickData).then(setRentalVerification);
  }, [id]);

  function saveAttribute(params) {
    return api
      .updateRentalVerification({ id, ...params })
      .then(api.pickData)
      .then(setRentalVerification);
  }

  const enrollment = rentalVerification.reportingAccountEnrollment;

  return (
    <AdminContainer variant="detail" customer={enrollment.customer}>
      <AlignedListContainer maxWidth="sm">
        <ListItem>
          <ListItemText>Rental Verification Id: {rentalVerification.id}</ListItemText>
        </ListItem>
        <CustomerLinkListItem customer={enrollment.customer} disableGutters />
        <ListItem>
          <ListItemText>
            Reporting enrollment:{" "}
            <Link to={`/reporting-account-enrollments/${enrollment.id}`}>
              {enrollment.id} ({enrollment.status})
            </Link>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Created:{" "}
            <Time d="(unset)" format={"MMM DD, YYYY HH:mm:ss"}>
              {rentalVerification.createdAt}
            </Time>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText
            className={clsx(!rentalVerification.updatedAt && classes.inactive)}
          >
            Updated:{" "}
            <Time d="(unset)" format={"MMM DD, YYYY HH:mm:ss"}>
              {rentalVerification.updatedAt}
            </Time>
          </ListItemText>
        </ListItem>

        <ListItem>
          <ListItemText>For Month: {rentalVerification.forMonth}</ListItemText>
        </ListItem>

        <ListItem>
          <ListItemText>
            Months Delinquency: {rentalVerification.monthsDelinquency}
          </ListItemText>
        </ListItem>

        <ListItem>
          <ListItemText>
            Status:{" "}
            <span
              className={clsx({
                [classes.pendingStatus]:
                  rentalVerification.status === "pending_verification" ||
                  "pending_confirmation",
                [classes.verifiedStatus]: rentalVerification.status === "verified",
                [classes.cancelledStatus]: rentalVerification.status === "canceled",
              })}
            >
              {rentalVerification.status}
            </span>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>Description: {rentalVerification.description}</ListItemText>
        </ListItem>
        <InlineEditable
          label="Close Tradeline"
          inputType="switcher"
          value={Boolean(rentalVerification.closeTradeline)}
          ListItemComponent={ListItem}
          onCommit={(closeTradeline) => saveAttribute({ closeTradeline })}
        />
        <InlineEditable
          label="Delete Tradeline"
          inputType="switcher"
          value={Boolean(rentalVerification.deleteTradeline)}
          ListItemComponent={ListItem}
          editable={false}
        />
        <ListItem>
          <ListItemText>
            <RentalVerificationProgress rv={rentalVerification} />
          </ListItemText>
        </ListItem>
        {/* Temporarily commented in case we need it */}
        {/* {checkboxMethods.map(([title, tsField, boolField]) => {
          const at = rentalVerification[tsField];
          return (
            <InlineEditable
              key={tsField}
              label={title}
              inputType="switcher"
              value={Boolean(at)}
              displayValue={
                at ? <Time format="MMM DD, YYYY HH:mm:ss">{at}</Time> : undefined
              }
              ListItemComponent={ListItem}
              onCommit={(checked) => saveAttribute({ [boolField]: checked })}
            />
          );
        })} */}

        <ExternalLinksList links={rentalVerification.externalLinks} />
        <AdminActionLinks
          links={rentalVerification.adminActionLinks}
          onActionSuccess={(resp) => setRentalVerification(resp.data)}
        />
      </AlignedListContainer>
      <Box mt={3}>
        <AuditLogsTable auditLogs={rentalVerification.auditLogs} />
      </Box>
    </AdminContainer>
  );
}

// const checkboxMethods = [
//   ["Equifax Uploaded", "uploadedToEquifaxAt", "uploadedToEquifax"],
//   ["Experian Uploaded", "uploadedToExperianAt", "uploadedToExperian"],
//   ["Transunion Uploaded", "uploadedToTransunionAt", "uploadedToTransunion"],
//   ["Equifax Confirmed", "confirmedInEquifaxAt", "confirmedInEquifax"],
//   ["Experian Confirmed", "confirmedInExperianAt", "confirmedInExperian"],
//   ["Transunion Confirmed", "confirmedInTransunionAt", "confirmedInTransunion"],
// ];

const useStyles = makeStyles((theme) => ({
  cancelledStatus: {
    color: theme.palette.error.main,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  inactive: {
    color: theme.palette.muted.main,
  },
  pendingStatus: {
    color: theme.palette.warning.main,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  verifiedStatus: {
    color: theme.palette.success.main,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
}));
