import React, { useEffect, useState } from "react";
import { AdminContainer } from "../../components/layouts";
import { Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import api from "../../api";
import { SmartRenderer } from "./ components/SmartRenderer";
import DetailSection from "../../components/DetailSection";
import { AuditLogs } from "../../components/AuditLogs";

export const VerificationIdPage = () => {
  const { verificationId } = useParams();
  const [verification, setVerification] = useState(null);
  useEffect(() => {
    api.getBoomScreenVerification(verificationId).then((resp) => {
      setVerification(resp.data);
    });
  }, []);

  const stepsData = verification?.steps?.length
    ? verification.steps.reduce((acc, step) => {
        acc[step.name] = step.data;
        return acc;
      }, {})
    : {};

  return (
    <AdminContainer variant="list">
      <Typography variant="h4">Verification details</Typography>

      {verification ? (
        <>
          <h1 className="text-2xl my-8">{verification.name}</h1>

          {typeof verification.data === "object" &&
          Object.keys(verification.data).length ? (
            <div className="max-w-[980px] p-8 bg-white">
              <SmartRenderer data={verification.data} />
            </div>
          ) : null}

          {Object.keys(stepsData).length ? (
            <div className="max-w-[980px] p-8 bg-white">
              <Typography variant="h6">Steps</Typography>
              <SmartRenderer data={stepsData} />
            </div>
          ) : null}
          <DetailSection title="Audit Logs">
            <AuditLogs
              auditableId={verification.id}
              auditableType="BoomScreen::Verification"
            />
          </DetailSection>
        </>
      ) : null}
    </AdminContainer>
  );
};
