import Container from "@material-ui/core/Container";
import Link from "../components/Link";
import React from "react";
import Typography from "@material-ui/core/Typography";
import config from "../config";
import { makeStyles } from "@material-ui/core/styles";
import { navRoutes } from "../components/AdminAppBar";
import { useUser } from "../hooks/user";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
}));

export default function DashboardPage() {
  const classes = useStyles();
  const { user } = useUser();

  return (
    <Container className={classes.root} maxWidth="xl">
      <Typography gutterBottom>Hello, {user.name}</Typography>
      <div className={classes.row}>
        <ul>
          {navRoutes.map(([name, href]) => (
            <li key={name}>
              <Link to={href}>{name}</Link>
            </li>
          ))}
        </ul>
        <iframe title="Redash" src={config.redashUrl} width="100%" height="500" />
      </div>
    </Container>
  );
}
