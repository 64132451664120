import { apiCollectionToRowResponse, rowQueryToApiParams } from "../modules/tableQuery";

import { AdminContainer } from "../components/layouts";
import MaterialTable from "material-table";
import React from "react";
import TableSelectFilter from "../components/TableSelectFilter";
import Time from "../lithic/Moment";
import api from "../api";
import { renderTableLink } from "../components/renderTableCell";
import tableIcons from "../components/tableIcons";
import { useTheme } from "@material-ui/core/styles";

export default function CreditAccountEnrollmentsListPage() {
  const tableRef = React.createRef();
  const lastDataRequestQueryRef = React.useRef({});
  const theme = useTheme();
  const columns = makeColumns(theme);
  return (
    <AdminContainer variant="list">
      <MaterialTable
        title="Boom Split Enrollments"
        tableRef={tableRef}
        columns={columns}
        options={{
          emptyRowsWhenPaging: false,
          filtering: true,
          pageSize: 50,
          pageSizeOptions: [50, 100, 200],
          sorting: true,
          thirdSortClick: false,
          filterCellStyle: {
            transform: "scale(0.80)",
            transformOrigin: "left",
            maxWidth: 0,
          },
        }}
        data={(q) => {
          lastDataRequestQueryRef.current = q;
          return api
            .listCreditAccountEnrollments(rowQueryToApiParams(q))
            .then((resp) => apiCollectionToRowResponse(resp));
        }}
        icons={tableIcons}
      />
    </AdminContainer>
  );
}

function makeColumns(theme) {
  function makeCellStyle(rowData) {
    if (rowData.status !== "submitted") {
      return { color: theme.palette.muted.dark };
    }
  }

  return [
    {
      title: "Id",
      field: "id",
      defaultSort: "desc",
      filtering: false,
      width: 20,
      render: renderTableLink({
        url: "/credit-account-enrollments/",
        body: "id",
        style: makeCellStyle,
      }),
    },
    {
      title: "Customer",
      field: "customer_name",
      sorting: false,
      filtering: false,
      render: renderTableLink({
        url: "/credit-account-enrollments/",
        body: "customer.name",
        style: makeCellStyle,
      }),
    },
    {
      title: "Email",
      field: "customer_email",
      sorting: false,
      filtering: false,
      render: renderTableLink({
        url: "/credit-account-enrollments/",
        body: "customer.email",
        style: makeCellStyle,
      }),
    },
    {
      title: "Status",
      field: "status",
      sorting: false,
      filterComponent: (props) => (
        <TableSelectFilter
          {...props}
          paramKey="status"
          lookup={{
            submitted: "Submitted",
            approved: "Approved",
            rejected: "Rejected",
          }}
        />
      ),
      defaultFilter: ["submitted", "approved", "rejected"],
      render: renderTableLink({
        url: "/credit-account-enrollments/",
        body: "status",
        style: makeCellStyle,
      }),
    },
    {
      title: "Submitted",
      field: "submitted_at",
      defaultSort: "desc",
      filtering: false,
      render: renderTableLink({
        url: "/credit-account-enrollments/",
        body: (rowData) => (
          <Time formatExact="MMM DD, YYYY" timezone={rowData.customerTimezone}>
            {rowData.submittedAt}
          </Time>
        ),
        style: makeCellStyle,
      }),
    },
    {
      title: "Created",
      field: "created_at",
      defaultSort: "desc",
      filtering: false,
      render: renderTableLink({
        url: "/credit-account-enrollments/",
        body: (rowData) => (
          <Time formatExact="MMM DD, YYYY" timezone={rowData.customerTimezone}>
            {rowData.createdAt}
          </Time>
        ),
        style: makeCellStyle,
      }),
    },
  ];
}
