import Link from "./Link";
import React from "react";
import SimpleTable from "./SimpleTable";
import Time from "../lithic/Moment";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    scrollbarWidth: "none" /* Firefox */,
    "&::-webkit-scrollbar": {
      display: "none" /* Chrome */,
    },
  },
}));

export default function Refurnishments({ refurnishments }) {
  const classes = useStyles();

  if (!refurnishments || !refurnishments.length)
    return <h5> No refurnishments at the moment </h5>;

  return (
    <SimpleTable
      className={classes.tableContainer}
      truncated
      tableProps={{ size: "small" }}
      headers={["ID", "Status", "Created At", "Strategy", "Bureau", "Furnishment"]}
      rows={refurnishments}
      keyRowAttr="id"
      toCells={(refurnishment) => [
        refurnishment.id,
        refurnishment.status,
        <Time key={3} formatExact={"MMM DD, YYYY HH:mm:ss"}>
          {refurnishment.createdAt}
        </Time>,
        refurnishment.strategy,
        refurnishment.bureau,
        <Link key={1} to={`/furnishments/${refurnishment.furnishmentId}`}>
          {refurnishment.furnishmentId}
        </Link>,
      ]}
    />
  );
}
