import { Button } from "@material-ui/core";
import React from "react";

export default function UploadReportButton({ id, disabled, onClick }) {
  return (
    <Button
      color="primary"
      variant="contained"
      onClick={() => onClick(id)}
      onMouseDown={(e) => e.preventDefault()}
      disabled={disabled}
    >
      Upload
    </Button>
  );
}
