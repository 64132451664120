import React, { useEffect, useState } from "react";
import DetailSection from "../../../components/DetailSection";
import { Button, Typography } from "@material-ui/core";
import tinycolor from "tinycolor2";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "@mui/material/Slider";

const ColorInput = ({ color, label, onChange }) => {
  const classes = useStyles();
  return (
    <div className={classes.colorInputWrapper}>
      <label>{label}</label>
      <input type="text" value={color} onChange={(e) => onChange(e.target.value)} />
      <input
        className={classes.mlAuto}
        type="color"
        value={color}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export const PartnerColorsSlice = ({ partner, onSave }) => {
  const classes = useStyles();
  const [lightingKoef, setLightingKoef] = useState(0.09);
  const [darkeningKoef, setDarkeningKoef] = useState(0.05);
  const [paletteColor, setPaletteColor] = useState(
    partner.settings?.colors?.primary?.[500] || "#000000"
  );
  const [colors, setColors] = useState(
    partner.settings?.colors?.primary || {
      "20": "",
      "50": "",
      "100": "",
      "200": "",
      "300": "",
      "400": "",
      "500": "",
      "600": "",
      "700": "",
      "800": "",
      "900": "",
    }
  );

  useEffect(() => {
    if (partner.settings?.colors?.primary) {
      setColors((prev) => {
        const partnerColors = {};
        Object.entries(partner.settings.colors.primary).forEach(
          ([key, value]) => (partnerColors[key] = value)
        );
        return {
          ...prev,
          ...partnerColors,
        };
      });

      if (partner.settings.colors.primary["500"]) {
        setPaletteColor(partner.settings.colors.primary["500"]);
      }
    }
  }, [partner?.settings?.colors?.primary]);

  const generate = () => {
    const shades = {};
    const shadesKey = [
      "20",
      "50",
      "100",
      "200",
      "300",
      "400",
      "500",
      "600",
      "700",
      "800",
      "900",
    ];

    if (!paletteColor || !tinycolor(paletteColor).isValid()) return;

    let numShades = 11;

    for (let i = 0; i < numShades; i++) {
      // Lightn before 500 and darken after 500
      const hsl = tinycolor(paletteColor).toHsl();

      if (i < 6) {
        hsl.l += lightingKoef * (6 - i);
      } else {
        hsl.l -= darkeningKoef * (i - 6);
      }

      shades[shadesKey[i]] = tinycolor(hsl).toHexString();
    }

    setColors(shades);
  };

  return (
    <DetailSection title="Color scheme">
      <hr className={classes.separator} />

      <Typography variant="subtitle1">Generate Primary palette on hex</Typography>
      <ColorInput
        color={paletteColor}
        label={"Base color"}
        onChange={(v) => {
          setPaletteColor(v);
        }}
      />

      <div
        style={{
          maxWidth: 350,
        }}
      >
        <Typography variant="subtitle2">Lighting koef</Typography>
        <div className={classes.flexSpb}>
          <p>mild</p>
          <p>strong</p>
        </div>
        <Slider
          value={parseInt(lightingKoef * 100).toFixed(0)}
          valueLabelDisplay="on"
          onChange={(_, value) => setLightingKoef(value / 100)}
          min={1}
          max={10}
        />
      </div>

      <div className={classes.maxW}>
        <Typography variant="subtitle2">darkening koef</Typography>
        <div className={classes.flexSpb}>
          <p>mild</p>
          <p>strong</p>
        </div>
        <Slider
          valueLabelDisplay="on"
          value={parseInt(darkeningKoef * 100).toFixed(0)}
          onChange={(_, value) => setDarkeningKoef(value / 100)}
          min={1}
          max={10}
        />
      </div>
      <Button
        onClick={() => {
          generate(paletteColor);
        }}
        color="primary"
        variant="contained"
      >
        Generate
      </Button>

      <hr className={classes.separator} />
      <div>
        <Typography variant="subtitle1">Primary</Typography>

        <ColorInput
          color={colors?.["20"] || ""}
          label={"20"}
          onChange={(v) => {
            setColors((prev) => {
              const newColors = { ...prev };
              newColors["20"] = v;
              return newColors;
            });
          }}
        />

        <ColorInput
          color={colors?.["50"] || ""}
          label={"50"}
          onChange={(v) => {
            setColors((prev) => {
              const newColors = { ...prev };
              newColors["50"] = v;
              return newColors;
            });
          }}
        />

        <ColorInput
          color={colors?.["100"] || ""}
          label={"100"}
          onChange={(v) => {
            setColors((prev) => {
              const newColors = { ...prev };
              newColors["100"] = v;
              return newColors;
            });
          }}
        />

        <ColorInput
          color={colors?.["200"] || ""}
          label={"200"}
          onChange={(v) => {
            setColors((prev) => {
              const newColors = { ...prev };
              newColors["200"] = v;
              return newColors;
            });
          }}
        />

        <ColorInput
          color={colors?.["300"] || ""}
          label={"300"}
          onChange={(v) => {
            console.log(v);
            setColors((prev) => {
              const newColors = { ...prev };
              newColors["300"] = v;
              return newColors;
            });
          }}
        />

        <ColorInput
          color={colors?.["400"] || ""}
          label={"400"}
          onChange={(v) => {
            setColors((prev) => {
              const newColors = { ...prev };
              newColors["400"] = v;
              return newColors;
            });
          }}
        />

        <ColorInput
          color={colors?.["500"] || ""}
          label={"500"}
          onChange={(v) => {
            setColors((prev) => {
              const newColors = { ...prev };
              newColors["500"] = v;
              return newColors;
            });
          }}
        />

        <ColorInput
          color={colors?.["600"] || ""}
          label={"600"}
          onChange={(v) => {
            setColors((prev) => {
              const newColors = { ...prev };
              newColors["600"] = v;
              return newColors;
            });
          }}
        />

        <ColorInput
          color={colors?.["700"] || ""}
          label={"700"}
          onChange={(v) => {
            setColors((prev) => {
              const newColors = { ...prev };
              newColors["700"] = v;
              return newColors;
            });
          }}
        />

        <ColorInput
          color={colors?.["800"] || ""}
          label={"800"}
          onChange={(v) => {
            setColors((prev) => {
              const newColors = { ...prev };
              newColors["800"] = v;
              return newColors;
            });
          }}
        />

        <ColorInput
          color={colors?.["900"] || ""}
          label={"900"}
          onChange={(v) => {
            setColors((prev) => {
              const newColors = { ...prev };
              newColors["900"] = v;
              return newColors;
            });
          }}
        />
      </div>

      <Button
        onClick={() => {
          onSave(colors);
        }}
        color="primary"
        variant="contained"
      >
        Save
      </Button>

      <hr className={classes.separator} />
    </DetailSection>
  );
};

const useStyles = makeStyles((theme) => ({
  colorInputWrapper: {
    display: "flex",
    gap: 10,
    alignItems: "center",
    marginBottom: 10,
    maxWidth: 350,
  },
  mlAuto: {
    marginLeft: "auto",
  },
  separator: {
    color: theme.palette.divider,
    margin: "10px 0",
  },
  maxW: {
    maxWidth: 350,
  },
  flexSpb: {
    display: "flex",
    justifyContent: "space-between",
  },
}));
