import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Divider from "@material-ui/core/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FurnishmentProgress from "./FurnishmentProgress";
import Link from "./Link";
import React from "react";
import Time from "../lithic/Moment";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";

export default function FurnishmentsTable({ furnishments }) {
  if (!furnishments || !furnishments.length) return null;

  return (
    <React.Fragment>
      {furnishments?.map((furnishment) => {
        return <FurnishmentRow key={furnishment.id} furnishment={furnishment} />;
      })}
    </React.Fragment>
  );
}

function FurnishmentRow({ furnishment }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`acc-content-${furnishment.id}`}
          id={`acc-id-${furnishment.id}`}
        >
          <div className={classes.summary_wrapper}>
            <div>
              <Link key={1} to={`/furnishments/${furnishment.id}`}>
                {furnishment.id}
              </Link>
            </div>
            <div>
              <Time formatExact={"MMM Do YYYY, h:mm:ss"}>{furnishment.createdAt}</Time>
            </div>

            <div>{furnishment.kind}</div>
            <div>
              <FurnishmentProgress furnishment={furnishment}></FurnishmentProgress>
            </div>
          </div>
        </AccordionSummary>
      </Accordion>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "5px",
  },
  summary_wrapper: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "0.2fr 1fr 1fr 1fr 1fr",
    columnGap: "15px",
  },
  column: {
    flexBasis: "20%",
  },
  halfColumn: {
    flexBasis: "10%",
  },
  details: {},
  rowClassApproved: {
    backgroundColor: lighten(theme.palette.success.light, 0.7),
  },
  rowClassRejected: {
    backgroundColor: lighten(theme.palette.error.light, 0.7),
  },
  rowClassPending: {
    backgroundColor: lighten(theme.palette.warning.light, 0.9),
  },
}));
