import { AdminContainer, AlignedListContainer } from "../components/layouts";

import BureauReportsTable from "../components/BureauReportsTable";
import DetailSection from "../components/DetailSection";
import FurnishmentProgress from "../components/FurnishmentProgress";
import { FurnishmentReportsTable } from "../components/FurnishmentReportsTable";
import FurnishmentStats from "../components/FurnishmentStats";
import FurnishmentErrors from "../components/FurnishmentErrors";
import { GutterlessListItem as ListItem } from "../components/lists";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import ReportingAccountEnrollmentsListPage from "../pages/ReportingAccountEnrollmentsListPage";
import Time from "../lithic/Moment";
import api from "../api";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";
import { useLoading } from "../hooks/loading";
import { useParams } from "react-router";

export default function FurnishmentDetailPage() {
  const classes = useStyles();

  const { id } = useParams();
  const { startLoading, stopLoading } = useLoading();

  const [furnishment, setFurnishment] = React.useState({});

  React.useEffect(() => {
    startLoading();
    getFurnishment();
    // eslint-disable-next-line
  }, [id]);

  function getFurnishment() {
    api
      .getFurnishment({ id })
      .then(api.pickData)
      .then((data) => {
        // eslint-disable-next-line no-console
        console.log("Current Furnishment Data:", data); // Вывод в консоль текущих данных о Furnishment
        setFurnishment(data);
        setTimeout(stopLoading, 200);
      });
  }

  function uploadReportsToBureau(reportId) {
    return api.uploadReportsToBureau({ id, reportId }).then(getFurnishment);
  }

  if (!furnishment) return null;

  const { reports, tradelineRequests } = furnishment;

  return (
    <AdminContainer variant="detail-wide">
      <h2>Furnishment Details</h2>
      <AlignedListContainer maxWidth="xl">
        <ListItem>
          <ListItemText>Furnishment id: {furnishment.id}</ListItemText>
        </ListItem>

        <ListItem>
          <ListItemText>
            Created at:{" "}
            <Time formatExact={"MMM Do YYYY, h:mm:ss"}>{furnishment.createdAt}</Time>
          </ListItemText>
        </ListItem>

        <ListItem>
          <ListItemText>Type: {furnishment.kind}</ListItemText>
        </ListItem>

        <ListItem>
          <ListItemText>Name: {furnishment.title}</ListItemText>
        </ListItem>

        <ListItem>
          <FurnishmentProgress furnishment={furnishment}></FurnishmentProgress>
        </ListItem>

        <FurnishmentStats furnishment={furnishment} />
        <FurnishmentErrors furnishment={furnishment} />

        <DetailSection mt={3} title="Reports">
          <FurnishmentReportsTable
            reports={reports}
            onReportUpload={uploadReportsToBureau}
          />
        </DetailSection>

        {furnishment.id && (
          <DetailSection mt={3} title="Enrollments">
            <ReportingAccountEnrollmentsListPage
              dataApi={(query) =>
                api.getReportingAccountEnrollmentsByFurnishment({
                  ...query,
                  furnishment_id: furnishment.id,
                })
              }
              className={classes.no_padding}
              filters={{
                has_verified_rv: {
                  enabled: false,
                },
                has_pending_verification_rv: {
                  enabled: false,
                },
                verifications: {
                  enabled: false,
                },
                // has_rejected_tv: {
                //   enabled: true,
                // },
                // has_verified_tv: {
                //   enabled: true,
                // },
              }}
            />
          </DetailSection>
        )}

        <DetailSection mt={0} title="Tradeline Requests">
          <BureauReportsTable reports={tradelineRequests} />
        </DetailSection>
      </AlignedListContainer>
    </AdminContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  subitemList: { flexDirection: "column", alignItems: "start", padding: 0 },
  icon: {
    marginLeft: theme.spacing(0.5),
  },
  highlight_alert: {
    backgroundColor: lighten(theme.palette.warning.light, 0.7),
  },
  highlight_success: {
    backgroundColor: lighten(theme.palette.success.light, 0.7),
  },
  checkbox_group: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  process_output_wrapper: {
    border: "1px solid",
    borderColor: theme.palette.success.main,
    padding: "10px",
  },
  process_output_table_wrapper: {
    overflow: "auto",
    maxHeight: "600px",
  },
  show_cursor: {
    cursor: "pointer",
  },
  no_padding: {
    padding: 0,
  },
}));
