import React from "react";

export default function useToggle(initial) {
  const [on, setState] = React.useState(initial || false);
  return {
    on,
    off: !on,
    setState,
    turnOn: () => setState(true),
    turnOff: () => setState(false),
    toggle: () => setState(!on),
  };
}
