import DetailSection from "./DetailSection";
import Money from "../lithic/Money";
import React from "react";
import SimpleTable from "./SimpleTable";
import Time from "../lithic/Moment";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    scrollbarWidth: "none" /* Firefox */,
    "&::-webkit-scrollbar": {
      display: "none" /* Chrome */,
    },
  },
}));

export default function Refunds({ refunds, refundedTotal }) {
  const classes = useStyles();

  return (
    <DetailSection title={`Refunds`}>
      <h4>
        {`Total refunded: `} <Money key={"money"}>{refundedTotal}</Money>
      </h4>
      {refunds && refunds.length ? (
        <SimpleTable
          className={classes.tableContainer}
          truncated
          tableProps={{ size: "small" }}
          headers={["id", "Created at", "Status", "total"]}
          rows={refunds}
          keyRowAttr="id"
          toCells={(refund) => [
            refund.id,
            <Time key={"time"} d="(unset)" formatExact={"MMM DD, YYYY HH:mm:ss"}>
              {refund.createdAt}
            </Time>,
            refund.status,
            <Money key={"money"}>{refund.total}</Money>,
          ]}
        />
      ) : (
        <>There are no refunds</>
      )}
    </DetailSection>
  );
}
