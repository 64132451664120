import CircularProgress from "@material-ui/core/CircularProgress";
import { Modal } from "@material-ui/core";
import React from "react";
import { useLoading } from "../hooks/loading";

export default function LoadingModal() {
  const { loading } = useLoading();
  return (
    <>
      <Modal open={loading}>
        <div
          style={{
            display: "flex",
            width: "100vw",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      </Modal>
    </>
  );
}
