import { Tab, Tabs } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import React, { useState } from "react";
import { EnrollmentDetailView } from "./EnrollmentDetailView";
import { FileViewer } from "./FileViewer";
import { RentalPayments } from "./RentalPayments";
import { ReportingAccountEnrollmentVerifications } from "../ReportingAccountEnrollmentDetailPage/ReportingAccountEnrollmentVerifications";
import IdentityVerificationTable from "../../components/IdentityVerificationTable";

/** Shows details such as documents, enrollment verification etc. on the enrollment verification page. */
export const DetailsView = ({
  verification,
  files,
  setFiles,
  selectedFile,
  setSelectedFile,
  selectedFileIndex,
  setSelectedFileIndex,
  onFilesDropped
}) => {
  const [tab, setTab] = useState(0);

  const tabs = []

  if (verification.kind !== "portal") {
    tabs.push({
      title: "Documents",
      component: () => (
        <FileViewer
          onFilesDropped={onFilesDropped}
          enrollmentId={verification.enrollment?.id}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          selectedFileIndex={selectedFileIndex}
          setSelectedFileIndex={setSelectedFileIndex}
          files={files}
          setFiles={setFiles}
        />
      ),
    })
  }

  tabs.push({
    title: "Enrollment",
    component: () => <EnrollmentDetailView verification={verification} />,
  })

  if (verification.kind === "landlord" || verification.kind === "lease") {
    tabs.push({
      title: "Rental payments",
      component: () => <RentalPayments enrollmentId={verification.enrollment.id} />,
    });
  }

  tabs.push({
    title: "Verifications",
    component: () => (
      <ReportingAccountEnrollmentVerifications
        enrollmentId={verification.enrollment.id}
      />
    ),
  });

  if (verification.kind === "identity") {
    tabs.push({
      title: "Plaid Identity Verification",
      component: () => <IdentityVerificationTable subjectId={verification.customer.id} subjectType="Boom::Customer"/>,
    });
  }

  return (
    <div>
      <TabContext value={tab}>
        <Tabs value={tab} onChange={(e, value) => setTab(value)}>
          {tabs.map((tab) => (
            <Tab key={tab.title} label={tab.title} />
          ))}
        </Tabs>

        {tabs.map((tab, index) => (
          <TabPanel key={tab.title} value={index}>
            {tab.component()}
          </TabPanel>
        ))}
      </TabContext>
    </div>
  );
};
