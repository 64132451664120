import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { AdminContainer } from "../../components/layouts";
import { Typography, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../api";
import { useLoading } from "../../hooks/loading";
import DetailSection from "../../components/DetailSection";
import AdminActionLinks from "../../components/AdminActionLinks";
import { ApplicantVerifications } from "./ApplicantVerifications";
import Time from "../../lithic/Moment";
import SafeExternalLink from "../../lithic/SafeExternalLink";
import SimpleTable from "../../components/SimpleTable";
import Link from "../../components/Link";
import Money from "../../lithic/Money";
import { AuditLogs } from "../../components/AuditLogs";

export default function ApplicantDetailPage() {
  const classes = useStyles();
  const { id } = useParams();

  const { startLoading, stopLoading } = useLoading();

  const [applicant, setApplicant] = useState(null);
  const refetchApplicant = () => {
    startLoading();
    api
      .getApplicant(id)
      .then((resp) => setApplicant(resp.data))
      .catch((err) => toast.error(err.message))
      .finally(stopLoading);
  };
  useEffect(() => {
    refetchApplicant();
  }, [id]);

  if (!applicant) return "Loading...";

  return (
    <AdminContainer variant="list">
      <div className={classes.applicantDetailsContainer}>
        <Typography variant="h4">Applicant Details</Typography>
        <Paper className={classes.detailPaper}>
          <Typography variant="h6">
            Name: {applicant.firstName} {applicant.lastName}
          </Typography>
          <Typography variant="body1">Email: {applicant.email}</Typography>
          <Typography variant="body1">Role: {applicant.role}</Typography>
          <Typography variant="body1">
            Application Status: {applicant.applicationStatus}
          </Typography>
          <Typography variant="body1">
            Created At:{" "}
            <Time key={applicant.id} d="(unset)" formatExact={"MMM DD, YYYY HH:mm:ss"}>
              {applicant.createdAt}
            </Time>
          </Typography>
          <Typography variant="body1">
            Updated At:{" "}
            <Time key={applicant.id} d="(unset)" formatExact={"MMM DD, YYYY HH:mm:ss"}>
              {applicant.updatedAt}
            </Time>
          </Typography>
          {/* Intercom link */}
          <SafeExternalLink href={applicant.intercomLink}>
            {applicant.intercomLink ? "Intercom link" : "--"}
          </SafeExternalLink>

          {/* PostHog recordings */}
          <div>
            <SafeExternalLink
              href={encodeURI(
                `https://us.posthog.com/project/67848/replay/recent?simpleFilters={"events":[],"properties":[]}&advancedFilters={"session_recording_duration":{"type":"recording","key":"duration","value":1,"operator":"gt"},"properties":[{"key":"customer_email","value":["${applicant.email}"],"operator":"exact","type":"person"}],"events":[],"actions":[],"date_from":"-30d","date_to":null,"console_logs":[],"snapshot_source":null,"console_search_query":"","operand":"AND"}#panel=docs:/docs/session-replay/installation`
              )}
            >
              {applicant.email ? "PostHog recordings" : "--"}
            </SafeExternalLink>
          </div>
        </Paper>
        <DetailSection title="Verifications">
          <ApplicantVerifications
            verifications={applicant.verifications}
            refetch={refetchApplicant}
          />
        </DetailSection>
        <AdminActionLinks
          links={applicant.adminActionLinks}
          onActionSuccess={(resp) => setApplicant(resp.data)}
        />
        {/* Temporarily. Waiting for the backend update */}
        <DetailSection>
          <Typography variant="h6" gutterBottom color="primary">
            Portal
          </Typography>
          <SafeExternalLink href="/">Open Portal</SafeExternalLink>
        </DetailSection>
        <DetailSection title="Invoices">
          <SimpleTable
            truncated
            tableProps={{ size: "small" }}
            headers={["ID", "Status", "Amount", "Updated at"]}
            rows={applicant.invoices}
            keyRowAttr="id"
            toCells={(row) => [
              <Link key={1} to={`/invoices/${row.id}`}>
                {row.id}
              </Link>,
              <Link key={4} to={`/invoices/${row.id}`}>
                {row.status}
              </Link>,
              <Money key={2} value={row.total} />,
              <Time key={5} format={"MMM DD, YYYY, h:mm:ss a"}>
                {row.updatedAt}
              </Time>,
            ]}
          />
        </DetailSection>
        <DetailSection title="Audit Logs">
          <AuditLogs auditableId={applicant.id} auditableType="BoomScreen::Applicant" />
        </DetailSection>
      </div>
    </AdminContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  applicantDetailsContainer: {
    flex: 1,
    padding: theme.spacing(2),
  },
  detailPaper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));
