import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import React from "react";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

export default function VisibilityInput({ endAdornment, ...rest }) {
  const [visible, setVisible] = React.useState(false);
  return (
    <Input
      type={visible ? "text" : "password"}
      {...rest}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            onClick={() => setVisible(!visible)}
            onMouseDown={(e) => e.preventDefault()}
          >
            {visible ? <Visibility /> : <VisibilityOff />}
          </IconButton>
          {endAdornment && endAdornment()}
        </InputAdornment>
      }
    />
  );
}
