import React from "react";
import _ from "lodash";
import clsx from "clsx";
import intersperse from "../lithic/intersperse";
import { makeStyles } from "@material-ui/core/styles";

export default function FurnishmentProgress({ furnishment }) {
  const classes = useStyles();
  const shorthands = {
    Equifax: "Eq",
    Experian: "Ex",
    Transunion: "Tr",
  };
  const uploadedPieces = _.map(shorthands, (short, long) => {
    const isSet = furnishment[`uploadedTo${long}`];
    const cls = isSet ? classes.done : classes.missing;
    const txt = `${short}⬆`;
    return (
      <span key={"u" + short} className={clsx(cls)}>
        {txt}
      </span>
    );
  });
  const parts = [...intersperse(uploadedPieces, "/")];
  return parts;
}

const useStyles = makeStyles((theme) => ({
  pending: {
    color: theme.palette.muted.main,
  },
  done: {
    color: theme.palette.success.main,
  },
  missing: {
    color: theme.palette.error.main,
  },
}));
