export const events = {
  accountclosed: "Account Closed",
  accounterased: "Account Erased",
  autopaycanceled: "Autopay Canceled",
  autopayscheduled: "Autopay Scheduled",
  autorenewcanceled: "Autorenew Canceled",
  autorenewresumed: "Autorenew Resumed",
  boomreportenrollmentfinished: "Boom Report Enrollment Finished",
  boomreportrefunded: "Boom Report Refunded",
  boomreporttradelineclosed: "Boom Report Tradeline Closed",
  boomreportunenrolledongoing: "Boom Report Unenrolled Ongoing",
  confirmedmovedoutdate: "Confirmed Moved Out Date",
  endingleaseupdatednewenddate: "Ending Lease Updated New End Date",
  endingleaseupdatedtomonthtomonth: "Ending Lease Updated To Month To Month",
  enrolled_renter_from_partner: "Enrolled Renter From Partner",
  failedresubscriptionpayment: "Failed Resubscription Payment",
  leaseisending: "Lease Is Ending",
  leasem2mwithunverifiedrentalverifications:
    "Lease M2M With Unverified Rental Verifications",
  payment_issues: "Payment Issues",
  paymentcanceled: "Payment Canceled",
  paymentedited: "Payment Edited",
  paymentmethodhasissue: "Payment Method Has Issue",
  paymentmethodupdated: "Payment Method Updated",
  paymentprocessing: "Payment Processing",
  paymentprocessing_dupe: "Payment Processing Dupe",
  paymentscheduled: "Payment Scheduled",
  payoutsending: "Payout Sending",
  plaid_connect_url_requested: "Plaid connect URL requested",
  plaid_identity_manual_verification_failed: "Plaid Identity Manual Verification Failed",
  plaid_identity_manual_verification_required:
    "Plaid Identity Manual Verification Required",
  plaid_identity_verification_finished: "Plaid Identity Verification Finished",
  refereeconverted: "Referee Converted",
  referraladded: "Referral Added",
  referralconverted: "Referral Converted",
  referralsignedup: "Referral Signed Up",
  registered: "Registered",
  rental_verification_uploaded_to_datalink: "Rental Verification Uploaded To Datalink",
  reportingaccountenrollmentapproved: "Reporting Account Enrollment Approved",
  reportingaccountenrollmentrejected: "Reporting Account Enrollment Rejected",
  reportingaccountenrollmentsubmitted: "Reporting Account Enrollment Submitted",
  reportingaccountenrollmentsubscribed: "Reporting Account Enrollment Subscribed",
  reportingaccountenrollmentsubscribed__ongoing:
    "Reporting Account Enrollment Subscribed Ongoing",
  reportingaccountenrollmentsubscribed__ongoingpast:
    "Reporting Account Enrollment Subscribed Ongoing Past",
  reportingaccountenrollmentsubscribed__past:
    "Reporting Account Enrollment Subscribed Past",
  resubscriptionsoonnotificationemail: "Resubscription Soon Notification Email",
  subscriptionexpired: "Subscription Expired",
  subscriptionrenewed: "Subscription Renewed",
  tradeline_support_submitted: "Tradeline Support Submitted",
  unverifiedrentalverificationrequires: "Unverified Rental Verification Requires",
  "upload-your-lease-email": "Upload Your Lease Email",
  verification_issues: "Verification Issues",
  optout_option_available: "Opt-out option available",
  opt_in_option_available: "Opt-in option available",
};

export const supportedFeatures = [
  "api_access",
  "rent_reporting",
  "boom_screen",
  "bypass_bank_linking",
  "delinquency_reporting",
  "free_checkout",
  "free_ongoing",
  "past_reporting_payout",
  "sdk",
  "skip_customer_identity_verification",
  "unexpired_enrollments_opt_in_period",
  "show_full_ssn",
  "plaid_shared_token",
  "trusted_kyc",
  "manual_invoicing"
];
export const propertiesManagementSystems = [
  "appfolio",
  "buildium",
  "quext",
  "rent_manager",
  "rentvine",
  "resman",
  "yardi",
];

export const availableRenterBenefits = [
  "rent_reporting",
  "credit_monitoring",
  "identity_theft_protection",
];
