import DetailSection from "./DetailSection";
import React from "react";
import SimpleTable from "./SimpleTable";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    scrollbarWidth: "none" /* Firefox */,
    "&::-webkit-scrollbar": {
      display: "none" /* Chrome */,
    },
    subitemList: { flexDirection: "column", alignItems: "start", padding: 0 },
  },
}));

export default function FurnishmentStats({ furnishment }) {
  const classes = useStyles();

  if (!furnishment?.stats) return null;

  const stats = [
    { bureau: "equifax", ...furnishment.stats.equifax },
    { bureau: "experian", ...furnishment.stats.experian },
    { bureau: "transunion", ...furnishment.stats.transunion },
  ];

  return (
    <DetailSection title="Stats">
      <SimpleTable
        className={classes.tableContainer}
        headers={[
          "Bureau",
          "Picked Tradelines",
          "Furnished Tradelines",
          "Open Tradelines",
          "Closed Tradelines",
          "Deleted Tradelines",
          "Not Found Tradelines",
          "Errors",
          "Refurnishments",
        ]}
        rows={stats}
        keyRowAttr="bureau"
        toCells={(row) => [
          row.bureau,
          row.selectedTradelines,
          row.furnishedTradelines,
          row.openTradelines,
          row.closedTradelines,
          row.deletedTradelines,
          row.notFoundTradelines,
          row.errors,
          row.processedRefurnishments,
        ]}
      />
    </DetailSection>
  );
}
