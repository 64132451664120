import { apiCollectionToRowResponse, rowQueryToApiParams } from "../modules/tableQuery";

import { AdminContainer } from "../components/layouts";
import MaterialTable from "material-table";
import React from "react";
import Time from "../lithic/Moment";
import api from "../api";
import { formatMoney } from "../lithic/Money";
import { renderTableLink } from "../components/renderTableCell";
import tableIcons from "../components/tableIcons";
import { useTheme } from "@material-ui/core/styles";

export default function PayoutsListPage() {
  const tableRef = React.createRef();
  const theme = useTheme();
  const columns = makeColumns(theme);
  return (
    <AdminContainer variant="list">
      <MaterialTable
        title="Payouts"
        tableRef={tableRef}
        columns={columns}
        options={{
          emptyRowsWhenPaging: false,
          pageSize: 50,
          pageSizeOptions: [50, 100, 200],
          sorting: true,
          thirdSortClick: false,
        }}
        data={(q) => {
          return api
            .listPayouts(rowQueryToApiParams(q))
            .then((resp) => apiCollectionToRowResponse(resp));
        }}
        icons={tableIcons}
      />
    </AdminContainer>
  );
}

function makeColumns(theme) {
  function makeCellStyle(rowData) {
    if (!rowData.total.cents) {
      return { color: theme.palette.muted.main };
    }
  }
  return [
    {
      title: "Id",
      field: "id",
      defaultSort: "desc",
      width: 20,
      render: renderTableLink({ url: "/payouts/", body: "id", style: makeCellStyle }),
    },
    {
      title: "Payee",
      field: "legalEntityName",
      sorting: false,
      render: renderTableLink({
        url: "/payouts/",
        body: "legalEntityName",
        style: makeCellStyle,
      }),
    },
    {
      title: "Status",
      field: "status",
      sorting: false,
      render: renderTableLink({ url: "/payouts/", body: "status", style: makeCellStyle }),
    },
    {
      title: "Total",
      field: "total",
      sorting: false,
      render: renderTableLink({
        url: "/payouts/",
        body: (rowData) => formatMoney(rowData.total),
        style: makeCellStyle,
      }),
    },
    {
      title: "Opening",
      field: "periodBegin",
      sorting: false,
      render: renderTableLink({
        url: "/payouts/",
        body: (rowData) => (
          <Time formatExact="MMM DD, YYYY" timezone="America/New_York">
            {rowData.period.start}
          </Time>
        ),
        style: makeCellStyle,
      }),
    },
    {
      title: "Closing",
      field: "periodEnd",
      sorting: false,
      render: renderTableLink({
        url: "/payouts/",
        body: (rowData) => (
          <Time formatExact="MMM DD, YYYY" timezone="America/New_York">
            {rowData.period.end}
          </Time>
        ),
        style: makeCellStyle,
      }),
    },
  ];
}
