import {
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ConfirmationDialog from "./ConfirmationDialog";
import DateTimeRangePicker from "./DateTimeRangePicker";
import FormControl from "@material-ui/core/FormControl";
import React from "react";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import api from "../api";
import dayjs from "dayjs";
import { isoString } from "../lithic/Moment";
import { makeStyles } from "@material-ui/core/styles";
import objectSupport from "dayjs/plugin/objectSupport";
import { useHistory } from "react-router";
import { useToastError } from "./FormError";
import useToggle from "../state/useToggle";

dayjs.extend(objectSupport);

export default function AdminActionLinks({ links, onActionSuccess, onActionError }) {
  const classes = useStyles();
  const toggle = useToggle();
  const history = useHistory();
  const toastError = useToastError();
  onActionError = onActionError || toastError;

  const [activeLink, setActiveLink] = React.useState();
  const [customState, setCustomState] = React.useState({});
  if (_.isEmpty(links)) {
    return null;
  }
  const customControls = activeLink
    ? _.find(customControlDefs, ({ urlTemplate }) => urlTemplate.test(activeLink.url))
    : null;
  const fullCustomState = customControls && {
    ...customControls.defaultState(),
    ...customState,
  };

  return (
    <Box mt={3} className={classes.container}>
      <Typography variant="h6" gutterBottom color="primary">
        Actions
      </Typography>
      {links.map((link) => (
        <div key={link.name} className={classes.buttonGroup}>
          <Button
            color="secondary"
            variant="contained"
            className={classes.action}
            onClick={() => {
              setActiveLink(link);
              toggle.toggle();
            }}
          >
            {link.name}
          </Button>
          <Typography>{link.help}</Typography>
        </div>
      ))}
      <ConfirmationDialog
        open={toggle.on}
        onCancel={toggle.turnOff}
        onConfirm={() => {
          let params = activeLink.params;
          if (customControls) {
            params = { ...params, ...customControls.toParams(fullCustomState) };
          }
          return api[activeLink.httpMethod](activeLink.url, params)
            .then(api.followRedirect(history))
            .then(onActionSuccess, onActionError)
            .finally(toggle.turnOff);
        }}
        text={!customControls && "Are you sure? " + _.get(activeLink, "help")}
        title={_.get(activeLink, "name")}
        cancelProps={{ children: "Go back" }}
        confirmProps={{ children: "Yes" }}
      >
        {customControls &&
          customControls.render({
            classes,
            customState: fullCustomState,
            setCustomState,
          })}
      </ConfirmationDialog>
    </Box>
  );
}

/**
 * Support for custom controls in the admin action modals.
 * The urlTemplate should match the action URL
 * (we assume action urls are unique).
 * - defaultState returns a default state used for the controls;
 * - toParams takes the updated UI state and converts it into
 * data to be POSTed.
 * - render renders the components.
 */
const customControlDefs = [
  // Retry an invoice
  {
    urlTemplate: /\/v1\/invoices\/\d+\/retry/,
    defaultState: () => ({
      period: {
        start: dayjs().add(1, "hour"),
        end: dayjs().add(2, "hours"),
      },
    }),
    toParams: function ({ period }) {
      return { periodBegin: isoString(period.start), periodEnd: isoString(period.end) };
    },
    render: function InvoiceRetry({ classes, customState, setCustomState }) {
      return (
        <div className={classes.modalInputs}>
          <DateTimeRangePicker
            value={customState.period}
            onChange={(period) => setCustomState({ period })}
            pickerProps={{ ampm: false }}
          />
        </div>
      );
    },
  },
  // Create a rental verification for a reporting account
  {
    urlTemplate: /\/v1\/reporting_account_enrollments\/\d+\/rental_verifications/,
    defaultState: () => ({
      month: "" + dayjs().month(),
      year: "" + dayjs().year(),
      closeTradeline: false,
    }),
    toParams: function ({ month, year, closeTradeline }) {
      const p = { closeTradeline };

      p.month = Number(month) + 1;
      p.year = Number(year);

      return p;
    },
    render: function InvoiceRetry({ classes, customState, setCustomState }) {
      return (
        <div className={classes.modalInputs}>
          <FormControlLabel
            label="Close Tradeline"
            control={
              <Checkbox
                checked={customState.closeTradeline}
                onChange={(e) =>
                  setCustomState({ ...customState, closeTradeline: e.target.checked })
                }
              />
            }
          />
          <>
            <FormControl>
              <InputLabel>Month</InputLabel>
              <Select
                value={customState.month}
                onChange={(e) =>
                  setCustomState({ ...customState, month: e.target.value })
                }
              >
                {_.range(0, 12).map((month) => (
                  <MenuItem key={month} value={"" + month}>
                    {dayjs({ month }).format("MMMM")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel>Year</InputLabel>
              <Select
                value={customState.year}
                onChange={(e) => setCustomState({ ...customState, year: e.target.value })}
              >
                {_.range(2000, dayjs().year() + 5).map((year) => (
                  <MenuItem key={year} value={"" + year}>
                    {dayjs({ year }).format("YYYY")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        </div>
      );
    },
  },
  // Custom control for rerunning individual reports
  {
    urlTemplate: /\/v1\/applicants\/\d+\/rerun_reports/,
    defaultState: () => ({
      reportType: "all",
    }),
    toParams: function ({ reportType }) {
      return {
        report_types: reportType === "all" ? undefined : [reportType],
      };
    },
    render: function ReportTypeSelector({ classes, customState, setCustomState }) {
      return (
        <div className={classes.modalInputs}>
          <Typography className={classes.modalInput}>
            Are you sure? Re-run the reports for this application. This cost us $$$$$.
            Please only perform this action if you need to.
          </Typography>
          <FormControl className={classes.modalInput}>
            <InputLabel>Report Type</InputLabel>
            <Select
              value={customState.reportType}
              onChange={(e) => setCustomState({ reportType: e.target.value })}
            >
              <MenuItem value="all">All Reports</MenuItem>
              <MenuItem value="credit_report">Credit Report</MenuItem>
              <MenuItem value="criminal_report">Criminal Report</MenuItem>
              <MenuItem value="eviction_report">Eviction Report</MenuItem>
            </Select>
          </FormControl>
        </div>
      );
    },
  },
];

const useStyles = makeStyles((theme) => ({
  action: {
    marginRight: theme.spacing(2),
    boxSizing: "content-box",
  },
  buttonGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  modalInputs: {
    display: "flex",
    flexDirection: "column",
  },
  modalInput: {
    marginBottom: theme.spacing(2),
  },
}));
