import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import dayjs from "dayjs";
import dayjsAdvancedFormat from "dayjs/plugin/advancedFormat";
import dayjsTimezone from "dayjs/plugin/timezone";
import dayjsUtc from "dayjs/plugin/utc";

dayjs.extend(dayjsAdvancedFormat);
dayjs.extend(dayjsUtc);
dayjs.extend(dayjsTimezone);

const defaultTz = dayjs.tz.guess();

export default function Time({
  children,
  format,
  formatExact,
  timezone,
  local,
  d,
  defaultTimezone,
}) {
  const { timezone: tzFromContext } = useTimezone() || {};
  const [useLocalTz, setUseLocalTz] = React.useState(false);

  if (!children) {
    return d || null;
  }

  // Allow this to be passed in
  defaultTimezone = defaultTimezone || defaultTz;

  const passedTz = timezone || tzFromContext;
  let tzToUse;
  if (local) {
    tzToUse = defaultTimezone;
  } else if (!passedTz) {
    tzToUse = defaultTimezone;
  } else if (useLocalTz) {
    tzToUse = defaultTimezone;
  } else {
    tzToUse = passedTz;
  }

  const onClick = passedTz ? () => setUseLocalTz(!useLocalTz) : null;
  const t = dayjs(children).tz(tzToUse);

  let s;
  if (formatExact) {
    s = t.format(formatExact);
  } else {
    if (!_.endsWith(format, "Z")) {
      format += " Z";
    }
    s = t.format(format);
    if (_.endsWith(s, ":00")) {
      s = s.substring(0, s.length - 3);
    } else if (_.endsWith(s, "00")) {
      s = s.substring(0, s.length - 2);
    }
  }

  return <time onClick={onClick}>{s}</time>;
}

Time.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  format: PropTypes.string,
};

export function durationName(minutes) {
  const minutePart = minutes % 60;
  const hourPart = Math.floor(minutes / 60);
  if (minutePart === 0 && hourPart === 0) {
    return "0 minutes";
  }
  const parts = [null, null];
  if (hourPart === 1) {
    parts[0] = `1 hour`;
  } else if (hourPart > 1) {
    parts[0] = `${hourPart} hours`;
  }
  if (minutePart === 1) {
    parts[1] = `1 minute`;
  } else if (minutePart > 1) {
    parts[1] = `${minutePart} minutes`;
  }

  return _.compact(parts).join(", ");
}

export function durationClock(minutes) {
  const minutePart = minutes % 60;
  const hourPart = Math.floor(minutes / 60);
  return _.padStart(hourPart, 2, "0") + ":" + _.padStart(minutePart, 2, "0");
}

export function Period({ value, format, d, className, timeProps }) {
  if (!value || (!value.start && !value.end)) {
    return d;
  }
  return (
    <span className={className}>
      <Time format={format} d="" {...timeProps}>
        {value.start}
      </Time>{" "}
      to{" "}
      <Time format={format} d="" {...timeProps}>
        {value.end}
      </Time>
    </span>
  );
}

export const TimezoneContext = React.createContext();
export const useTimezone = () => React.useContext(TimezoneContext);
export function UseTimezone({ children, timezone }) {
  return (
    <TimezoneContext.Provider value={{ timezone }}>{children}</TimezoneContext.Provider>
  );
}

/**
 * Return x if it's a string,
 * otherwise call toISOString.
 * Allows us to treat dayjs values, and string values,
 * as the same thing.
 * @param x
 * @return {string}
 */
export function isoString(x) {
  if (typeof x === "string" || !x) {
    return x;
  }
  return x.toISOString();
}

export function dayFormat(x) {
  if (typeof x === "string" || !x) {
    return x;
  }
  return x.format();
}

export function toTz(x, tz) {
  if (!x) {
    return x;
  }
  if (typeof x === "string") {
    return dayjs(x).tz(tz);
  }
  return x.tz(tz);
}
