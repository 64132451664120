import "handsontable/dist/handsontable.full.css";

import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TablePagination,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import FormError, { useError } from "../components/FormError";
import { get, startCase } from "lodash";

import ConfirmationDialog from "../components/ConfirmationDialog";
import Handsontable from "handsontable";
import { HotTable } from "@handsontable/react";
import React from "react";
import api from "../api";
import dayjs from "dayjs";
import displayPhone from "../modules/displayPhone";
import { localStorageCache } from "../lithic/localStorageHelper";
import { propertiesManagementSystems } from "./PartnerDetailPage/constants";
import { useTheme } from "@material-ui/core/styles";
import useToggle from "../state/useToggle";
import config from "../config";

export default function PartnerdListPage() {
  const tableRef = React.useRef();
  const changeToggle = useToggle();
  const addToggle = useToggle();
  const theme = useTheme();
  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(500);
  const [search, setSearch] = React.useState("");
  const [data, setData] = React.useState(null);
  const [changes, setChanges] = React.useState(null);

  const [formMessage, setFormMessage] = useError();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  function saveAttribute({ id, param, newValue }) {
    api
      .updatePartner({ id, [param]: newValue })
      .then(api.pickData)
      .then(changeToggle.turnOff)
      .catch((error) => {
        hotInstance.undo();
        setFormMessage(error);
      });
  }

  function addPartner(partner) {
    setFormMessage("");
    return api
      .createPartner(partner)
      .then(api.getPartners)
      .then((resp) => {
        return (
          setData({ items: resp.data.items, totalCount: resp.data.totalCount }),
          addToggle.turnOff()
        );
      })
      .catch((error) => {
        let errorMsg;
        if (get(error, "response.status") === 400) {
          const msg = get(error, "response.data.error.message");
          if (msg === "email is already taken") {
            errorMsg = "It looks like partner with this email already exists";
          }
        }
        setFormMessage(errorMsg || error);
      });
  }

  const hotInstance = tableRef.current?.hotInstance;

  React.useEffect(() => {
    api
      .getPartners({
        page: page + 1,
        search: search,
        perPage: rowsPerPage,
      })
      .then((resp) => {
        setData({ items: resp.data.items, totalCount: resp.data.totalCount });
      });
  }, [page, search, rowsPerPage]);

  function statusRenderer(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    if (value === "active") {
      td.style.background = theme.palette.success.main;
    } else if (value === "submitted") {
      td.style.background = theme.palette.warning.main;
    } else {
      td.style.background = theme.palette.info.main;
    }
    td.style.fontSize = theme.typography.fontSizeTable;
    td.style.color = theme.palette.primary.contrastText;
  }

  function cellCustomRenderer(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    td.style.fontSize = theme.typography.fontSizeTable;
  }

  function partnerLinkRenderer(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    td.innerHTML = value ? `<a href="/partners/${value}">${value}</a>` : "";
    td.style.fontSize = theme.typography.fontSizeTable;
    return td;
  }

  function linkRenderer(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    td.innerHTML = value ? `<a href=${value}>${value}</a>` : "";
    td.style.fontSize = theme.typography.fontSizeTable;
    return td;
  }

  function dateRenderer(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    td.innerHTML = value ? dayjs(value).format("MMM DD, YYYY") : "";
    td.style.fontSize = theme.typography.fontSizeTable;
    return td;
  }

  function phoneRenderer(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    td.innerHTML = value ? displayPhone(value) : "";
    td.style.fontSize = theme.typography.fontSizeTable;
    return td;
  }

  const tableSetttings = {
    data: data?.items,
    className: "htCenter htMiddle handsontable",
    rowHeaders: true,
    columns: [
      {
        data: "id",
        title: "Id",
        renderer: partnerLinkRenderer,
      },
      {
        data: "createdAt",
        title: "Created",
        renderer: dateRenderer,
      },
      {
        data: "status",
        title: "Status",
        renderer: statusRenderer,
      },
      {
        data: "name",
        title: "Company Name",
        renderer: Handsontable.renderers.TextRenderer,
      },
      {
        data: "email",
        title: "Email",
        renderer: Handsontable.renderers.TextRenderer,
      },
      {
        data: "contactPhoneNumber",
        title: "Phone Number",
        renderer: phoneRenderer,
      },
      {
        data: "contactFirstName",
        title: "Contact First Name",
        renderer: Handsontable.renderers.TextRenderer,
      },
      {
        data: "contactLastName",
        title: "Contact Last Name",
        renderer: Handsontable.renderers.TextRenderer,
      },
      {
        title: "PMS",
        data: "propertyManagementSystem",
        renderer: Handsontable.renderers.TextRenderer,
      },
      {
        title: "Total Customers",
        data: "totalCustomers",
        renderer: Handsontable.renderers.TextRenderer,
      },
      {
        title: "Units",
        data: "units",
        renderer: Handsontable.renderers.TextRenderer,
      },
    ],
    afterGetColHeader: (column, TH) => {
      TH.style.fontWeight = "bold";
      TH.style.fontSize = theme.typography.fontSizeTable;
    },
    height: "75vh",
    renderer: cellCustomRenderer,
    width: "100%",
    rowHeights: 35,
    dropdownMenu: true,
    filters: true,
    search: true,
    columnSorting: true,
    fixedColumnsLeft: 2,
    overflow: "hidden",
    currentRowClassName: classes.activeRow,
    licenseKey: "non-commercial-and-evaluation",
    afterLoadData: (sourceData, initialLoad, source) => {
      const defaultValue = config.apiHost.includes("staging")
        ? [
            {
              column: 2,
              operation: "conjunction",
              conditions: [{ name: "by_value", args: [["active"]] }],
            },
          ]
        : [];
      let conditions = localStorageCache.getItem("filterConditions", defaultValue);
      if (initialLoad) {
        localStorageCache.removeItem("filterConditions");
        localStorageCache.setItem("filterConditions", defaultValue);
      } else if (conditions.length) {
        let plugin = hotInstance.getPlugin("Filters");
        conditions.forEach((condition) => {
          plugin.addCondition(
            condition.column,
            condition.conditions[0].name,
            condition.conditions[0].args,
            condition.operation
          );
        });
      }
    },
    afterFilter: (conditionsStack) => {
      localStorageCache.setItem("filterConditions", conditionsStack);
    },
    afterSetDataAtCell: (changes, source) => {
      changes?.forEach(([row, prop, oldValue, newValue]) => {
        const currentRow = hotInstance.toPhysicalRow(row);
        const rowData = hotInstance.getSourceDataAtRow(currentRow);
        const id = rowData.id;
        const param = prop.includes(".") ? prop.split(".").pop() : prop;
        if (oldValue !== newValue && source === "edit") {
          setFormMessage();
          changeToggle.turnOn();
          setChanges({ id, param, newValue });
        }
      });
    },
  };

  return (
    <>
      <ConfirmationDialog
        open={changeToggle.on}
        onCancel={() => {
          hotInstance.undo();
          if (changeToggle.on) {
            changeToggle.turnOff();
          }
        }}
        onConfirm={() => {
          if (changeToggle.on) {
            saveAttribute(changes);
          }
        }}
        title="Are you sure you want to save changes?"
        cancelProps={{ children: "Go back" }}
        confirmProps={{ children: "Yes", disabled: formMessage }}
      >
        <FormError error={formMessage} resetError={setFormMessage} />
      </ConfirmationDialog>
      <div className={classes.header}>
        <Typography variant="h5" color="primary">
          Partners
        </Typography>
        <TextField
          label="Search"
          variant="outlined"
          onChange={(event) => setSearch(event.target.value)}
        />
        <div>
          <Button color="primary" variant="contained" onClick={addToggle.turnOn}>
            Add new partner
          </Button>
        </div>
      </div>
      <PartnerForm
        toggle={addToggle}
        addPartner={addPartner}
        error={formMessage}
        resetError={setFormMessage}
      />
      <HotTable settings={tableSetttings} ref={tableRef} />
      <TablePagination
        component="div"
        count={data?.totalCount}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[500, 1000]}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  activeRow: {
    background: theme.palette.primary.main,
  },

  header: {
    margin: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

function PartnerForm({ toggle, addPartner, error, resetError }) {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [url, setUrl] = React.useState("");
  const [propertyManagementSystem, setPropertyManagementSystem] = React.useState("");
  const [serviceType, setServiceType] = React.useState("landlord");

  const newPartner = {
    name,
    email,
    url,
    serviceType,
    propertyManagementSystem,
  };

  const valid = name && email && url;

  return (
    <ConfirmationDialog
      title="Add new partner"
      open={toggle.on}
      onCancel={toggle.turnOff}
      onConfirm={() => {
        addPartner(newPartner);
      }}
      cancelProps={{ children: "Go back" }}
      confirmProps={{ children: "Yes", disabled: !valid }}
    >
      <FormError error={error} resetError={resetError} />
      <FormControl margin="normal" fullWidth>
        <TextField
          label="Company Name"
          type="text"
          value={name}
          variant="outlined"
          onChange={(e) => setName(e.target.value)}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <TextField
          required
          label="Company Email"
          type="email"
          value={email}
          variant="outlined"
          onChange={(e) => setEmail(e.target.value)}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <TextField
          label="Company Website"
          type="email"
          value={url}
          variant="outlined"
          onChange={(e) => setUrl(e.target.value)}
        />
      </FormControl>

      <FormControl margin="normal" fullWidth>
        <Select value={serviceType} onChange={(e) => setServiceType(e.target.value)}>
          <MenuItem key={"ss"} value={"landlord"}>
            Landlord
          </MenuItem>
          <MenuItem key={"fs"} value={"full_service"}>
            Full Service
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl margin="normal" fullWidth>
        <Select
          value={propertyManagementSystem}
          label={startCase(propertyManagementSystem)}
          onChange={(e) => setPropertyManagementSystem(e.target.value)}
        >
          <MenuItem key="" value="">
            -
          </MenuItem>

          {propertiesManagementSystems.map((pms) => (
            <MenuItem key={pms} value={pms}>
              {startCase(pms)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ConfirmationDialog>
  );
}
