import { makeStyles, useTheme } from "@material-ui/core/styles";

import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import { InputLabel } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import Select from "@material-ui/core/Select";
import _ from "lodash";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

export default function MultiSelect({ className, chip, options, label, ...rest }) {
  const classes = useStyles();
  const theme = useTheme();
  let renderValue = null;
  if (chip) {
    renderValue = (selected) => (
      <div className={classes.chips}>
        {selected.map((value) => (
          <Chip key={value} label={value} className={classes.chip} />
        ))}
      </div>
    );
  }
  let optionObjs, optionValues;
  if (!options) {
    optionObjs = [];
    optionValues = [];
  } else if (_.isString(options[0])) {
    optionObjs = options.map((x) => ({ label: x, value: x }));
    optionValues = options;
  } else {
    optionObjs = options;
    optionValues = _.map(options, "value");
  }
  return (
    <FormControl className={clsx(classes.formControl, className)}>
      <InputLabel>{label}</InputLabel>
      <Select input={<Input />} renderValue={renderValue} multiple {...rest}>
        {optionObjs.map(({ label, value }) => (
          <MenuItem
            key={value}
            value={value}
            style={getMultiselectStyles(value, optionValues, theme)}
          >
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function getMultiselectStyles(candidate, selected, theme) {
  return {
    fontWeight:
      selected.indexOf(candidate) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
