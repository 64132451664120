import { apiCollectionToRowResponse, rowQueryToApiParams } from "../modules/tableQuery";

import { AdminContainer } from "../components/layouts";
import MaterialTable from "material-table";
import React from "react";
import Time from "../lithic/Moment";
import api from "../api";
import { formatMoney } from "../lithic/Money";
import { renderTableLink } from "../components/renderTableCell";
import tableIcons from "../components/tableIcons";
import { useTheme } from "@material-ui/core/styles";

export default function InvoicesListPage() {
  const tableRef = React.createRef();
  const theme = useTheme();
  const columns = makeColumns(theme);
  return (
    <AdminContainer variant="list">
      <MaterialTable
        title="Invoices"
        tableRef={tableRef}
        columns={columns}
        options={{
          emptyRowsWhenPaging: false,
          pageSize: 50,
          pageSizeOptions: [50, 100, 200],
          sorting: true,
          thirdSortClick: false,
        }}
        data={(q) => {
          return api
            .listInvoices(rowQueryToApiParams(q))
            .then((resp) => apiCollectionToRowResponse(resp));
        }}
        icons={tableIcons}
      />
    </AdminContainer>
  );
}

function makeColumns(theme) {
  function makeCellStyle(rowData) {
    if (!rowData.total.cents) {
      return { color: theme.palette.muted.main };
    }
  }
  return [
    {
      title: "Id",
      field: "id",
      defaultSort: "desc",
      width: 20,
      render: renderTableLink({ url: "/invoices/", body: "id", style: makeCellStyle }),
    },
    {
      title: "Customer",
      field: "customerName",
      sorting: false,
      render: renderTableLink({
        url: "/invoices/",
        body: "customerName",
        style: makeCellStyle,
      }),
    },
    {
      title: "Partner",
      field: "partnerName",
      sorting: false,
      render: renderTableLink({
        url: "/invoices/",
        body: "partnerName",
        style: makeCellStyle,
      }),
    },
    {
      title: "Status",
      field: "status",
      sorting: false,
      render: renderTableLink({
        url: "/invoices/",
        body: "status",
        style: makeCellStyle,
      }),
    },
    {
      title: "Total",
      field: "total",
      sorting: false,
      render: renderTableLink({
        url: "/invoices/",
        body: (rowData) => formatMoney(rowData.total),
        style: makeCellStyle,
      }),
    },
    {
      title: "Opening",
      field: "periodBegin",
      sorting: false,
      render: renderTableLink({
        url: "/invoices/",
        body: (rowData) => {
          if (rowData.period) {
            return (
              <Time format="MMM DD, YYYY HH:MM" timezone={rowData.customerTimezone}>
                {rowData.period.start}
              </Time>
            );
          } else {
            return "-";
          }
        },
        style: makeCellStyle,
      }),
    },
    {
      title: "Closing",
      field: "periodEnd",
      sorting: false,
      render: renderTableLink({
        url: "/invoices/",
        body: (rowData) => {
          if (rowData.period) {
            return (
              <Time format="MMM DD, YYYY HH:MM" timezone={rowData.customerTimezone}>
                {rowData.period.end}
              </Time>
            );
          } else {
            return "-";
          }
        },
        style: makeCellStyle,
      }),
    },
  ];
}
