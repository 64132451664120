import {
  CancelOutlined,
  Check,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@material-ui/icons";
import { Collapse, IconButton, Typography } from "@material-ui/core";
import React, { useState } from "react";

import Link from "../components/Link";
import MaterialLink from "@material-ui/core/Link";
import { StatusLabel } from "../components/StatusLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import api from "../api";
import clsx from "clsx";
import dayjs from "dayjs";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";

export default function IdentityVerificationTable({ className, subjectId, subjectType }) {
  const [identityVerifications, setIdentityVerifications] = React.useState([]);

  React.useEffect(() => {
    if (!subjectId) return;

    async function fetchData() {
      api
        .listIdentityVerifications({ subjectId, subjectType })
        .then(api.pickData)
        .then((data) => setIdentityVerifications(data.items))
        .catch((error) => setIdentityVerifications([]));
    }

    fetchData();
  }, [subjectId]);

  return (
    <TableContainer className={className}>
      <Table>
        <TableBody>
          {identityVerifications?.map((identityVerification) => {
            return (
              <IdentityVerificationRow
                key={identityVerification.id}
                identityVerification={identityVerification}
                customer={identityVerification.customer}
                partner={identityVerification.partner}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function IdentityVerificationRow({ cells, identityVerification, customer, partner }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const css = clsx({
    [classes.rowClassPending]:
      identityVerification.status === "pending" ||
      identityVerification.status === "review_required",
    [classes.rowClassFailed]: identityVerification.status === "failed",
    [classes.rowClassFinished]: identityVerification.status === "finished",
  });

  const renderCheck = (checkName, checkKey) => {
    if (identityVerification.checks[checkKey] === null) return <></>;
    return (
      <TableRow>
        <TableCell>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            {identityVerification.checks[checkKey] ? (
              <Check className={`${classes.green}`} />
            ) : (
              <CancelOutlined color="error" />
            )}
            <Typography style={{ marginLeft: 32 }}>{checkName} check</Typography>
          </div>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <React.Fragment>
      <TableRow
        key={identityVerification.id}
        className={clsx(classes.identityVerificationRow, css)}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
        </TableCell>
        <TableCell key={0}>{identityVerification.id}</TableCell>
        <TableCell>
          {dayjs(identityVerification.updatedAt).format("D MMM YYYY hh:mm a")}
        </TableCell>
        <TableCell key={1}>
          {customer ? (
            <Link to={`/customers/${customer.id}`}>{customer.name}</Link>
          ) : null}
          {partner ? <Link to={`/partners/${partner.id}`}>{partner.name}</Link> : null}
        </TableCell>
        <TableCell key={2}>{identityVerification.provider}</TableCell>
        <TableCell key={3}>{identityVerification.kind}</TableCell>
        <TableCell key={4} className={classes.identityVerificationStatus}>
          <StatusLabel status={identityVerification.status} label=" " />
        </TableCell>
        <TableCell key={5}>
          {identityVerification.url && (
            <MaterialLink
              href={identityVerification.url}
              target="_blank"
              rel="noreferrer"
            >
              Plaid dashboard
            </MaterialLink>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={24}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="medium" aria-label="purchases">
              <TableBody style={{ width: "100%" }}>
                {renderCheck("Basic", "basic")}
                {renderCheck("Document", "document")}
                {renderCheck("Risk", "risk")}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  rowClassFinished: {
    backgroundColor: lighten(theme.palette.success.light, 0.7),
  },
  rowClassFailed: {
    backgroundColor: lighten(theme.palette.error.light, 0.7),
  },
  rowClassPending: {
    backgroundColor: lighten(theme.palette.warning.light, 0.9),
  },
  green: {
    color: "green",
  },
}));
