import { apiCollectionToRowResponse, rowQueryToApiParams } from "../modules/tableQuery";

import { AdminContainer } from "../components/layouts";
import Autorenew from "@material-ui/icons/Autorenew";
import MaterialTable from "material-table";
import Money from "../lithic/Money";
import React from "react";
import TableDateRangeFilter from "../components/TableDateRangeFilter";
import TableSelectFilter from "../components/TableSelectFilter";
import Time from "../lithic/Moment";
import api from "../api";
import { renderTableLink } from "../components/renderTableCell";
import tableIcons from "../components/tableIcons";
import { useTheme } from "@material-ui/core/styles";

export default function PaymentsListPage() {
  const tableRef = React.createRef();
  const lastDataRequestQueryRef = React.useRef({});
  const theme = useTheme();
  const columns = makeColumns(theme);
  return (
    <AdminContainer variant="list">
      <MaterialTable
        title="Payments"
        tableRef={tableRef}
        columns={columns}
        localization={{
          toolbar: {
            exportPDFName: "", // Hide this export option
          },
        }}
        options={{
          exportButton: true,
          exportCsv: (columns, data) => {
            return api
              .buildDownloadPaymentsUrl(
                rowQueryToApiParams(lastDataRequestQueryRef.current)
              )
              .then((resp) => {
                window.location.href = resp.data.url;
              });
          },
          exportPdf: () => null,
          emptyRowsWhenPaging: false,
          filtering: true,
          pageSize: 50,
          pageSizeOptions: [50, 100, 200],
          sorting: true,
          thirdSortClick: false,
          rowStyle: (rowData) => {
            if (rowData.softDeletedAt) {
              return { textDecoration: "line-through" };
            }
          },
          filterCellStyle: {
            transform: "scale(0.80)",
            transformOrigin: "left",
            maxWidth: 0,
          },
        }}
        data={(q) => {
          lastDataRequestQueryRef.current = q;
          return api
            .listPayments(rowQueryToApiParams(q))
            .then((resp) => apiCollectionToRowResponse(resp));
        }}
        icons={tableIcons}
      />
    </AdminContainer>
  );
}

function makeColumns(theme) {
  function makeCellStyle(rowData) {
    if (rowData.status === "scheduled") {
      return { color: theme.palette.muted.dark };
    }
  }

  return [
    {
      title: "Id",
      field: "id",
      defaultSort: "desc",
      filtering: false,
      width: 20,
      render: renderTableLink({ url: "/payments/", body: "id", style: makeCellStyle }),
    },
    {
      title: "Amount",
      field: "amount",
      sorting: false,
      filtering: false,
      render: renderTableLink({
        url: "/payments/",
        body: (rowData) => <Money value={rowData.amount} />,
        style: makeCellStyle,
      }),
    },
    {
      title: "Customer",
      field: "customer_name",
      sorting: false,
      filtering: false,
      render: renderTableLink({
        url: "/payments/",
        body: "customerName",
        style: makeCellStyle,
      }),
    },
    {
      title: "Recipient",
      field: "receiving_legal_entity",
      sorting: false,
      filtering: false,
      render: renderTableLink({
        url: "/payments/",
        body: (rowData) => (
          <>
            <span>{rowData.receivingLegalEntity.name}</span>
            <br />
            <span style={{ whiteSpace: "nowrap" }}>{rowData.strategySummary}</span>
          </>
        ),
        style: makeCellStyle,
      }),
    },
    {
      title: "Status",
      field: "status",
      sorting: false,
      filterComponent: (props) => (
        <TableSelectFilter
          {...props}
          paramKey="status"
          lookup={{
            scheduled: "Scheduled",
            processing: "Processing",
            deleted: "Deleted",
          }}
        />
      ),
      defaultFilter: ["scheduled", "processing"],
      render: renderTableLink({
        url: "/payments/",
        body: "status",
        style: makeCellStyle,
      }),
    },
    {
      title: "Reviewed",
      field: "reviewed_at",
      sorting: false,
      filterName: "review",
      filterComponent: (props) => (
        <TableSelectFilter
          {...props}
          paramKey="reviewed"
          lookup={{ reviewed: "Reviewed", unreviewed: "Unreviewed" }}
        />
      ),
      align: "center",
      render: renderTableLink({
        url: "/payments/",
        body: (rowData) => (rowData.reviewedAt ? "✔️" : "✖️"),
        style: makeCellStyle,
      }),
    },
    {
      title: "Starting",
      field: "apply_at",
      defaultSort: "desc",
      filterName: "apply_at",
      filterComponent: (props) => <TableDateRangeFilter {...props} paramKey="applyat" />,
      render: renderTableLink({
        url: "/payments/",
        body: (rowData) => (
          <Time formatExact="MMM DD, YYYY" timezone={rowData.customerTimezone}>
            {rowData.applyAt}
          </Time>
        ),
        style: makeCellStyle,
      }),
    },
    {
      title: "Created",
      field: "created_at",
      defaultSort: "desc",
      filtering: false,
      render: renderTableLink({
        url: "/payments/",
        body: (rowData) => (
          <span style={{ display: "flex" }}>
            <Time formatExact="MMM DD, YYYY" timezone={rowData.customerTimezone}>
              {rowData.createdAt}
            </Time>
            {rowData.paymentSeriesId && <Autorenew fontSize="small" />}
          </span>
        ),
        style: makeCellStyle,
      }),
    },
  ];
}
