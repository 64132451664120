import { Checkbox, MenuItem, TextField } from "@material-ui/core";

import ConfirmationDialog from "./ConfirmationDialog";
import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formGroup: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridGap: "10px",
    width: "450px",
    marginTop: "20px",
  },
  formControl: {
    width: "150px",
  },
  vCenter: {
    display: "flex",
    alignItems: "center",
  },
}));

export default function CreateRefurnishmentModal({
  toggle,
  onCommit,
  error,
  resetError,
}) {
  const [fields, setFields] = React.useState({});

  const classes = useStyles();

  const BUREAUS = ["experian", "equifax", "transunion"];

  return (
    <ConfirmationDialog
      title="Create Refurnishment"
      open={toggle.on}
      onCancel={toggle.turnOff}
      onConfirm={() => onCommit(fields)}
      cancelProps={{ children: "Go back" }}
      confirmProps={{ children: "Create" }}
      error={error}
      resetError={resetError}
    >
      {BUREAUS.map((bureau) => (
        <>
          <div className={classes.formGroup}>
            <h3>{bureau.toUpperCase()}</h3>
            <TextField
              className={classes.formControl}
              label="Refurnishment"
              type="text"
              value={fields[bureau + "Refurnish"] || false}
              variant="outlined"
              onChange={(e) =>
                setFields({ ...fields, [bureau + "Refurnish"]: e.target.value })
              }
              select
            >
              <MenuItem key={1} value={true}>
                ON
              </MenuItem>
              <MenuItem key={2} value={false}>
                OFF
              </MenuItem>
            </TextField>
            <div className={clsx(classes.formControl, classes.vCenter)}>
              With swap?
              <Checkbox
                disabled={!fields[bureau + "Refurnish"]}
                key={3}
                color="primary"
                onChange={(e) =>
                  setFields({
                    ...fields,
                    [bureau + "RefurnishWithSwap"]: e.target.checked,
                  })
                }
                checked={fields[bureau + "RefurnishWithSwap"] || false}
              />
            </div>
          </div>
        </>
      ))}
    </ConfirmationDialog>
  );
}
