import { Tab, Tabs } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import React, { useState } from "react";
import { EnrollmentDetailView, PartnerDetailView } from "./PartnerDetailView";
import { FileViewer } from "../EnrollmentVerificationDetailPage/FileViewer";
import IdentityVerificationTable from "../../components/IdentityVerificationTable";

/** Shows details such as documents, enrollment verification etc. on the enrollment verification page. */
export const DetailsView = ({
  verification,
  files,
  setFiles,
  selectedFile,
  setSelectedFile,
  selectedFileIndex,
  setSelectedFileIndex,
  onFilesDropped
}) => {
  const [tab, setTab] = useState(0);

  const tabs = []

  if (verification.kind !== "portal") {
    tabs.push({
      title: "Documents",
      component: () => (
        <FileViewer
          onFilesDropped={onFilesDropped}
          enrollmentId={verification.enrollment?.id}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          selectedFileIndex={selectedFileIndex}
          setSelectedFileIndex={setSelectedFileIndex}
          files={files}
          setFiles={setFiles}
        />
      ),
    })
  }

  tabs.push({
    title: "Details",
    component: () => <PartnerDetailView verification={verification} />,
  })

  tabs.push({
    title: "Plaid Identity Verification",
    component: () => <IdentityVerificationTable subjectId={verification.partner?.id} subjectType="Boom::Partner"/>,
  });

  return (
    <div>
      <TabContext value={tab}>
        <Tabs value={tab} onChange={(e, value) => setTab(value)}>
          {tabs.map((tab) => (
            <Tab key={tab.title} label={tab.title} />
          ))}
        </Tabs>

        {tabs.map((tab, index) => (
          <TabPanel key={tab.title} value={index}>
            {tab.component()}
          </TabPanel>
        ))}
      </TabContext>
    </div>
  );
};
