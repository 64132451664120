import FormControl from "@material-ui/core/FormControl";
import HiddenCopyInput from "./HiddenCopyInput";
import InlineEditable from "./InlineEditable";
import { InputLabel } from "@material-ui/core";
import { GutterlessListItem as ListItem } from "./lists";
import React from "react";
import SafeExternalLink from "../lithic/SafeExternalLink";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import normalizeUrl from "normalize-url";

const useStyles = makeStyles((theme) => ({
  subitemList: { flexDirection: "column", alignItems: "start" },
  extCtrlElement: {
    display: "block",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  form: {
    maxWidth: "100%",
  },
}));

export default function PortalLogin({
  portalAuthCredential,
  onPortalChange,
  portalStatus,
}) {
  if (portalAuthCredential) {
    return (
      <AuthedPortal
        portalAuthCredential={portalAuthCredential}
        onPortalChange={onPortalChange}
        portalStatus={portalStatus}
      />
    );
  }
  return <NoPortal />;
}

function NoPortal() {
  const classes = useStyles();
  return (
    <ListItem className={classes.subitemList}>
      <Typography variant="h6" gutterBottom color="primary">
        Portal
      </Typography>
      <Typography>Customer has not linked any portal yet.</Typography>
    </ListItem>
  );
}

function getPortalStatusFormatted(portalVerificationField) {
  return portalVerificationField.split("_")[2];
}

function AuthedPortal({ portalAuthCredential, onPortalChange, portalStatus }) {
  const classes = useStyles();

  let url = portalAuthCredential.portal.loginUrl;

  try {
    url = normalizeUrl(url);
  } catch {
    url = portalAuthCredential.portal.loginUrl;
    console.log(`Invalid ${url}`);
  }

  return (
    <ListItem className={classes.subitemList}>
      <Typography variant="h6" gutterBottom color="primary">
        Portal: {portalAuthCredential.portal.name}
      </Typography>

      {portalStatus && (
        <Typography variant="subtitle1" gutterBottom>
          Status: <strong>{getPortalStatusFormatted(portalStatus)}</strong>
        </Typography>
      )}

      <Typography gutterBottom>
        Log into the renter portal to verify their lease and rental details.
      </Typography>
      <SafeExternalLink href={url} className={classes.extCtrlElement}>
        Open Portal
      </SafeExternalLink>
      <form className={classes.form}>
        <InlineEditable
          label="Login URL"
          value={portalAuthCredential.portal.loginUrl}
          ListItemComponent={ListItem}
          onCommit={(loginUrl) => onPortalChange({ loginUrl })}
          inputProps={{ className: classes.extCtrlElement }}
        />
        <InlineEditable
          label="Portal Username"
          value={portalAuthCredential.username}
          ListItemComponent={ListItem}
          onCommit={(username) => onPortalChange({ username })}
          displayFunc={() => (
            <FormControl className={classes.extCtrlElement}>
              <InputLabel>Portal Username</InputLabel>
              <HiddenCopyInput value={portalAuthCredential.username} />
            </FormControl>
          )}
        />
        <InlineEditable
          label="Portal Password"
          value={portalAuthCredential.password}
          ListItemComponent={ListItem}
          onCommit={(password) => onPortalChange({ password })}
          displayFunc={() => (
            <FormControl className={classes.extCtrlElement}>
              <InputLabel>Portal Password</InputLabel>
              <HiddenCopyInput value={portalAuthCredential.password} />
            </FormControl>
          )}
        />
      </form>
    </ListItem>
  );
}
