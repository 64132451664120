import React, { useEffect, useState } from "react";
import { Button, TextField } from "@material-ui/core";
import DetailSection from "../../../components/DetailSection";

export const BorderRadiusSlice = ({ partner, onSave }) => {
  const [borderRadius, setBorderRadius] = useState(
    partner.settings?.baseBorderRadius || "8px"
  );

  useEffect(() => {
    if (partner.settings?.baseBorderRadius) {
      setBorderRadius(partner.settings.baseBorderRadius);
    }
  }, [partner]);

  return (
    <DetailSection title="Border radius">
      <TextField
        label="Border radius"
        value={borderRadius}
        onChange={(e) => setBorderRadius(e.target.value)}
      />

      <Button variant="contained" color="primary" onClick={() => onSave(borderRadius)}>
        Save
      </Button>
    </DetailSection>
  );
};
