import React from "react";
import SimpleTable from "../components/SimpleTable";
import Time from "../lithic/Moment";
import _ from "lodash";

export default function AuditLogsTable({ auditLogs, className }) {
  const includeMessages = _.some(auditLogs, (al) => !_.isEmpty(al.messages));
  const includeBy = _.some(auditLogs, "actor");
  const includeReason = _.some(auditLogs, "reason");
  return (
    <SimpleTable
      className={className}
      tableProps={{ size: "small" }}
      headers={concat(
        ["Audit Logs", "At", "From", "To"],
        includeMessages && "Messages",
        includeBy && "By",
        includeReason && "Reason"
      )}
      rows={auditLogs}
      getKey={(row) => row.at}
      toCells={(row) =>
        concat(
          [
            row.event,
            <Time key={1} format={"MMM DD, YYYY HH:mm:ss"}>
              {row.at}
            </Time>,
            row.fromState,
            row.toState,
          ],
          includeMessages && row.messages?.join(", "),
          includeBy && actor(row.actor),
          includeReason && row.reason
        )
      }
    />
  );
}

function concat(...values) {
  return _.flatten(_.filter(values, (x) => x !== false));
}

function actor(a) {
  if (!a) {
    return "";
  }
  return `${a.email} (${a.id})`;
}
