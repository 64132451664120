import FurnishmentReportsList from "./FurnishmentReportsList";
import React from "react";
import _ from "lodash";

export default function BureauReportsTable({ reports, upload, onReportUpload }) {
  const experianReports = _.filter(reports, (r) => r.bureau === "experian");
  const equifaxReports = _.filter(reports, (r) => r.bureau === "equifax");
  const transunionReports = _.filter(reports, (r) => r.bureau === "transunion");

  return (
    <div key="reports-tab">
      <FurnishmentReportsList
        reports={experianReports}
        withLink
        upload={upload}
        onReportUpload={onReportUpload}
      />
      <FurnishmentReportsList
        reports={equifaxReports}
        withLink
        upload={upload}
        onReportUpload={onReportUpload}
      />
      <FurnishmentReportsList
        reports={transunionReports}
        withLink
        upload={upload}
        onReportUpload={onReportUpload}
      />
    </div>
  );
}
