import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import useQueryParam from "../state/useQueryParam";
import { useDebouncedValue } from "../hooks/useDebouncedValue";

export default function TableInputFilter({
  paramKey,
  columnDef,
  onFilterChanged,
  placeholder = "Enter value",
}) {
  const classes = useStyles();
  const [paramValue, setParamValue] = useQueryParam(paramKey, "", {
    parse,
    serialize,
  });
  const [value, setValue] = React.useState(paramValue);
  const [debouncedValue] = useDebouncedValue(value);

  React.useEffect(() => {
    if (debouncedValue.length > 0) {
      onFilterChanged(columnDef.tableData.id, debouncedValue);
    } else {
      onFilterChanged(columnDef.tableData.id, null);
    }
    if (debouncedValue !== paramValue) {
      setParamValue(debouncedValue);
    }
    // eslint-disable-next-line
  }, [debouncedValue]);

  return (
    <TextField
      value={value}
      onChange={(e) => setValue([e.target.value])}
      className={classes.root}
      placeholder={placeholder}
      variant="outlined"
      size="small"
      type="number"
    />
  );
}

TableInputFilter.propTypes = {
  paramKey: PropTypes.string.isRequired,
  columnDef: PropTypes.object.isRequired,
  onFilterChanged: PropTypes.func.isRequired,
};

function parse(value) {
  if (!value) return [];
  return value.split(",");
}

function serialize(value) {
  return value.join(",");
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px",
    },
  },
}));
