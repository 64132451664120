import CopyContentIconButton from "./CopyContentIconButton";
import React from "react";
import VisibilityInput from "./VisibilityInput";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

export default function HiddenCopyInput({ value, inForm, formClass, ...rest }) {
  const classes = useStyles();
  const inp = (
    <VisibilityInput
      readOnly
      value={value}
      endAdornment={() => <CopyContentIconButton value={value} />}
      autoComplete="ignore"
      {...rest}
    />
  );
  if (inForm) {
    return <form className={clsx(classes.form, formClass)}>{inp}</form>;
  }
  return inp;
}

const useStyles = makeStyles(() => ({
  form: {
    display: "inline",
  },
}));
