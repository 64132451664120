import DropZone from "./DropZone";
import GetApp from "@material-ui/icons/GetApp";
import { GutterlessListItem } from "./lists";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import OpenInBrowser from "@material-ui/icons/OpenInBrowser";
import React from "react";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import api from "../api";
import { makeStyles } from "@material-ui/core/styles";

export default function DocumentsList({ documents, onDocumentsDropped, disableDrop }) {
  const classes = useStyles();

  if (_.isEmpty(documents)) {
    return (
      <ListItem className={classes.subitemList}>
        <Typography gutterBottom>Customer has not uploaded any documents.</Typography>
        <DropZone disabled={disableDrop} onFilesDropped={onDocumentsDropped} />
      </ListItem>
    );
  }
  return (
    <GutterlessListItem className={classes.subitemList}>
      {documents.map((d) => (
        <Document key={d.id} doc={d} />
      ))}
      <DropZone disabled={disableDrop} onFilesDropped={onDocumentsDropped} />
    </GutterlessListItem>
  );
}

function Document({ doc }) {
  const classes = useStyles();
  return (
    <div>
      ({doc.id}) {doc.name}
      {doc.description ? `: ${doc.description}` : ""}
      {doc.contentFiles.map((cf) => (
        <React.Fragment key={cf.id}>
          <a href={cf.presignedDownloadUrl}>
            <IconButton className={classes.icon}>
              <GetApp />
            </IconButton>
          </a>
          <a href={cf.presignedInlineUrl}>
            <IconButton className={classes.icon}>
              <OpenInBrowser />
            </IconButton>
          </a>
        </React.Fragment>
      ))}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  subitemList: { flexDirection: "column", alignItems: "start", padding: 0 },
  icon: {
    marginLeft: theme.spacing(0.5),
  },
}));

export function useDocumentsState(enrollmentCase, entity) {
  enrollmentCase = enrollmentCase || {};

  const createDocumentsFromFiles = React.useCallback(
    (files) => {
      const createDocuments = files.map((file) =>
        api
          .createPresignedDocumentUrl({
            customerId: enrollmentCase.customer.id,
            filename: file.name,
          })
          .then(api.pickData)
          .tap((d) => uploadFile(d.presignedUrl, file))
          .then((d) =>
            api.addDocumentForReportingAccountEnrollment({
              id: enrollmentCase.id,
              s3_url: d.presignedUrl,
              entity,
            })
          )
      );
      return Promise.all(createDocuments);
    },
    [enrollmentCase.id]
  );

  return { createDocumentsFromFiles };
}

const uploadFile = (uploadUrl, fileObj) => {
  return fetch(uploadUrl, { method: "PUT", body: fileObj, mode: "cors" });
};
