import { AdminContainer, AlignedListContainer } from "../components/layouts";
import {
  BaseTextListItem,
  CustomerLinkListItem,
  GutterlessListItem as ListItem,
} from "../components/lists";

import AdminActionLinks from "../components/AdminActionLinks";
import ExternalLinksList from "../components/ExternalLinksList";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import Time from "../lithic/Moment";
import api from "../api";
import { useParams } from "react-router";

function TextListItem(props) {
  return <BaseTextListItem disableGutters {...props} />;
}

export default function CardDetailPage() {
  const { id } = useParams();
  const [card, setCard] = React.useState({});
  React.useEffect(() => {
    api.getCard({ id }).then(api.pickData).then(setCard);
  }, [id]);

  return (
    <AdminContainer variant="detail-wide" customer={card.customer}>
      <AlignedListContainer maxWidth="xl">
        <ListItem>
          <ListItemText>Card Id: {card.id}</ListItemText>
        </ListItem>
        <CustomerLinkListItem customer={card.customer} disableGutters />
        <TextListItem>
          Created: <Time format={"MMM DD, YYYY HH:mm:ss"}>{card.createdAt}</Time>
        </TextListItem>
        {card.softDeletedAt && (
          <TextListItem>
            Deleted At: <Time format={"MMM DD, YYYY HH:mm:ss"}>{card.softDeletedAt}</Time>{" "}
            <strong>(cannot be used for payments)</strong>
          </TextListItem>
        )}
        <TextListItem>Brand: {card.brand}</TextListItem>
        <TextListItem>Last 4: {card.last4}</TextListItem>
        <TextListItem>
          Expiration: {card.expMonth}/{card.expYear}
        </TextListItem>
        <TextListItem>Funding: {card.funding}</TextListItem>
        <TextListItem>Cardholder: {card.cardholderName}</TextListItem>
        <TextListItem>Postal Code: {card.addressPostalCode}</TextListItem>
        <ExternalLinksList links={card.externalLinks} />
        <AdminActionLinks
          links={card.adminActionLinks}
          onActionSuccess={(resp) => setCard(resp.data)}
        />
      </AlignedListContainer>
    </AdminContainer>
  );
}
