import React from "react";
import { Redirect } from "react-router";
import { useUser } from "../hooks/user";

export default function RootPage() {
  const { userAuthed, userUnauthed } = useUser();
  if (userAuthed) {
    return <Redirect to="/dashboard" />;
  }
  if (userUnauthed) {
    return <Redirect to="/sign-in" />;
  }
  return null;
}
