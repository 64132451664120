import {
  apiCollectionToRowResponse,
  rowQueryToApiParams,
} from "../../modules/tableQuery";

import { AdminContainer } from "../../components/layouts";
import MaterialTable from "material-table";
import React from "react";
import TableSelectFilter from "../../components/TableSelectFilter";
import Time from "../../lithic/Moment";
import api from "../../api";
import { renderTableLink } from "../../components/renderTableCell";
import tableIcons from "../../components/tableIcons";
import { useTheme } from "@material-ui/core/styles";
import SafeExternalLink from "../../lithic/SafeExternalLink";
import { LookupPartner } from "./LookupPartner";

export default function ApplicationListPage() {
  const tableRef = React.createRef();
  const lastDataRequestQueryRef = React.useRef({});
  const theme = useTheme();
  const columns = makeColumns(theme);
  return (
    <AdminContainer variant="list">
      <MaterialTable
        title="Applications"
        tableRef={tableRef}
        columns={columns}
        options={{
          emptyRowsWhenPaging: false,
          filtering: true,
          pageSize: 50,
          pageSizeOptions: [50, 100, 200],
          sorting: true,
          thirdSortClick: false,
          filterCellStyle: {
            transform: "scale(0.80)",
            transformOrigin: "left",
            maxWidth: 0,
          },
        }}
        data={(q) => {
          lastDataRequestQueryRef.current = q;
          return api
            .listApplications(rowQueryToApiParams(q, true))
            .then((resp) => apiCollectionToRowResponse(resp));
        }}
        icons={tableIcons}
      />
    </AdminContainer>
  );
}

function makeColumns(theme) {
  function makeCellStyle(rowData) {
    if (rowData.status !== "created") {
      return { color: theme.palette.muted.dark };
    }
  }

  return [
    {
      title: "Id",
      field: "id",
      defaultSort: "desc",
      filtering: false,
      width: 20,
      render: renderTableLink({
        url: "/applications/",
        body: "id",
        style: makeCellStyle,
      }),
    },
    {
      title: "Applicants",
      field: "applicants",
      sorting: false,
      filtering: false,
      render: renderTableLink({
        url: "/applications/",
        body: (rowData) => {
          return (
            <div>
              {rowData.applicants?.length
                ? rowData.applicants?.map((applicant) => (
                    <p key={applicant.id}>
                      {applicant.firstName + " " + applicant.lastName}
                    </p>
                  ))
                : "--"}
            </div>
          );
        },
      }),
    },
    {
      title: "Invites",
      field: "invites",
      sorting: false,
      filtering: false,
      render: (rowData) => {
        return (
          <div>
            {rowData.pendingInvitations?.length
              ? rowData.pendingInvitations?.map((invite) => (
                  <p key={invite.id}>{invite.email}</p>
                ))
              : "--"}
          </div>
        );
      },
    },

    {
      title: "Email",
      field: "email",
      sorting: false,
      filtering: false,
      render: renderTableLink({
        url: "/applications/",
        body: (rowData) =>
          rowData.applicants?.map((applicant) => applicant.email).join(", "),
      }),
    },
    {
      title: "Verification template",
      field: "verificationTemplateName",
      sorting: false,
      filtering: false,
      render: (rowData) => (
        <SafeExternalLink
          href={rowData.verificationTemplate.url}
          style={{
            color: theme.palette.dark.main,
          }}
        >
          {rowData.verificationTemplate.name}
        </SafeExternalLink>
      ),
    },
    {
      title: "Partner",
      field: "from_partner",
      sorting: false,
      filterComponent: (props) => <LookupPartner {...props} paramKey="partner.name" />,
      render: renderTableLink({
        url: "/partners/",
        id: (rowData) => rowData.partner.id,
        body: (rowData) => rowData.partner.name,
        style: makeCellStyle,
      }),
    },
    {
      title: "Status",
      field: "status",
      sorting: false,
      filterComponent: (props) => (
        <TableSelectFilter
          {...props}
          paramKey="status"
          lookup={{
            pending: "Pending",
            started: "Started",
            submitted: "Submitted",
            approved: "Approved",
            approved_with_conditions: "Approved with conditions",
            rejected: "Rejected",
            canceled: "Canceled",
          }}
        />
      ),
      defaultFilter: [
        "pending",
        "started",
        "submitted",
        "approved",
        "approved_with_conditions",
        "rejected",
        "canceled",
      ],
      render: renderTableLink({
        url: "/applications/",
        body: "status",
        style: makeCellStyle,
      }),
    },
    {
      title: "Intercom Links",
      field: "intercom_links",
      filtering: false,
      render: (rowData) => (
        <div
          style={{
            maxWidth: "150px",
          }}
        >
          {rowData.applicants?.map((applicant) => (
            <SafeExternalLink
              key={applicant.id}
              href={applicant.intercomLink}
              style={{
                color: theme.palette.dark.main,
                // truncate text
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "block",
              }}
            >
              {applicant.intercomLink}
            </SafeExternalLink>
          ))}
        </div>
      ),
    },
    {
      title: "Submitted",
      field: "submitted_at",
      filtering: false,
      render: renderTableLink({
        url: "/applications/",
        body: (rowData) => (
          <Time formatExact="MMM DD, YYYY" timezone={rowData.applicantTimezone}>
            {rowData.submittedAt}
          </Time>
        ),
        style: makeCellStyle,
      }),
    },
    {
      title: "Created",
      field: "created_at",
      filtering: false,
      render: renderTableLink({
        url: "/applications/",
        body: (rowData) => (
          <Time formatExact="MMM DD, YYYY" timezone={rowData.applicantTimezone}>
            {rowData.createdAt}
          </Time>
        ),
        style: makeCellStyle,
      }),
    },
  ];
}
