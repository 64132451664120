import { lighten, makeStyles } from "@material-ui/core";

import DetailsView from "./DetailsView";
import LoadingModal from "../../components/LoadingModal";
import PvsTable from "../../components/PvsTable";
import React from "react";
import api from "../../api";
import { useEffect } from "react";
import { useLoading } from "../../hooks/loading";
import { useParams } from "react-router";
import { useState } from "react";
import { useToastError } from "../../components/FormError";

export default function PaymentVerificationDetailPage() {
  const classes = useStyles();
  const { id } = useParams();
  const { startLoading, stopLoading } = useLoading();

  const [rentalVerifications, setRentalVerifications] = useState([]);
  const [enrollment, setEnrollment] = useState(null);
  const [files, setFiles] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileIndex, setSelectedFileIndex] = React.useState(0);
  const [tab, setTab] = useState(0);
  const toastError = useToastError();

  useEffect(() => {
    if (id) {
      refreshPvs();
      fetchEnrollment();
    }
  }, [id]);

  const fetchEnrollment = async () => {
    try {
      const response = await api.getReportingAccountEnrollment({ id });
      setEnrollment(response.data);
    } catch (error) {
      toastError(error.message);
    }
  };

  function refreshPvs() {
    startLoading();
    api
      .getRentalVerifications({ id })
      .then(api.pickData)
      .then(({ items }) => setRentalVerifications(items))
      .catch((error) => {
        setRentalVerifications([]);
        toastError(error);
      })
      .finally(stopLoading);
  }

  return (
    <div className={classes.container}>
      <div className={classes.tableContainer}>
        <PvsTable
          headers={["", "Month", "Status", "Delinquency", "Processed by"]}
          enrollmentId={id}
          rentalVerifications={rentalVerifications}
          refreshPvs={refreshPvs}
          setSelectedFileIndex={setSelectedFileIndex}
          files={files}
          setTab={setTab}
        />
      </div>
      <div className={classes.detailsViewContainer}>
        <DetailsView
          enrollmentId={id}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          files={files}
          setFiles={setFiles}
          selectedFileIndex={selectedFileIndex}
          setSelectedFileIndex={setSelectedFileIndex}
          tab={tab}
          setTab={setTab}
          customerId={enrollment?.case?.customer?.id}
        />
      </div>
      <LoadingModal />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100vh",
    marginTop: theme.spacing(5),
    marginBottom: 0,
    position: "relative",
    backgroundColor: theme.palette.light.main,
  },
  detailsViewContainer: {
    flex: 2,
    height: "100%",
    overflowY: "scroll",
    position: "sticky",
    top: 0,
    right: 0,
  },
  errorColor: {
    color: theme.palette.error.main,
  },
  selectedStyle: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: lighten(theme.palette.secondary.light, 0.7),
    padding: theme.spacing(1),
  },
  successColor: {
    color: theme.palette.success.main,
  },
  tableContainer: {
    flex: 3,
    height: "100%",
    paddingRight: 16,
    overflowY: "scroll",
  },
}));
