import React from "react";
import _ from "lodash";

import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import YesNoIcon from "../../../../components/YesOrNo";

const useStyles = makeStyles((theme) => ({
  paper: { padding: 32, marginTop: "72px", top: "222px", position: "sticky" },
  keyValue: { display: "flex", alignItems: "center", gap: "10px" },
  keyValueStart: { display: "flex", flexDirection: "column" },
  pre: {
    fontFamily: "monospace",
    lineBreak: "no-wrap",
  },
  blockText: { display: "block" },
}));

const TypeRenderer = ({ data }) => {
  const classes = useStyles();
  if (typeof data === "boolean") return <YesNoIcon key={2} yes={data} />;

  if (typeof data === "string") {
    if (moment(data, moment.ISO_8601, true).isValid())
      return (
        <Typography variant="body2" className={classes.blockText}>
          {moment(data).format("lll")}
        </Typography>
      );

    // This is the consensus that we agreed on with backend
    // team to indicate that this is a preformatted text
    if (data.substring(0, 5) === "@pre@")
      return (
        <Typography variant="body2" className={classes.blockText}>
          <pre className={classes.pre}>{data.substring(5, data.length - 5)}</pre>
        </Typography>
      );

    return (
      <Typography variant="body2" className={classes.blockText}>
        {data}
      </Typography>
    );
  }
  if (typeof data === "number")
    return (
      <Typography variant="body2" className={classes.blockText}>
        {data}
      </Typography>
    );

  if (_.isArray(data) && data.length && _.every(data, (e) => typeof e === "string"))
    return (
      <Typography variant="body2" className={classes.blockText}>
        {data.join(", ")}
      </Typography>
    );

  return (
    <Typography variant="body2" color="textSecondary" className={classes.blockText}>
      {JSON.stringify(data)}
    </Typography>
  );
};

export const JSONView = ({ data, children }) => {
  const classes = useStyles();

  if (!data) return <Typography>No data</Typography>;

  return (
    <Paper square className={classes.paper}>
      {Object.entries(data).map(([title, data]) => {
        if (data === null) return null;
        return (
          <div
            key={title}
            className={
              typeof data === "string" && data.substring(0, 5) === "@pre@"
                ? classes.keyValueStart
                : classes.keyValue
            }
          >
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.blockText}
            >
              {_.startCase(title)}:{" "}
            </Typography>
            <TypeRenderer data={data} />
          </div>
        );
      })}
      <>{children}</>
    </Paper>
  );
};
