import React from "react";
import _ from "lodash";
import clsx from "clsx";
import intersperse from "../lithic/intersperse";
import { makeStyles } from "@material-ui/core/styles";

export default function RentalVerificationProgress({ rv }) {
  const classes = useStyles();
  const shorthands = {
    Equifax: "Eq",
    Experian: "Ex",
    Transunion: "Tr",
  };
  const uploadedPieces = _.map(shorthands, (short, long) => {
    const isSet = Boolean(rv[`uploadedTo${long}At`]);
    const cls = isSet ? classes.done : classes.missing;
    const txt = `${short}⬆`;
    return (
      <span key={"u" + short} className={clsx(cls)}>
        {txt}
      </span>
    );
  });
  const confirmedPieces = _.map(shorthands, (short, long) => {
    const isSet = Boolean(rv[`confirmedIn${long}At`]);
    const depIsSet = Boolean(rv[`uploadedTo${long}At`]);
    let cls;
    if (isSet) {
      cls = classes.done;
    } else if (depIsSet) {
      cls = classes.missing;
    } else {
      cls = classes.pending;
    }
    const txt = `${short}✔`;
    return (
      <span key={"c" + short} className={clsx(cls)}>
        {txt}
      </span>
    );
  });
  const parts = [
    ...intersperse(uploadedPieces, "/"),
    <br key="sep" />,
    ...intersperse(confirmedPieces, "/"),
  ];
  return parts;
}

const useStyles = makeStyles((theme) => ({
  pending: {
    color: theme.palette.muted.main,
  },
  done: {
    color: theme.palette.success.main,
  },
  missing: {
    color: theme.palette.error.main,
  },
}));
