import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import Select from "@material-ui/core/Select";

export default function SelectInput({ options, onChangeValue, ...rest }) {
  const props = rest;
  if (options) {
    props.children = options.map(({ value, label }) => (
      <MenuItem key={value} value={value}>
        {label}
      </MenuItem>
    ));
  }
  function handleChange(e) {
    if (props.onChange) {
      props.onChange(e);
    }
    if (onChangeValue) {
      onChangeValue(e.target.value);
    }
  }
  return <Select {...props} onChange={handleChange} />;
}
