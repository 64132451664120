import { ListItem } from "@material-ui/core";
import React from "react";
import api from "../../api";
import InlineEditable from "../../components/InlineEditable";
import { AlignedListContainer } from "../../components/layouts";
import { CustomerLinkListItem } from "../../components/lists";
import VerificationStatus from "../../components/VerificationStatus";
import SafeExternalLink from "../../lithic/SafeExternalLink";
import displayPhone from "../../modules/displayPhone";
import fullName from "../../modules/fullName";
import { renderItem } from "./ReportingAccountEnrollmentDetailPage";

export const CustomerDetailsSection = ({ enrollment, setEnrollment } = {}) => {
  const ecase = enrollment.case;
  const customer = ecase.customer;

  function callEnrollmentApi(apiMethod, params) {
    return apiMethod({ ...params, id: enrollment.id })
      .then(api.pickData)
      .then(setEnrollment);
  }

  const customerFullName = fullName(customer, {
    firstNameField: "firstName",
    lastNameField: "lastName",
  });

  return (
    <AlignedListContainer maxWidth="xl">
      <CustomerLinkListItem label="Id:" value={customer.id} customer={customer} />
      {renderItem("Full name", customerFullName)}
      {renderItem("Email", customer.email)}
      {renderItem("Phone", displayPhone(customer.phone))}
      {renderItem(
        "Intercom contact",
        enrollment.intercomLink ? (
          <SafeExternalLink href={enrollment.intercomLink}>
            {enrollment.intercomLink}
          </SafeExternalLink>
        ) : (
          <span>Intercom Contact not available</span>
        )
      )}
      {renderItem("Identity", <VerificationStatus label=" " status={customer.identityVerificationStatus?.replace('identity_verification_', '')}/>)}

      {renderItem(
        "Pending customer Action for",
        enrollment.earliestPendingCustomerActionTimestamp || "NA"
      )}
      <InlineEditable
        label="Note"
        value={enrollment.note}
        ListItemComponent={ListItem}
        onCommit={(note) =>
          callEnrollmentApi(api.updateReportingAccountEnrollment, { note })
        }
      />
    </AlignedListContainer>
  );
};
