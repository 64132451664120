import Link from "@material-ui/core/Link";
import React from "react";

export default function SafeExternalLink({
  href,
  className,
  style,
  title,
  children,
  component: Component,
}) {
  Component = Component || Link;
  return (
    <Component
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
      style={style}
      title={title}
    >
      {children}
    </Component>
  );
}
