import "typeface-montserrat";

import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#ff83c8",
      main: "#E6007E",
    },
    secondary: {
      lighter: "#d2c7ff",
      light: "#9e84ff",
      main: "#3D07FF",
    },
    error: {
      main: "rgb(222,30,13)",
    },
    warning: {
      main: "rgb(238,125,120)",
      lighter: "rgb(238, 192, 120)",
    },
    success: {
      main: "rgb(39,174,96)",
    },
    info: {
      main: "rgb(169,169,169)",
    },
    muted: {
      main: "rgb(169,169,169)",
      dark: "rgb(134,134,134)",
    },
    light: {
      main: "rgb(255,255,255)",
    },
    dark: {
      main: "rgb(0,0,0)",
    },
  },
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
    fontSizeTable: "16px",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": "Montserrat",
      },
    },
    MuiTimelineItem: {
      missingOppositeContent: {
        "&:before": {
          display: "none",
        },
      },
    },
  },
});

export default theme;
