/**
 * Use
 * import { GutterlessListItem as ListItem } from "../components/layouts";
 * to switch between gutters and no gutters easily.
 */

import { List, ListItem, ListItemText } from "@material-ui/core";

import Link from "./Link";
import React from "react";

export function GutterlessListItem(props) {
  return <ListItem disableGutters {...props} />;
}

function TextListItem({ children, ...rest }) {
  return (
    <ListItem {...rest}>
      <ListItemText>{children}</ListItemText>
    </ListItem>
  );
}

export const BaseTextListItem = TextListItem;

export function TextList({ items }) {
  return (
    <List dense disablePadding>
      {items.map((t) => (
        <TextListItem key={t}>{t}</TextListItem>
      ))}
    </List>
  );
}

export function CustomerLinkListItem({ customer, label, value, ...rest }) {
  return (
    <ListItem {...rest}>
      <ListItemText>
        {label || "Customer"}
        {": "}
        <Link to={`/customers/${customer?.id}`}>
          {value || `${customer?.name} (${customer?.email})`}
        </Link>
      </ListItemText>
    </ListItem>
  );
}

export const PartnerLink = ({ id, displayText, ...rest }) => {
  return (
    <Link {...rest} to={`/partners/${id}`}>
      {displayText || id}
    </Link>
  );
};

export function PartnerLinkListItem({ partner, label, ...rest }) {
  return (
    <ListItem {...rest}>
      <ListItemText>
        {label || "Partner"}
        {": "}
        <Link to={`/partners/${partner?.id}`}>
          {partner?.name} ({partner?.email})
        </Link>
      </ListItemText>
    </ListItem>
  );
}
