import React from "react";
import Time, { Period } from "../../lithic/Moment";

import { Button, Menu, MenuItem } from "@material-ui/core";
import { Select, FormControl } from "@material-ui/core";
import { MoreHorizOutlined } from "@material-ui/icons";
import api from "../../api";
import SimpleTable from "../../components/SimpleTable";
import { toast } from "react-toastify";
import SubscriptionStatus from "../../components/SubscriptionStatus";

export const SubscriptionsSection = ({ subscriptions, customerId, updateSubscription }) => {
  return (
    <SimpleTable
      truncated
      tableProps={{ size: "small" }}
      headers={[
        "Status",
        "Period",
        "Auto Renew",
        "Created At",
        "Actions",
      ]}
      rows={subscriptions}
      keyRowAttr="id"
      toCells={(row) => {
        return [
          <SubscriptionStatus key={row.id} status={row.status} />,
          <Period
            value={{start: row.startedAt, end: row.endedAt}}
            format={"MMM DD, YYYY"}
            d="(unset)"
            key={3}
          />,
          row.autoRenew ? "✔️" : "✖️",
          <Time key={2} format={"MMM DD, YYYY HH:mm:ss"}>
            {row.createdAt}
          </Time>,
          <ActionsButton
            key={4}
            subscription={row}
            customerId={customerId}
            updateSubscription={updateSubscription}
          />
        ];
      }}
    />
  );
};

export const ActionsButton = ({
  subscription,
  customerId,
  updateSubscription,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [renewalPeriod, setRenewalPeriod] = React.useState("1");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hadnleRenewalPeriodSelect = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setRenewalPeriod(event.target.value);
    handleActionClick("renew", event.target.value);
  };

  const handleActionClick = async (action, value = null) => {
    if (!subscription.id || !customerId) {
      return;
    }
    try {
      let updatedSubscription;

      switch (action) {
        case "cancel":
          updatedSubscription = await api.cancelCustomerSubscription({
            subscriptionId: subscription.id,
            customerId: customerId,
          });
          break;
        case "enableAutoRenew":
          updatedSubscription = await api.updateCustomerSubscription({
            subscriptionId: subscription.id,
            customerId: customerId,
            autoRenew: true,
          });
          break;
        case "disableAutoRenew":
          updatedSubscription = await api.updateCustomerSubscription({
            subscriptionId: subscription.id,
            customerId: customerId,
            autoRenew: false,
          });
          break;
        case "renew":
          updatedSubscription = await api.renewCustomerSubscription({
            customerId: customerId,
            renewalPeriod: parseInt(value),
          });
          break;
        default:
          break;
      }

      if (updatedSubscription) {
        updateSubscription(updatedSubscription.data);
        toast.success("Subscription has successfully updated");
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizOutlined />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleActionClick("cancel")}>
          Cancel
        </MenuItem>
        {subscription.autoRenew ? (
          <MenuItem onClick={() => handleActionClick("disableAutoRenew")}>
            Turn off auto renew
          </MenuItem>
        ) : (
          <MenuItem onClick={() => handleActionClick("enableAutoRenew")}>
            Turn on auto renew
          </MenuItem>
        )}
        <MenuItem onClick={() => handleActionClick("renew")}>
          Renew subscription for
          <FormControl style={{ marginLeft: "10px" }}>
            <Select
              value={renewalPeriod}
              onChange={(event) => hadnleRenewalPeriodSelect(event)}
            >
              <MenuItem value={1}>1 month</MenuItem>
              <MenuItem value={2}>2 months</MenuItem>
              <MenuItem value={3}>3 months</MenuItem>
              <MenuItem value={6}>6 months</MenuItem>
              <MenuItem value={12}>1 year</MenuItem>
            </Select>
          </FormControl>
        </MenuItem>
      </Menu>
    </>
  );
};
