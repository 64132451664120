import DropZone from "./DropZone";
import ListItem from "@material-ui/core/ListItem";
import React from "react";
import api from "../api";
import { makeStyles } from "@material-ui/core/styles";

export default function AssetUpload({ onAssetDropped, disableDrop, dropzoneLabel }) {
  const classes = useStyles();

  return (
    <ListItem className={classes.subitemList}>
      <DropZone
        disabled={disableDrop}
        onFilesDropped={onAssetDropped}
        dropzoneLabel={dropzoneLabel}
      />
    </ListItem>
  );
}

const useStyles = makeStyles((theme) => ({
  subitemList: {
    flexDirection: "column",
    alignItems: "start",
    padding: 0,
    width: "200px",
  },
  icon: {
    marginLeft: theme.spacing(0.5),
  },
}));

export function createAssetFromFile(files, owner, subject) {
  const file = files[0];
  const queryParams = new URLSearchParams({
    filename: file.name,
    type: file.type,
  }).toString();

  return api
    .presignAsset(queryParams)
    .then(({ data }) => uploadFile(data.url, file))
    .then(({ url }) =>
      api.createAsset({
        ...owner,
        ...subject,
        url,
        metadata: {
          size: file.size,
          original_filename: file.name,
          content_type: file.type,
        },
      })
    );
}

const uploadFile = (uploadUrl, fileObj) => {
  return fetch(uploadUrl, { method: "PUT", body: fileObj, mode: "cors" });
};
