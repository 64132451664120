import {
  Grid,
  IconButton,
  Paper,
  Typography
} from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import { Alert } from "@mui/material";
import { split } from "lodash";
import React from "react";
import DocumentsList, { useDocumentsState } from "../../components/DocumentsList";
import { AlignedListContainer } from "../../components/layouts";
import { PartnerLink } from "../../components/lists";
import MailTo from "../../components/MailTo";
import VerificationStatus from "../../components/VerificationStatus";
import Money from "../../lithic/Money";
import displayPhone from "../../modules/displayPhone";
import oneLineAddress from "../../modules/oneLineAddress";
import { renderItem } from "./ReportingAccountEnrollmentDetailPage";

export const LandlordAndLeaseDetails = ({
  enrollment,
  landlordDetailsToggle,
  leaseDetailsToggle,
  setEnrollment,
  uploadedLeaseVerifyRejectState,
}) => {
  const ecase = enrollment.case;
  const legalEntity = ecase?.legalEntity;
  const landlordStatus = split(ecase.landlordVerificationStatus, "_")[2];
  const leaseStatus = split(ecase.leaseVerificationStatus, "_")[2];
  const partner = enrollment.partner;

  const { createDocumentsFromFiles } = useDocumentsState(
    ecase,
    "reporting_account_enrollment"
  );

  return (
    <Grid container spacing={2}>
      {legalEntity || partner ? (
        <Grid alignItems="stretch" item xs={4}>
          <DetailCard
            title="Landlord"
            onEditClicked={!partner && landlordDetailsToggle.turnOn}
          >
            {partner ? (
              <>
                <Alert icon={<ErrorOutline />}>
                  This landlord is from the partner portal
                </Alert>
                {renderItem("Id", <PartnerLink id={partner.id} />)}
                {renderItem("Name", partner.name)}
                {renderItem("Email", partner.email)}
                {renderItem("Service Type", partner.serviceType)}
              </>
            ) : (
              <>
                {renderItem("First Name", legalEntity.contactFirstName)}
                {renderItem("Last Name", legalEntity.contactLastName)}
                {renderItem("Company Name", legalEntity.companyName)}
                {renderItem(
                  "Email",
                  <MailTo key="mto">{legalEntity.email}</MailTo>
                )}
                {renderItem("Phone", displayPhone(legalEntity.phone))}
                {renderItem(
                  "Status",
                  <VerificationStatus status={landlordStatus} label=" " />
                )}
              </>
            )}
          </DetailCard>
        </Grid>
      ) : null}

      <Grid item xs={4}>
        <DetailCard title="Lease" onEditClicked={leaseDetailsToggle.turnOn}>
          {renderItem("Address", ecase.address && oneLineAddress(ecase.address))}
          {renderItem(
            "Monthly rent amount",
            <Money key={2}>{ecase.monthlyRentAmount}</Money>
          )}
          {renderItem("Rent day of month", ecase.monthlyRentDayOfMonth)}
          {renderItem("Move in date", ecase.moveInDate)}
          {renderItem("End Date", ecase.leaseEndDate)}
          {renderItem("Lease month to month", ecase.leaseMonthToMonth ? "✔️" : "✖️")}
          {renderItem("Pending Lease End Date", ecase.pendingLeaseEndDate)}
          {renderItem("Pending Lease M2M", ecase.pendingLeaseMonthToMonth ? "✔️" : "✖️")}
          {renderItem("Moved Out Date", ecase.movedOutDate)}
          {renderItem("Status", <VerificationStatus status={leaseStatus} label=" " />)}
        </DetailCard>
      </Grid>
      <Grid item xs={4}>
        <DetailCard title="Documents">
          <Grid container direction="column">
            <Grid item>
              <DocumentsList
                documents={enrollment.documents}
                onDocumentsDropped={createDocumentsFromFiles}
                disableDrop={!ecase}
              />
            </Grid>
          </Grid>
        </DetailCard>
      </Grid>
    </Grid>
  );
};

const DetailCard = ({ title, children, onEditClicked }) => {
  return (
    <Paper style={{ padding: 8, height: "100%" }}>
      <AlignedListContainer>
        <Grid alignItems="center" container>
          <Grid item xs={20}>
            <Typography gutterBottom variant="h6" color="primary">
              {title}
            </Typography>
          </Grid>
          {onEditClicked ? (
            <Grid item xs={4}>
              <IconButton onClick={onEditClicked}>
                <EditIcon />
              </IconButton>
            </Grid>
          ) : null}
        </Grid>

        {children}
      </AlignedListContainer>
    </Paper>
  );
};
