import DetailSection from "./DetailSection";
import React from "react";
import SafeExternalLink from "../lithic/SafeExternalLink";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";

export default function ExternalLinksList({ links }) {
  const classes = useStyles();
  if (_.isEmpty(links)) {
    return null;
  }
  return (
    <DetailSection mt={2} title="External Links">
      {links.map(({ name, url }) => (
        <div key={url} className={classes.link}>
          <SafeExternalLink href={url}>Open {name}</SafeExternalLink>
        </div>
      ))}
    </DetailSection>
  );
}

const useStyles = makeStyles((theme) => ({
  link: {
    marginBottom: theme.spacing(1),
  },
}));
