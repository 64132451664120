import CheckIcon from "@material-ui/icons/Check";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(1),
  },
  success: {
    color: theme.palette.success.main,
    marginLeft: theme.spacing(1),
  },
}));

export default function YesNoIcon({ yes }) {
  const classes = useStyles();
  if (yes) {
    return <CheckIcon className={classes.success} />;
  }
  return <CloseSharpIcon className={classes.error} />;
}
