import React from "react";

/**
 * Use this when you want to be able to trigger a useEffect.
 */
export default function useDirty() {
  const [i, seti] = React.useState(1);
  const incr = React.useCallback(() => seti(i + 1), [i]);
  return [i, incr];
}
