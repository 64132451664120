import React, { useEffect } from "react";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { Typography } from "@material-ui/core";
import { events } from "../constants";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    height: 350,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

const not = (a, b) =>
  a?.filter(({ name }) => !b?.some(({ name: name1 }) => name1 === name));

const intersection = (a, b) =>
  a?.filter(({ name }) => b?.some(({ name: name1 }) => name === name1));

const union = (a, b) => [...(a ?? []), ...(not(b, a) ?? [])];

export default function TransferList({ data, onSave }) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [options, setOptions] = React.useState([]);
  const [whitelist, setWhitelist] = React.useState(data);

  const reset = () => {
    const options = Object.keys(events).reduce((acc, v) => {
      if (!data?.some(({ name }) => name === v)) {
        acc.push({ name: v });
      }
      return acc;
    }, []);

    setOptions(options);
    setWhitelist([...(data ?? [])]);
    setChecked([]);
  };

  useEffect(reset, [data]);

  const leftChecked = intersection(checked, options);
  const rightChecked = intersection(checked, whitelist);

  const handleToggle = (value) => () => {
    setChecked((prev) => {
      const currentIndex = prev.findIndex((v) => v.name === value.name);
      const ret = [...prev];
      if (currentIndex === -1) {
        ret.push(value);
      } else {
        ret.splice(currentIndex, 1);
      }
      return ret;
    });
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items?.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setWhitelist(whitelist.concat(leftChecked));
    setOptions(not(options, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setOptions(options.concat(rightChecked));
    setWhitelist(not(whitelist, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item>
          <CustomList
            title="Options"
            items={options}
            handleToggle={handleToggle}
            handleToggleAll={handleToggleAll}
            numberOfChecked={numberOfChecked}
            checked={checked}
            setWhitelist={setWhitelist}
          />
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <CustomList
            title="Whitelist"
            items={whitelist}
            handleToggle={handleToggle}
            handleToggleAll={handleToggleAll}
            numberOfChecked={numberOfChecked}
            checked={checked}
            setWhitelist={setWhitelist}
          />
        </Grid>
      </Grid>
      <Button
        color="secondary"
        variant="contained"
        onClick={() => {
          onSave(whitelist);
        }}
      >
        SAVE
      </Button>
      <Button
        style={{
          marginLeft: 10,
        }}
        color="secondary"
        variant="outlined"
        onClick={reset}
      >
        RESET CHANGES
      </Button>
    </>
  );
}

const CustomList = ({
  title,
  items,
  handleToggleAll,
  numberOfChecked,
  handleToggle,
  checked,
  setWhitelist,
}) => {
  const classes = useStyles();

  return (
    <Card>
      <div style={{ padding: "10px 15px", display: "flex", gap: 15, items: "center" }}>
        <Checkbox
          onClick={handleToggleAll(items)}
          checked={numberOfChecked(items) === items?.length && items?.length !== 0}
          indeterminate={
            numberOfChecked(items) !== items?.length && numberOfChecked(items) !== 0
          }
          disabled={items?.length === 0}
          inputProps={{ "aria-label": "all items selected" }}
        />
        <div>
          <Typography variant="body" style={{ fontWeight: 600 }}>
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {`${numberOfChecked(items)}/${items?.length} selected`}
          </Typography>
        </div>

        {title === "Whitelist" ? (
          <Typography
            variant="body"
            style={{ fontWeight: 600, marginLeft: "auto", alignSelf: "center" }}
          >
            Override
          </Typography>
        ) : null}
      </div>

      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items?.map((value) => {
          const labelId = `transfer-list-all-item-${value.name}-label`;

          return (
            <ListItem
              key={
                title === "Whitelist" ? `${value.name}_${value?.override}` : value.name
              }
              role="listitem"
              style={{
                paddingRight: title === "Whitelist" ? "50px" : undefined,
              }}
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked?.findIndex(({ name }) => name === value.name) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={events[value.name] ?? value.name}
                secondary={value.name}
              />
              {title === "Whitelist" ? (
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    checked={value.override}
                    onClick={() => {
                      if (value.override) {
                        setWhitelist((prev) => {
                          const match = prev.findIndex((v) => v.name === value.name);
                          if (match !== -1) {
                            prev[match] = { ...prev[match], override: false };
                          }
                          return [...prev];
                        });
                      } else {
                        setWhitelist((prev) => {
                          const match = prev.findIndex((v) => v.name === value.name);
                          if (match !== -1) {
                            prev[match] = { ...prev[match], override: true };
                          }
                          return [...prev];
                        });
                      }
                    }}
                  />
                </ListItemSecondaryAction>
              ) : null}
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );
};
