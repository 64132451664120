import DetailSection from "../components/DetailSection";
import IconButton from "@material-ui/core/IconButton";
import Link from "./Link";
import React from "react";
import SaveAlt from "@material-ui/icons/SaveAlt";
import SimpleTable from "../components/SimpleTable";
import Time from "../lithic/Moment";
import YesNoIcon from "../components/YesOrNo";
import api from "../api";
import config from "../config";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    scrollbarWidth: "none" /* Firefox */,
    "&::-webkit-scrollbar": {
      display: "none" /* Chrome */,
    },
  },
}));

export default function BankAccounts({ customerId }) {
  const classes = useStyles();
  const [bankAccounts, setBankAccounts] = React.useState([]);

  React.useEffect(() => {
    if (!customerId) return;

    async function fetchData() {
      api
        .getCustomerBankAccounts({ id: customerId })
        .then(api.pick("data.bankAccounts"))
        .then(setBankAccounts);
    }

    fetchData();
  }, [customerId]);

  return (
    <DetailSection title="Bank Accounts">
      <SimpleTable
        className={classes.tableContainer}
        truncated
        tableProps={{ size: "small" }}
        headers={["", "Bank", "Account", "Last4", "Active?", "Created", " "]}
        rows={bankAccounts}
        keyRowAttr="id"
        toCells={(bankAccount) => [
          <Link key={1} to={`/bank-accounts/${bankAccount.id}`}>
            {bankAccount.id}
          </Link>,
          bankAccount.institution?.name,
          bankAccount.name,
          bankAccount.last4,
          <YesNoIcon key={2} yes={bankAccount.active} />,
          <Time key={3} format={"MMM DD, YYYY HH:mm:ss"}>
            {bankAccount.createdAt}
          </Time>,
          <a
            key={4}
            target="_blank"
            rel="noreferrer"
            href={`${config.apiHost}${bankAccount.transactionsDownloadUrl}`}
          >
            <IconButton>
              <SaveAlt color="primary" />
            </IconButton>
          </a>,
        ]}
      />
    </DetailSection>
  );
}
