import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  approved: {
    color: theme.palette.success.main,
  },
  base: {
    fontSize: "0.8rem",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  rejected: {
    color: theme.palette.error.main,
  },
  pending: {
    color: theme.palette.warning.main,
  },
}));

export function RentalVerificationStatus({ status, label }) {
  const classes = useStyles();
  return (
    <span
      className={clsx(classes.base, {
        [classes.pending]: status === "pending_verification",
        [classes.approved]: status === "verified" || status === "pending_confirmation",
        [classes.rejected]: status === "manual_verification_needed",
      })}
    >
      {status.replace(/_/g, " ")}
    </span>
  );
}
