import React, { useEffect } from "react";

import { ChangesRenderer } from "./ChangesRenderer";
import { LinkIconToCustomer } from "./customers/LinkIconToCustomer";
import SimpleTable from "../components/SimpleTable";
import api from "../api";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useState } from "react";

export const AuditLogs = ({ auditableId, auditableType }) => {
  const [auditLogs, setAuditLogs] = useState(null);

  useEffect(() => {
    if (auditableId && auditableType)
      (async () => {
        try {
          const result = await api.getAuditLogs(auditableId, auditableType);
          setAuditLogs(result.data.auditLogs.reverse());
        } catch (err) {
          toast.error(err.message);
        }
      })();
  }, [auditableId, auditableType]);

  if (!auditableId) return <p>Loading...</p>;

  return (
    <SimpleTable
      //   className={className}
      tableProps={{ size: "small" }}
      headers={["Version", "Event", "Changes", "Date", "Changed By"]}
      rows={auditLogs}
      getKey={(row) => row.at}
      toCells={(auditLog) => [
        auditLog.version,
        auditLog.event,
        <ChangesRenderer key={auditLog.id} changes={auditLog.changes} />,
        dayjs(auditLog.createdAt).format("MMM DD, YYYY HH:mm:ss"),
        <LinkIconToCustomer key={auditLog.id} person={auditLog.actor} />,
      ]}
    />
  );
};
