import {
  Avatar,
  Button,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Check as CheckIcon, Close, Pageview, Person } from "@material-ui/icons";

import Collapse from "@mui/material/Collapse";
import InlineEditable from "../components/InlineEditable";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Link from "../components/Link";
import { LinkIconToCustomer } from "../components/customers/LinkIconToCustomer";
import React from "react";
import { RentalVerificationStatus } from "../components/RentalVerificationStatus";
import TruncatedContainer from "./TruncatedContainer";
import WarningIcon from "@mui/icons-material/Warning";
import api from "../api";
import clsx from "clsx";
import dayjs from "dayjs";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";
import { useLoading } from "../hooks/loading";
import { useToastError } from "./FormError";

/**
 * Render a simple table.
 * @param rows Array of rows.
 * @param headers Header names.
 * @param toCells
 * @param keyCellIndex Index of a unique field that can be used as a 'key' in an array of cells.
 *   Defaults to 0 because that's normally the 'id' cell.
 * @param keyRowAttr Attribute of a 'row' object that can be used as a 'key'.
 *   Takes precendence over keyCellIndex.
 * @param tableProps Props passed to the Table component.
 * @param truncated If true, use TruncatedContainer to only render part of the table.
 * @param {string|function} rowClass
 * @param dense
 * @param padFirst By default, first child has no padding.
 *   If true, pad first child, like a default table.
 * @param padLast
 * @param className
 * @constructor
 */
export default function PvsTable({
  className,
  tableProps,
  truncated,
  rentalVerifications,
  refreshPvs,
  setSelectedFileIndex,
  files,
  headers,
  readOnly,
  setTab,
}) {
  const classes = useStyles();

  const tbl = (
    <TableContainer className={clsx(className, classes.tableMinHeight)}>
      <Table {...tableProps}>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell key={header}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rentalVerifications?.length
            ? rentalVerifications?.map((rentalVerification) => {
                return (
                  <RentalVerificationRow
                    key={rentalVerification?.rentalVerification.id}
                    rv={rentalVerification}
                    refreshPvs={refreshPvs}
                    setSelectedFileIndex={setSelectedFileIndex}
                    files={files}
                    readOnly={readOnly}
                    setTab={setTab}
                  />
                );
              })
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
  if (!truncated) {
    return tbl;
  }
  return <TruncatedContainer>{tbl}</TruncatedContainer>;
}

function RentalVerificationRow({
  rv,
  refreshPvs,
  files,
  setSelectedFileIndex,
  readOnly,
  setTab,
}) {
  React.useEffect(() => {
    setRentalVerificationWithTransactions(rv);
  }, [rv]);

  const [rentalVerificationWithTransactions, setRentalVerificationWithTransactions] =
    React.useState(rv);
  const { startLoading, stopLoading } = useLoading();
  const { loading, sources } = rentalVerificationWithTransactions;
  const { rentalVerification } = rentalVerificationWithTransactions;
  const canOpen = sources?.length > 0;
  const [open, setOpen] = React.useState(
    canOpen && rentalVerification.status === "pending_verification" && !readOnly
  );
  const [amount, setAmount] = React.useState(0);
  const [note, setNote] = React.useState("");
  const toastError = useToastError();

  const classes = useStyles();

  const css = clsx({
    [classes.rowClassPending]: rentalVerification.status === "pending_verification",
    [classes.rowClassRejected]:
      rentalVerification.status === "manual_verification_needed",
    [classes.rowClassApproved]:
      rentalVerification.status === "verified" ||
      rentalVerification.status === "pending_confirmation",
  });

  function rvSource(source) {
    let payload;
    switch (source.name) {
      case "transaction":
        payload = {
          source: {
            name: "transaction",
            transactionId: source.transaction.id,
          },
        };
        break;
      case "pms":
        payload = {
          source: {
            name: "pms",
            transactionId: source.transaction.id,
          },
        };
        break;
      case "document":
        payload = {
          source: {
            name: "document",
            documentId: source.document.id,
          },
        };
        break;
      case "portal":
        payload = {
          source: { name: "portal", amount },
        };
        break;
      case "landlord":
        payload = {
          source: { name: "landlord" },
        };
        break;
      case "other":
        payload = {
          source: { name: "other", note },
        };
        break;
      default:
        payload = {};
        break;
    }
    return payload;
  }

  function rejectRentPayment(id) {
    api
      .rejectRentPayment({ id })
      .then(() => {
        refreshPvs();
        setOpen(false);
      })
      .catch(toastError)
      .finally(stopLoading);
  }

  function approveTransaction(rentalVerification, source) {
    const transactionSource = rvSource(source);
    startLoading();
    api
      .verifyRentPayment({ id: rentalVerification.id, ...transactionSource })
      .then(() => {
        refreshPvs();
        setOpen(false);
      })
      .catch(toastError)
      .finally(stopLoading);
  }

  function unlinkTransaction(rentalVerification, source) {
    const transactionSource = rvSource(source);
    startLoading();
    api
      .removeTransactionSource({ id: rentalVerification.id, ...transactionSource })
      .then(refreshPvs)
      .catch(toastError)
      .finally(stopLoading);
  }

  return (
    <React.Fragment>
      <TableRow
        key={rentalVerification.id}
        className={clsx(classes.rentalVerificationRow, css)}
      >
        <TableCell>
          {canOpen && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell key={0}>
          <Link to={`/rental-verifications/${rentalVerification.id}`}>
            {rentalVerification.description}
          </Link>
        </TableCell>
        <TableCell key={1} className={classes.rentalVerificationStatus}>
          <RentalVerificationStatus status={rentalVerification.status} label=" " />
        </TableCell>
        <TableCell key={2}>
          {rentalVerification.monthsDelinquency
            ? `${rentalVerification.monthsDelinquency} month(s)`
            : ""}
        </TableCell>
        <TableCell key={3}>
          {rentalVerification.status !== "pending_verification" ? (
            <LinkIconToCustomer person={rentalVerification.processedBy} />
          ) : null}
        </TableCell>
        <TableCell key={4}>
          {readOnly
            ? null
            : ["pending_verification"].includes(rentalVerification.status) && (
                <Button
                  className={classes.redButton}
                  variant="outlined"
                  onClick={() => {
                    rejectRentPayment(rentalVerification.id);
                  }}
                >
                  <Close />
                </Button>
              )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <TableBody>
                {sources?.map((source) => {
                  const { transaction, document } = source;
                  const file = document?.contentFiles[0];
                  const chipProperties =
                    source.status === "verified"
                      ? { className: classes.approvedSourceChip }
                      : { variant: "outlined" };
                  const docImage = !file ? null : file.mimeType === "application/pdf" ? (
                    <Avatar className={classes.documentIcon}>
                      <Pageview />
                    </Avatar>
                  ) : (
                    <img
                      style={{ width: "120px" }}
                      src={file.presignedInlineUrl}
                      alt={file.name}
                    />
                  );
                  const transactionsCss = clsx({
                    [classes.rowClassRejected]:
                      transaction && transaction?.potentialFraud,
                  });
                  return (
                    <TableRow key={transaction?.id} className={transactionsCss}>
                      <TableCell scope="row">
                        {source.suggestedByCustomer ? <Person /> : null}
                        {transaction && transaction?.potentialFraud ? (
                          <WarningIcon className={classes.errorColor} />
                        ) : null}
                      </TableCell>
                      <TableCell scope="row">
                        {transaction
                          ? transaction?.date
                          : document
                          ? dayjs(document.createdAt).format("YYYY-MM-DD")
                          : null}
                      </TableCell>
                      <TableCell>
                        <Chip size="small" label={source.name} {...chipProperties} />
                      </TableCell>
                      <TableCell scope="row">
                        {source.name === "transaction" && transaction?.bankAccount?.id ? (
                          <Link
                            to={`/bank-accounts/${transaction.bankAccount?.id}`}
                            className={classes.noWrap}
                          >
                            {transaction?.bankAccount?.name}
                          </Link>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {["pms", "transaction"].includes(source.name)
                          ? transaction?.categories.join(", ")
                          : null}
                      </TableCell>
                      <TableCell style={{ width: "100%" }}>
                        {["pms", "transaction"].includes(source.name)
                          ? transaction?.name
                          : null}
                        {source.name === "other" &&
                          (source.status === "verified" || readOnly ? (
                            source.note
                          ) : (
                            <InlineEditable
                              label=""
                              value={note}
                              onCommit={setNote}
                              inputProps={{ style: { width: "300px" } }}
                            />
                          ))}
                        {source.name === "document" ? (
                          <div>
                            {file && !readOnly ? (
                              <Link
                                onClick={() => {
                                  const idx = files?.findIndex((doc) => {
                                    return doc.id === file?.id;
                                  });
                                  setTab(0);
                                  setSelectedFileIndex(idx);
                                }}
                              >
                                {docImage}
                              </Link>
                            ) : (
                              docImage
                            )}
                            <br />
                            {document.description}
                          </div>
                        ) : null}
                      </TableCell>
                      <TableCell className={classes.noWrap}>
                        {source.processedBy ? (
                          <LinkIconToCustomer person={source.processedBy} />
                        ) : null}
                      </TableCell>
                      <TableCell style={{ width: "100%" }}>
                        {source.name === "portal" ? (
                          <>
                            {source.amount ? (
                              <Typography variant="h6">${source.amount} </Typography>
                            ) : null}
                            {source.status === "verified" || readOnly ? null : (
                              <InlineEditable
                                value={amount}
                                displayValue={
                                  <Typography variant="h6">${amount}</Typography>
                                }
                                onCommit={(amount) => {
                                  setAmount(parseFloat(amount));
                                }}
                                inputProps={{
                                  style: {
                                    width: "300px",
                                  },
                                  type: "number",
                                  step: 0.5,
                                }}
                              />
                            )}
                          </>
                        ) : null}
                        {["pms", "transaction"].includes(source.name) ? (
                          <Typography variant="h6">
                            {transaction.amountFormatted}
                          </Typography>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {source.status === "pending" && !readOnly ? (
                          <Button
                            variant="outlined"
                            size="small"
                            disabled={
                              (source.name === "portal" && !amount) ||
                              (source.name === "other" && !note)
                            }
                            onClick={() => {
                              approveTransaction(rentalVerification, source);
                            }}
                            className={classes.greenButton}
                          >
                            <CheckIcon fontSize="small" />
                          </Button>
                        ) : null}
                        {source.status === "verified" && !readOnly ? (
                          <Button
                            variant="outlined"
                            size="small"
                            disabled={loading}
                            onClick={() => unlinkTransaction(rentalVerification, source)}
                            className={classes.redButton}
                          >
                            <Close />
                          </Button>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  errorColor: {
    color: theme.palette.error.main,
  },
  documentIcon: {
    backgroundColor: theme.palette.primary.main,
  },
  nopadFirst: {
    "&:first-child": {
      paddingLeft: 0,
    },
  },
  nopadLast: {
    "&:last-child": {
      paddingRight: 0,
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  rowClassApproved: {
    backgroundColor: lighten(theme.palette.success.light, 0.7),
  },
  rowClassRejected: {
    backgroundColor: lighten(theme.palette.error.light, 0.7),
  },
  rowClassPending: {
    backgroundColor: lighten(theme.palette.warning.light, 0.9),
  },
  textAlignCenter: {
    textAlign: "center",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  tableMinHeight: {
    minHeight: "100px",
  },
  greenButton: {
    borderColor: theme.palette.success.light,
    color: theme.palette.success.light,
  },
  redButton: {
    borderColor: theme.palette.error.light,
    color: theme.palette.error.light,
  },
  approvedSourceChip: {
    backgroundColor: theme.palette.success.light,
    color: "#FFFFFF",
  },
}));
