import { apiCollectionToRowResponse, rowQueryToApiParams } from "../modules/tableQuery";

import { AdminContainer } from "../components/layouts";
import { ListItemText } from "@material-ui/core";
import MaterialTable from "material-table";
import React from "react";
import { StatusLabel } from "../components/StatusLabel";
import TableSelectFilter from "../components/TableSelectFilter";
import Time from "../lithic/Moment";
import api from "../api";
import { renderTableLink } from "../components/renderTableCell";
import tableIcons from "../components/tableIcons";
import { useTheme } from "@material-ui/core/styles";

export default function EnrollmentVerificationsListPage() {
  const tableRef = React.createRef();
  const theme = useTheme();
  const columns = makeColumns(theme);

  return (
    <AdminContainer variant="list">
      <MaterialTable
        title="Enrollment Verifications"
        tableRef={tableRef}
        columns={columns}
        options={{
          emptyRowsWhenPaging: false,
          filtering: true,
          search: false,
          pageSize: 50,
          pageSizeOptions: [50, 100, 200],
          sorting: true,
          thirdSortClick: false,
          filterCellStyle: {
            transform: "scale(0.80)",
            transformOrigin: "left",
            maxWidth: 0,
          },
        }}
        data={(q) => {
          return api
            .listRentalVerifications(rowQueryToApiParams(q))
            .then((resp) => apiCollectionToRowResponse(resp));
        }}
        icons={tableIcons}
      />
    </AdminContainer>
  );
}

function makeColumns(theme) {
  function styleKindOfVerification(rowData) {
    let color;
    let backgroundColor;
    switch (rowData.kind) {
      case "identity":
        color = theme.palette.light.main;
        backgroundColor = theme.palette.warning.lighter;
        break;
      case "lease":
        color = theme.palette.light.main;
        backgroundColor = theme.palette.primary.light;
        break;
      case "portal":
        color = theme.palette.light.main;
        backgroundColor = theme.palette.warning.main;
        break;
      case "landlord":
        color = theme.palette.light.main;
        backgroundColor = theme.palette.secondary.lighter;
        break;
      default:
        color = theme.palette.dark.main;
        backgroundColor = theme.palette.info.main;
        break;
    }
    return { color, backgroundColor, padding: theme.spacing(0.5), borderRadius: 100 };
  }
  return [
    {
      title: "Id",
      field: "id",
      defaultSort: "desc",
      filtering: false,
      width: 20,
      render: renderTableLink({ url: "/enrollment-verifications/", body: "id" }),
    },
    {
      title: "Name",
      field: "customer_name",
      sorting: false,
      filtering: false,
      render: renderTableLink({
        url: "/reporting-account-enrollments/",
        id: "enrollmentId",
        body: "customer.name",
      }),
    },
    {
      title: "Address",
      field: "one_line_address",
      filtering: false,
      sorting: false,
      render: (rowData) => rowData.oneLineAddress,
    },
    {
      title: "Verification type",
      field: "kind",
      filterComponent: (props) => (
        <TableSelectFilter
          {...props}
          paramKey="kind"
          onlyOneSelection={false}
          lookup={{
            identity: "identity",
            lease: "lease",
            landlord: "landlord",
            portal: "portal",
          }}
        />
      ),
      defaultFilter: ["identity", "lease", "landlord"],
      render: renderTableLink({
        url: "/enrollment-verifications/",
        body: "kind",
        style: styleKindOfVerification,
      }),
    },
    {
      title: "Progress",
      field: "progress",
      filtering: false,
      sorting: false,
      render: (rowData) => (
        <ListItemText>
          {rowData.progress?.processed} / {rowData.progress?.total}
        </ListItemText>
      ),
    },
    {
      title: "Status",
      field: "status",
      filtering: true,
      sorting: false,
      filterComponent: (props) => (
        <TableSelectFilter
          {...props}
          paramKey="status"
          onlyOneSelection={true}
          lookup={{
            pending: "pending",
            approved: "approved",
            rejected: "rejected",
            canceled: "canceled",
          }}
        />
      ),
      defaultFilter: ["pending"],
      render: (verification) => (
        <StatusLabel key={verification.id} status={verification.status} label=" " />
      ),
    },
    {
      title: "Updated",
      field: "updated_at",
      filtering: false,
      sorting: false,
      render: (rowData) => <Time formatExact="MMM DD, YYYY">{rowData.updatedAt}</Time>,
    },
    {
      title: "Created",
      field: "created_at",
      filtering: false,
      sorting: false,
      render: (rowData) => <Time formatExact="MMM DD, YYYY">{rowData.createdAt}</Time>,
    },
  ];
}
