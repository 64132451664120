import { AdminContainer, AlignedListContainer } from "../components/layouts";
import {
  BaseTextListItem,
  CustomerLinkListItem,
  GutterlessListItem as ListItem,
} from "../components/lists";

import AccountBalancesTable from "../components/AccountBalancesTable";
import AdminActionLinks from "../components/AdminActionLinks";
import ExternalLinksList from "../components/ExternalLinksList";
import HiddenCopyInput from "../components/HiddenCopyInput";
import LegalEntityListItems from "../components/LegalEntityListItems";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import Time from "../lithic/Moment";
import api from "../api";
import { makeStyles } from "@material-ui/core/styles";
import useDirty from "../state/useDirty";
import { useParams } from "react-router";

function TextListItem(props) {
  return <BaseTextListItem disableGutters {...props} />;
}

export default function BankAccountDetailPage() {
  const { id } = useParams();
  const classes = useStyles();
  const [bankAccount, setBankAccount] = React.useState({ display: {} });
  const [dirty, setDirty] = useDirty();

  React.useEffect(() => {
    api.getBankAccount({ id }).then(api.pickData).then(setBankAccount);
  }, [id, dirty]);

  return (
    <AdminContainer variant="detail-wide" customer={bankAccount.customer}>
      <AlignedListContainer maxWidth="xl">
        <ListItem>
          <ListItemText>Bank Account Id: {bankAccount.id}</ListItemText>
        </ListItem>
        {bankAccount.customer ? (
          <CustomerLinkListItem customer={bankAccount.customer} disableGutters />
        ) : (
          <LegalEntityListItems legalEntity={bankAccount.legalEntity} disableGutters />
        )}
        <TextListItem>
          Created: <Time format={"MMM DD, YYYY HH:mm:ss"}>{bankAccount.createdAt}</Time>
        </TextListItem>
        {bankAccount.softDeletedAt && (
          <TextListItem>
            Deleted At:{" "}
            <Time format={"MMM DD, YYYY HH:mm:ss"}>{bankAccount.softDeletedAt}</Time>{" "}
            <strong>(cannot be used for payments)</strong>
          </TextListItem>
        )}
        <TextListItem>Name: {bankAccount.name}</TextListItem>
        <TextListItem>
          Account Type: {bankAccount.accountType}/{bankAccount.accountSubtype}
        </TextListItem>
        <TextListItem>Institution: {bankAccount.display.institutionName}</TextListItem>
        <TextListItem>Routing: {bankAccount.routingNumber}</TextListItem>
        <TextListItem>
          Account:{" "}
          <HiddenCopyInput
            value={bankAccount.accountNumber}
            disableUnderline
            inForm
            formClass={classes.inlineInput}
          />
        </TextListItem>
        <TextListItem>Plaid Account ID: {bankAccount.plaidAccountId}</TextListItem>
        <TextListItem>
          Plaid Access Token:{" "}
          <HiddenCopyInput
            value={bankAccount.plaidAccessToken}
            disableUnderline
            inForm
            formClass={classes.inlineInput}
          />
        </TextListItem>
        {bankAccount.plaidItemDetail?.error && (
          <TextListItem>
            <span className={classes.textAlert}>Plaid Error:</span>{" "}
            {renderPlaidError(bankAccount.plaidItemDetail.error)}
          </TextListItem>
        )}
        <AccountBalancesTable
          balances={bankAccount.allBalances}
          highlightIf={(row) => row.modelInstance?.id === bankAccount.id}
          onRefreshNeeded={setDirty}
        />
        <ExternalLinksList links={bankAccount.externalLinks} />
        <AdminActionLinks
          links={bankAccount.adminActionLinks}
          onActionSuccess={(resp) => setBankAccount(resp.data)}
        />
      </AlignedListContainer>
    </AdminContainer>
  );
}

function renderPlaidError(err) {
  return `${err.errorType}/${err.errorCode} ${err.errorMessage}`;
}

const useStyles = makeStyles((theme) => ({
  inlineInput: {
    marginLeft: theme.spacing(2),
    minWidth: 170,
  },
  textAlert: {
    color: theme.palette.error.main,
    fontWeight: "bold",
  },
}));
