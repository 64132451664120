import _ from "lodash";
import humps from "humps";

export function rowQueryToApiParams(q, withSorts) {
  const { filters, orderBy, orderDirection, page, pageSize, search } = q;
  const ourFilters = _.chain(filters)
    .map((f) => {
      return {
        name: f.column.filterName || f.column.field,
        operator: f.operator,
        values: f.value,
      };
    })
    .reject(({ values }) => _.isEmpty(values))
    .value();

  return {
    page: page + 1,
    perPage: pageSize,
    search,
    sorts:
      withSorts && humps.decamelize(_.get(orderBy, "field"))
        ? [
            {
              [humps.decamelize(humps.decamelize(_.get(orderBy, "field")))]:
                orderDirection,
            },
          ]
        : [],
    orderBy: humps.decamelize(_.get(orderBy, "field")),
    orderDirection: orderDirection || "asc",
    filters: ourFilters,
  };
}

export function apiCollectionToRowResponse(resp, mapData) {
  mapData = mapData || _.identity;
  const { items, currentPage, totalCount } = resp.data;
  return {
    data: items.map(({ id, ...rest }) =>
      mapData({
        key: id,
        id,
        ...rest,
      })
    ),
    page: currentPage - 1,
    totalCount,
  };
}
