import React, { useEffect, useState } from "react";
import { Button, TextField } from "@material-ui/core";
import DetailSection from "../../../components/DetailSection";

export const FontsSlice = ({ partner, onSave }) => {
  const [fontFamily, setFontFamily] = useState(partner.settings?.fontFamily);
  const [fontFamilyLink, setFontFamilyLink] = useState(partner.settings?.fontFamilyLink);

  useEffect(() => {
    if (partner.settings?.fontFamily) {
      setFontFamily(partner.settings.fontFamily);
      if (partner.settings.fontFamilyLink) {
        setFontFamilyLink(partner.settings.fontFamilyLink);
      }
    }
  }, [partner]);

  return (
    <DetailSection title="Fonts">
      <TextField
        label="Font family CSS string"
        placeholder="'Open Sans', sans-serif"
        value={fontFamily}
        onChange={(e) => setFontFamily(e.target.value)}
      />
      <br />

      <TextField
        label="Font family link"
        placeholder="https://fonts.googleapis.com/css2?family=Danfo&display=swap"
        value={fontFamilyLink}
        onChange={(e) => setFontFamilyLink(e.target.value)}
      />
      <br />

      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          onSave({
            fontFamily,
            fontFamilyLink,
          })
        }
      >
        Save
      </Button>
    </DetailSection>
  );
};
