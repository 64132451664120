import { AdminContainer, AlignedListContainer } from "../components/layouts";
import {
  BaseTextListItem,
  CustomerLinkListItem,
  GutterlessListItem as ListItem,
} from "../components/lists";

import AdminActionLinks from "../components/AdminActionLinks";
import Box from "@material-ui/core/Box";
import CopyContentIconButton from "../components/CopyContentIconButton";
import ExternalLinksList from "../components/ExternalLinksList";
import InlineEditable from "../components/InlineEditable";
import LegalEntityListItems from "../components/LegalEntityListItems";
import Link from "../components/Link";
import ListItemText from "@material-ui/core/ListItemText";
import Money from "../lithic/Money";
import React from "react";
import SafeExternalLink from "../lithic/SafeExternalLink";
import SimpleTable from "../components/SimpleTable";
import Time from "../lithic/Moment";
import _ from "lodash";
import api from "../api";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  active: {
    color: theme.palette.success.main,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  iconButton: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  started: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  submitted: {
    color: theme.palette.warning.main,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
}));

function TextListItem(props) {
  return <BaseTextListItem disableGutters {...props} />;
}

export default function AffiliateDetailPage() {
  const { id } = useParams();
  const classes = useStyles();
  const [affiliate, setAffiliate] = React.useState({});

  React.useEffect(() => {
    api.getAffiliate({ id }).then(api.pickData).then(setAffiliate);
  }, [id]);

  function saveAttribute(params) {
    return api
      .updateAffiliate({ id, ...params })
      .then(api.pickData)
      .then(setAffiliate);
  }

  return (
    <AdminContainer variant="detail" customer={affiliate.customer}>
      <AlignedListContainer maxWidth="xl">
        <ListItem>
          <ListItemText>Affiliate Id: {affiliate.id}</ListItemText>
        </ListItem>
        <TextListItem>
          Created: <Time format={"MMM DD, YYYY HH:mm:ss"}>{affiliate.createdAt}</Time>
        </TextListItem>
        <TextListItem>
          Status:{" "}
          <span
            className={clsx({
              [classes.submitted]: affiliate.status === "submitted",
              [classes.approved]: affiliate.status === "approved",
              [classes.started]: affiliate.status === "started",
            })}
          >
            {affiliate.status}
          </span>
        </TextListItem>
        <LegalEntityListItems
          legalEntity={affiliate.legalEntity}
          editable
          onCommit={saveAttribute}
        />
        {affiliate.linkedCustomer && (
          <CustomerLinkListItem
            label="Linked Customer"
            customer={affiliate.linkedCustomer}
            disableGutters
          />
        )}
        <InlineEditable
          label="Code"
          value={affiliate.affiliateCode}
          ListItemComponent={ListItem}
          onCommit={(affiliateCode) => saveAttribute({ affiliateCode })}
        />
        <TextListItem>
          Affiliate Link:{" "}
          <SafeExternalLink href={affiliate.referralLink}>
            {affiliate.referralLink}
          </SafeExternalLink>
          <CopyContentIconButton
            value={affiliate.referralLink}
            className={classes.iconButton}
          />
        </TextListItem>
        <TextListItem>
          Plaid Web Auth Link:{" "}
          <SafeExternalLink href={affiliate.plaidAuthLink}>
            {affiliate.plaidAuthLink}
          </SafeExternalLink>
          <CopyContentIconButton
            value={affiliate.plaidAuthLink}
            className={classes.iconButton}
          />
        </TextListItem>
        <TextListItem>
          Direct ACH Link (unsafe):{" "}
          <SafeExternalLink href={affiliate.achLinkerLink}>
            {affiliate.achLinkerLink}
          </SafeExternalLink>
          <CopyContentIconButton
            value={affiliate.achLinkerLink}
            className={classes.iconButton}
          />
        </TextListItem>
        <InlineEditable
          label="Payout Bank Account"
          value={affiliate.payoutBankAccount?.id || ""}
          displayValue={affiliate.payoutBankAccount?.label || "(not set)"}
          ListItemComponent={ListItem}
          onCommit={(payoutBankAccountId) => saveAttribute({ payoutBankAccountId })}
          inputType="select"
          inputProps={{
            options: (affiliate.bankAccounts || []).map((ba) => ({
              value: ba.id,
              label: ba.label,
            })),
          }}
        />
        <TextListItem>
          Dashboard Invite:{" "}
          <AffiliateDashboardInvite affiliate={affiliate} classes={classes} />
        </TextListItem>
        <InlineEditable
          label="Company URL"
          value={affiliate.companyUrl}
          ListItemComponent={ListItem}
          displayValue={
            <SafeExternalLink href={affiliate.companyUrl}>
              {affiliate.companyUrl}
            </SafeExternalLink>
          }
          onCommit={(companyUrl) => saveAttribute({ companyUrl })}
        />
        <InlineEditable
          label="Referral Compensation"
          value={affiliate.customerReferralRate?.cents}
          ListItemComponent={ListItem}
          displayValue={<Money value={affiliate.customerReferralRate} />}
          onCommit={(cents) =>
            saveAttribute({
              customerReferralRate: _.merge({}, affiliate.customerReferralRate, {
                cents,
              }),
            })
          }
        />
        <ExternalLinksList links={affiliate.externalLinks} />
        <AdminActionLinks
          links={affiliate.adminActionLinks}
          onActionSuccess={(resp) => setAffiliate(resp.data)}
        />
      </AlignedListContainer>
      <Box mt={5}>
        <SimpleTable
          truncated
          tableProps={{ size: "small" }}
          headers={["Payouts", "Closes", "Status", "Total"]}
          rows={affiliate.payouts}
          toCells={(row) => [
            <Link key="pid" to={`/payouts/${row.id}`}>
              {row.id}
            </Link>,

            <Time key={1} format={"MMM DD, YYYY"}>
              {row.period.end}
            </Time>,
            row.status,
            <Money key={3}>{row.total}</Money>,
          ]}
        />
      </Box>
    </AdminContainer>
  );
}

function AffiliateDashboardInvite({ affiliate, classes }) {
  if (affiliate.dashboardInviteClaimed) {
    return "Claimed";
  }
  if (!affiliate.dashboardInviteLink) {
    return "<Missing>";
  }
  return (
    <>
      <SafeExternalLink href={affiliate.dashboardInviteLink}>
        {affiliate.dashboardInviteLink}
      </SafeExternalLink>
      <CopyContentIconButton
        value={affiliate.dashboardInviteLink}
        className={classes.iconButton}
      />
    </>
  );
}
