import React, { Fragment } from "react";
import SimpleTable from "../../components/SimpleTable";
import { StatusLabel } from "../../components/StatusLabel";
import { Button, ListItemText } from "@material-ui/core";
import Time from "../../lithic/Moment";
import { startCase } from "lodash";
import SafeExternalLink from "../../lithic/SafeExternalLink";
import api from "../../api";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";

const ExternalLink = ({ href, key }) => {
  if (!href) {
    return <ListItemText key={key}>--</ListItemText>;
  }

  return (
    <SafeExternalLink href={href}>
      {href.split("/")?.pop() ?? "External Link"}
    </SafeExternalLink>
  );
};

export const ApplicantVerifications = ({ verifications, refetch }) => {
  const history = useHistory();
  const { id } = useParams();
  return (
    <SimpleTable
      headers={[
        "Name",
        "Status",
        "External URL",
        "Vendor",
        "Updated at",
        "Created at",
        "Actions",
      ]}
      rows={verifications}
      onClick={(verification) => {
        history.push(`/applicants/${id}/verifications/${verification.id}`);
      }}
      keyRowAttr="id"
      toCells={(verification) => [
        <ListItemText key={1}>{startCase(verification.name)}</ListItemText>,
        <StatusLabel key={2} status={verification.status} label=" " />,
        <ExternalLink key={3} href={verification.externalUrl} />,
        <ListItemText key={4}>{startCase(verification.vendor)}</ListItemText>,
        <Time key={5} d="(unset)" formatExact={"MMM DD, YYYY HH:mm:ss"}>
          {verification.updatedAt}
        </Time>,
        <Time key={6} d="(unset)" formatExact={"MMM DD, YYYY HH:mm:ss"}>
          {verification.createdAt}
        </Time>,
        <div key={7} className="flex gap-2">
          {verification.name === "verified_income_verification" ? (
            <Button
              variant="contained"
              color="primary"
              className="block"
              disabled={["finished", "finished_with_warnings"].includes(
                verification.status
              )}
              onClick={() => {
                api
                  .bypassVerifiedIncomeVerification({
                    verificationId: verification.id,
                  })
                  .then(() => {
                    toast.success("Bypassed verification");
                    refetch();
                  })
                  .catch((err) => {
                    toast.error(err.message);
                  });
              }}
            >
              Bypass
            </Button>
          ) : (
            <div className="min-w-[85px] h-1 inline-block"></div>
          )}
          <Button
            variant="contained"
            color="primary"
            className="block"
            disabled={verification.status === "pending"}
            onClick={() => {
              api
                .restartVerifiedIncomeVerification({
                  verificationId: verification.id,
                })
                .then(() => {
                  toast.success("Restarted verification back to pending");
                  refetch();
                })
                .catch((err) => {
                  toast.error(err.message);
                });
            }}
          >
            Restart
          </Button>
          ,
          <Button
            variant="contained"
            color="primary"
            className="block"
            onClick={() => {
              history.push(`/applicants/${id}/verifications/${verification.id}`);
            }}
          >
            View
          </Button>
        </div>,
      ]}
    />
  );
};
