import Box from "@material-ui/core/Box";
import React from "react";
import Typography from "@material-ui/core/Typography";

export default function DetailSection({
  mt,
  level = 1,
  title,
  children,
  button,
  ...rest
}) {
  mt = mt === undefined ? 3 : mt;
  return (
    <Box mt={mt} {...rest}>
      <Typography gutterBottom variant={level === 1 ? "h6" : "body1"} color="primary">
        {title}
      </Typography>
      {button && button}
      {children}
    </Box>
  );
}
