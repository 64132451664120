import { CircularProgress, ListItem, ListItemText, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import api from "../../api";
import { AlignedListContainer } from "../../components/layouts";
import PortalLogin from "../../components/PortalLogin";
import { formatMoney } from "../../lithic/Money";

export const EnrollmentDetailView = ({ enrollmentId }) => {
  const [reportingAccountEnrollmentData, setReportingAccountEnrollmentData] = useState(
    null
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .getReportingAccountEnrollment({
        id: enrollmentId,
      })
      .then(api.pickData)
      .then(setReportingAccountEnrollmentData)
      .then(() => setLoading(false))
      .catch(() => console.log("error"));
  }, [enrollmentId]);

  if (loading) return <CircularProgress />;

  if (!reportingAccountEnrollmentData) return <></>;

  const raeCase = reportingAccountEnrollmentData.case;
  const customer = raeCase?.customer;
  const subscribedOngoing = reportingAccountEnrollmentData.subscribedOngoing;

  const renderText = (title, value, { link } = {}) => {
    return (
      <ListItem>
        <ListItemText>
          {title}:{" "}
          {link ? (
            <Link target="_blank" to={{ pathname: link }}>
              {value}
            </Link>
          ) : (
            <strong>{value}</strong>
          )}
        </ListItemText>
      </ListItem>
    );
  };

  return (
    <AlignedListContainer maxWidth="xl">
      <Typography variant="h6" color="primary">
        Customer
      </Typography>
      {renderText("Id", customer.id, { link: `/customers/${customer.id}` })}

      {renderText("First Name", customer.firstName)}
      {renderText("Last Name", customer.lastName)}
      {renderText(
        "Date of Birth",
        customer.dob ? dayjs(customer.dob).format("D MMM YYYY") : "-"
      )}

      {renderText("Email", customer.email)}
      {renderText("Phone", customer.phone)}

      <Typography variant="h6" color="primary">
        Enrollment
      </Typography>
      {renderText("Id", reportingAccountEnrollmentData.id, {
        link: `/reporting-account-enrollments/${reportingAccountEnrollmentData.id}`,
      })}
      {renderText("Status", reportingAccountEnrollmentData.status)}
      {renderText(
        "Report Past Payments",
        `${reportingAccountEnrollmentData.reportPastPayments}`
      )}
      {renderText(
        "Subscribed Ongoing",
        subscribedOngoing
          ? `${dayjs(subscribedOngoing.start).format("D MMM YYYY")} - ${dayjs(
              subscribedOngoing.end
            ).format("D MMM YYYY")}`
          : "-"
      )}
      {renderText(
        "Enrollment Date",
        reportingAccountEnrollmentData?.createdAt
          ? `${dayjs(reportingAccountEnrollmentData?.createdAt).format("D MMM YYYY")}`
          : "-"
      )}
      {renderText("Intercom Link", customer.intercomUrl ? customer.intercomUrl : "-", {
        link: customer.intercomUrl,
      })}

      <Typography variant="h6" color="primary">
        Lease
      </Typography>
      {renderText(
        "Address",
        raeCase.address &&
          `${raeCase.address.address1} ${raeCase.address.address2}\n${raeCase.address.city} ${raeCase.address.country} \n${raeCase.address.postalCode} ${raeCase.address.stateOrProvince}`
      )}
      {renderText(
        "Rental Amount",
        raeCase?.monthlyRentAmount ? formatMoney(raeCase?.monthlyRentAmount) : "-"
      )}
      {renderText(
        "Move in date",
        raeCase?.moveInDate ? `${dayjs(raeCase?.moveInDate).format("D MMM YYYY")}` : "-"
      )}

      <Typography variant="h6" color="primary">
        Landlord
      </Typography>
      {raeCase.legalEntity?.type === "landlord" && renderText(
        "Landlord",
        `${raeCase.legalEntity?.contactFirstName} ${raeCase.legalEntity?.contactLastName}`
      )}
      {raeCase.legalEntity?.type === "company" && renderText(
        "Company",
        raeCase.legalEntity?.companyName
      )}
      {renderText(
        "Email",
        raeCase.legalEntity?.email
      )}
      {renderText(
        "Phone",
        raeCase.legalEntity?.phone
      )}

      <PortalLogin portalAuthCredential={raeCase.portalAuthCredential} />
    </AlignedListContainer>
  );
};
