import Button from "@material-ui/core/Button";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { GutterlessListItem as ListItem } from "../components/lists";
import React from "react";
import VerificationStatus from "./VerificationStatus";
import { makeStyles } from "@material-ui/core/styles";
import { useError } from "./FormError";
import useToggle from "../state/useToggle";

const useStyles = makeStyles((theme) => ({
  rejectButton: { marginLeft: theme.spacing(1) },
  actionButtonContainer: { marginTop: theme.spacing(1) },
}));

export function useVerifyRejectState() {
  const approveToggle = useToggle();
  const rejectToggle = useToggle();
  const [approveError, setApproveError] = useError();
  const [rejectError, setRejectError] = useError();
  return {
    approveToggle,
    rejectToggle,
    approveError,
    setApproveError,
    rejectError,
    setRejectError,
  };
}

export default function VerifyReject({
  onConfirmApprove,
  onConfirmReject,
  confirmationText,
  rejectionText,
  title,
  status,
  caseStatus,
  approveRejectState,
}) {
  const classes = useStyles();
  if (caseStatus === "closed") {
    return null;
  }

  const {
    approveToggle,
    rejectToggle,
    approveError,
    setApproveError,
    rejectError,
    setRejectError,
  } = approveRejectState;

  const approveText = `Are you sure you want to verify ${title} details?`;
  const rejectText =
    "Rejecting verification will display an issue card for this customer. Is this what you want to do?";

  return (
    <>
      <VerificationStatus label="Status: " status={status} />
      <ListItem className={classes.actionButtonContainer}>
        {status !== "rejected" && (
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              setRejectError("");
              rejectToggle.turnOn();
            }}
            className={status !== "verified" ? classes.rejectButton : ""}
          >
            {/*Shorten the reject button if both are showing*/}
            Reject{status !== "verified" ? "" : ` ${title}`}
          </Button>
        )}
      </ListItem>
      <ConfirmationDialog
        open={approveToggle.on}
        onCancel={approveToggle.turnOff}
        onConfirm={() => onConfirmApprove()}
        title={confirmationText ?? approveText}
        error={approveError}
        resetError={setApproveError}
        cancelProps={{ children: "Go back" }}
        confirmProps={{ children: "Yes, approve" }}
      />
      <ConfirmationDialog
        open={rejectToggle.on}
        onCancel={rejectToggle.turnOff}
        onConfirm={() => onConfirmReject()}
        title={rejectionText ?? rejectText}
        error={rejectError}
        resetError={setRejectError}
        cancelProps={{ children: "Go back" }}
        confirmProps={{ children: "Yes, reject" }}
      />
    </>
  );
}
