import { Button, lighten, makeStyles } from "@material-ui/core";

import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import clsx from "clsx";
import { useState } from "react";

export const ChangesRenderer = ({ changes }) => {
  const classes = useStyles();

  const [isExpanded, setIsExpanded] = useState(false);

  function formatValue(value) {
    if (typeof value === "object") {
      return JSON.stringify(value);
    } else {
      return value.toString();
    }
  }

  return (
    <div>
      <TableContainer>
        <Table size={"small"}>
          <TableBody>
            {Object.keys(changes)
              .slice(0, isExpanded ? undefined : 3)
              .map((changeKey) => {
                const changeObject = changes[changeKey];
                const oldValue = Array.isArray(changeObject)
                  ? changeObject[0]
                  : undefined;
                const currentValue = Array.isArray(changeObject)
                  ? changeObject[1]
                  : changeObject;

                return (
                  <TableRow key={changeKey} className={classes.tableRow}>
                    <TableCell
                      key={0}
                      className={clsx(classes.tableCell, classes.changeKeyText)}
                    >
                      {changeKey}
                    </TableCell>
                    <TableCell
                      key={1}
                      className={clsx(classes.tableCell, classes.beforeContainer)}
                    >
                      {oldValue === null ? "-" : formatValue(oldValue)}
                    </TableCell>
                    <TableCell
                      key={2}
                      className={clsx(classes.tableCell, classes.afterContainer)}
                    >
                      {currentValue === null ? "-" : formatValue(currentValue)}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      {Object.keys(changes).length > 3 && (
        <Button variant="text" onClick={() => setIsExpanded((prev) => !prev)}>
          View {isExpanded ? "less" : "more"}
        </Button>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  tableCell: {
    border: "none",
  },
  inactive: {
    color: theme.palette.muted.main,
  },
  changeKeyText: {
    textAlign: "left",
  },
  beforeContainer: {
    flex: 1,
    padding: 8,
    margin: 4,
    backgroundColor: lighten(theme.palette.error.light, 0.8),
    width: "40%",
  },
  afterContainer: {
    flex: 1,
    padding: 8,
    margin: 4,
    backgroundColor: lighten(theme.palette.success.light, 0.8),
    width: "40%",
  },
  changeContainer: {
    display: "flex",
    flexDirection: "row",
  },
}));
