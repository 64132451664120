import React from "react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { StatusLabel } from "../../components/StatusLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import api from "../../api";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Typography } from "@material-ui/core";
import Time from "../../lithic/Moment";
import clsx from "clsx";
import { lighten } from "@material-ui/core/styles/colorManipulator";

export const RentalPayments = ({ enrollmentId }) => {
  const [bankTransactions, setBankTransactions] = useState(null);
  const [loading, setLoading] = useState(false);
  const headers = ["Month", "Amount", "Categories", "Transaction name", "Status", "Date"];

  useEffect(() => {
    if (!enrollmentId) return;

    async function fetchData() {
      setLoading(true);

      api
        .getBankTransactions({ id: enrollmentId })
        .then(api.pickData)
        .then((data) => setBankTransactions(data.bankTransactions))
        .catch((error) => setBankTransactions([]));

      setLoading(false);
    }

    fetchData();
  }, [enrollmentId]);

  if (loading) return <CircularProgress />;

  if (bankTransactions?.length === 0)
    return <Typography>No approved rental payments</Typography>;

  if (!bankTransactions) return <></>;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((h) => (
              <TableCell key={h}>{h}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {bankTransactions?.map((bankTransaction) => {
            return (
              <BankTransactionRow
                key={bankTransaction.transactionId}
                bankTransaction={bankTransaction}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function BankTransactionRow({ bankTransaction }) {
  const classes = useStyles();

  const css = clsx({
    [classes.rowClassPending]: bankTransaction.status === "pending",
    [classes.rowClassApproved]: bankTransaction.status === "approved",
  });

  return (
    <React.Fragment>
      <TableRow key={bankTransaction.id} className={css}>
        <TableCell key={0} className={classes.noWrap}>
          {dayjs(bankTransaction.month).format("MMM YYYY")}
        </TableCell>
        <TableCell key={1}>
          <Typography variant="h6">{bankTransaction.amountFormatted}</Typography>
        </TableCell>
        <TableCell key={2}>{bankTransaction.categories.join(" ")}</TableCell>
        <TableCell key={3}>{bankTransaction.name}</TableCell>
        <TableCell key={4}>
          <StatusLabel status={bankTransaction.status} label=" " />
        </TableCell>
        <TableCell key={5} className={classes.noWrap}>
          <Time key={1} format={"MMM DD, YYYY"}>
            {bankTransaction.date}
          </Time>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  rowClassApproved: {
    backgroundColor: lighten(theme.palette.success.light, 0.7),
  },
  rowClassPending: {
    backgroundColor: lighten(theme.palette.warning.light, 0.9),
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
}));
