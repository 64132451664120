import { DateTimePicker } from "@material-ui/pickers";
import React from "react";
import clsx from "clsx";
import dayjs from "dayjs";
import { makeStyles } from "@material-ui/core/styles";

export default function DateTimeRangePicker({ value, onChange, className, pickerProps }) {
  const { start, end } = value;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <div className={classes.label}>Start:</div>
        <DateTimePicker
          value={start}
          onChange={(value) => onChange({ start: value, end: dayjs(end) })}
          {...pickerProps}
        />
      </div>
      <div className={classes.row}>
        <div className={classes.label}>End:</div>
        <DateTimePicker
          value={end}
          onChange={(value) => onChange({ start: dayjs(start), end: value })}
          {...pickerProps}
        />
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    marginRight: theme.spacing(2),
    width: "2.5rem",
  },
}));
