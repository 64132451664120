import Link from "./Link";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import oneLineAddress from "../modules/oneLineAddress";

export default function PaymentInstrumentListItems({ instrument }) {
  return (
    <React.Fragment>
      <ListItem>
        <ListItemText inset>
          {instrument.adminLink ? (
            <Link to={instrument.adminLink}>Id: {instrument.id}</Link>
          ) : (
            `Id: ${instrument.id}`
          )}
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText inset>Type: {instrument.paymentMethodType}</ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText inset>Name: {instrument.display.name}</ListItemText>
      </ListItem>
      {instrument.display.institutionName && (
        <ListItem>
          <ListItemText inset>
            Institution: {instrument.display.institutionName}
          </ListItemText>
        </ListItem>
      )}
      {instrument.display.last4 && (
        <ListItem>
          <ListItemText inset>
            Last 4 of Account #: {instrument.display.last4}
          </ListItemText>
        </ListItem>
      )}
      {instrument.display.address && (
        <ListItem>
          <ListItemText inset>
            Address: {oneLineAddress(instrument.display.address)} (Id:{" "}
            {instrument.display.address.id})
          </ListItemText>
        </ListItem>
      )}
    </React.Fragment>
  );
}
