import Button from "@material-ui/core/Button";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { GutterlessListItem as ListItem } from "../components/lists";
import React from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: { marginRight: theme.spacing(1) },
}));

export default function ApproveRejectEnrollment({
  enrollment,
  toggle,
  title,
  error,
  resetError,
  onConfirmApprove,
  onConfirmReject,
  onConfirmReconsider,
  onConfirmCaseClose,
}) {
  const classes = useStyles();

  const [message, setMessage] = React.useState("");
  const [activeConfirm, setActiveConfirm] = React.useState("");
  const activeConfirmFunc =
    {
      approve: onConfirmApprove,
      reject: onConfirmReject,
      reconsider: onConfirmReconsider,
      close: onConfirmCaseClose,
    }[activeConfirm] || _.identity;

  const approveText = `Are you sure you want to approve this ${title} enrollment?`;
  const rejectText = `Are you sure? This will force the customer to create a new enrollment ${title} to retry.`;
  const reconsiderText = `Put this ${title} enrollment back into a 'pending' state so you can re-action to approve or reject.`;

  return (
    <>
      <ListItem>
        {enrollment.canApprove && (
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={() => {
              resetError();
              setMessage(approveText);
              setActiveConfirm("approve");
              toggle.turnOn();
            }}
          >
            Approve
          </Button>
        )}
        {enrollment.canReject && (
          <Button
            color="primary"
            variant="outlined"
            className={classes.button}
            onClick={() => {
              resetError();
              setMessage(rejectText);
              setActiveConfirm("reject");
              toggle.turnOn();
            }}
          >
            Reject
          </Button>
        )}
        {enrollment.canReconsider && (
          <Button
            color="primary"
            variant="outlined"
            className={classes.button}
            onClick={() => {
              resetError();
              setMessage(reconsiderText);
              setActiveConfirm("reconsider");
              toggle.turnOn();
            }}
          >
            Reconsider
          </Button>
        )}
      </ListItem>
      <ConfirmationDialog
        open={toggle.on}
        onCancel={toggle.turnOff}
        onConfirm={() => activeConfirmFunc()}
        title={message}
        error={error}
        resetError={resetError}
        cancelProps={{ children: "Go back" }}
        confirmProps={{ children: "Yes" }}
      />
    </>
  );
}
