import { AdminContainer, AlignedListContainer } from "../../components/layouts";
import { BaseTextListItem, GutterlessListItem as ListItem } from "../../components/lists";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { merge, remove, startCase, camelCase } from "lodash";
import {
  propertiesManagementSystems,
  supportedFeatures,
  availableRenterBenefits,
} from "./constants";

import AddIcon from "@material-ui/icons/Add";
import AdminActionLinks from "../../components/AdminActionLinks";
import { AuditLogs } from "../../components/AuditLogs";
import Box from "@material-ui/core/Box";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import CopyContentIconButton from "../../components/CopyContentIconButton";
import DetailSection from "../../components/DetailSection";
import EditIcon from "@material-ui/icons/Edit";
import ExternalLinksList from "../../components/ExternalLinksList";
import InlineEditable from "../../components/InlineEditable";
import Link from "../../components/Link";
import ListItemText from "@material-ui/core/ListItemText";
import Money from "../../lithic/Money";
import React from "react";
import SafeExternalLink from "../../lithic/SafeExternalLink";
import SimpleTable from "../../components/SimpleTable";
import Switch from "@material-ui/core/Switch";
import Time from "../../lithic/Moment";
import TransferList from "./components/TransferList";
import api from "../../api";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { useError } from "../../components/FormError";
import { useParams } from "react-router";
import useToggle from "../../state/useToggle";
import AssetUpload, { createAssetFromFile } from "../../components/AssetUpload";
import { PartnerColorsSlice } from "./components/PartnerColorsSlice";
import dayjs from "dayjs";
import DatePicker from "../../components/DatePicker";
import AirlineStopsIcon from "@mui/icons-material/AirlineStops";
import { BorderRadiusSlice } from "./components/BorderRadiusSlice";
import { FontsSlice } from "./components/FontsSlice";
import { useUser } from "../../hooks/user";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  active: {
    color: theme.palette.success.main,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  iconButton: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  started: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  submitted: {
    color: theme.palette.warning.main,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  nonClickableLabel: {
    display: "flex",
    gap: 10,
    alignItems: "center",
  },
  logoWrapper: {
    padding: "10px",
    boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
  },
}));

const SERVICE_TYPES = {
  landlord: "Landlord",
  full_service: "Full Service",
};

function TextListItem(props) {
  return <BaseTextListItem disableGutters {...props} />;
}

const verificationNames = [
  "rental_application",
  "identity_verification",
  "verified_income_verification",
  "credit_report",
  "criminal_report",
  "housing_history",
  "eviction_report",
];

export default function PartnerDetailPage({ partnerId }) {
  const { id } = useParams();
  const classes = useStyles();
  const [partner, setPartner] = React.useState({});
  const pricingTierToggle = useToggle(false);
  const [pricingTierError, setPricingTierError] = useError();
  const [selectedPricingTier, setSelectedPricingTier] = React.useState({});
  const [tiers, setTiers] = React.useState([{}]);

  React.useEffect(() => {
    api
      .getPartner({ id: partnerId || id })
      .then(api.pickData)
      .then(setPartner);
  }, [id, partnerId]);

  function saveAttribute(params) {
    return api
      .updatePartner({
        id,
        ...params,
        contactFirstName: partner.contactFirstName || "",
        contactLastName: partner.contactLastName || "",
      })
      .then(api.pickData)
      .then(setPartner);
  }

  const [invoices, setInvoices] = React.useState([]);
  React.useEffect(() => {
    api
      .getPartnerInvoices({ id: partnerId || id })
      .then(api.pick("data.items"))
      .then(setInvoices);
  }, [id, partnerId]);

  const [payouts, setPayouts] = React.useState([]);
  React.useEffect(() => {
    api
      .getPartnerPayouts({ id: partnerId || id })
      .then(api.pick("data.items"))
      .then(setPayouts);
  }, [id, partnerId]);

  const [paymentInstruments, setPaymentInstruments] = React.useState([]);
  const { user } = useUser();
  const [newRole, setNewRole] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [partnerUserModal, setPartnerUserModal] = React.useState(false);
  const [confirmFeatureModalOpen, setConfirmFeatureModalOpen] = React.useState(false);
  const [newFeature, setNewFeature] = React.useState(null);

  React.useEffect(() => {
    api
      .getPartnerPaymentInstruments({ id: partnerId || id })
      .then(api.pick("data.items"))
      .then(setPaymentInstruments);
  }, [id, partnerId]);

  React.useEffect(() => {
    setTiers([...(partner.pricingTiers || []), {}]);
  }, [partner]);

  const [defaultScreeningFees, setDefaultScreeningFees] = React.useState({});
  React.useEffect(() => {
    api
      .getVerificationSchemas()
      .then(api.pickData)
      .then((schemas) => {
        const fees = schemas.reduce(
          (acc, schema) => ({
            ...acc,
            [schema.name]: schema.screeningFee?.cents || 0,
          }),
          {}
        );
        setDefaultScreeningFees(fees);
      });
  }, []);

  const verificationSchemaPrice = (schemaName) => {
    return (
      partner.boomScreenScreeningFees?.[camelCase(schemaName)] ??
      defaultScreeningFees[schemaName] ??
      0
    );
  };

  const setLogo = (files) => {
    const owner = {
      owner_id: partner.id,
      owner_type: "Boom::Partner",
    };

    const subject = {
      subject_id: partner.id,
      subject_type: "Boom::Partner",
    };

    createAssetFromFile(files, owner, subject)
      .then(({ data }) => api.setPartnerLogo(partner.id, { asset_id: data.asset.id }))
      .then(api.pickData)
      .then((partner) => {
        setPartner({ ...partner });
      });
  };

  const handleConfirmFeatureModalOpen = (feature) => {
    setNewFeature(feature);
    setConfirmFeatureModalOpen(true);
  };

  const handleConfirmFeatureModalClose = () => {
    setNewFeature(null);
    setConfirmFeatureModalOpen(false);
  };

  const handleFeatureChange = () => {
    const features = partner.features || [];
    if (features.includes(newFeature)) {
      const updatedFeatures = features.filter((f) => f !== newFeature);
      saveAttribute({ features: updatedFeatures });
    } else {
      features.push(newFeature);
      saveAttribute({ features });
    }
    handleConfirmFeatureModalClose();
  };

  return (
    <AdminContainer variant="detail" customer={partner.customer}>
      <AlignedListContainer maxWidth="xl">
        <ListItem>
          <ListItemText>Partner Id: {partner.id}</ListItemText>
        </ListItem>

        <ListItem>
          <ListItemText>Partner Name: {partner.name}</ListItemText>
        </ListItem>

        <TextListItem>
          Created: <Time format={"MMM DD, YYYY HH:mm:ss"}>{partner.createdAt}</Time>
        </TextListItem>
        <TextListItem>
          Status:{" "}
          <span
            className={clsx({
              [classes.submitted]: partner.status === "submitted",
              [classes.active]: partner.status === "active",
              [classes.started]: partner.status === "started",
            })}
          >
            {partner.status}
          </span>
        </TextListItem>

        <TextListItem>
          Notify partner about started application:{" "}
          <Switch
            checked={Boolean(partner.notifyAboutStartedApplication)}
            color={"primary"}
            onChange={(ev) =>
              saveAttribute({
                settings: {
                  ...partner.settings,
                  notification_settings: {
                    application_started: ev.target.checked,
                  },
                },
              })
            }
          />
        </TextListItem>

        <DetailSection title="Logo">
          <div className={classes.logoWrapper}>
            <div>
              {partner?.logoUrl ? (
                <img style={{ width: "120px" }} src={partner.logoUrl} alt={""} />
              ) : (
                "No logo"
              )}
            </div>
            <div>
              <AssetUpload
                dropzoneLabel={"Drag or Select a logo"}
                onAssetDropped={setLogo}
                disableDrop={!partner?.id}
              />
            </div>
          </div>
        </DetailSection>

        <FontsSlice
          partner={partner}
          onSave={({ fontFamily, fontFamilyLink }) => {
            saveAttribute({
              settings: {
                ...partner.settings,
                fontFamily: fontFamily,
                fontFamilyLink: fontFamilyLink,
              },
            });
          }}
        />

        <BorderRadiusSlice
          partner={partner}
          onSave={(borderRadius) => {
            saveAttribute({
              ...partner,
              settings: {
                ...partner.settings,
                base_border_radius: borderRadius,
              },
            });
          }}
        />

        <PartnerColorsSlice
          partner={partner}
          onSave={(primary) => {
            saveAttribute({
              ...partner,
              settings: {
                ...partner.settings,
                colors: {
                  primary: primary,
                },
              },
            });
          }}
        />

        <ListItem>
          <ListItemText>Total Customers: {partner.totalCustomers}</ListItemText>
        </ListItem>

        <ListItem>
          <ListItemText>Contact First Name: {partner.contactFirstName}</ListItemText>
        </ListItem>

        <ListItem>
          <ListItemText>Contact Last Name: {partner.contactLastName}</ListItemText>
        </ListItem>

        <ListItem>
          <ListItemText>Contact Phone: {partner.contactPhoneNumber}</ListItemText>
        </ListItem>

        <ListItem>
          <ListItemText>Contact Email: {partner.email}</ListItemText>
        </ListItem>

        <TextListItem>
          Plaid Web Auth Link:{" "}
          <SafeExternalLink href={partner.plaidAuthLink}>
            {partner.plaidAuthLink}
          </SafeExternalLink>
          <CopyContentIconButton
            value={partner.plaidAuthLink}
            className={classes.iconButton}
          />
        </TextListItem>
        <TextListItem>
          Direct ACH Link (unsafe):{" "}
          <SafeExternalLink href={partner.achLinkerLink}>
            {partner.achLinkerLink}
          </SafeExternalLink>
          <CopyContentIconButton
            value={partner.achLinkerLink}
            className={classes.iconButton}
          />
        </TextListItem>
        <InlineEditable
          label="Webhook URL"
          value={partner.webhookUrl}
          ListItemComponent={ListItem}
          displayValue={partner.webhookUrl}
          onCommit={(webhookUrl) => saveAttribute({ webhookUrl })}
        />
        <InlineEditable
          label="Invoice Bank Account"
          value={partner.invoiceBankAccount?.id || ""}
          displayValue={partner.invoiceBankAccount?.label || "(not set)"}
          ListItemComponent={ListItem}
          onCommit={(invoiceBankAccountId) => saveAttribute({ invoiceBankAccountId })}
          inputType="select"
          inputProps={{
            options: (partner.bankAccounts || []).map((ba) => ({
              value: ba.id,
              label: ba.label,
            })),
          }}
        />
        <InlineEditable
          label="Payout Bank Account"
          value={partner.payoutBankAccount?.id || ""}
          displayValue={partner.payoutBankAccount?.label || "(not set)"}
          ListItemComponent={ListItem}
          onCommit={(payoutBankAccountId) => saveAttribute({ payoutBankAccountId })}
          inputType="select"
          inputProps={{
            options: (partner.bankAccounts || []).map((ba) => ({
              value: ba.id,
              label: ba.label,
            })),
          }}
        />
        <InlineEditable
          label="Company URL"
          value={partner.url}
          ListItemComponent={ListItem}
          displayValue={
            <SafeExternalLink href={partner.url}>{partner.url}</SafeExternalLink>
          }
          onCommit={(url) => saveAttribute({ url })}
        />
        <InlineEditable
          label="Service Type"
          value={partner.serviceType}
          displayValue={partner.serviceType}
          ListItemComponent={ListItem}
          onCommit={(serviceType) => saveAttribute({ serviceType })}
          inputType="select"
          inputProps={{
            options: ["landlord", "full_service"].map((st) => ({
              value: st,
              label: SERVICE_TYPES[st],
            })),
          }}
        />
        <InlineEditable
          label="Opt out period in days"
          value={partner.optOutPeriodDays}
          displayValue={partner.optOutPeriodDays}
          ListItemComponent={ListItem}
          onCommit={(optOutPeriodDays) => saveAttribute({ optOutPeriodDays })}
        />
        <InlineEditable
          label="Opt in period in days"
          value={partner.optInPeriodDays}
          displayValue={partner.optInPeriodDays}
          ListItemComponent={ListItem}
          onCommit={(optInPeriodDays) => saveAttribute({ optInPeriodDays })}
        />
        <InlineEditable
          label="Verification balance threshold in $"
          value={partner.verificationBalanceThreshold}
          displayValue={partner.verificationBalanceThreshold}
          ListItemComponent={ListItem}
          onCommit={(verificationBalanceThreshold) =>
            saveAttribute({ verificationBalanceThreshold })
          }
        />
        <InlineEditable
          label="Property Management System"
          value={partner.propertyManagementSystem}
          displayValue={startCase(partner.propertyManagementSystem)}
          ListItemComponent={ListItem}
          onCommit={(propertyManagementSystem) =>
            saveAttribute({ propertyManagementSystem })
          }
          inputType="select"
          inputProps={{
            options: ["", ...propertiesManagementSystems].map((value) => ({
              value,
              label: value ? startCase(value) : "-",
            })),
          }}
        />
        <InlineEditable
          label="Plaid bank linking redirect URL"
          value={partner.plaidBankLinkingRedirectUrl}
          ListItemComponent={ListItem}
          displayValue={
            <SafeExternalLink href={partner.plaidBankLinkingRedirectUrl}>
              {partner.plaidBankLinkingRedirectUrl}
            </SafeExternalLink>
          }
          onCommit={(plaidBankLinkingRedirectUrl) =>
            saveAttribute({ plaidBankLinkingRedirectUrl })
          }
        />
        <Box mt={5}>
          <SimpleTable
            truncated
            tableProps={{ size: "small" }}
            headers={[
              "Partner Events",
              "Service",
              "Name",
              "Created",
              "Processed",
              "Message",
            ]}
            rows={partner.events}
            toCells={(row) => [
              row.id,
              row.service,
              row.name,
              <Time key={1} format={"MMM DD, YYYY HH:mm:ss"}>
                {row.createdAt}
              </Time>,
              <Time key={2} format={"MMM DD, YYYY HH:mm:ss"}>
                {row.processedAt}
              </Time>,
              row.message,
            ]}
          />
        </Box>
        <DetailSection title={`Tiers List`}>
          <SimpleTable
            truncated
            tableProps={{ size: "small" }}
            headers={[
              "",
              "Tier",
              "Trial Ended On",
              "Lower Bound",
              "Upper Bound",
              "Enrollment fee",
              "Price per enrollment(Ongoing)",
              "Price per enrollment(Past Reporting)",
            ]}
            rows={tiers}
            keyRowAttr="id"
            toCells={(row) => [
              <PricingTierAction
                key={`pt`}
                onClick={(pt) => {
                  pricingTierToggle.turnOn();
                  setSelectedPricingTier(pt);
                }}
                pricingTier={row}
              />,
              row.name,
              row.trialEndedAt,
              row.lowerBound,
              row.upperBound,
              <Money key={`pre-${row.id}`} value={row.enrollmentFee} />,
              <Money key={`prog-${row.id}`} value={row.pricePerEnrollmentOngoing} />,
              <Money
                key={`prrp-${row.id}`}
                value={row.pricePerEnrollmentPastReporting}
              />,
            ]}
          />
        </DetailSection>

        <DetailSection title={`Users`}>
          <SimpleTable
            truncated
            tableProps={{ size: "small" }}
            headers={["ID", "Name", "Email", "Created At", "Impersonate", "Role", ""]}
            rows={partner.users}
            keyRowAttr="id"
            toCells={(row) => [
              row.id,
              row.name,
              row.email,
              row.createdAt,
              <ImpersonateUserAction
                key={`pt`}
                onClick={(user) => {
                  api
                    .impersonatePartnerUser(partner.id, user)
                    .then((_) =>
                      window.open(
                        process.env.BOOMSCREEN_PORTAL_URL || "https://portal.boompay.app",
                        "_blank"
                      )
                    );
                }}
                user={row}
              />,
              row.role,
              <Button
                variant="contained"
                color="primary"
                key={`action-${row.id}`}
                // disabled if role is not super_admin
                disabled={!user.roles.includes("super_admin")}
                onClick={() => {
                  setNewPassword("");
                  setNewRole(row.role);
                  setPartnerUserModal({ id: row.id, role: row.role });
                }}
              >
                Edit
              </Button>,
            ]}
          />
        </DetailSection>

        <InlineEditable
          label="Monthly Minimum Payment"
          displayValue={<Money value={partner.paymentMonthlyMinimum} />}
          value={(partner.paymentMonthlyMinimum?.cents / 100).toFixed(2)}
          onCommit={(dollars) =>
            saveAttribute({
              paymentMonthlyMinimum: {
                ...partner.paymentMonthlyMinimum,
                cents: dollars * 100,
              },
            })
          }
        />

        <InlineEditable
          label="Payout per Enrollment Past Reporting"
          displayValue={<Money value={partner.payoutPerEnrollmentPastReporting} />}
          value={(partner.payoutPerEnrollmentPastReporting?.cents / 100).toFixed(2)}
          onCommit={(dollars) =>
            saveAttribute({
              payoutPerEnrollmentPastReporting: {
                ...partner.payoutPerEnrollmentPastReporting,
                cents: dollars * 100,
              },
            })
          }
        />

        <InlineEditable
          label="Identification number (equifax)"
          value={partner.identificationNumbers?.equifax}
          ListItemComponent={ListItem}
          onCommit={(value) =>
            saveAttribute({
              identificationNumbers: {
                ...partner.identificationNumbers,
                equifax: value?.trim() || null,
              },
            })
          }
        />

        <InlineEditable
          label="Identification number (experian)"
          value={partner.identificationNumbers?.experian}
          ListItemComponent={ListItem}
          onCommit={(value) =>
            saveAttribute({
              identificationNumbers: {
                ...partner.identificationNumbers,
                experian: value?.trim() || null,
              },
            })
          }
        />

        <InlineEditable
          label="Identification number (transunion)"
          value={partner.identificationNumbers?.transunion}
          ListItemComponent={ListItem}
          onCommit={(value) =>
            saveAttribute({
              identificationNumbers: {
                ...partner.identificationNumbers,
                transunion: value?.trim() || null,
              },
            })
          }
        />

        <DetailSection title={`Features`}>
          <FormGroup>
            {supportedFeatures.map((feature) => (
              <div key={feature} className={classes.nonClickableLabel}>
                {/* Used to be a full clickable line. Now only checkbox */}
                <Checkbox
                  checked={(partner?.features ?? []).includes(feature)}
                  onChange={() => handleConfirmFeatureModalOpen(feature)}
                />
                <Typography variant="body2">{feature}</Typography>
              </div>
            ))}
          </FormGroup>
        </DetailSection>

        <DetailSection title={`Renter Benefits`}>
          <FormGroup>
            {availableRenterBenefits.map((benefit) => (
              <div key={benefit} className={classes.nonClickableLabel}>
                {/* Used to be a full clickable line. Now only checkbox */}
                <Checkbox
                  checked={(partner?.settings?.renterBenefits ?? []).includes(benefit)}
                  onChange={(e) => {
                    const renterBenefits = partner.settings?.renterBenefits || [];
                    if (e.target.checked) {
                      renterBenefits.push(benefit);
                    } else {
                      remove(renterBenefits, (f) => f === benefit);
                    }
                    saveAttribute({
                      settings: {
                        ...partner.settings,
                        renterBenefits,
                      },
                    });
                  }}
                />

                <Typography variant="body2">{benefit}</Typography>
              </div>
            ))}
          </FormGroup>
        </DetailSection>

        <DetailSection title={`Whitelist`}>
          <FormControlLabel
            control={<Switch checked={partner.allowedCustomerEvents !== null} />}
            label={`${
              partner.allowedCustomerEvents === null ? "Enable" : "Disable"
            } event filtering`}
            onClick={(e) => {
              saveAttribute({
                allowed_customer_events:
                  partner.allowedCustomerEvents === null ? [] : null,
              });
            }}
          />

          {partner.allowedCustomerEvents !== null ? (
            <>
              <Typography variant="body2">
                <span style={{ fontWeight: "bold" }}>Note:</span> Empty white list is not
                the same as &quot;Disable event filtering&quot;.
              </Typography>
              <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                {`- Empty white list means no events will be sent to partner's customers.
- Upon clicking on "Disable event filtering" - all events will be sent to partner's customers.
- Non-empty white list - only events in the white list will be sent to partner's customers.
- Overridden means that partner's customer will get a "special version of the event"`}
              </Typography>
              <TransferList
                data={partner.allowedCustomerEvents}
                onSave={(data) => {
                  saveAttribute({
                    allowed_customer_events: data,
                  });
                }}
              />
            </>
          ) : null}
        </DetailSection>

        <ExternalLinksList links={partner.externalLinks} />
        <AdminActionLinks
          links={partner.adminActionLinks}
          onActionSuccess={(resp) => setPartner(resp.data)}
        />
      </AlignedListContainer>
      <Box mt={5}>
        <SimpleTable
          truncated
          tableProps={{ size: "small" }}
          headers={["Instrument", "Name", "Institution", "Last4"]}
          rows={paymentInstruments}
          keyRowAttr="id"
          toCells={(row) => [
            <Link key={1} to={row.adminLink}>
              {startCase(row.paymentMethodType)} ({row.id})
            </Link>,
            <Link key={3} to={row.adminLink}>
              {row.name}
            </Link>,
            row.display.institutionName,
            row.display.last4,
          ]}
        />
      </Box>
      <DetailSection title={`Invoices`}>
        <SimpleTable
          truncated
          tableProps={{ size: "small" }}
          headers={["Invoices", "Status", "Amount", "Begin", "End"]}
          rows={invoices}
          keyRowAttr="id"
          toCells={(row) => [
            <Link key={1} to={`/invoices/${row.id}`}>
              {row.id}
            </Link>,
            <Link key={4} to={`/invoices/${row.id}`}>
              {row.status === "paid" ? "paid" : "unpaid"}
            </Link>,
            <Money key={2} value={row.total} />,
            <Time key={5} format={"MMM DD, YYYY HH:mm:ss"} timezone="Etc/GMT+5">
              {row.period.start}
            </Time>,
            <Time key={6} format={"MMM DD, YYYY HH:mm:ss"} timezone="Etc/GMT+5">
              {row.period.end}
            </Time>,
          ]}
        />
      </DetailSection>

      <DetailSection title={`Payouts`}>
        <SimpleTable
          truncated
          tableProps={{ size: "small" }}
          headers={["ID", "Status", "Amount", "Begin", "End"]}
          rows={payouts}
          keyRowAttr="id"
          toCells={(row) => [
            <Link key={1} to={`/payouts/${row.id}`}>
              {row.id}
            </Link>,
            <Link key={4} to={`/payouts/${row.id}`}>
              {row.status === "settled" ? "paid" : "unpaid"}
            </Link>,
            <Money key={2} value={row.total} />,
            <Time key={5} format={"MMM DD, YYYY HH:mm:ss"} timezone="Etc/GMT+5">
              {row.period.start}
            </Time>,
            <Time key={6} format={"MMM DD, YYYY HH:mm:ss"} timezone="Etc/GMT+5">
              {row.period.end}
            </Time>,
          ]}
        />
      </DetailSection>

      <DetailSection title="Audit Logs">
        <AuditLogs auditableId={partner.id} auditableType="Boom::Partner" />
      </DetailSection>
      <DetailSection title="Pets">
        <InlineEditable
          label="Pets Screening URL"
          value={partner.settings?.petScreeningUrl}
          ListItemComponent={ListItem}
          onCommit={(value) =>
            saveAttribute({
              settings: {
                ...partner.settings,
                petScreeningUrl: value,
              },
            })
          }
        />
      </DetailSection>
      <DetailSection title={`Verification Schema Prices`}>
        <SimpleTable
          tableProps={{ size: "small" }}
          headers={["Verification Schema", "Price"]}
          rows={verificationNames}
          toCells={(row) => [
            startCase(row),
            <InlineEditable
              key={row}
              displayValue={
                <Money
                  value={{
                    cents: verificationSchemaPrice(row),
                  }}
                />
              }
              value={(verificationSchemaPrice(row) / 100).toFixed(2)}
              onCommit={(dollars) => {
                const updatedFees = {
                  ...partner.boomScreenScreeningFees,
                  [camelCase(row)]: dollars * 100,
                };

                saveAttribute({
                  settings: {
                    ...partner.settings,
                    boomScreenScreeningFees: updatedFees,
                  },
                });
              }}
            />,
          ]}
        />
      </DetailSection>

      <PricingTierForm
        toggle={pricingTierToggle}
        pricingTier={selectedPricingTier}
        error={pricingTierError}
        onCommit={(params, doCreate) => {
          const data = {
            ...params,
            partner_id: partner.id,
            tier_id: selectedPricingTier.id,
          };
          if (doCreate) {
            api
              .createPricingTier(data)
              .then(api.pickData)
              .then(setPartner)
              .then(pricingTierToggle.turnOff)
              .catch(setPricingTierError);
          } else {
            api
              .updatePricingTier(data)
              .then(api.pickData)
              .then(setPartner)
              .then(pricingTierToggle.turnOff)
              .catch(setPricingTierError);
          }
        }}
      />

      {/* Update password modal */}
      <ConfirmationDialog
        title="Edit"
        open={Boolean(partnerUserModal)}
        onCancel={() => {
          setPartnerUserModal(false);
          setNewPassword("");
        }}
        onConfirm={() => {
          const payload = {};
          if (newRole !== "owner") {
            payload.role = newRole;
          }

          if (newPassword) {
            payload.password = newPassword;
          }

          api
            .patchPartnerUser(partnerUserModal.id, payload)
            .then((response) => {
              setPartnerUserModal(false);
              toast.success(`User ${response.data.name} updated successfully!`);
            })
            .catch((error) => {
              toast.error(error.message);
            });
        }}
        text="Are you sure?"
        cancelProps={{ children: "Go back" }}
        confirmProps={{
          children: "Update",
          disabled: !newPassword && partnerUserModal.role === newRole,
        }}
      >
        <Box>
          {partnerUserModal.role !== "owner" ? (
            <FormControl fullWidth>
              <TextField
                label="Role"
                type="text"
                variant="outlined"
                onChange={(e) => {
                  setNewRole(e.target.value);
                }}
                defaultValue={partnerUserModal.role}
                select
              >
                <MenuItem key={1} value="admin">
                  Admin
                </MenuItem>
                <MenuItem key={2} value="property_manager">
                  Property manager
                </MenuItem>
              </TextField>
            </FormControl>
          ) : null}
          <FormControl
            margin={partnerUserModal.role !== "owner" ? "normal" : "none"}
            fullWidth
          >
            <TextField
              required
              label="Password"
              type="password"
              variant="outlined"
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
          </FormControl>
        </Box>
      </ConfirmationDialog>

      <ConfirmationDialog
        title="Confirm Feature Change"
        open={confirmFeatureModalOpen}
        onCancel={handleConfirmFeatureModalClose}
        onConfirm={handleFeatureChange}
        text={`Are you sure you want to turn  ${
          (partner?.features ?? []).includes(newFeature) ? "off" : "on"
        } the feature: ${newFeature}?`}
        cancelProps={{ children: "Cancel" }}
        confirmProps={{ children: "Confirm" }}
      />
    </AdminContainer>
  );
}

// function PartnerDashboardInvite({ partner, classes }) {
//   if (partner.dashboardInviteClaimed) {
//     return "Claimed";
//   }
//   if (!partner.dashboardInviteLink) {
//     return "<Missing>";
//   }
//   return (
//     <>
//       <SafeExternalLink href={partner.dashboardInviteLink}>
//         {partner.dashboardInviteLink}
//       </SafeExternalLink>
//       <CopyContentIconButton
//         value={partner.dashboardInviteLink}
//         className={classes.iconButton}
//       />
//     </>
//   );
// }

function PricingTierAction({ pricingTier, onClick }) {
  if (pricingTier?.id) {
    return (
      <>
        <IconButton
          onClick={() => {
            onClick(pricingTier);
          }}
          key={1}
        >
          <EditIcon color={"primary"} />
        </IconButton>
      </>
    );
  }

  return (
    <>
      <IconButton
        onClick={() => {
          onClick({});
        }}
        key={1}
      >
        <AddIcon color={"primary"} />
      </IconButton>
    </>
  );
}

function ImpersonateUserAction({ user, onClick }) {
  if (!user || !user.email.includes("@boompay.app")) return null;

  if (user?.id) {
    return (
      <>
        <IconButton
          onClick={() => {
            onClick(user);
          }}
          key={user.id}
        >
          <AirlineStopsIcon color={"primary"} />
        </IconButton>
      </>
    );
  }
}

function PricingTierForm({ toggle, pricingTier, error, resetError, onCommit }) {
  const [fields, setFields] = React.useState({ ...pricingTier });

  React.useEffect(() => {
    setFields({ ...pricingTier });
  }, [pricingTier]);

  function mergeFields(p) {
    setFields({ ...fields, ...p });
  }

  function handleConfirm() {
    const doCreate = pricingTier?.id ? false : true;
    onCommit(fields, doCreate);
  }

  const valid =
    pricingTier?.id ||
    (fields.lowerBound &&
      fields.upperBound &&
      fields.name &&
      fields.enrollmentFee &&
      fields.pricePerEnrollmentOngoing);

  return (
    <ConfirmationDialog
      title="Edit Pricing Tier"
      open={toggle.on}
      onCancel={toggle.turnOff}
      onConfirm={handleConfirm}
      cancelProps={{ children: "Go back" }}
      confirmProps={{ children: "Save", disabled: !valid }}
      error={error}
      resetError={resetError}
    >
      <FormControl margin="normal" fullWidth>
        <TextField
          required
          label="Name"
          type="text"
          value={fields.name}
          variant="outlined"
          onChange={(e) => mergeFields({ name: e.target.value })}
        />
      </FormControl>

      <FormControl margin="normal" fullWidth>
        <DatePicker
          label="Trial Ended On"
          format="MM/DD/YYYY"
          value={fields.trialEndedAt ? fields.trialEndedAt : null}
          disablePast
          onChange={(value) =>
            mergeFields({ trialEndedAt: dayjs(value).format("YYYY-MM-DD") })
          }
        />
      </FormControl>

      <FormControl margin="normal" fullWidth>
        <TextField
          required
          label="Lower Bound"
          type="number"
          value={fields.lowerBound}
          variant="outlined"
          onChange={(e) => mergeFields({ lowerBound: e.target.value })}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <TextField
          label="Upper Bound"
          type="number"
          value={fields.upperBound}
          variant="outlined"
          onChange={(e) => mergeFields({ upperBound: e.target.value })}
        />
      </FormControl>

      <FormControl margin="normal" fullWidth>
        <TextField
          label={
            <>
              Enrollment Fee: <Money>{fields.enrollmentFee}</Money>
            </>
          }
          type="number"
          value={fields.enrollmentFee?.cents}
          variant="outlined"
          onChange={(e) =>
            mergeFields({
              enrollmentFee: merge({}, fields.enrollmentFee, {
                cents: e.target.value,
              }),
            })
          }
        />
      </FormControl>

      <FormControl margin="normal" fullWidth>
        <TextField
          label={
            <>
              Price per enrollment(Ongoing):{" "}
              <Money>{fields.pricePerEnrollmentOngoing}</Money>
            </>
          }
          type="number"
          value={fields.pricePerEnrollmentOngoing?.cents}
          variant="outlined"
          onChange={(e) =>
            mergeFields({
              pricePerEnrollmentOngoing: merge({}, fields.pricePerEnrollmentOngoing, {
                cents: e.target.value,
              }),
            })
          }
        />
      </FormControl>

      <FormControl margin="normal" fullWidth>
        <TextField
          label={
            <>
              Price per enrollment(Past Reporting):{" "}
              <Money>{fields.pricePerEnrollmentPastReporting}</Money>
            </>
          }
          type="number"
          value={fields.pricePerEnrollmentPastReporting?.cents}
          variant="outlined"
          onChange={(e) =>
            mergeFields({
              pricePerEnrollmentPastReporting: merge(
                {},
                fields.pricePerEnrollmentPastReporting,
                {
                  cents: e.target.value,
                }
              ),
            })
          }
        />
      </FormControl>
    </ConfirmationDialog>
  );
}
