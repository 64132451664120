import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  green: {
    color: theme.palette.success.main,
  },
  base: {
    fontSize: "0.8rem",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  red: {
    color: theme.palette.error.main,
  },
  yellow: {
    color: theme.palette.warning.main,
  },
}));

export function StatusLabel({ status, label }) {
  const classes = useStyles();
  return (
    <span
      className={clsx(classes.base, {
        [classes.yellow]: status === "pending",
        [classes.green]: status === "finished" || status === "approved",
        [classes.red]: status === "failed" || status === "rejected",
      })}
    >
      {status === "approved" ? "Verified" : status.replaceAll("_", " ")}
    </span>
  );
}
