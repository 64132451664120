import { cyan, deepOrange, lime } from "@mui/material/colors";

export const bureaus = ["equifax", "experian", "transunion"];

export const Type = {
  FURNISHMENT: "furnishment",
  REFURNISHMENT: "refurnishment",
  TRADELINE: "tradeline",
};

export const colorMap = {
  [Type.FURNISHMENT]: cyan[500],
  [Type.REFURNISHMENT]: lime[500],
  [Type.TRADELINE]: deepOrange[500],
};

export const Actions = {
  GET_FEED_TIMELINE: "GET_FEED_TIMELINE",
  GET_FEED_TIMELINE_SUCCESS: "GET_FEED_TIMELINE_SUCCESS",
  GET_FEED_TIMELINE_ERROR: "GET_FEED_TIMELINE_ERROR",
};
