import api from "../api";
import { localStorageCache } from "../lithic/localStorageHelper";
import refreshAsync from "./refreshAsync";

export default function signOut() {
  api
    .signOut()
    .then(() => localStorageCache.clear())
    .then(refreshAsync);
}
