import { AdminContainer, AlignedListContainer } from "../components/layouts";
import {
  CustomerLinkListItem,
  GutterlessListItem as ListItem,
  PartnerLinkListItem,
} from "../components/lists";
import Time, { Period, isoString } from "../lithic/Moment";

import AdminActionLinks from "../components/AdminActionLinks";
import { AuditLogs } from "../components/AuditLogs";
import Box from "@material-ui/core/Box";
import DetailSection from "../components/DetailSection";
import ExternalLinksList from "../components/ExternalLinksList";
import InlineEditable from "../components/InlineEditable";
import Link from "../components/Link";
import ListItemText from "@material-ui/core/ListItemText";
import Money from "../lithic/Money";
import PaymentInstrumentListItems from "../components/PaymentInstrumentListItems";
import React from "react";
import Refunds from "../components/Refunds";
import api from "../api";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  inactive: {
    color: theme.palette.muted.main,
  },
}));

export default function InvoiceDetailPage() {
  const { id } = useParams();
  const classes = useStyles();

  const [invoice, setInvoice] = React.useState({});
  React.useEffect(() => {
    api.getInvoice({ id }).then(api.pickData).then(setInvoice);
  }, [id]);

  function saveAttribute(params) {
    return api
      .updateInvoice({ id, ...params })
      .then(api.pickData)
      .then(setInvoice);
  }

  const instrument = invoice.card || invoice.bankAccount;
  return (
    <AdminContainer variant="detail" customer={invoice.customer}>
      <AlignedListContainer maxWidth="sm">
        <ListItem>
          <ListItemText>Invoice Id: {invoice.id}</ListItemText>
        </ListItem>
        {invoice.customer && (
          <CustomerLinkListItem disableGutters customer={invoice.customer} />
        )}
        {invoice.partner && (
          <PartnerLinkListItem disableGutters partner={invoice.partner} />
        )}
        <ListItem>
          <ListItemText>
            Created:{" "}
            <Time d="(unset)" format={"MMM DD, YYYY HH:mm:ss"}>
              {invoice.createdAt}
            </Time>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText className={clsx(!invoice.updatedAt && classes.inactive)}>
            Updated:{" "}
            <Time d="(unset)" format={"MMM DD, YYYY HH:mm:ss"}>
              {invoice.updatedAt}
            </Time>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText className={clsx(!invoice.softDeletedAt && classes.inactive)}>
            Deleted:{" "}
            <Time d="(unset)" format={"MMM DD, YYYY HH:mm:ss"}>
              {invoice.softDeletedAt}
            </Time>
          </ListItemText>
        </ListItem>
        <InlineEditable
          label="Period"
          value={invoice.period}
          displayValue={
            <Period d="(unset)" format={"MMM DD, YYYY HH:mm:ss"} value={invoice.period} />
          }
          inputType="datetimerange"
          inputProps={{ ampm: false }}
          ListItemComponent={ListItem}
          onCommit={({ start, end }) =>
            saveAttribute({
              periodBegin: isoString(start),
              periodEnd: isoString(end),
            })
          }
          editable={invoice.editable}
        />
        <ListItem>
          <ListItemText>Status: {invoice.status}</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            Total: <Money value={invoice.total} />
          </ListItemText>
        </ListItem>

        <ListItem>
          <ListItemText>
            Refunded Total: <Money value={invoice.refundedTotal} />
          </ListItemText>
        </ListItem>

        <ListItem>
          <ListItemText>Payment Method:</ListItemText>
        </ListItem>
        {instrument && <PaymentInstrumentListItems instrument={instrument} />}
        <ListItem className={clsx(!invoice.lineItems?.length && classes.inactive)}>
          <ListItemText>
            Line Items: {!invoice.lineItems?.length && "(none)"}
          </ListItemText>
        </ListItem>
        {invoice.lineItems?.map(
          ({
            id,
            description,
            amount,
            billedPaymentId,
            reportingAccountEnrollmentId,
            refundable,
          }) => (
            <ListItem key={id}>
              <ListItemText inset>
                {reportingAccountEnrollmentId ? (
                  <Link
                    to={`/reporting-account-enrollments/${reportingAccountEnrollmentId}`}
                  >
                    {description} - <Money value={amount} /> (Id: {id})
                  </Link>
                ) : (
                  <>
                    {description} - <Money value={amount} /> (Id: {id})
                  </>
                )}
                {billedPaymentId && (
                  <span>
                    {" "}
                    <Link to={`/payments/${billedPaymentId}`}>
                      Payment {billedPaymentId}
                    </Link>
                  </span>
                )}
                {!refundable && <span> {`(Refunded)`}</span>}
              </ListItemText>
            </ListItem>
          )
        )}
        <ExternalLinksList links={invoice.externalLinks} />
        <AdminActionLinks
          links={invoice.adminActionLinks}
          onActionSuccess={(resp) => setInvoice(resp.data)}
        />
      </AlignedListContainer>

      <Refunds refunds={invoice.refunds} refundedTotal={invoice.refundedTotal} />

      <Box mt={3}>
        <DetailSection title="Audit Logs">
          <AuditLogs auditableId={invoice.id} auditableType="Invoice" />
        </DetailSection>
      </Box>
    </AdminContainer>
  );
}
