import { apiCollectionToRowResponse, rowQueryToApiParams } from "../modules/tableQuery";

import { AdminContainer } from "../components/layouts";
import MaterialTable from "material-table";
import React from "react";
import Time from "../lithic/Moment";
import api from "../api";
import displayPhone from "../modules/displayPhone";
import { renderTableLink } from "../components/renderTableCell";
import tableIcons from "../components/tableIcons";

export default function CustomersListPage() {
  const tableRef = React.createRef();
  return (
    <AdminContainer variant="list">
      <MaterialTable
        title="Customers"
        tableRef={tableRef}
        columns={columns}
        options={{
          emptyRowsWhenPaging: false,
          pageSize: 50,
          pageSizeOptions: [50, 100, 200],
          sorting: true,
          thirdSortClick: false,
        }}
        data={(q) => {
          return api
            .getCustomers(rowQueryToApiParams(q))
            .then((resp) => apiCollectionToRowResponse(resp));
        }}
        icons={tableIcons}
      />
    </AdminContainer>
  );
}

const columns = [
  {
    title: "Id",
    field: "id",
    defaultSort: "desc",
    width: 20,
    render: renderTableLink({ url: "/customers/", body: "id" }),
  },
  {
    title: "Email",
    field: "email",
    defaultSort: "asc",
    render: renderTableLink({ url: "/customers/", body: "email" }),
  },
  {
    title: "Name",
    field: "name",
    sorting: false,
    render: renderTableLink({ url: "/customers/", body: "name" }),
  },
  {
    title: "Phone",
    field: "phone",
    defaultSort: "asc",
    render: renderTableLink({
      url: "/customers/",
      body: (rowData) => displayPhone(rowData.phone),
    }),
  },
  {
    title: "Created",
    field: "createdAt",
    defaultSort: "desc",
    render: renderTableLink({
      url: "/customers/",
      body: (rowData) => (
        <Time formatExact="MMM DD, YYYY" timezone={rowData.timezone}>
          {rowData.createdAt}
        </Time>
      ),
    }),
  },
];
