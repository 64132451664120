import { Button, CircularProgress, ListItem, ListItemText } from "@material-ui/core";
import { AdminContainer } from "../../components/layouts";

import dayjs from "dayjs";
import React, { useState } from "react";
import { useParams } from "react-router";
import api from "../../api";
import DetailSection from "../../components/DetailSection";
import { useToastError } from "../../components/FormError";
import { TradelineFurnishmentStatusLabel } from "../../components/TradelineFurnishmentStatusLabel";
import { useLoading } from "../../hooks/loading";
import Time from "../../lithic/Moment";
import { toast } from "react-toastify";


export default function TradelineDetailPage() {
  const { id } = useParams();

  const [tradeline, setTradeline] = useState(null);

  const toastError = useToastError();

  const { startLoading, stopLoading } = useLoading();

  React.useEffect(() => {
    (async () => {
      startLoading();
      try {
        setTradeline((await api.getTradeline(id)).data);
      } catch (err) {
        toastError(err.message);
      }
      stopLoading();
    })();
  }, [id]);

  if (!tradeline) return null;

  return (
    <AdminContainer variant="detail-wide">
      <DetailSection title="Tradeline Details">
        {renderItem("ID", tradeline.id)}
        {renderItem("Consumer Account Number", tradeline.consumerAccountNumber)}
        {renderItem("Bureau", tradeline.bureau)}
        {renderItem(
          "Furnishment Status",
          <TradelineFurnishmentStatusLabel status={tradeline.furnishmentStatus} />
        )}
        {renderItem("Status", tradeline.status)}
        {renderItem("Bureau Status", (
          <>
            {tradeline.bureauStatus || "-"}
            &nbsp;
            {
              <BureauSyncStatusButton tradelineId={tradeline.id} />
            }
          </>
        ))}
        {renderItem("Bureau synced at",
          tradeline.bureauStatusSyncedAt
            ? dayjs(tradeline.bureauStatusSyncedAt).format("YYYY-MM-DD HH:ss")
            : "N/A"
        )}
        {renderItem(
          "First furnished at",
          <Time format={"MMM DD, YYYY HH:mm:ss"}>{tradeline.firstFurnishedAt}</Time>
        )}
        {renderItem(
          "Last furnished at",
          <Time format={"MMM DD, YYYY HH:mm:ss"}>{tradeline.lastFurnishedAt}</Time>
        )}
        {renderItem(
          "Tradeline opened at",
          tradeline.tradelineOpenedAt ? (
            <Time format={"MMM DD, YYYY HH:mm:ss"}>{tradeline.tradelineOpenedAt}</Time>
          ) : (
            "N/A"
          )
        )}
        {renderItem(
          "Tradeline closed at",
          tradeline.tradelineClosedAt ? (
            <Time format={"MMM DD, YYYY HH:mm:ss"}>{tradeline.tradelineClosedAt}</Time>
          ) : (
            "N/A"
          )
        )}
        {renderItem(
          "Tradeline deleted at",
          tradeline.tradelineDeletedAt ? (
            <Time format={"MMM DD, YYYY HH:mm:ss"}>{tradeline.tradelineDeletedAt}</Time>
          ) : (
            "N/A"
          )
        )}
        {renderItem("Bureau response", (
          <pre>{tradeline.bureauResponse}</pre>
        ))}
      </DetailSection>
    </AdminContainer>
  );
}

export const renderItem = (label, value) => {
  return (
    <ListItem>
      <ListItemText>
        {label}: <span style={{ fontWeight: 700 }}>{value}</span>
      </ListItemText>
    </ListItem>
  );
};

const BureauSyncStatusButton = ({ tradelineId }) => {
  const [loading, setLoading] = useState(false);

  const syncTradeline = async () => {
    setLoading(true);
    try {
      await api.syncBureauStatusForTradeline(tradelineId);
      toast.success("Status sync requested, please check again after a few minutes");
    } catch (err) {
      toast.error(err.message);
    }
    setLoading(false);
  };

  if (loading) return <CircularProgress size={16} />;

  return (
    <Button onClick={syncTradeline} variant="contained" color="primary">
      Sync
    </Button>
  );
};
