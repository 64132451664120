import React from "react";
import SimpleTable from "./SimpleTable";
import UploadReportButton from "./UploadReportButton";
import SafeExternalLink from "../lithic/SafeExternalLink";
import Time from "../lithic/Moment";

export function FurnishmentReportsTable({ reports = [], onReportUpload}) {
  const bureauReports = reports.reduce((bureaus, report) => {
    return {
      ...bureaus,
      [report.bureau]: [
        ...bureaus[report.bureau],
        report,
      ]
    }
  },  { experian: [], equifax: [], transunion: []})

  function showTimeComponent(date) {
    return date ? <Time formatExact={"MMM Do YYYY, h:mm:ss"}>{date}</Time> : "";
  }

  function showUploadButton(report) {
    return ["pending", "failed"].includes(report.status) ? <UploadReportButton bureau={report.bureau} id={report.id} onClick={onReportUpload} /> : "";
  }

  return (
    <SimpleTable 
      headers={[
        "ID",
        "Bureau",
        "Status",
        "Created At",
        "Uploaded At",
        "Download",
        "",
      ]}
      rows={Object.values(bureauReports).flat()}
      toCells={report => [
        report.id,
        report.bureau,
        report.status,
        showTimeComponent(report.createdAt),
        showTimeComponent(report.uploadedAt),
        <SafeExternalLink href={report.url} key={report.id}>Link</SafeExternalLink>,
        showUploadButton(report),
      ]}
    />
  )
}