import { ListItem } from "@material-ui/core";
import React from "react";
import DetailSection from "../../components/DetailSection";
import { AlignedListContainer } from "../../components/layouts";
import VerificationStatus from "../../components/VerificationStatus";
import Time from "../../lithic/Moment";
import { renderItem } from "./ReportingAccountEnrollmentDetailPage";
import { ReportingAccountEnrollmentVerifications } from "./ReportingAccountEnrollmentVerifications";

export const EnrollmentDetails = ({ enrollment }) => {
  const submitted = enrollment.auditLogs?.find((al) => al.event === "submit")?.at;

  return (
    <AlignedListContainer>
      {renderItem("Reporting enrollment id", enrollment.id)}
      {renderItem(
        "Submitted",
        <Time d="(unset)" format={"MMM DD, YYYY HH:mm:ss"}>
          {submitted}
        </Time>
      )}

      {renderItem("Status", <VerificationStatus status={enrollment.status} label="" />)}
      <ListItem>
        <DetailSection title="Verifications">
          <ReportingAccountEnrollmentVerifications enrollmentId={enrollment?.id} />
        </DetailSection>
      </ListItem>
    </AlignedListContainer>
  );
};
