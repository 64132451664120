import Link from "../../components/Link";
import Person from "@material-ui/icons/Person";
import React from "react";
import clsx from "clsx";
import fullName from "../../modules/fullName";
import { makeStyles } from "@material-ui/core/styles";

export function LinkIconToCustomer({ person }) {
  const classes = useStyles();

  const personFullName = person
    ? fullName(person, {
        firstNameField: "firstName",
        lastNameField: "lastName",
      })
    : "";

  if (person) {
    return (
      <Link
        className={clsx(classes.main, classes.admin)}
        title={person.name}
        to={`/customers/${person.id}`}
      >
        <Person className={classes.icon} />
        &nbsp;{person.name ? person.name : personFullName}
      </Link>
    );
  }

  return "";
}

const useStyles = makeStyles((theme) => ({
  main: {
    lineHeight: 1.5,
  },
  icon: {
    fontSize: "1rem",
    marginBottom: "-2px",
    marginLeft: theme.spacing(0.5),
  },
  admin: {
    color: theme.palette.primary.main,
  },
  customer: {
    color: theme.palette.warning.main,
  },
}));
