import AccountBalancesTable from "./AccountBalancesTable";
import Link from "./Link";
import { GutterlessListItem as ListItem } from "./lists";
import React from "react";
import Typography from "@material-ui/core/Typography";
import _ from "lodash";
import api from "../api";
import { makeStyles } from "@material-ui/core/styles";
import useDirty from "../state/useDirty";

const useStyles = makeStyles((theme) => ({
  subitemList: { flexDirection: "column", alignItems: "start" },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
}));

export default function PlaidInfo({
  customer,
  bankAccount,
  fundingBankAccount,
  showBalanceCheck,
}) {
  const classes = useStyles();
  const [accountBalances, setAccountBalances] = React.useState([]);
  const [dirty, setDirty] = useDirty();

  const customerId = customer?.id;
  React.useEffect(() => {
    if (!customerId) {
      return;
    }
    api
      .getAccountBalances({ id: customerId })
      .then(api.pickData)
      .then((d) => setAccountBalances(d.items));
  }, [customerId, dirty]);

  if (!bankAccount) {
    return null;
  }
  function rowIs(row, ba) {
    return ba && row.modelInstance?.id === ba.id;
  }

  return (
    <ListItem className={classes.subitemList}>
      <Typography variant="h6" gutterBottom color="primary">
        Plaid
      </Typography>
      {bankAccount && (
        <>
          <div className={classes.row}>
            <Typography>
              Funding Bank Account:{" "}
              <Link to={`/bank-accounts/${bankAccount.id}`}>
                {bankAccount.name} x-{bankAccount.last4}
              </Link>
            </Typography>
          </div>
          {bankAccount.balances ? (
            <div className={classes.row}>
              <Typography>Balance: {bankAccount.balances.display}</Typography>
            </div>
          ) : (
            <div className={classes.row}>
              <Typography>Balance Unavailable</Typography>
            </div>
          )}
        </>
      )}
      {!_.isEmpty(accountBalances) && (
        <AccountBalancesTable
          balances={accountBalances}
          highlightIf={(row) => rowIs(row, bankAccount) || rowIs(row, fundingBankAccount)}
          renderSubname={(row) => {
            if (rowIs(row, bankAccount) && rowIs(row, fundingBankAccount)) {
              return "Used for enrollment and funding";
            } else if (rowIs(row, bankAccount)) {
              return "Used for enrollment";
            } else if (rowIs(row, fundingBankAccount)) {
              return "Used for funding";
            } else {
              return "";
            }
          }}
          onRefreshNeeded={setDirty}
          showBalanceCheck={showBalanceCheck}
        />
      )}
    </ListItem>
  );
}
