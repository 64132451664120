import { apiCollectionToRowResponse, rowQueryToApiParams } from "../modules/tableQuery";

import { AdminContainer } from "../components/layouts";
import ConfirmationDialog from "../components/ConfirmationDialog";
import CreateFurnishmentModal from "../components/CreateFurnishmentModal";
import FurnishmentProgress from "../components/FurnishmentProgress";
import MaterialTable from "material-table";
import React from "react";
import Time from "../lithic/Moment";
import api from "../api";
import { renderTableLink } from "../components/renderTableCell";
import tableIcons from "../components/tableIcons";
import { useLoading } from "../hooks/loading";
import { useTheme } from "@material-ui/core/styles";
import useToggle from "../state/useToggle";

export default function FurnishmentsListPage() {
  const tableRef = React.createRef();
  const lastDataRequestQueryRef = React.useRef({});
  const theme = useTheme();
  const columns = makeColumns(theme);
  const creatingFurnishmentWarningToggle = useToggle();
  const createFurnishmentToggle = useToggle();
  const [createFurnishmentError, setCreateFurnishmentError] = React.useState();

  const { startLoading, loading, stopLoading } = useLoading();

  const handleGenerateFurnishment = (kind) => {
    startLoading();
    setCreateFurnishmentError(null);
    return api
      .generateFurnishment({ kind })
      .then(() => {
        createFurnishmentToggle.turnOff();
        creatingFurnishmentWarningToggle.turnOn();
      })
      .catch((e) => {
        setCreateFurnishmentError(e);
      })
      .finally(stopLoading);
  };

  return (
    <AdminContainer variant="list">
      {!loading && (
        <MaterialTable
          title="Boom Report Furnishments"
          tableRef={tableRef}
          columns={columns}
          options={{
            emptyRowsWhenPaging: false,
            filtering: true,
            pageSize: 50,
            pageSizeOptions: [50, 100, 200],
            sorting: true,
            thirdSortClick: false,
            filterCellStyle: {
              transform: "scale(0.80)",
              transformOrigin: "left",
              maxWidth: 0,
            },
          }}
          data={(q) => {
            lastDataRequestQueryRef.current = q;
            return api
              .listFurnishments(rowQueryToApiParams(q))
              .then((resp) => apiCollectionToRowResponse(resp));
          }}
          icons={tableIcons}
          actions={[
            {
              isFreeAction: true,
              icon: () => <>+</>,
              tooltip: "Generate Furnishment",
              onClick: () => createFurnishmentToggle.turnOn(),
            },
          ]}
        />
      )}
      <ConfirmationDialog
        open={creatingFurnishmentWarningToggle.on}
        onCancel={creatingFurnishmentWarningToggle.turnOff}
        onConfirm={creatingFurnishmentWarningToggle.turnOff}
        text={
          "Furnishment is being created in the background. Please wait at least 5 mins"
        }
        title={"Please wait!!!"}
        confirmProps={{ children: "Close" }}
      />

      <CreateFurnishmentModal
        toggle={createFurnishmentToggle}
        onCommit={handleGenerateFurnishment}
        error={createFurnishmentError}
      />
    </AdminContainer>
  );
}

function makeColumns() {
  return [
    {
      title: "Id",
      field: "id",
      defaultSort: "desc",
      filtering: false,
      width: 20,
      render: renderTableLink({
        url: "/furnishments/",
        body: "id",
      }),
    },
    {
      title: "Created",
      field: "created_at",
      defaultSort: "desc",
      filtering: false,
      render: renderTableLink({
        url: "/furnishments/",
        body: (rowData) => <Time formatExact="MMM DD, YYYY">{rowData.createdAt}</Time>,
      }),
    },
    {
      title: "Kind",
      field: "kind",
      defaultSort: "desc",
      filtering: false,
      render: renderTableLink({
        url: "/furnishments/",
        body: "kind",
      }),
    },
    {
      title: "Enrollments",
      field: "enrollments_count",
      defaultSort: "desc",
      filtering: false,
      render: renderTableLink({
        url: "/furnishments/",
        body: (rowData) => rowData.enrollmentsCount,
      }),
    },
    {
      title: "Upload Status",
      field: "",
      defaultSort: "desc",
      filtering: false,
      render: renderTableLink({
        url: "/furnishments/",
        body: (rowData) => (
          <FurnishmentProgress furnishment={rowData}></FurnishmentProgress>
        ),
      }),
    },
    {
      title: "Status",
      field: "status",
      defaultSort: "desc",
      filtering: false,
      render: renderTableLink({
        url: "/furnishments/",
        body: "status",
      }),
    },
  ];
}
