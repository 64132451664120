import React from "react";
import { Tooltip } from "@material-ui/core";
import moment from "moment";

import { useMemo } from "react";
import cx from "clsx";
import { Check, ThreeDRotation } from "@material-ui/icons";

const PaymentHistoryStatus = {
  "*": " ",
  0: <ThreeDRotation size="20px" className="stroke-warning-600" />,
  1: <Check size="20px" className="stroke-success-700" />,
  E: <Check size="20px" className="stroke-success-700" />,
  no_data: "",
};

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const PaymentHistory = ({ payment_history, date_reported }) => {
  const monthReported = moment(date_reported, "MMDDYYYY").toDate().getMonth();

  const data = useMemo(() => {
    const filtered_payment_history =
      payment_history?.filter((el) => el.code !== "/") || [];

    if (!filtered_payment_history.length) return [];

    let ret = filtered_payment_history;

    if (monthReported !== 11) {
      const prevMonth = Array(11 - monthReported).fill({
        code: "no_data",
        description: "No payment history",
      });
      const nextMonth = Array(monthReported + 1).fill({
        code: "no_data",
        description: "No payment history",
      });

      ret = [...prevMonth, ...filtered_payment_history, ...nextMonth];
    }

    const inversed = [];

    for (let i = 0; i < ret.length; i = i + 12) {
      inversed.push(...ret.slice(i, i + 12).reverse());
    }

    return inversed;
  }, [payment_history]);

  return (
    <div className="grid max-w-[540px] grid-cols-12 gap-2">
      {months.map((month) => {
        return (
          <div
            className="flex h-10 w-10 items-center justify-center text-sm font-medium text-gray-300"
            key={month}
          >
            <p>{month}</p>
          </div>
        );
      })}
      {data?.map((el, idx) => {
        // * and "" are the same
        const code = el.code === "" ? "*" : String(el.code);

        const cellClx = () => {
          if (code === "no_data") return "bg-gray-80";
          if (["1", "E"].includes(code)) return "bg-green-50 text-green-600";
          if (code === "0") return "bg-yellow-50 text-red-600";
          if (["2", "3", "4", "5", "6", "7", "8", "9"].includes(code))
            return "bg-red-50 text-red-500";

          return "bg-gray-100 text-gray-1000";
        };

        return (
          <Tooltip key={code + idx} title={el.description}>
            <div
              className={cx(
                "flex h-10 w-10 items-center justify-center rounded-lg text-sm font-bold",
                cellClx()
              )}
            >
              {PaymentHistoryStatus[el?.code || ""] ?? el.code}
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};
