import React from "react";
import Time, { Period } from "../../lithic/Moment";

import { Button, Menu, MenuItem } from "@material-ui/core";
import { MoreHorizOutlined } from "@material-ui/icons";
import dayjs from "dayjs";
import { last, orderBy } from "lodash";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AutoRenewStatus from "../../components/AutoRenewStatus";
import PaymentStatus, { PAID, REFUNDED } from "../../components/PaymentStatus";
import SimpleTable from "../../components/SimpleTable";
import SubscriptionStatus from "../../components/SubscriptionStatus";
import Money from "../../lithic/Money";

const ONGOING_SUBSCRIPTION = "Ongoing Subscription";
const REPORT_PAST_PAYMENTS = "Past Payments";
const ENROLLMENT_FEE = "Enrollment Fee";

const createProduct = (
  id,
  name,
  amount,
  createdAt,
  status,
  period,
  autoRenew,
  actions
) => {
  return {
    id,
    name,
    amount,
    createdAt,
    status,
    period,
    autoRenew,
    actions,
  };
};

const getLatestInvoiceAndLineItemForDescription = (invoices, filterDescriptions) => {
  const allRelatedInvoices = orderBy(
    invoices.filter((invoice) =>
      invoice.lineItems.some((lineItem) =>
        filterDescriptions.includes(lineItem.description)
      )
    ),
    "createdAt",
    "desc"
  );

  const latestRelatedInvoice = allRelatedInvoices[0];
  const oldestRelatedInvoice = last(allRelatedInvoices);

  const latestRelatedInvoiceLineItem = latestRelatedInvoice?.lineItems.find((lineItem) =>
    filterDescriptions.includes(lineItem.description)
  );

  return {
    allRelatedInvoices,
    latestRelatedInvoice,
    oldestRelatedInvoice,
    latestRelatedInvoiceLineItem,
    latestRelatedInvoiceLineItemRefund: latestRelatedInvoice?.refunds.find(
      (refund) =>
        refund.status === "refunded" &&
        refund.lineItems.find(
          (lineItem) => lineItem.id === latestRelatedInvoiceLineItem.id
        )
    ),
  };
};

const InvoiceMenuItems = ({ invoices }) => {
  const history = useHistory();

  return (
    <>
      {invoices.map((invoice, index) => (
        <MenuItem
          key={invoice.id}
          onClick={() => history.push(`/invoices/${invoice.id}`)}
        >
          {index === 0
            ? "View latest invoice"
            : `Invoice for ${dayjs(invoice.createdAt).format("MMM DD, YYYY")}`}
        </MenuItem>
      ))}
    </>
  );
};

export const ProductsSection = ({ enrollment }) => {
  const products = [];

  if (enrollment.subscribedOngoing) {
    const {
      allRelatedInvoices,
      oldestRelatedInvoice,
      latestRelatedInvoiceLineItem,
      latestRelatedInvoiceLineItemRefund,
    } = getLatestInvoiceAndLineItemForDescription(enrollment.invoices, [
      "BoomReport ongoing rent report",
      "BoomReport ongoing resubscription",
      "Ongoing Reporting ($24 per year)",
    ]);

    products.push(
      createProduct(
        ONGOING_SUBSCRIPTION,
        <>
          Ongoing Reporting{" ("}
          <SubscriptionStatus status={enrollment.ongoingSubscriptionStatus} />
          {")"}
        </>,
        latestRelatedInvoiceLineItem?.amount,
        oldestRelatedInvoice?.createdAt,
        <PaymentStatus status={latestRelatedInvoiceLineItemRefund ? REFUNDED : PAID} />,
        <Period
          d="Not enrolled"
          timeProps={{ formatExact: "MMM DD, YYYY" }}
          value={enrollment.subscribedOngoing}
        />,
        <AutoRenewStatus status={enrollment.autoRenewStatus} />,
        <InvoiceMenuItems invoices={allRelatedInvoices} />
      )
    );
  }

  if (enrollment.reportPastPayments) {
    const {
      allRelatedInvoices,
      latestRelatedInvoice,
      latestRelatedInvoiceLineItem,
      latestRelatedInvoiceLineItemRefund,
    } = getLatestInvoiceAndLineItemForDescription(enrollment.invoices, [
      "BoomReport past rent report",
      "Past Reporting ($25 - up to 2 years)",
    ]);

    products.push(
      createProduct(
        REPORT_PAST_PAYMENTS,
        "Past Reporting",
        latestRelatedInvoiceLineItem?.amount,
        latestRelatedInvoice?.createdAt,
        <PaymentStatus status={latestRelatedInvoiceLineItemRefund ? REFUNDED : PAID} />,
        "N/A",
        "N/A",
        <InvoiceMenuItems invoices={allRelatedInvoices} />
      )
    );
  }

  if (enrollment.invoices) {
    const {
      allRelatedInvoices,
      latestRelatedInvoice,
      latestRelatedInvoiceLineItem,
      latestRelatedInvoiceLineItemRefund,
    } = getLatestInvoiceAndLineItemForDescription(enrollment.invoices, [
      "BoomReport Enrollment fee",
      "BoomReport Fee",
    ]);

    if (latestRelatedInvoice) {
      products.push(
        createProduct(
          ENROLLMENT_FEE,
          "Enrollment Fee",
          latestRelatedInvoiceLineItem?.amount,
          latestRelatedInvoice.createdAt,
          <PaymentStatus status={latestRelatedInvoiceLineItemRefund ? REFUNDED : PAID} />,
          "N/A",
          "N/A",
          <InvoiceMenuItems invoices={allRelatedInvoices} />
        )
      );
    }
  }

  return (
      <SimpleTable
        truncated
        tableProps={{ size: "small" }}
        headers={[
          "Product Name",
          "Amount",
          "Product Created At",
          "Payment Status",
          "Period",
          "Autorenew",
          "Actions",
        ]}
        rows={products}
        keyRowAttr="id"
        toCells={(row) => {
          return [
            row.name,
            <Money key={1} value={row.amount} />,
            <Time key={2} format={"MMM DD, YYYY HH:mm:ss"}>
              {row.createdAt}
            </Time>,
            row.status,
            row.period,
            row.autoRenew,
            <ActionsButton key={3} actions={row.actions} />,
          ];
        }}
      />
  );
};

export const ActionsButton = ({ actions }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizOutlined />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {actions}
      </Menu>
    </>
  );
};
