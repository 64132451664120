import { MenuItem, TextField } from "@material-ui/core";

import ConfirmationDialog from "./ConfirmationDialog";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formGroup: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridGap: "10px",
    width: "450px",
    marginTop: "20px",
  },
  formControl: {
    width: "200px",
    marginBottom: "50px",
  },
  vCenter: {
    display: "flex",
    alignItems: "center",
  },
}));

export default function CreateFurnishmentModal({ toggle, onCommit, error, resetError }) {
  const [kind, setKind] = React.useState("");

  const classes = useStyles();

  const KINDS = ["weekly", "monthly"];

  return (
    <ConfirmationDialog
      title="Create Furnishment"
      open={toggle.on}
      onCancel={toggle.turnOff}
      onConfirm={() => onCommit(kind)}
      cancelProps={{ children: "Go back" }}
      confirmProps={{ children: "Create" }}
      error={error}
      resetError={resetError}
    >
      <TextField
        className={classes.formControl}
        label="Furnishment"
        type="text"
        value={kind}
        variant="outlined"
        onChange={(e) => setKind(e.target.value)}
        select
      >
        {KINDS.map((v, idx) => (
          <MenuItem key={idx} value={v}>
            {v}
          </MenuItem>
        ))}
      </TextField>
    </ConfirmationDialog>
  );
}
