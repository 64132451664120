import { AdminContainer } from "../../components/layouts";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import api from "../../api";
import { useLoading } from "../../hooks/loading";
import { toast } from "react-toastify";
import { ApplicantsList } from "./ApplicantsList";
import AuditLogsTable from "../../components/AuditLogsTable";
import DetailSection from "../../components/DetailSection";
import AdminActionLinks from "../../components/AdminActionLinks";
import SafeExternalLink from "../../lithic/SafeExternalLink";
import Link from "../../components/Link";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { Autocomplete } from "@material-ui/lab";
import Money from "../../lithic/Money";
import { AuditLogs } from "../../components/AuditLogs";

export default function ApplicationDetailPage() {
  const { id } = useParams();
  const { startLoading, stopLoading } = useLoading();
  const [application, setApplication] = useState(null);

  useEffect(() => {
    startLoading();
    api
      .getApplication(id)
      .then((result) => setApplication(result.data))
      .catch((err) => toast.error(err.message))
      .finally(() => {
        stopLoading();
      });
  }, [id]);

  if (!application) return <div>Loading…</div>;

  return (
    <AdminContainer variant="list">
      <Typography variant="h4">Application Details</Typography>
      <Typography variant="body1">Application Status: {application.status}</Typography>
      <DetailSection title="Partner">
        <Link to={`/partners/${application.partner.id}`}>{application.partner.name}</Link>
      </DetailSection>
      <DetailSection title="Verification template">
        <SafeExternalLink href={application.verificationTemplate.url}>
          {application.verificationTemplate.name}
        </SafeExternalLink>
      </DetailSection>
      <DetailSection title="Applicants">
        <ApplicantsList applicants={application.applicants} />
      </DetailSection>
      <DetailSection title="Unit">
        <Typography>Current Unit: {application.unit.title}</Typography>
        <Typography>Address Unit: {application.unit.address}</Typography>
        <Typography>
          Monthly Rent Amount: <Money>{application.unit.monthlyRentAmount}</Money>
        </Typography>
        <ChangeUnitButton
          partnerId={application.partner.id}
          applicationId={application.id}
          applicationUnit={application.unit}
        />
      </DetailSection>
      <DetailSection title="Audit Logs">
        <AuditLogs auditableId={application.id} auditableType="BoomScreen::Application" />
      </DetailSection>
      <AdminActionLinks
        links={application.adminActionLinks}
        onActionSuccess={(resp) => setApplication(resp.data)}
      />
      {/* Temporarily. Waiting for the backend update */}
      <DetailSection>
        <Typography variant="h6" gutterBottom color="primary">
          Portal
        </Typography>
        <SafeExternalLink href="/">Open Portal</SafeExternalLink>
      </DetailSection>
    </AdminContainer>
  );
}

function ChangeUnitButton({ partnerId, applicationId, applicationUnit }) {
  const [openModal, setOpenModal] = useState(false);
  const [units, setUnits] = useState(null);
  const [newUnit, setNewUnit] = useState(applicationUnit);

  useEffect(() => {
    api
      .getPartnerUnits({ id: partnerId })
      .then((result) => setUnits(result.data.items))
      .catch((err) => toast.error(err.message));
  }, [partnerId]);

  if (!units) return <div>loading...</div>;

  return (
    <Box mt={1}>
      <Button color="primary" variant="contained" onClick={() => setOpenModal(true)}>
        Change Unit
      </Button>

      <ConfirmationDialog
        title="Change Unit"
        open={openModal}
        onCancel={() => setOpenModal(false)}
        onConfirm={() => {
          api
            .changeApplicationUnit(applicationId, { unit_id: newUnit.id })
            .then(() => {
              toast.success("Application updated successfully!");
              setOpenModal(false);
            })
            .catch((err) => toast.error(err.message));
        }}
        text="Are you sure?"
        cancelProps={{ children: "Go back" }}
        confirmProps={{
          children: "Update",
          disabled: newUnit?.id === applicationUnit.id || !newUnit,
        }}
      >
        <Autocomplete
          id="combo-box-demo"
          options={units}
          getOptionLabel={(value) => `${value.id} - ${value.title}`}
          onChange={(event, newValue) => {
            setNewUnit(newValue);
          }}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField variant="outlined" label="Unit Search" {...params} fullWidth />
          )}
        />
      </ConfirmationDialog>
    </Box>
  );
}
