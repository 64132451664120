import Button from "@material-ui/core/Button";
import React from "react";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    alignSelf: "flex-end",
  },
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
  },
}));

export default function TypeToConfirm({
  confirmString,
  inputLabel,
  inputProps,
  buttonLabel,
  buttonProps,
  onConfirm,
  rootClassName,
}) {
  const [value, setValue] = React.useState("");
  const classes = useStyles();
  const fullBtnProps = {
    variant: "contained",
    color: "primary",
    children: buttonLabel,
    ...buttonProps,
  };
  const fullInputProps = {
    variant: "outlined",
    label: inputLabel,
    ...inputProps,
  };
  return (
    <form className={clsx(classes.root, rootClassName)} noValidate autoComplete="off">
      <TextField
        {...fullInputProps}
        value={value}
        className={clsx(classes.input, fullInputProps.className)}
        onChange={(ev) => setValue(ev.target.value)}
      />
      <Button
        onClick={() => {
          onConfirm();
          setValue("");
        }}
        {...fullBtnProps}
        disabled={value !== confirmString}
        className={clsx(classes.button, fullInputProps.className)}
      />
    </form>
  );
}
