import { apiCollectionToRowResponse, rowQueryToApiParams } from "../modules/tableQuery";

import { AdminContainer } from "../components/layouts";
import { ListItemText } from "@material-ui/core";
import MaterialTable from "material-table";
import React from "react";
import { StatusLabel } from "../components/StatusLabel";
import TableSelectFilter from "../components/TableSelectFilter";
import Time from "../lithic/Moment";
import api from "../api";
import { renderTableLink } from "../components/renderTableCell";
import tableIcons from "../components/tableIcons";
import { useTheme } from "@material-ui/core/styles";

export default function KybVerificationsListPage() {
  const tableRef = React.createRef();
  const theme = useTheme();
  const columns = makeColumns(theme);

  return (
    <AdminContainer variant="list">
      <MaterialTable
        title="KYB Verifications"
        tableRef={tableRef}
        columns={columns}
        options={{
          emptyRowsWhenPaging: false,
          filtering: true,
          search: false,
          pageSize: 50,
          pageSizeOptions: [50, 100, 200],
          sorting: true,
          thirdSortClick: false,
          filterCellStyle: {
            transform: "scale(0.80)",
            transformOrigin: "left",
            maxWidth: 0,
          },
        }}
        data={(q) => {
          return api
            .listKybVerifications(rowQueryToApiParams(q))
            .then((resp) => apiCollectionToRowResponse(resp));
        }}
        icons={tableIcons}
      />
    </AdminContainer>
  );
}

function makeColumns(theme) {
  return [
    {
      title: "Id",
      field: "id",
      defaultSort: "desc",
      filtering: false,
      width: 20,
      render: renderTableLink({ url: "/kyb-verifications/", body: "id" }),
    },
    {
      title: "Name",
      field: "partner_name",
      sorting: false,
      filtering: false,
      render: renderTableLink({
        url: "/partners/",
        id: "partner.id",
        body: "partner.name",
      }),
    },
    {
      title: "Email",
      field: "email",
      filtering: false,
      sorting: false,
      render: (rowData) => rowData.partner.email,
    },
    {
      title: "Progress",
      field: "progress",
      filtering: false,
      sorting: false,
      render: (rowData) => (
        <ListItemText>
          {rowData.progress?.processed} / {rowData.progress?.total}
        </ListItemText>
      ),
    },
    {
      title: "Status",
      field: "status",
      filtering: true,
      sorting: false,
      filterComponent: (props) => (
        <TableSelectFilter
          {...props}
          paramKey="status"
          onlyOneSelection={true}
          lookup={{
            pending: "pending",
            ready_to_review: "ready_to_review",
            approved: "approved",
            rejected: "rejected",
            canceled: "canceled",
          }}
        />
      ),
      defaultFilter: ["ready_to_review"],
      render: (verification) => (
        <StatusLabel key={verification.id} status={verification.status} label=" " />
      ),
    },
    {
      title: "Updated",
      field: "updated_at",
      filtering: false,
      sorting: false,
      render: (rowData) => <Time formatExact="MMM DD, YYYY">{rowData.updatedAt}</Time>,
    },
    {
      title: "Created",
      field: "created_at",
      filtering: false,
      sorting: false,
      render: (rowData) => <Time formatExact="MMM DD, YYYY">{rowData.createdAt}</Time>,
    },
  ];
}
