import { CircularProgress, ListItem, ListItemText, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import api from "../../api";
import { AlignedListContainer } from "../../components/layouts";
import PortalLogin from "../../components/PortalLogin";
import { formatMoney } from "../../lithic/Money";
import { toast } from "react-toastify";

export const EnrollmentDetailView = ({ verification }) => {
  const [reportingAccountEnrollmentData, setReportingAccountEnrollmentData] = useState(
    null
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const result = await api.getReportingAccountEnrollment({
          id: verification.enrollment.id,
        });
        setReportingAccountEnrollmentData(result.data);
      } catch (err) {
        toast.error(err.message)
      }
      setLoading(false);
    })();
  }, [verification]);

  if (loading) return <CircularProgress />;

  if (!reportingAccountEnrollmentData) return <></>;

  const { subscribedOngoing, partner, case: raeCase } = reportingAccountEnrollmentData;
  const customer = raeCase?.customer;

  const renderText = (title, value, { link } = {}) => {
    return (
      <ListItem>
        <ListItemText>
          {title}:{" "}
          {link ? (
            <Link target="_blank" to={{ pathname: link }}>
              {value}
            </Link>
          ) : (
            <strong>{value}</strong>
          )}
        </ListItemText>
      </ListItem>
    );
  };

  return (
    <AlignedListContainer maxWidth="xl">
      {partner !== null && (
        <>
          <Typography variant="h6" color="primary">
            Partner
          </Typography>

          {renderText("Type", partner.serviceType)}
          {renderText("Name", partner.name)}
          {renderText("Status", partner.status)}
          {renderText("URL", partner.url)}
        </>
      )}

      <Typography variant="h6" color="primary">
        Customer Details
      </Typography>
      {renderText("Id", customer.id, { link: `/customers/${customer.id}` })}
      {verification.kind !== "identity" && (
        <>
          {renderText("First Name", customer.firstName)}
          {renderText("Last Name", customer.lastName)}
          {renderText(
            "Date of Birth",
            customer.dob ? dayjs(customer.dob).format("D MMM YYYY") : "-"
          )}
        </>
      )}
      {renderText("Email", customer.email)}
      {renderText("Phone", customer.phone)}

      <Typography variant="h6" color="primary">
        Enrollment Details
      </Typography>
      {renderText("Id", reportingAccountEnrollmentData.id, {
        link: `/reporting-account-enrollments/${reportingAccountEnrollmentData.id}`,
      })}
      {renderText("Status", reportingAccountEnrollmentData.status)}
      {renderText(
        "Report Past Payments",
        `${reportingAccountEnrollmentData.reportPastPayments}`
      )}
      {renderText(
        "Subscribed Ongoing",
        subscribedOngoing
          ? `${dayjs(subscribedOngoing.start).format("D MMM YYYY")} - ${dayjs(
              subscribedOngoing.end
            ).format("D MMM YYYY")}`
          : "-"
      )}
      {renderText(
        "Enrollment Date",
        reportingAccountEnrollmentData?.createdAt
          ? `${dayjs(reportingAccountEnrollmentData?.createdAt).format("D MMM YYYY")}`
          : "-"
      )}
      {renderText("Intercom Link", customer.intercomUrl ? customer.intercomUrl : "-", {
        link: customer.intercomUrl,
      })}
      {verification.kind !== "lease" && (
        <>
          <Typography variant="h6" color="primary">
            Lease Details
          </Typography>
          {renderText(
            "Address",
            raeCase.address &&
              `${raeCase.address.address1} ${raeCase.address.address2}\n${raeCase.address.city} ${raeCase.address.country} \n${raeCase.address.postalCode} ${raeCase.address.stateOrProvince}`
          )}
          {renderText(
            "Rental Amount",
            raeCase?.monthlyRentAmount
              ? `${formatMoney(raeCase?.monthlyRentAmount)}`
              : "-"
          )}
          {renderText(
            "Move in date",
            raeCase?.moveInDate
              ? `${dayjs(raeCase?.moveInDate).format("D MMM YYYY")}`
              : "-"
          )}
        </>
      )}

      {["lease", "landlord"].includes(verification.kind) && (
        <PortalLogin portalAuthCredential={raeCase.portalAuthCredential} portalStatus={raeCase?.portalVerificationStatus}/>
      )}
    </AlignedListContainer>
  );
};
