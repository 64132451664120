import BannerMessage from "./BannerMessage";
import { Logger } from "../lithic/logger";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import { toast } from "react-toastify";

const logger = new Logger("form-error");

const defaultMessage = "Something went wrong. Please try again.";

export function useError(initialState) {
  const [error, setErrorInner] = React.useState(initialState || null);
  function setError(e) {
    setErrorInner(e);
    return null;
  }
  return [error, setError];
}

/**
 * @return {string|null}
 */
export function extractErrorMessage(error, defaultMsg) {
  if (!error || _.isString(error)) {
    return error;
  }
  const status = _.get(error, "response.data.error.status") || 500;
  let msg;
  if (status >= 500) {
    msg = defaultMessage;
  } else {
    msg = _.get(error, "response.data.error.message") || defaultMsg || defaultMessage;
  }
  if (msg === defaultMsg) {
    // We couldn't parse anything meaningful, so log it out
    logger.error(error);
  }
  return msg;
}

export default function FormError(props) {
  const { error } = props;
  const msg = extractErrorMessage(error);
  if (!msg) {
    return null;
  }
  function resetError() {
    props.resetError && props.resetError("");
  }
  return <BannerMessage onPress={resetError} text={msg} />;
}

FormError.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  resetError: PropTypes.func,
};

export function useToastError() {
  return (error) => toast.error(extractErrorMessage(error));
}
