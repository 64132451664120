import { Checkbox } from "@material-ui/core";
import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TruncatedContainer from "./TruncatedContainer";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import toValue from "../lithic/toValue";

/**
 * Render a simple table.
 * @param rows Array of rows.
 * @param headers Header names.
 * @param toCells
 * @param keyCellIndex Index of a unique field that can be used as a 'key' in an array of cells.
 *   Defaults to 0 because that's normally the 'id' cell.
 * @param keyRowAttr Attribute of a 'row' object that can be used as a 'key'.
 *   Takes precendence over keyCellIndex.
 * @param getKey Function called with each row to generate a unique key.
 *   Takes precedence over keyRowAttr.
 * @param tableProps Props passed to the Table component.
 * @param truncated If true, use TruncatedContainer to only render part of the table.
 * @param {string|function} rowClass
 * @param center Center content if true.
 * @param dense
 * @param padFirst By default, first child has no padding.
 *   If true, pad first child, like a default table.
 * @param padLast
 * @param className
 * @constructor
 */
export default function SimpleTable({
  rows,
  headers,
  toCells,
  keyCellIndex,
  keyRowAttr,
  getKey,
  tableProps,
  truncated,
  rowClass,
  center,
  dense,
  padFirst,
  padLast,
  className,
  onSelectAllClick,
  selected,
}) {
  const classes = useStyles();

  function getKeyFunc(row, cells) {
    if (getKey) {
      return getKey(row);
    }
    if (keyRowAttr) {
      return row[keyRowAttr];
    }
    return cells[keyCellIndex || 0];
  }

  const cellCls = clsx(
    padFirst ? null : classes.nopadFirst,
    padLast ? null : classes.nopadLast
  );
  const tbl = (
    <TableContainer className={className}>
      <Table size={dense ? "small" : "medium"} {...tableProps}>
        <TableHead>
          <TableRow>
            {headers.map((h) =>
              h === "Select" ? (
                <TableCell
                  key={h}
                  className={cellCls}
                  align={center ? "center" : "inherit"}
                >
                  <Checkbox
                    key={1}
                    color="primary"
                    onChange={onSelectAllClick}
                    checked={selected === rows?.length}
                    indeterminate={selected > 0 && selected < rows?.length}
                  />
                </TableCell>
              ) : (
                <TableCell
                  key={h}
                  className={cellCls}
                  align={center ? "center" : "inherit"}
                >
                  {h}
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => {
            const cells = toCells(row);
            const key = getKeyFunc(row, cells);
            return (
              <TableRow key={key} className={toValue(rowClass, row)}>
                {cells.map((c, i) => (
                  <TableCell
                    key={i}
                    className={cellCls}
                    align={center ? "center" : "inherit"}
                  >
                    {c}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
  if (!truncated) {
    return tbl;
  }
  return <TruncatedContainer>{tbl}</TruncatedContainer>;
}

const useStyles = makeStyles((theme) => ({
  nopadFirst: {
    "&:first-child": {
      paddingLeft: 0,
    },
  },
  nopadLast: {
    "&:last-child": {
      paddingRight: 0,
    },
  },
}));
