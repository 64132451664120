import { Button } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import ScrollTopOnMount from "../lithic/ScrollToTopOnMount";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(2),
  },
  title: {
    textAlign: "center",
  },
  submitButton: {
    marginTop: theme.spacing(3),
  },
  switchContainer: {
    marginTop: theme.spacing(3),
    textAlign: "center",
  },
  switchButton: {
    marginTop: theme.spacing(3),
  },
}));

export function AuthPage({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ScrollTopOnMount top={0} />
      <Card className={classes.card}>
        <CardContent>{children}</CardContent>
      </Card>
    </div>
  );
}

export function AuthTitle({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.title}>
      <Typography variant="h3">{children}</Typography>
    </div>
  );
}

export function AuthButton({ children }) {
  const classes = useStyles();
  return (
    <Button
      className={classes.submitButton}
      margin="normal"
      fullWidth
      type="submit"
      size="large"
      variant="contained"
      color="primary"
    >
      {children}
    </Button>
  );
}
