import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Box, Button, FormControl, ListItemText, TextField } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import SimpleTable from "../../components/SimpleTable";
import Time from "../../lithic/Moment";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import api from "../../api";
import { toast } from "react-toastify";

export const ApplicantsList = ({ applicants }) => {
  const theme = useTheme();

  if (!applicants) return null;

  const styleApplicant = (applicant) => {
    return {
      padding: theme.spacing(0.5),
      borderRadius: 100,
      textDecoration: "none",
    };
  };

  return (
    <SimpleTable
      headers={[
        "Id",
        "Full Name",
        "Role",
        "Email",
        "Application Status",
        "Updated at",
        "Created at",
        "Actions",
      ]}
      rows={applicants}
      keyRowAttr="id"
      toCells={(applicant) => [
        <Link key={1} to={`/applicants/${applicant.id}`}>
          {applicant.id}
        </Link>,
        <ListItemText key={2} style={styleApplicant(applicant)}>
          {`${applicant.firstName} ${applicant.lastName}`}
        </ListItemText>,
        <ListItemText key={3}>{applicant.role}</ListItemText>,
        <ListItemText key={4}>{applicant.email}</ListItemText>,
        <ListItemText key={5}>{applicant.applicationStatus}</ListItemText>,
        <Time key={6} formatExact={"MMM DD, YYYY HH:mm:ss"}>
          {applicant.updatedAt}
        </Time>,
        <Time key={7} formatExact={"MMM DD, YYYY HH:mm:ss"}>
          {applicant.createdAt}
        </Time>,
        <ChangeNameButton
          key={8}
          applicantId={applicant.id}
          fullName={{ firstName: applicant.firstName, lastName: applicant.lastName }}
        />,
      ]}
    />
  );
};

function ChangeNameButton({ applicantId, fullName }) {
  const [openModal, setOpenModal] = React.useState(false);
  const [newFullName, setNewFullName] = React.useState(fullName);

  useEffect(() => {
    setNewFullName(fullName);
  }, [fullName]);

  return (
    <Box>
      <Button color="primary" variant="contained" onClick={() => setOpenModal(true)}>
        Change Name
      </Button>

      <ConfirmationDialog
        title="Change Name"
        open={openModal}
        onCancel={() => setOpenModal(false)}
        onConfirm={() => {
          api
            .changeApplicantName(applicantId, newFullName)
            .then(() => {
              toast.success("Applicant updated successfully!");
              setOpenModal(false);
            })
            .catch((err) => toast.error(err.message));
        }}
        text="Are you sure?"
        cancelProps={{ children: "Go back" }}
        confirmProps={{
          children: "Update",
          disabled:
            fullName.firstName === newFullName.firstName &&
            fullName.lastName === newFullName.lastName,
        }}
      >
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            label="First Name"
            value={newFullName.firstName}
            onChange={(e) =>
              setNewFullName((prev) => ({ ...prev, firstName: e.target.value }))
            }
            fullWidth
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            variant="outlined"
            label="Last Name"
            value={newFullName.lastName}
            onChange={(e) =>
              setNewFullName((prev) => ({ ...prev, lastName: e.target.value }))
            }
            fullWidth
          />
        </FormControl>
      </ConfirmationDialog>
    </Box>
  );
}
