import { Button, makeStyles, MenuItem, Select, Typography } from "@material-ui/core";
import { Download } from "@mui/icons-material";
import React, { useEffect } from "react";
import api from "../../api";
import { useLoading } from "../../hooks/loading";
import { toast } from "react-toastify";
import UploadButton from "../../components/UploadButton";

export const FileViewer = ({
  enrollmentId,
  selectedFile,
  setSelectedFile,
  selectedFileIndex,
  setSelectedFileIndex,
  files,
  setFiles,
  onFilesDropped,
}) => {
  const classes = useStyles();

  const { startLoading, stopLoading } = useLoading();

  useEffect(() => {
    if (enrollmentId) {
      (async () => {
        startLoading();
        try {
          const result = await api.getFilesForEnrollment(enrollmentId);
          setFiles(result.data.files);
        } catch (err) {
          toast.error(err.message);
        }
        stopLoading();
      })();
    }
  }, [enrollmentId]);

  useEffect(() => {
    if (files) {
      setSelectedFile(files[selectedFileIndex]);
    }
  }, [files, selectedFileIndex]);

  if (files?.length === 0) return <Typography>No uploaded documents</Typography>;

  return (
    <div>
      <div className={classes.headerContainer}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedFileIndex}
          style={{ maxWidth: "70%" }}
          onChange={(e) => {
            setSelectedFileIndex(e.target.value);
          }}
        >
          {files?.map((file, index) => (
            <MenuItem key={file.id} value={index}>
              {file.name}
            </MenuItem>
          ))}
        </Select>
        <Button
          href={selectedFile?.url}
          target="_blank"
          download={true}
          className={classes.downloadButton}
          variant="outlined"
          color="primary"
        >
          <Download />
        </Button>

        <UploadButton onFilesDropped={onFilesDropped} />
      </div>

      {selectedFile && (
        <>
          {(selectedFile.mimeType === "application/pdf" ||
            selectedFile.name.endsWith("pdf")) && (
            <iframe
              style={{ width: "100%", height: "75vh", marginTop: 32 }}
              src={selectedFile.url}
              title={selectedFile.name}
            ></iframe>
          )}
          {selectedFile.mimeType.startsWith("image") && (
            <img
              key={selectedFile.url}
              style={{ width: "100%", marginTop: 32 }}
              src={selectedFile.url}
              alt={selectedFile.name}
            />
          )}
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: "flex",
    flexDirection: "row",
  },
  downloadButton: {
    marginLeft: 16,
  },
}));
