import { Tab, Tabs } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import React from "react";
import { FileViewer } from "../EnrollmentVerificationDetailPage/FileViewer";
import { ReportingAccountEnrollmentVerifications } from "../ReportingAccountEnrollmentDetailPage/ReportingAccountEnrollmentVerifications";
import { EnrollmentDetailView } from "./EnrollmentDetailView";
import BankAccounts from "../../components/BankAccounts";

export default function DetailsView({
  enrollmentId,
  selectedFile,
  setSelectedFile,
  selectedFileIndex,
  setSelectedFileIndex,
  files,
  setFiles,
  tab,
  setTab,
  customerId,
}) {
  const tabs = [
    {
      title: "Documents",
      component: (
        <FileViewer
          enrollmentId={enrollmentId}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          selectedFileIndex={selectedFileIndex}
          setSelectedFileIndex={setSelectedFileIndex}
          files={files}
          setFiles={setFiles}
        />
      ),
    },
    {
      title: "Enrollment",
      component: <EnrollmentDetailView enrollmentId={enrollmentId} />,
    },
    {
      title: "Verifications",
      component: <ReportingAccountEnrollmentVerifications enrollmentId={enrollmentId} />,
    },
    {
      title: "Bank Accounts",
      component: <BankAccounts customerId={customerId} />,
    },
  ];
  return (
    <TabContext value={tab}>
      <Tabs value={tab} onChange={(e, value) => setTab(value)}>
        {tabs.map((tab) => (
          <Tab key={tab.title} label={tab.title} />
        ))}
      </Tabs>

      {tabs.map((tab, index) => (
        <TabPanel key={tab.title} value={index}>
          {tab.component}
        </TabPanel>
      ))}
    </TabContext>
  );
}
