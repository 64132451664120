import Container from "@material-ui/core/Container";
import { List } from "@material-ui/core";
import LoadingModal from "./LoadingModal";
import React from "react";
import { UseTimezone } from "../lithic/Moment";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  adminContainer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(20),
    padding: "0 50px 0 50px",
  },
  cfOuter: {
    margin: "0 auto",
    display: "flex",
    justifyContent: "start",
  },
  cfInner: {
    width: "100%",
  },
  "aligned-container-left": {
    marginLeft: 0,
    paddingLeft: 0,
  },
  "aligned-container-right": {
    marginRight: 0,
    paddingRight: 0,
  },
}));

export function constrained(maxWidth) {
  return (Wrapped) => {
    return (props) => {
      const styles = useStyles();
      return (
        <Container maxWidth={maxWidth} className={styles.cfOuter}>
          <div className={styles.cfInner}>
            <Wrapped {...props} />
          </div>
        </Container>
      );
    };
  };
}

export function AdminContainer({ children, variant, customer, className }) {
  const classes = useStyles();
  let el = (
    <Container
      className={[classes.adminContainer, className]}
      {...variantOptions[variant]}
    >
      {children}
      <LoadingModal />
    </Container>
  );
  if (customer?.timezone) {
    el = <UseTimezone timezone={customer.timezone}>{el}</UseTimezone>;
  }
  return el;
}

/**
 * Container, used under AdminContainer, that is aligned to part of the page,
 * for containing a list.
 */
export function AlignedListContainer({ direction, children, ...rest }) {
  const styles = useStyles();
  direction = direction || "left";
  return (
    <Container {...rest} className={styles[`aligned-container-${direction}`]}>
      <List dense>{children}</List>
    </Container>
  );
}

const variantOptions = {
  list: { maxWidth: "xl" },
  detail: { maxWidth: "lg" },
  "detail-wide": { maxWidth: "xl" },
};
