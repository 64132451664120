import "./TableDateRangeFilter.js.css";

import Clear from "@material-ui/icons/Clear";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import React from "react";
import dayjs from "dayjs";
import { makeStyles } from "@material-ui/core";
import useQueryParam from "../state/useQueryParam";

export default function TableDateRangeFilter({ paramKey, columnDef, onFilterChanged }) {
  const classes = useStyles();
  const [range, setRange] = useQueryParam(paramKey, null, {
    parse,
    serialize,
  });

  React.useEffect(() => {
    // Run this on load, since this is a controlled component because we can't control table filtering.
    if (range[0]) {
      const dateStrs = range.map((d) => dayjs(d).format("YYYY-MM-DD"));
      onFilterChanged(columnDef.tableData.id, dateStrs);
    }
    // eslint-disable-next-line
  }, []);

  function handleChange(val) {
    const dateStrs = val.map((d) => dayjs(d).format("YYYY-MM-DD"));
    setRange(dateStrs);
    onFilterChanged(columnDef.tableData.id, dateStrs);
  }

  function handleClear() {
    setRange([null, null]);
    onFilterChanged(columnDef.tableData.id, []);
  }

  return (
    <div className={classes.root}>
      <DateRangePicker
        onChange={handleChange}
        value={range}
        calendarIcon={null}
        clearIcon={null}
        rangeDivider={null}
      />
      <IconButton
        className={classes.button}
        onClick={handleClear}
        onMouseDown={(e) => e.preventDefault()}
      >
        <Clear />
      </IconButton>
    </div>
  );
}

TableDateRangeFilter.propTypes = {
  paramKey: PropTypes.string.isRequired,
};

function parse(v) {
  if (!v) {
    return [null, null];
  }
  const [lowerStr, upperStr] = v.split("-");
  const lower = dayjs.unix(Number(lowerStr)).toDate();
  const upper = dayjs.unix(Number(upperStr)).toDate();
  return [lower, upper];
}

function serialize(o) {
  if (!o[0]) {
    return undefined;
  }
  return `${dayjs(o[0]).unix()}-${dayjs(o[1]).unix()}`;
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  button: {
    paddingLeft: 0,
  },
}));
