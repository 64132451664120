import { useHistory, useLocation } from "react-router";

import React from "react";
import _ from "lodash";
import qs from "../lithic/queryString";

export default function useQueryParam(key, defaultValue, { parse, serialize } = {}) {
  const history = useHistory();
  const location = useLocation();

  const parsed = qs.parse(location.search);

  let queryParamState = defaultValue;
  if (_.has(parsed, key)) {
    queryParamState = parsed[key];
  }

  if (parse) {
    queryParamState = parse(queryParamState);
  }

  const setQueryParamState = React.useCallback(
    (value) => {
      let toWrite = value;
      if (toWrite === null) {
        toWrite = undefined;
      }
      if (serialize) {
        toWrite = serialize(toWrite);
      }
      const params = {
        ...qs.parse(location.search),
        [key]: toWrite,
      };
      history.push(`${location.pathname}?${qs.stringify(params)}`);
    },
    [history, key, location, serialize]
  );

  return [queryParamState, setQueryParamState];
}
