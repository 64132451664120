/**
 * Pull out first/last name from an entity,
 * and join together on a space if both are set,
 * otherwise just return what is set, or a placeholder.
 *
 * If parenField is given, include it at the end of the string.
 * So for example,
 * `fullName({firstName: 'Buzz', lastName: 'Lightyear', email: 'buzz@nasa.gov'}, {parenField: 'email'})`
 * would return 'Buzz Lightyear (buzz@nasa.gov)'.
 *
 * @param options.firstNameField 'firstName'
 * @param options.lastNameField 'lastName'
 * @param options.parenField ''
 * @param options.fallback '<No Name>'
 * @return {string}
 */
export default function fullName(entity, options) {
  options = {
    firstNameField: "firstName",
    lastNameField: "lastName",
    parenField: "",
    fallback: "<No Name>",
    ...options,
  };
  const parenVal = options.parenField ? entity[options.parenField] : "";
  const parenStr = parenVal ? `(${parenVal})` : "";
  const names = [
    entity[options.firstNameField],
    entity[options.lastNameField],
    parenStr,
  ].filter(Boolean);
  if (names.length === 0) {
    return options.fallback;
  }
  return names.join(" ");
}
