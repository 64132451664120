import Assignment from "@material-ui/icons/Assignment";
import IconButton from "@material-ui/core/IconButton";
import React from "react";

export default function CopyContentIconButton({ value, ...rest }) {
  return (
    <IconButton
      onClick={() => window.navigator.clipboard.writeText(value)}
      onMouseDown={(e) => e.preventDefault()}
      {...rest}
    >
      <Assignment />
    </IconButton>
  );
}
