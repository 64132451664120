import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";

import { AvatarGroup } from "@mui/material";
import moment from "moment";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Type, colorMap } from "../constants";
import { groupBy } from "../helpers";
import Link from "../../../../components/Link";
import { useHistory } from "react-router";
import { JSONView } from "./JSONView";
import { Avatar } from "./Avatar";

const useStyles = makeStyles((theme) => ({
  wrapper: { display: "flex", position: "relative", alignItems: "flex-start" },
  timeline: { flexShrink: 0, flexGrow: 1, maxWidth: 700 },
  timelineItemHeader: { position: "relative" },
  avatarGroup: { minWidth: 80, justifyContent: "center" },
  timelineContent: { paddingTop: 50 },
  paper: {
    padding: "6px 16px",
    marginBottom: "10px",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      cursor: "pointer",
      opacity: 0.8,
      transform: "translate(-2px, -2px) scale(1.02) ",
    },
  },
  paperContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  paperHeader: { display: "flex", alignItems: "center", gap: "8px" },
  paperTitle: { fontSize: 16 },
  timeWrapper: { display: "flex", gap: "8px" },
  paperTime: { minWidth: 70, flexShrink: 0, display: "block" },
  blockText: { display: "block" },
  selected: {
    padding: "6px 16px",
    marginBottom: "10px",
    outlineWidth: 2,
    outlineColor: theme.palette.primary.main,
    outlineStyle: "solid",
    outlineOffset: 0,
  },
  groupByText: {
    position: "absolute",
    top: -2,
    left: 98,
    fontSize: 18,
    fontWeight: 600,
  },
  secondaryTail: { backgroundColor: theme.palette.secondary.main },
  [Type.FURNISHMENT]: { borderLeft: `3px solid ${colorMap[Type.FURNISHMENT]}` },
  [Type.REFURNISHMENT]: { borderLeft: `3px solid ${colorMap[Type.REFURNISHMENT]}` },
  [Type.TRADELINE]: { borderLeft: `3px solid ${colorMap[Type.TRADELINE]}` },
  floatingTooltip: { padding: 32, marginTop: "72px", top: "222px", position: "sticky" },
}));

export const Feed = ({ data }) => {
  const classes = useStyles();
  const history = useHistory();
  const [selected, setSelected] = React.useState(null);
  const groupedData = useMemo(
    () => (data?.length ? groupBy(data, (e) => e.at.split("T")[0]) : null),
    [data]
  );
  if (!data || !groupedData) return <Typography>No data</Typography>;
  return (
    <div className={classes.wrapper}>
      <Timeline className={classes.timeline}>
        {groupedData.map((e) => (
          <TimelineItem key={e[0] + "e"}>
            <TimelineSeparator>
              <div className={classes.timelineItemHeader}>
                <AvatarGroup max={3} spacing={5} className={classes.avatarGroup}>
                  {e[1].some(({ type }) => type === Type.FURNISHMENT) ? (
                    <Avatar type={Type.FURNISHMENT} />
                  ) : null}
                  {e[1].some(({ type }) => type === Type.REFURNISHMENT) ? (
                    <Avatar type={Type.REFURNISHMENT} />
                  ) : null}
                  {e[1].some(({ type }) => type === Type.TRADELINE) ? (
                    <Avatar type={Type.TRADELINE} />
                  ) : null}
                </AvatarGroup>
                <Typography
                  variant="h6"
                  color="textSecondary"
                  className={classes.groupByText}
                >
                  {moment(e[0]).format("L")}
                </Typography>
              </div>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent className={classes.timelineContent}>
              {e[1].map((e1) => {
                const key = e1.url + e1.event + e1.type + e1.at;
                return (
                  <Paper
                    key={key}
                    elevation={3}
                    className={key === selected?.id ? classes.selected : classes.paper}
                    onClick={() => {
                      setSelected({ id: key, data: e1.metadata, url: e1.url });
                    }}
                  >
                    <div className={classes.paperContent}>
                      <div className={classes.paperHeader}>
                        {e1.type === Type.REFURNISHMENT ? (
                          <Avatar type={Type.REFURNISHMENT} />
                        ) : e1.type === Type.FURNISHMENT ? (
                          <Avatar type={Type.FURNISHMENT} />
                        ) : (
                          <Avatar type={Type.TRADELINE} />
                        )}

                        <Typography variant="subtitle2" color="textSecondary">
                          {e1.event}
                        </Typography>
                      </div>

                      <div className={classes.timeWrapper}>
                        {e1.metadata?.kind ? (
                          <Typography
                            variant="subtitle2"
                            className={classes.blockText}
                            color="textSecondary"
                          >
                            {e1.metadata.kind}
                          </Typography>
                        ) : null}
                        <Typography
                          variant="subtitle2"
                          color="textSecondary"
                          align="end"
                          className={classes.paperTime}
                        >
                          {moment(e1.at).format("LTS")}
                        </Typography>
                      </div>
                    </div>

                    <Typography
                      variant="h6"
                      component="h2"
                      className={classes.paperTitle}
                    >
                      {e1.title}
                    </Typography>
                  </Paper>
                );
              })}
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>

      {selected ? (
        <JSONView data={selected.data}>
          <>
            {selected?.url ? (
              <Link onClick={() => history.push(selected.url)}>{selected.url}</Link>
            ) : null}
          </>
        </JSONView>
      ) : (
        <Paper square className={classes.floatingTooltip}>
          Select card to reveal more info
        </Paper>
      )}
    </div>
  );
};
