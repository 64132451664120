import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  approved: {
    color: theme.palette.success.main,
  },
  base: {
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  rejected: {
    color: theme.palette.error.main,
  },
  started: {
    color: theme.palette.secondary.main,
  },
  archived: {
    color: theme.palette.warning.main,
  },
}));

export default function VerificationStatus({ status, label = "Status" }) {
  const classes = useStyles();
  return (
    <span>
      {label}
      <span
        className={clsx(classes.base, {
          [classes.submitted]: status === "submitted" || status === "pending",
          [classes.approved]: status === "approved" || status === "verified",
          [classes.started]: status === "started",
          [classes.rejected]: status === "rejected",
        })}
      >
        {status}
      </span>
    </span>
  );
}
