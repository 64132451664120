import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { colorMap, Type } from "../constants";

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 24,
    width: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    fontWeight: 600,
    borderRadius: "50%",
  },
}));

export const Avatar = ({ type }) => {
  const classes = useStyles();
  return (
    <div className={classes.avatar} style={{ backgroundColor: colorMap[type] }}>
      {type === Type.REFURNISHMENT ? "R" : type === Type.FURNISHMENT ? "F" : "T"}
    </div>
  );
};
